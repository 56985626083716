import { FlexBox, Image, TextField, theme } from '@rario/shared-components'
import { SIDENAV_BOTTOM_TRUSTED } from 'components/Header/MenuListData'
import { SidenavBottomType } from 'interfaces'

const TrustedBy: React.FunctionComponent<{}> = () => {
  return (
    <FlexBox flexDirection={'column'}>
      <TextField
        fontSize={'12px'}
        lineHeight={'16px'}
        fontWeight="400"
        fontFamily={theme.fonts.primary}
        letterSpacing={theme.letterSpacings.primary}
        opacity={0.6}
        pt={30}
        textAlign="center"
      >
        Trusted By
      </TextField>
      <FlexBox
        width={'80%'}
        m="auto"
        justifyContent={'space-around'}
        flexDirection={'row'}
        pt={20}
        alignItems="center"
        height="60px"
      >
        {SIDENAV_BOTTOM_TRUSTED.map(({ name = '', logo, width, height }: SidenavBottomType) => {
          return <Image key={name} src={logo} name={name} height={height} width={width} />
        })}
      </FlexBox>
      <FlexBox
        width={'80%'}
        m="auto"
        justifyContent={'center'}
        flexDirection={'row'}
        pt={20}
        alignItems="center"
      >
        <Image
          src={'https://assets.rario.com/RARIO/App/images/menu/sheild.png'}
          name={'sheild'}
          height={15}
          width={15}
        />
        <TextField
          fontSize={'10px'}
          lineHeight={'14px'}
          fontWeight="400"
          opacity={0.6}
          pb={0}
          pl={'5px'}
        >
          100% Secure
        </TextField>
      </FlexBox>
    </FlexBox>
  )
}

export default TrustedBy
