import { NextComponentType } from 'next'
import { FlexBox, theme, TextField, Button } from '@rario/shared-components'
import HomeNavIcon from 'assets/svg/HomeNavIcon'
import Error500Banner from 'assets/svg/Error500Banner'
import Head from 'next/head'
import { useRouter } from 'next/router'

const ErrorInternalServer: NextComponentType = () => {
  const { push } = useRouter()
  return (
    <>
      <Head>
        <title>RARIO | Officially Licensed Cricket Digital Player Cards Platform</title>
        <meta
          name="description"
          content="World's first officially licensed digital collectibles platform for cricket enables fans to buy, sell & trade cricket Digital Player Cards of their favorite cricket players and cricket leagues!"
        />
      </Head>
      <FlexBox
        flexDirection="column"
        alignItems="center"
        justifyContent={'center'}
        width="100%"
        padding="0 9px"
        mt="40px"
      >
        <FlexBox width="100%" height="100%" justifyContent="center">
          <Error500Banner />
        </FlexBox>
        <TextField
          mt="34px"
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontWeight="600"
          fontSize="20px"
          lineHeight="28px"
          textAlign={'center'}
          pb="0"
        >
          Unexpected Error
        </TextField>
        <TextField
          mt="8px"
          fontWeight="400"
          fontSize="10px"
          lineHeight="14px"
          color={theme.colors.whites[8]}
          textAlign={'center'}
          pb="0"
        >
          Sorry, it seems there has been an internal error.
          <br /> Please try again later.
        </TextField>
        <Button
          variant="dynamic"
          name="GO TO HOME"
          onClick={() => push('/')}
          rightIcon={<HomeNavIcon fill={theme.colors.primaryText} />}
          fontSize={'16px'}
          mt={'21px'}
        />
      </FlexBox>
    </>
  )
}

export default ErrorInternalServer
