const AboutUsIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '22', height = '18', fill = '#FF4F44' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 16.9995H21M13.875 16.9995H19V4.19946C19 3.07936 19.0002 2.51938 18.7822 2.09155C18.5905 1.71523 18.2841 1.40925 17.9078 1.2175C17.48 0.999512 16.9203 0.999512 15.8002 0.999512H9.2002C8.08009 0.999512 7.51962 0.999512 7.0918 1.2175C6.71547 1.40925 6.40973 1.71523 6.21799 2.09155C6 2.51938 6 3.07936 6 4.19946V6.99946M14 11.3676V16.9995L3 16.9995V11.3676C3 10.8422 3 10.5795 3.063 10.3345C3.11883 10.1173 3.21073 9.91114 3.33496 9.72447C3.47513 9.51386 3.6707 9.3382 4.06152 8.98709L6.36153 6.92078C7.11753 6.24159 7.49533 5.90201 7.92269 5.77319C8.29913 5.65972 8.70071 5.65972 9.07715 5.77319C9.50451 5.90201 9.88267 6.24159 10.6387 6.92078L12.9387 8.98709C13.3295 9.3382 13.5247 9.51385 13.6649 9.72447C13.7891 9.91114 13.8812 10.1173 13.937 10.3345C14 10.5795 14 10.8422 14 11.3676Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default AboutUsIcon
