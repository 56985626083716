import { FlexBox, TextField } from '@rario/shared-components'
import ReloadIcon from 'assets/svg/ReloadIcon'
import { DEFAULT_TOAST_MESSAGE } from 'constant/toolTipMessages'

const ToastMessage: React.FunctionComponent<{ message?: string }> = ({ message }) => {
  return (
    <FlexBox alignItems="center" padding="5px 10px" width="100%" justifyContent="space-between">
      <TextField pb={0} mr="10px">
        {message ?? DEFAULT_TOAST_MESSAGE}
      </TextField>
      <FlexBox onClick={() => window.location.reload()}>
        <ReloadIcon fill="white" width="50px" />
      </FlexBox>
    </FlexBox>
  )
}

export default ToastMessage
