import { customStyled, FlexBox, ThemeType } from '@rario/shared-components'

import {
  typography,
  border,
  BorderProps,
  HeightProps,
  WidthProps,
  height,
  width,
  variant,
  background,
  BackgroundProps,
} from 'styled-system'

export type InputTypes = BorderProps &
  HeightProps &
  WidthProps &
  BackgroundProps & { variant?: string }

export const InputWrapper = customStyled(FlexBox)<InputTypes>`
  flex-direction: row;
  ${border};
  ${height};
  ${width};
  flex: 1;
  width:100%;
`

export const InputType = customStyled.input<
  InputTypes & { paddingLeft: any; maxWidth: any; theme?: ThemeType }
>`
  width: 100%;
  max-width:${(props) => props.maxWidth};
  height: auto; 
  padding-left:${(props) => props.paddingLeft};
  ${typography};
  ${border};
  color: white;
  background: transparent;
  ${({ theme }) =>
    variant({
      variants: {
        underline: {
          background: theme.colors.black,
          borderBottom: `1px solid ${theme.colors.primaryBorder}`,
        },
        outlined: {
          background: theme.colors.black,
          border: `1px solid ${theme.colors.primaryBorder}`,
        },
        noborder: {
          border: 0,
          background: theme.colors.transparent,
        },
        outlinedWithIcon: {
          background: theme.colors.black,
          border: `1px solid ${theme.colors.primaryBorder}`,
          paddingLeft: '80px',
        },
        comment: {
          background: '#181818',
          fontSize: 14,
        },
      },
    })};
  &::placeholder{
    font-size:16px;
    color:#fff;
    opacity:0.6;
    line-height:19px;
    font-family:  ${({ theme }) => theme.fonts.secondary};
  };
  @media (max-width: 768px) {
    &::placeholder{
      font-size:12px;
      font-family:  ${({ theme }) => theme.fonts.secondary};
    }
  }
  ${background};
`

InputType.defaultProps = {
  autoComplete: 'off',
}

export const Separator = customStyled.span`
  border-left: 1px solid whitesmoke;
  padding-right: 7px;
  margin-top: 4px;
  margin-bottom: 4px;
`

export const IconWrapper = customStyled.span<
  { isOpen: boolean; theme?: ThemeType } & HeightProps & WidthProps
>`
transition: 0.2s;  
transform: rotate(${({ isOpen }) => (!isOpen ? '90' : '270')}deg);
  ${height};
  ${width};
  > svg {
    fill: whitesmoke;
    ${height};
    ${width};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    > svg {
      fill: white;
      width:16px;
      height: 11px;
    } 
  }
  cursor: pointer;
  align-self: center;
`
