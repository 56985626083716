import { TextField } from '../TextField'
import { TableRow, TableWrapper, TableRowData, Drops } from './Table.style'
import { theme, FlexBox } from '@rario/shared-components'
import DropDown from 'assets/svg/DropDown'
import { useState } from 'react'

type tableData = Array<string[]>

export const Table: React.FunctionComponent<{
  tableData: tableData
  renderHeader?: boolean
  title?: string
  subTitle?: string
  isPlayerPool?: boolean
}> = ({ tableData, renderHeader = false, title, subTitle, isPlayerPool }) => {
  if (isPlayerPool && tableData.every((data) => Array.isArray([data]))) {
    const hashMap: any = {}
    tableData.forEach(function (arr) {
      // If your subArrays can be in any order, you can use .sort to have consistant order
      hashMap[arr.join('|')] = arr
    })

    const finalData = Object.keys(hashMap).map(function (k) {
      return hashMap[k]
    })
    tableData = [...finalData]
  }
  return (
    <FlexBox flexDirection="column" py={10}>
      {title && (
        <TextField
          pb={0}
          mt={'10px'}
          mb={'25px'}
          fontWeight={theme.fontWeights[5]}
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontSize={'12px'}
          lineHeight={'16px'}
          textTransform={'uppercase'}
        >
          {title}
        </TextField>
      )}
      {subTitle && (
        <TextField
          pb={0}
          mb={'25px'}
          fontWeight={theme.fontWeights[3]}
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontSize={'12px'}
          lineHeight={'16px'}
          textTransform={'capitalize'}
        >
          {subTitle}
        </TextField>
      )}
      <FlexBox overflow={'auto'}>
        <TableWrapper>
          {tableData.map((tableRows, index) => {
            if (!renderHeader && index === 0) {
              return null
            }

            const [isOpen, setIsOpen] = useState(isPlayerPool)
            return (
              <>
                <TableRow
                  key={index}
                  addBorder={index === 0 && renderHeader}
                  onClick={() => isPlayerPool && setIsOpen(!isOpen)}
                >
                  {tableRows.map((rowData) => {
                    return (
                      <>
                        <TableRowData
                          key={rowData}
                          isPlayerPool
                          isOpen={isOpen}
                          showCursor={isPlayerPool && index > 0}
                        >
                          <TextField
                            pb={0}
                            fontWeight={
                              index === 0 && renderHeader
                                ? theme.fontWeights[3]
                                : theme.fontWeights[4]
                            }
                            fontFamily={theme.fonts.secondary}
                            fontSize={index === 0 && renderHeader ? '10px' : '12px'}
                            lineHeight={index === 0 && renderHeader ? '14px' : '16px'}
                            textTransform={'capitalize'}
                            color={theme.colors.white}
                          >
                            {!isOpen && isPlayerPool && rowData.length > 5 && index > 0
                              ? rowData.slice(0, 6) + '...'
                              : ''}
                            {!isOpen && isPlayerPool && rowData.length > 5 && index > 0 && <br />}
                            {rowData}
                          </TextField>
                        </TableRowData>
                      </>
                    )
                  })}
                  {index === 0 && isPlayerPool && <TableRowData></TableRowData>}
                  {index > 0 && isPlayerPool && (
                    <TableRowData position isPlayerPool showCursor={isPlayerPool && index > 0}>
                      <Drops alignSelf={'center'} width="11px" isOpen={isOpen}>
                        <DropDown />
                      </Drops>
                    </TableRowData>
                  )}
                </TableRow>
              </>
            )
          })}
        </TableWrapper>
      </FlexBox>
    </FlexBox>
  )
}

export default Table
