import { LoaderContainer } from './Loader.style'
import { MarginProps } from 'styled-system'
import { Image, theme, FlexBox, TextField } from '@rario/shared-components'
import { getCDNUrl } from 'utils/utils'

export type LoaderVariants = 'black' | 'transparent'

const Loader: React.FunctionComponent<
  {
    height?: string
    loadtext?: string
    loadSubText?: string
    bg?: LoaderVariants
  } & MarginProps
> = ({ height = '100vh', loadtext, loadSubText, bg = 'transparent', ...restProps }) => {
  const loaderImage = getCDNUrl(`/App/images/loader/app-loader-${bg}-1.gif`)
  return (
    <LoaderContainer height={height} my={['auto', 'inherit']}>
      <FlexBox p="50px 10px" flexDirection={'column'} alignItems="center" {...restProps}>
        <Image
          src={loaderImage}
          width={`min(${height} , 250px)`}
          height={`min(${height} , 250px)`}
          name="loader"
        />

        {loadtext && (
          <TextField
            fontSize={'24px'}
            lineHeight={'34px'}
            fontWeight={'600'}
            marginTop={'30px'}
            fontFamily={theme.fonts.secondary}
            color={theme.colors.white}
          >
            {loadtext}
          </TextField>
        )}
        {loadSubText && (
          <TextField
            fontSize={'14px'}
            lineHeight={'20px'}
            fontWeight={400}
            marginTop={'10px'}
            fontFamily={theme.fonts.secondary}
            color={theme.colors.whites[7]}
          >
            {loadSubText}
          </TextField>
        )}
      </FlexBox>
    </LoaderContainer>
  )
}

export default Loader
