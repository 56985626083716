export const BurnCardInUseMessage = 'The card is in-use on D3 (In-Use).'

export const BurnCardOnSaleMessage =
  'The card is listed on marketplace. Delist before burning a card on sale.'

export const BurnCardProcessingMessage = 'The card was selected for a recent burn transaction.'

export const BurnCampaignRulesViolatingMessage =
  'You have exceeded the tier limit of maximum selection of cards to burn.'

export const BurngOnSaleListingMessage = 'The card is listed on marketplace.'

export const BurnSelectionCompleteMessage =
  'You have exceeded the limit of maximum selection of cards to burn.'
