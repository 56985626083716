import { isPlatformCR } from 'utils/platform'
import CricrushLogo from '../assets/svg/CricrushLogo'
import RarioLogo from '../assets/svg/RarioLogo'

const Logo: React.FunctionComponent<{
  width?: string
  height?: string
  isBeta?: boolean
}> = ({ width, height, isBeta = false }) => {
  return isPlatformCR() ? (
    <CricrushLogo className="cursor-pointer" width={width} height={height} />
  ) : (
    <RarioLogo isBeta={isBeta} className="cursor-pointer" width={width} height={height} />
  )
}

export default Logo
