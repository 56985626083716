import { FunctionComponent, useEffect, useState } from 'react'
import { FlexBox, TextField, theme, trackEvent } from '@rario/shared-components'
import { ShareButtonsContainer, Container } from './ShareButtons.styles'
import HeaderTelegramIcon from 'assets/svg/HeaderTelegramIcon'
import { ShowShareButtonProps } from 'interfaces'
import CopyIcon from 'assets/svg/ShareCopyIcon'
import { copyToClipboard, getAppWebViewDataFromLocalStorage } from 'utils/utils'
import TickIcon from 'assets/svg/SquareCheckIcon'
import WhatsAppIcon from 'assets/svg/WhatsAppIcon'
import HeaderTwitterIcon from 'assets/svg/HeaderTwitterIcon'
import MailIcon from 'assets/svg/MailIcon'
import { useCustomUser } from 'contexts/CustomUserProvider'
import { useRouter } from 'next/router'
import { appendHomeAsReferer } from 'utils/urlUtils'
const DEFAULT_TEXT = `You've got to check out this awesome Digital Player Card, I just found on Rario!`
const ShareButtons: FunctionComponent<ShowShareButtonProps> = ({
  showShareButtons,
  text = DEFAULT_TEXT,
  isUserProfilePage,
  ShareWrapperProps,
  isReferEarn,
}) => {
  const { user } = useCustomUser()

  const [pageURL, setPageURL] = useState('')
  const [copyText, setCopyText] = useState<string>()
  const [onCopied, setOnCopied] = useState<boolean>(false)
  const mobileAppBaseConfigData = getAppWebViewDataFromLocalStorage()
  const router = useRouter()
  const { referer } = router?.query

  useEffect(() => {
    if (!referer) {
      setPageURL(appendHomeAsReferer(window.location.href))
      return
    }
    setPageURL(window.location.href)
  }, [referer])

  const shareUrl = encodeURIComponent(pageURL)

  function copyAndTrackOnClick() {
    trackEvent('Section Interacted', 'click', {
      sub_category: 'listings-share_button',
      category: 'share',
      view_type: 'constrained',
      click_type: 'icon',
      click_action: 'copy',
    })

    !isUserProfilePage ? copyToClipboard(pageURL) : copyToClipboard(pageURL + `/${user?.username}`)
    setOnCopied(true)
    !isReferEarn && setCopyText('Copied Successfully')
    setTimeout(() => {
      setCopyText('')
      setOnCopied(false)
    }, 3000)
  }

  const handleAndTrackShareLink = (name: string, link: string) => {
    trackEvent('Section Interacted', 'click', {
      sub_category: 'listings-share_button',
      category: 'share',
      view_type: 'constrained',
      click_type: 'icon',
      click_text: name,
    })

    if (mobileAppBaseConfigData) {
      window &&
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            action: 'share_url',
            url: link,
          })
        )
    } else {
      window.open(link)
    }
  }

  const ShareIcons = [
    {
      name: 'whatsApp',
      icon: <WhatsAppIcon fill="#0088cc" width="25px" height="25px" />,
      link: `https://wa.me?text=${text}  ${shareUrl}`,
    },
    {
      name: 'Twitter',
      icon: <HeaderTwitterIcon fill="#0088cc" width="25px" height="25px" />,
      link: `https://twitter.com/intent/tweet?text=${text}  ${shareUrl}`,
    },
    {
      name: 'Telegram',
      icon: <HeaderTelegramIcon fill="#0088cc" width="25px" height="25px" />,
      link: `https://t.me/share/url?url=${shareUrl}&text=${text}`,
    },
    {
      name: 'Mail',
      icon: <MailIcon width="25px" height="25px" fill={theme.colors.black} />,
      link: `mailto:?subject=${text}&body=${text}    ${shareUrl}`,
    },
  ]

  useEffect(() => {
    if (showShareButtons) {
      trackEvent('Section Viewed', 'track', {
        sub_category: 'listings-share_button',
        category: 'share',
        view_type: 'constrained',
      })
    }
  }, [showShareButtons])

  return (
    <ShareButtonsContainer isUserProfilePage={isUserProfilePage} flexDirection="column">
      {showShareButtons && (
        <FlexBox
          className="container"
          width={'310px'}
          padding="12px 0"
          height={'40px 0px'}
          background={theme.colors.white}
          boxShadow="inset 0px -1px 0px rgba(0, 0, 0, 0.15)"
          alignItems="center"
          borderRadius="250px"
          position="absolute"
          zIndex={999}
          top="-40px"
          left={'-280px'}
          {...ShareWrapperProps}
          justifyContent="center"
          flexDirection={'column'}
        >
          <FlexBox>
            {ShareIcons.map((item, index) => {
              return (
                <Container key={index}>
                  <FlexBox
                    height="45px"
                    borderRadius="10px"
                    border="1px solid #ccc"
                    onClick={() => handleAndTrackShareLink(item.name, item.link)}
                    padding="7.5px 10px"
                    textAlign="center"
                    className="shareicon"
                  >
                    {item?.icon}
                  </FlexBox>
                </Container>
              )
            })}
            <Container>
              <FlexBox
                alignItems={'center'}
                height="45px"
                borderRadius="10px"
                border="1px solid #ccc"
                padding="7.5px 10px"
                textAlign="center"
                onClick={() => {
                  copyAndTrackOnClick()
                }}
                className="shareicon"
              >
                {onCopied ? (
                  <TickIcon height="20px" width="20px" />
                ) : (
                  <CopyIcon width="20px" height="20px" fill={theme.colors.mint} />
                )}
              </FlexBox>
            </Container>
          </FlexBox>
          {copyText && (
            <TextField
              fontSize={'14px'}
              letterSpacing="1px"
              lineHeight="16px"
              textAlign={'center'}
              position="absolute"
              top="65px"
              backgroundColor={'white'}
              borderRadius={' 0px 0px 20px 20px'}
              color="black"
              padding={'7px 12px'}
            >
              {copyText}
            </TextField>
          )}
        </FlexBox>
      )}
    </ShareButtonsContainer>
  )
}

export default ShareButtons
