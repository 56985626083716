import { IApiException } from 'api/Api.exception'
import { getUserWalletBalance } from 'api/User.api'
import { emitCustomEvent } from 'components/CustomEvents'
import { PendingKycToastMessage } from 'constant'
import { useCustomAuth } from 'contexts/CustomAuthProvider'
import { useCustomUser } from 'contexts/CustomUserProvider'
import { PaymentKycStateEnum, WalletBalanceProps, riskProfileStatusEnum } from 'interfaces'
import { useState, useEffect } from 'react'

import { trackApiErrorResponse } from 'utils/trackEventUtils'

const useUserWallet = () => {
  const { accessToken } = useCustomAuth()
  const { user } = useCustomUser()
  const [walletBalance, setWalletBalance] = useState<WalletBalanceProps | undefined>()
  const [isWalletBalanceLoading, setIsWalletBalanceLoading] = useState<boolean>(false)

  const getBalance = async (): Promise<WalletBalanceProps | undefined> => {
    try {
      setIsWalletBalanceLoading(true)
      const { data } = await getUserWalletBalance(accessToken)
      return data
    } catch (e) {
      if (!user?.isPhoneVerified) return

      const error = e as IApiException
      const errorResponse = error.response
        ? error.response
        : {
            message:
              user?.kycStatus !== PaymentKycStateEnum.SUCCESS
                ? PendingKycToastMessage
                : error.message,
          }
      emitCustomEvent('errorCodes', errorResponse)
      trackApiErrorResponse(error, {
        subCategory: 'wallet-get_wallet_balance_api_error_response',
        category: 'wallet',
      })
    } finally {
      setIsWalletBalanceLoading(false)
    }
  }

  useEffect(() => {
    if (accessToken && user?.riskProfileStatus !== riskProfileStatusEnum.BLACKLIST) {
      getBalance().then((res) => {
        setWalletBalance(res)
      })
    }
  }, [accessToken])

  return { walletBalance, isWalletBalanceLoading }
}

export default useUserWallet
