import customStyled from '../../theme/customStyled'
import { ThemeType } from '../../theme'

export const Wrapper = customStyled.div<{ theme?: ThemeType; disabled?: boolean }>`
    float: left;
    width: 100%;
    height: 45px;
    position: relative;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;  

    & .ReactSwipeButton * {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none;   /* Chrome/Safari/Opera */
        -khtml-user-select: none;    /* Konqueror */
        -moz-user-select: none;      /* Firefox */
        -ms-user-select: none;       /* Internet Explorer/Edge */
        user-select: none;  
    }
    & .right-twisted-edge-container {
        background: linear-gradient(225deg, transparent 7px, ${({ theme, disabled }) =>
          disabled ? '#4F5358' : theme.colors.white} 7px);
    }
    & .left-twisted-edge-container {
        background: linear-gradient(45deg, transparent 7px, ${({ theme, disabled }) =>
          disabled ? '#4F5358' : theme.colors.white} 7px);
    }
`

export const SliderContainer = customStyled.div<{ theme?: ThemeType; disabled?: boolean }>`
    float: left;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    & .rsbContainerUnlocked {
        width: 50%!important;
        margin-left: 25%;
        transition: 0.5s;
        cursor: default;
    }

    & .rsbContainerUnlocked .rsbcSlider{
        left: 100%!important;
        cursor: default;
        pointer-events: none;
    }

    & .rsbContainerUnlocked .rsbcSliderArrow {
        transition: 0.5s;
        margin-right: -60px;
    }

    & .rsbContainerUnlocked .rsbcSliderCircle {
        transition: 0.5s;
        margin-right: -60px;
    }

    & .right-twisted-edge-button {
        background: linear-gradient(225deg, transparent 7px, ${({ theme, disabled }) =>
          disabled ? '#9B9D9F' : theme.colors.white} 7px);
    }
    & .left-twisted-edge-button {
        background: linear-gradient(45deg, transparent 7px, ${({ theme, disabled }) =>
          disabled ? '#9B9D9F' : theme.colors.white} 7px);
    }

`

export const SlidingButtonContainer = customStyled.div<{ unlocked: boolean }>`
    float: left;
    width: 100%;
    position: absolute;
    height: 42px;
    top: 2px;
    left: 50px;
    margin-left:-97%;
    margin-left: ${({ unlocked }) => (unlocked ? '-102%' : '-97%')} ;
    border-radius: 25px;
    z-index: 100;
    cursor: pointer;
`

export const SliderArrow = customStyled.span`
    float: left;
    position: absolute;
    height: 8px;
    width: 8px;
    top:50%;
    right: 35px;
    margin-top: -6px;
    z-index: 10;
`

export const SliderButton = customStyled.span<{ theme?: ThemeType }>`
    position: absolute;
    right: 0;
    background: transparent;
    top:0;
    height: 32px;
    width: 50px;
    margin: 5px;
`

export const ContainerText = customStyled.div<{ theme?: ThemeType; disabled?: boolean }>`
    float: left;
    position: absolute;
    top:0;
    left:0;
    right:0;
    height: 42px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 2px;
    font-size: 14px;
    color: ${({ theme, disabled }) => (disabled ? '#686B70' : theme.colors.white)};
    font-family: ${({ theme }) => theme.fonts.primary};
    letter-spacing: ${({ theme }) => theme.letterSpacings.primary};
    font-weight: 600;
`
