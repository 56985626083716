const CurrencyIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ width = '24', height = '24', className = '', fill = '#FF4F44' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.75 12C15.75 12.7417 15.5301 13.4667 15.118 14.0834C14.706 14.7001 14.1203 15.1807 13.4351 15.4645C12.7498 15.7484 11.9958 15.8226 11.2684 15.6779C10.541 15.5333 9.8728 15.1761 9.34835 14.6517C8.8239 14.1272 8.46675 13.459 8.32205 12.7316C8.17736 12.0042 8.25162 11.2502 8.53545 10.5649C8.81928 9.87971 9.29993 9.29404 9.91661 8.88199C10.5333 8.46993 11.2583 8.25 12 8.25C12.9946 8.25 13.9484 8.64509 14.6517 9.34835C15.3549 10.0516 15.75 11.0054 15.75 12ZM23.25 6V18C23.25 18.1989 23.171 18.3897 23.0303 18.5303C22.8897 18.671 22.6989 18.75 22.5 18.75H1.5C1.30109 18.75 1.11032 18.671 0.96967 18.5303C0.829018 18.3897 0.75 18.1989 0.75 18V6C0.75 5.80109 0.829018 5.61032 0.96967 5.46967C1.11032 5.32902 1.30109 5.25 1.5 5.25H22.5C22.6989 5.25 22.8897 5.32902 23.0303 5.46967C23.171 5.61032 23.25 5.80109 23.25 6ZM21.75 10.3453C20.8984 10.0935 20.1233 9.63263 19.4953 9.00467C18.8674 8.37671 18.4065 7.60162 18.1547 6.75H5.84531C5.5935 7.60162 5.13263 8.37671 4.50467 9.00467C3.87671 9.63263 3.10162 10.0935 2.25 10.3453V13.6547C3.10162 13.9065 3.87671 14.3674 4.50467 14.9953C5.13263 15.6233 5.5935 16.3984 5.84531 17.25H18.1547C18.4065 16.3984 18.8674 15.6233 19.4953 14.9953C20.1233 14.3674 20.8984 13.9065 21.75 13.6547V10.3453Z"
      fill={fill}
    />
  </svg>
)

export default CurrencyIcon
