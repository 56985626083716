import { getD3TrialWidgetStatus, getGrootUser } from 'api/User.api'
import { CustomUserContext } from 'contexts/CustomUserContext'
import {
  PaymentKycStateEnum,
  UserProfileProps,
  MetaDataProps,
  UserBillingDetailsProps,
  riskProfileStatusEnum,
} from 'interfaces'
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useCustomAuth } from './CustomAuthProvider'

export const useCustomUser = () => useContext(CustomUserContext)

const CustomUserProvider = ({ children }: { children: ReactNode }) => {
  const { accessToken, isAuthLoading } = useCustomAuth()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error>()
  const [user, setUser] = useState<UserProfileProps | undefined>(undefined)
  const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(false)
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false)
  const [firstName, setFirstName] = useState<string | undefined>()
  const [userCohort, setUserCohort] = useState<string | undefined>()
  const [lastName, setLastName] = useState<string | undefined>()
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>()
  const [phoneIsoCode, setPhoneIsoCode] = useState<string | undefined>()
  const [emailAddress, setEmailAddress] = useState<string | undefined>()
  const [kycStatus, setKycStatus] = useState<PaymentKycStateEnum | undefined>()
  const [riskProfileStatus, setRiskProfileStatus] = useState<riskProfileStatusEnum | undefined>()
  const [postalCode, setPostalCode] = useState<string | undefined>()
  const [country, setCountry] = useState<string | undefined>()
  const [username, setUsername] = useState<string | undefined>()

  const [cohortDataLoading, setCohortDataLoading] = useState(false)

  const getWidgetStatus = async () => {
    try {
      setCohortDataLoading(true)
      const { data } = await getD3TrialWidgetStatus(accessToken)
      if (data) {
        const cohortData = {
          isTrialEnabled: data?.getCohort?.isTrialEnabled,
          hasPlayedTrialOnce: !!data?.getCohort?.lastPlayedTrialTimestamp,
          userCohortName: data?.getCohort?.cohortName,
        }
        user && setUser({ ...user, ...cohortData })
        setCohortDataLoading(false)
      }
    } catch {
      setCohortDataLoading(false)
    }
  }

  const getUserData = async () => {
    try {
      const { data } = await getGrootUser(accessToken)
      if (data) {
        isEmailVerified && setIsEmailVerified(isEmailVerified)
        isEmailVerified ? setUser({ ...data, isEmailVerified }) : setUser(data)
        setIsLoading(false)
      }
    } catch (error: any) {
      console.log('customUserProvider getGrootUser error: ', error)
      setError(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (accessToken) {
      getUserData()
    }
  }, [accessToken])

  useEffect(() => {
    if (!accessToken && !isAuthLoading) {
      setIsLoading(false)
    }
  }, [isAuthLoading])

  useEffect(() => {
    if (user?.isPhoneVerified && accessToken) {
      getWidgetStatus()
    }
  }, [accessToken, user?.isPhoneVerified])

  useEffect(() => {
    if (user && isPhoneVerified) {
      setUser({ ...user, isPhoneVerified })
    }
  }, [isPhoneVerified])

  useEffect(() => {
    if (user && isEmailVerified) {
      setUser({ ...user, isEmailVerified })
    }
  }, [isEmailVerified])

  useEffect(() => {
    if (user && username) {
      setUser({ ...user, username })
    }
  }, [username])

  useEffect(() => {
    if (user && phoneNumber && phoneIsoCode) {
      setUser({ ...user, phoneNumber, phoneIsoCode })
    }
  }, [phoneIsoCode, phoneNumber])

  useEffect(() => {
    if (user && firstName && lastName) {
      setUser({
        ...user,
        profile: {
          firstName,
          lastName,
          dateOfBirth: user?.profile?.dateOfBirth,
          issuingCountry: user?.profile?.issuingCountry,
        },
      })
    }
  }, [firstName, lastName])

  useEffect(() => {
    if (user) {
      const rnWewviewData = {
        userId: user?.userId,
        email: user?.email,
        phone: user?.phoneNumber,
      }
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ action: 'userData', data: JSON.stringify(rnWewviewData) })
      )
    }
  }, [user])

  useEffect(() => {
    if (user && emailAddress) {
      setUser({ ...user, email: emailAddress || '' })
    }
  }, [emailAddress])

  useEffect(() => {
    if (user && kycStatus) {
      setUser({ ...user, kycStatus })
    }
  }, [kycStatus])

  useEffect(() => {
    if (user && userCohort) {
      setUser({
        ...user,
        metadata: {
          ...user?.metadata,
          cohorts: [userCohort],
        } as MetaDataProps,
      })
    }
  }, [userCohort])

  useEffect(() => {
    if (user) {
      setUser({
        ...user,
        billingDetails: { ...user.billingDetails, postalCode } as UserBillingDetailsProps,
      })
    }
  }, [postalCode])

  useEffect(() => {
    if (user) {
      setUser({
        ...user,
        billingDetails: { ...user.billingDetails, country } as UserBillingDetailsProps,
      })
    }
  }, [country])

  useEffect(() => {
    if (user) {
      setUser({ ...user, riskProfileStatus })
    }
  }, [riskProfileStatus])

  const value = useMemo(
    () => ({
      user,
      isLoading,
      setIsPhoneVerified,
      setIsEmailVerified,
      error,
      setIsLoading,
      setFirstName,
      setLastName,
      setPhoneNumber,
      setUserCohort,
      setPostalCode,
      setCountry,
      setEmailAddress,
      setPhoneIsoCode,
      setUsername,
      cohortDataLoading,
      setKycStatus,
      setRiskProfileStatus,
    }),
    [
      user,
      isLoading,
      setIsPhoneVerified,
      setIsEmailVerified,
      userCohort,
      postalCode,
      country,
      error,
      setIsLoading,
      cohortDataLoading,
      isAuthLoading,
      setKycStatus,
      setRiskProfileStatus,
      setUsername,
    ]
  )

  return <CustomUserContext.Provider value={value}>{children}</CustomUserContext.Provider>
}

export default CustomUserProvider
