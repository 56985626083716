const GridMenuIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  opacity?: number
}> = ({ className = '', width = '20', height = '20', opacity = 0.7 }) => (
  <svg
    className={className}
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 15.75C15 16.4404 15.5596 17 16.25 17C16.9404 17 17.5 16.4404 17.5 15.75C17.5 15.0596 16.9404 14.5 16.25 14.5C15.5596 14.5 15 15.0596 15 15.75Z"
      stroke="#E6E6E7"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 15.75C7.5 16.4404 8.05964 17 8.75 17C9.44036 17 10 16.4404 10 15.75C10 15.0596 9.44036 14.5 8.75 14.5C8.05964 14.5 7.5 15.0596 7.5 15.75Z"
      stroke="#E6E6E7"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 8.25C15 8.94036 15.5596 9.5 16.25 9.5C16.9404 9.5 17.5 8.94036 17.5 8.25C17.5 7.55964 16.9404 7 16.25 7C15.5596 7 15 7.55964 15 8.25Z"
      stroke="#E6E6E7"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 8.25C7.5 8.94036 8.05964 9.5 8.75 9.5C9.44036 9.5 10 8.94036 10 8.25C10 7.55964 9.44036 7 8.75 7C8.05964 7 7.5 7.55964 7.5 8.25Z"
      stroke="#E6E6E7"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default GridMenuIcon
