const CommunityNavIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  isActive?: boolean
  fill?: string
  opacity?: number
}> = ({
  className = '',
  width = '25',
  height = '24',
  isActive = false,
  fill = 'white',
  opacity = 1,
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={opacity}>
      <path
        d="M7.51025 20.3018C8.83174 21.0639 10.365 21.5 12.0001 21.5C16.9707 21.5 21 17.4706 21 12.5C21 7.52944 16.9706 3.5 12 3.5C7.02944 3.5 3 7.52944 3 12.5C3 14.1351 3.43604 15.6684 4.19819 16.9899L4.19883 16.991C4.2737 17.1208 4.31123 17.1859 4.32816 17.2471C4.34396 17.3043 4.34918 17.3553 4.34513 17.4144C4.34084 17.4772 4.31914 17.5423 4.27629 17.6708L4.27468 17.6757L3.50586 19.9821L3.50526 19.9839C3.34281 20.4713 3.26154 20.7151 3.3194 20.8773C3.36981 21.0187 3.4812 21.13 3.62256 21.1804C3.785 21.2383 4.02953 21.1569 4.51807 20.994L6.82454 20.2252L6.82611 20.2247C6.9568 20.1811 7.02212 20.1594 7.08542 20.155C7.14458 20.151 7.19578 20.1561 7.25293 20.1719C7.31434 20.1888 7.3797 20.2265 7.51025 20.3018Z"
        fill="url(#paint0_linear_1308_4813)"
        fillOpacity="0.25"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    {isActive && (
      <defs>
        <linearGradient
          id="paint0_linear_1308_4813"
          x1="12"
          y1="23.1521"
          x2="11.4608"
          y2="4.97781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill} />
          <stop offset="1" stopColor={fill} stopOpacity="0" />
        </linearGradient>
      </defs>
    )}
  </svg>
)

export default CommunityNavIcon
