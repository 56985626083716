export type MouseEventType = (e: React.MouseEvent<HTMLElement>) => void

export type StylePropertyValue = number | string | number[] | string[] | (string | null)[]

export enum ThemeEnum {
  // eslint-disable-next-line no-unused-vars
  RARIO = 'RARIO',
  // eslint-disable-next-line no-unused-vars
  CR = 'CR',
  // eslint-disable-next-line no-unused-vars
  D3 = 'D3',
  // eslint-disable-next-line no-unused-vars
  WEBVIEW_APP = 'WEBVIEW_APP',
}

export type SortByItem = { label: string; value: string; isRefined: boolean }

export interface SortByProps {
  onSelect?(selectedIndex: string, name: string): void
  items: SortByItem[]
  selectedValue: string
  disableFilter: boolean
}
export type CloudinaryImageProps = {
  src: string
  height?: number
  width?: number
  format?: string
  quality?: string
  scale?: boolean
  fill?: boolean
  fitAvailableSpace?: boolean
}
export default {}
