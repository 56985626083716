const DownArrowIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
  opacity?: number
}> = ({ className = '', width = '17', height = '9', fill = 'white', opacity = 1 }) => (
  <svg
    className={className}
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 17 9"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5312 2.57812L9.57812 8.57812C9.25 8.85938 8.875 9 8.5 9C8.07812 9 7.70312 8.85938 7.42188 8.57812L1.46875 2.57812C1 2.15625 0.859375 1.5 1.09375 0.9375C1.32812 0.375 1.89062 0 2.5 0H14.4531C15.0625 0 15.5781 0.375 15.8125 0.9375C16.0469 1.5 15.9531 2.15625 15.5312 2.57812Z"
      fill={fill}
    />
  </svg>
)

export default DownArrowIcon
