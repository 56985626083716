const DropDown: React.FunctionComponent<{
  className?: string
  fill?: string
  height?: number
  width?: number
}> = ({ className = '', fill = '#fff', width = 11, height = 6 }) => (
  <svg
    width={width}
    height={height}
    viewBox={'0 0 ' + width + ' ' + height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.628906 4.81641L4.78516 0.90625C4.92188 0.769531 5.08594 0.6875 5.25 0.6875C5.38672 0.6875 5.55078 0.769531 5.6875 0.878906L9.84375 4.78906C10.1172 5.03516 10.1172 5.44531 9.87109 5.71875C9.625 5.99219 9.21484 5.99219 8.94141 5.74609L5.22266 2.24609L1.53125 5.74609C1.25781 5.99219 0.847656 5.99219 0.601562 5.71875C0.355469 5.47266 0.355469 5.0625 0.628906 4.81641Z"
      fill={fill}
      fillOpacity="0.8"
    />
  </svg>
)
export default DropDown
