import { WITHDRAW_WORKING_DAY } from './withdrawal'

export const CVV_MESSAGE = '3 digit card verification code on the back of your card'
export const FEE_MESSAGE =
  'There could be a slight difference in the actual fee charged by the Payment Gateway.'
export const DEFAULT_TOAST_MESSAGE =
  'Oops! Something went wrong. We encountered an error while processing this request. Please reload the page and continue.'
export const SERVICE_FEE_MESSAGE =
  '1 Withdrawal in a month is free. The 25$ transaction fee charged by banks/ payment partners is borne by Rario.'
export const TRANSACTION_MESSAGE = `Being an international transaction, the amount may take ${WITHDRAW_WORKING_DAY} working days to reach your bank account.`
export const COUNTRY_CHANGE_MESSAGE = 'You can change the country in the previous step.'
export const BONUS_MESSAGE =
  'Just a little something extra from our side ; ) You can even use a limited percentage of this amount for each of your purchases.'
export const PACK_PURCHASE_SERVICE_FEE_MESSAGE =
  'Equalisation levy is a tax charged by government for foreign ecommerce players selling goods in India'
export const ADD_BANK_ACCOUNT_TOAST_MESSAGE =
  'Account details have been submitted. It may take up to 24 hours to verify your account.'
