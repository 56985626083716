import { FlexBox, TextField, theme } from '@rario/shared-components'
import CurrencyIcon from 'assets/svg/CurrencyIcon'
import { IconWrapperSquare } from 'components/Header/Header.styles'
import CurrencyToggle from './CurrencyToggle'
import { ThemeEnum } from 'interfaces'
import { PLATFORM_ORIGIN } from 'config'

export const MenuCurrencyToggle = () => {
  return (
    <FlexBox py={'12.5px'}>
      <IconWrapperSquare>
        <CurrencyIcon fill={theme.colors.secondary} />
      </IconWrapperSquare>
      <FlexBox flexDirection={'column'} pl="10px" width={'80%'}>
        <TextField
          fontSize={16}
          lineHeight={'22px'}
          fontWeight="600"
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          highlightColor={theme.colors.secondary}
        >
          Preferred Currency
        </TextField>
        <TextField fontSize={'12px'} lineHeight={'16px'} fontWeight="400" opacity={0.6}>
          {PLATFORM_ORIGIN === ThemeEnum.RARIO ? 'Rario App' : 'CricRush'} in your currency
        </TextField>
      </FlexBox>
      <CurrencyToggle source="more_menu" />
    </FlexBox>
  )
}
