import { D3LogoLink } from 'constant'

const LockIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
  isD3?: boolean
}> = ({ className = '', width = '19', height = '30', fill = '#36B286', isD3 }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00391 15.8V10.2C1.00391 9.07985 1.00391 8.51987 1.22189 8.09204C1.41364 7.71572 1.71938 7.40973 2.0957 7.21799C2.52353 7 3.084 7 4.2041 7H13.8041C14.9242 7 15.4839 7 15.9117 7.21799C16.288 7.40973 16.5944 7.71572 16.7861 8.09204C17.0041 8.51987 17.0039 9.07985 17.0039 10.2V15.8C17.0039 16.9201 17.0041 17.4802 16.7861 17.908C16.5944 18.2844 16.288 18.5902 15.9117 18.782C15.4839 19 14.9242 19 13.8041 19H4.2041C3.084 19 2.52353 19 2.0957 18.782C1.71938 18.5902 1.41364 18.2844 1.22189 17.908C1.00391 17.4802 1.00391 16.9201 1.00391 15.8ZM6.00391 6.76923V4C6.00391 2.34315 7.34705 1 9.00391 1C10.6608 1 12.0039 2.34315 12.0039 4V6.76923C12.0039 6.89668 11.9003 7 11.7728 7H6.23438C6.10693 7 6.00391 6.89668 6.00391 6.76923Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    {isD3 && <image width="15" height="15" y="12" x="1" xlinkHref={D3LogoLink} />}
  </svg>
)

export default LockIcon
