import { useState } from 'react'
import { GridContainer, MainVideo } from './CustomVideo.styles'
import Video from '../Video/Video'
import UnMute from 'assets/svg/UnMute'
import Mute from 'assets/svg/Mute'
import { StylePropertyValue } from 'interfaces'
import PlayIcon from 'assets/svg/PlayIcon'
import PauseIcon from 'assets/svg/PauseIcon'
import { TextField, FlexBox } from '@rario/shared-components'

export type MediaType = { src: string | string[]; poster?: string }

interface CustomVideoProps {
  media: MediaType
  mediaType?: string | string[]
  onClick?: () => void
  onEnd?: () => void
  play?: boolean
  showManualControls?: boolean
  height?: StylePropertyValue
  width?: StylePropertyValue
  controlsPositionBottom?: StylePropertyValue
  controlsPositionLeft?: StylePropertyValue
  isMute?: boolean
  loop?: boolean
  objectFit?: string
}

export const CustomVideo: React.FunctionComponent<CustomVideoProps> = ({
  media,
  mediaType,
  onClick,
  onEnd,
  play = true,
  showManualControls = false,
  height = ['310px', null, null, '460px'],
  width = '100%',
  controlsPositionBottom = '10px',
  controlsPositionLeft = 'none',
  isMute = true,
  loop = true,
  objectFit,
}) => {
  const [mute, setMuteState] = useState<boolean>(isMute)
  const [isPlaying, setIsPlaying] = useState<boolean>(play)

  return (
    <GridContainer>
      <MainVideo overflow="hidden" flexDirection="column">
        <FlexBox flexDirection="column" justifyContent="space-around" alignItems="center">
          <Video
            src={media.src}
            mediaType={mediaType}
            controls={false}
            width={width}
            height={height}
            loop={loop}
            poster={media.poster}
            autoPlay
            muted={mute}
            play={isPlaying}
            onClick={onClick}
            onEnd={onEnd}
            playsinline
            objectFit={objectFit}
          />

          {showManualControls && (
            <FlexBox
              position="absolute"
              zIndex={99}
              bottom={controlsPositionBottom}
              left={controlsPositionLeft}
              alignItems={'center'}
              padding={'10px'}
              pb="5px"
              borderRadius={'20px'}
              background={'#00000033'}
            >
              <FlexBox cursor="pointer" alignItems={'center'}>
                <TextField
                  as="span"
                  onClick={(event) => {
                    event.stopPropagation()
                    setMuteState((currentState) => !currentState)
                  }}
                  pb="0px"
                  width={'24px'}
                >
                  {mute ? <Mute /> : <UnMute />}
                </TextField>
              </FlexBox>
              <FlexBox onClick={() => setIsPlaying(!isPlaying)} ml="15px" cursor="pointer" pb="4px">
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </FlexBox>
            </FlexBox>
          )}
        </FlexBox>
      </MainVideo>
    </GridContainer>
  )
}

export default CustomVideo
