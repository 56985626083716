import API_CONSTANTS from './constants'
import { doPost, doGet, doPatch } from 'api'
import { PreferencesValuesType, UserBillingDetailsProps, userLogActivityType } from 'interfaces'
import { getHeaders } from 'utils/utils'

type UpdateProfileFormDataProps = {
  firstName?: string
  lastName?: string
  title?: string
  username?: string
  dateOfBirth?: string
  nationalityCountry?: string
  billingDetails?: UserBillingDetailsProps
}

export const onContactUs = (formdata: any) => {
  const url = 'https://rario.freshdesk.com/api/v2/tickets'
  return doPost(
    url,
    { isAbsUrl: true, errorEvent: 'contactus' },
    {
      headers: {
        Authorization: `Basic ${process.env.FRESHDESK_BEARER_TOKEN}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formdata),
    }
  )
}

export const patchUserPrefrences = (
  name: string,
  data: PreferencesValuesType[] | boolean,
  accessToken: string
) => {
  return doPatch(
    API_CONSTANTS.USER_PREFERENCES,
    {},
    {
      headers: getHeaders(accessToken),
      body: JSON.stringify({
        name,
        value: data,
      }),
    }
  )
}

export const getUserPrefrences = (accessToken: string) => {
  return doGet(API_CONSTANTS.USER_PREFERENCES, {}, { headers: getHeaders(accessToken) })
}

// Public API
export const getUserById = (id: string) => {
  return doGet(API_CONSTANTS.GET_USER_BY_ID, { id }, { headers: getHeaders() })
}

export const getGrootUser = (accessToken: string) => {
  return doGet(API_CONSTANTS.GET_USER, {}, { headers: getHeaders(accessToken) })
}

export const getUserWalletBalance = (accessToken: string) => {
  return doGet(API_CONSTANTS.GET_USER_WALLET_BALANCE, {}, { headers: getHeaders(accessToken) })
}

export const getTransactionHistoryData = (
  page: number,
  accessToken: string,
  filterParams?: string
) => {
  return doGet(
    API_CONSTANTS.GET_USER_TRANSACTION_HISTORY + `?page=${page}&limit=10` + filterParams,
    {},
    { headers: getHeaders(accessToken) }
  )
}

export const getSalesOrderHistoryData = (
  page: number,
  accessToken: string,
  filterParamPath?: string
) => {
  return doGet(
    API_CONSTANTS.GET_USER_SALES_ORDER_HISTORY +
      `${filterParamPath ? '/' + filterParamPath : ''}` +
      `?page=${page}&limit=10`,
    {},
    {
      headers: getHeaders(accessToken),
    }
  )
}

export const updateUsersProfile = (formData: UpdateProfileFormDataProps, accessToken: string) => {
  return doPatch(
    API_CONSTANTS.PATCH_USER,
    {},
    { headers: getHeaders(accessToken), body: JSON.stringify(formData) }
  )
}

export const updateUsersBillingDetails = (
  data: { country: string; postalCode: string },
  accessToken: string
) => {
  return doPatch(
    API_CONSTANTS.PATCH_USER_BILLING_DETAILS,
    {},
    { headers: getHeaders(accessToken), body: JSON.stringify({ billingDetails: data }) }
  )
}

export const updateUsersSocial = (socialProfile: Object, accessToken: string) => {
  return doPatch(
    API_CONSTANTS.GET_USER,
    {},
    {
      headers: getHeaders(accessToken),
      body: JSON.stringify({
        socialProfile,
      }),
    }
  )
}

// Public API
export const geoVpnCheck = (ip: string) => {
  return doGet(API_CONSTANTS.GEO_CHECK, { ip }, { headers: getHeaders() })
}

// Public API
export const getLatestSale = () => {
  return doGet(API_CONSTANTS.GET_LATEST_SALES, {}, { headers: getHeaders() })
}

// Public API
export const getDestinationExchangeRate = (currency: string) => {
  return doGet(
    API_CONSTANTS.EXCHANGE_RATE + `/${currency}`,
    {},
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  )
}

// export const getCMSPageContents = (pageId: string) => {
//   return doGet(
//     API_CONSTANTS.GET_CMS_PAGE_CONTENTS,
//     { pageId },
//     {
//       headers: {
//         locale: 'en-US',
//         'content-type': 'application/json',
//       },
//     }
//   )
// }

// export const getLatestSale = () => {
//   return doGet(API_CONSTANTS.GET_LATEST_SALES, {
//     headers: {
//       'content-type': 'application/json',
//     },
//   })
// }

export const getSuccessReferalData = (page: number, accessToken: string) => {
  return doGet(
    API_CONSTANTS.GET_SUCCESS_REFERAL_DATA,
    { page },
    { headers: getHeaders(accessToken) }
  )
}

export const createReferralUrl = (accessToken: string) => {
  return doGet(API_CONSTANTS.POST_CREATE_REFFERAL_DATA, {}, { headers: getHeaders(accessToken) })
}

export const getReferreeData = (accessToken: string, isRefereeHasOrders: boolean) => {
  return doGet(
    API_CONSTANTS.USER_REFERRAL_DATA,
    { isRefereeHasOrders },
    { headers: getHeaders(accessToken) }
  )
}

export const getReferralCount = (accessToken: string) => {
  return doGet(API_CONSTANTS.GET_REFERRAL_COUNT, {}, { headers: getHeaders(accessToken) })
}

export const getUserActivityData = (
  page: number,
  accessToken: string,
  facetParams?: string,
  searchKeyword?: string,
  rangeParams?: string
) => {
  return doGet(
    API_CONSTANTS.GET_USER_ACTIVITY + facetParams + searchKeyword + rangeParams,
    { page },
    { headers: getHeaders(accessToken) }
  )
}

// X-Api-Key
export const logUserActivity = (useAgent: string, formData: userLogActivityType) => {
  return doPost(
    API_CONSTANTS.ON_USER_LOGOUT,
    {},
    {
      headers: {
        'user-agent': useAgent,
        'x-api-key': process.env.USER_ACTIVITY_KEY,
        'content-type': 'application/json',
      },
      body: JSON.stringify(formData),
    }
  )
}

export const mergeUserOnD3 = (accessToken: string) => {
  return doPost(
    API_CONSTANTS.GAME_API_D3,
    {},
    {
      headers: getHeaders(accessToken),
      body: JSON.stringify({
        operationName: 'Me',
        variables: {},
        query:
          'query Me {\n  me {\n    userId\n    emailVerified\n    utmParams {\n      source\n      medium\n      campaign\n    }\n    country\n    currency\n    conversionRate\n  }\n}',
      }),
    }
  )
}

// Public API
export const getContests = () => {
  return doGet(
    API_CONSTANTS.GET_CONTESTS,
    { isAbsUrl: true },
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  )
}

export const getUpcomingMatches = () => {
  return doPost(
    API_CONSTANTS.GET_UPCOMING_MATCHES,
    {},
    {
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        operationName: 'Query',
        variables: {},
        query:
          'query Query {\n  homeTours {\n    id\n    name\n    logoUrl\n    rounds {\n      id\n      dream11RoundId\n      isLegendAllowed\n      name\n      matchInfo {\n        id\n        homeSquad {\n          id\n          fullName\n          shortName\n          flagUrl\n          rarioSquadShortName\n        }\n        awaySquad {\n          id\n          fullName\n          shortName\n          flagUrl\n          rarioSquadShortName\n        }\n      }\n      winnings {\n        nft\n        prizePool\n        currency\n        marketingCreditsPrizePool\n      }\n      status\n      roundStartTime\n      lineupAnnounced\n      roundPriority\n    }\n    totalRounds\n    logoUrl\n  }\n}',
      }),
    }
  )
}

// @deprecated
export const getMyMatches = () => {
  return doPost(
    API_CONSTANTS.GAME_API_D3,
    {},
    {
      headers: getHeaders(),
      body: JSON.stringify({
        operationName: 'MyMatchesInfo',
        variables: { page: 1 },
        query:
          'query MyMatchesInfo($page: Int!, $roundId: Int, $roundStartTime: String) {\n  myMatchesInfo(page: $page, roundId: $roundId, roundStartTime: $roundStartTime) {\n    matches {\n      round {\n        id\n        dream11RoundId\n        name\n        matchInfo {\n          id\n          homeSquad {\n            id\n            fullName\n            shortName\n            flagUrl\n            rarioSquadShortName\n          }\n          awaySquad {\n            id\n            fullName\n            shortName\n            flagUrl\n            rarioSquadShortName\n          }\n        }\n        winnings {\n          nft\n          prizePool\n          currency\n          marketingCreditsPrizePool\n        }\n        status\n        roundStartTime\n        roundPriority\n        lineupAnnounced\n        userTotalContest\n        userTotalTeams\n        isLegendAllowed\n      }\n      roundId\n      tourId\n      userWinnings {\n        fiatWinnings\n        currency\n        marketingCreditWinnings\n        nftWinningCount\n      }\n    }\n    pageInfo {\n      nextPage\n      previousPage\n    }\n  }\n}',
      }),
    }
  )
}

export const getCampaignData = (registerationSource: string, accessToken: string) => {
  return doGet(
    API_CONSTANTS.GET_CAMPAIGN_DATA,
    { registerationSource },
    { headers: getHeaders(accessToken) }
  )
}

export const getD3TrialWidgetStatus = (accessToken: string) => {
  return doPost(
    API_CONSTANTS.GAME_API_D3,
    {},
    {
      headers: getHeaders(accessToken),
      body: JSON.stringify({
        operationName: 'GET_COHORT_QUERY',
        variables: {},
        query:
          'query GET_COHORT_QUERY {\n  getCohort {\n    userId\n    cohortName\n    trialTeamJoinCount\n    nonTrialTeamJoinCount\n    isCoolOffEnabled\n    isOnboardingBonusGranted\n    lastPlayedTrialTimestamp\n    lastPlayedNonTrialTimestamp\n    isTrialEnabled\n  }\n}',
      }),
    }
  )
}
export const getUserTaxSummary = (accessToken: string) => {
  return doGet(
    API_CONSTANTS.GET_USER_TAX_SUMMARY,
    { isAbsUrl: true },
    {
      headers: getHeaders(accessToken),
    }
  )
}

export const getAllTDSCertificates = (accessToken: string) => {
  return doGet(
    API_CONSTANTS.GET_ALL_TDS_CERTIFICATES,
    { isAbsUrl: true },
    {
      headers: getHeaders(accessToken),
    }
  )
}

export const getUserTDSCertificate = (accessToken: string, certificate_id: string) => {
  return doGet(
    API_CONSTANTS.GET_TDS_CERTIFICATES_WITH_ID,
    { isAbsUrl: true, certificate_id },
    {
      headers: getHeaders(accessToken),
    }
  )
}

export const createUserKycAttempts = (accessToken: string) => {
  return doPost(
    API_CONSTANTS.CREATE_USER_KYC_ATTEMPTS,
    {},
    {
      headers: getHeaders(accessToken),
    }
  )
}
