import { BurnedCard, CampaignData } from 'interfaces/burnEarn'
import { capitilizeFirstLetter, checkMinutesDiff, formatDate_ddmmmyy } from './utils'
import { CardInterface } from 'interfaces'

export const mapHowItWorksBurnAndEarnData = (
  startDate: string,
  endDate: string,
  burnMapping: string
) => {
  return [
    {
      title: 'Burn & Earn Campaign Timeline',
      description: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'unordered-list',
            data: {},
            content: [
              {
                nodeType: 'list-item',
                data: {},
                content: [
                  {
                    nodeType: 'paragraph',
                    data: {},
                    content: [
                      {
                        marks: [],
                        nodeType: 'text',
                        data: {},
                        value: `Start Date — ${startDate}`,
                      },
                    ],
                  },
                ],
              },
              {
                nodeType: 'list-item',
                data: {},
                content: [
                  {
                    nodeType: 'paragraph',
                    data: {},
                    content: [
                      {
                        marks: [],
                        nodeType: 'text',
                        data: {},
                        value: `End Date — ${endDate} or first 1,000 transactions, whichever is earlier`,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                marks: [],
                nodeType: 'text',
                data: {},
                value: '',
              },
            ],
          },
        ],
      },
    },
    {
      title: 'How It Works',
      description: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'unordered-list',
            data: {},
            content: [
              {
                nodeType: 'list-item',
                data: {},
                content: [
                  {
                    nodeType: 'paragraph',
                    data: {},
                    content: [
                      {
                        marks: [],
                        nodeType: 'text',
                        data: {},
                        value: `Select ${burnMapping} cards that you want to Burn from your vault. If you want to burn a card listed on marketplace, delist the card first. If you want to burn an in-use card, remove the card from d3 game participation`,
                      },
                    ],
                  },
                ],
              },
              {
                nodeType: 'list-item',
                data: {},
                content: [
                  {
                    nodeType: 'paragraph',
                    data: {},
                    content: [
                      {
                        marks: [],
                        nodeType: 'text',
                        data: {},
                        value:
                          'Confirm the burn of cards, start the Burn process and voila! you earn a new card from India vs Australia player pool',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                marks: [],
                nodeType: 'text',
                data: {},
                value: '',
              },
            ],
          },
        ],
      },
    },
    {
      title: 'Requirements',
      description: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'unordered-list',
            data: {},
            content: [
              {
                nodeType: 'list-item',
                data: {},
                content: [
                  {
                    nodeType: 'paragraph',
                    data: {},
                    content: [
                      {
                        marks: [],
                        nodeType: 'text',
                        data: {},
                        value: `Requires${burnMapping}player cards that aren’t listed or in-use`,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  ]
}

export const UpdateTheHowItWorksData = (selectedCampignData: CampaignData) => {
  const startDate = formatDate_ddmmmyy(selectedCampignData?.startDate, false)
  const endDate = formatDate_ddmmmyy(selectedCampignData?.endDate, false)

  let burnMapping = ''
  if (
    selectedCampignData?.ruleConditions?.eligibility?.scarcities &&
    selectedCampignData?.ruleConditions?.eligibility?.minScarcityCardsBurnCountMap
  ) {
    burnMapping = mapBurnCardTextBasedOnScaricity(
      selectedCampignData?.ruleConditions?.eligibility?.scarcities,
      selectedCampignData?.ruleConditions?.eligibility?.minScarcityCardsBurnCountMap
    )
  }

  return mapHowItWorksBurnAndEarnData(startDate, endDate, burnMapping)
}

export const mapBurnCardTextBasedOnScaricity = (
  scarcities: string[],
  minScarcityCardsBurnCountMap: { [key: string]: number }
) => {
  let burnMapping = ''
  scarcities.map(
    (item, index) =>
      (burnMapping =
        burnMapping +
        ` ${minScarcityCardsBurnCountMap?.[item]} ${capitilizeFirstLetter(item)}${
          scarcities.length - 2 === index ? ' &' : scarcities.length - 1 === index ? '' : ', '
        }`)
  )

  return burnMapping
}

export const mapcardAllocationTextBasedOnScaricity = (scarcityCardMap: {
  [key: string]: {
    count: number
  }
}) => {
  let allocationText = ''
  const keyArray = Object.keys(scarcityCardMap)
  if (keyArray && keyArray?.length) {
    keyArray.map(
      (item, index) =>
        (allocationText =
          allocationText +
          `${scarcityCardMap?.[item]?.count} ${capitilizeFirstLetter(item)}${
            keyArray.length - 2 === index ? ' & ' : keyArray.length - 1 === index ? '' : ', '
          }`)
    )
  }

  return allocationText
}

export const getBurnedCardData = () => {
  const getBurnData = JSON.parse(localStorage.getItem('burnCardData') as string)
  if (getBurnData === null) return []
  const updatedArray = getBurnData.filter((item: BurnedCard) =>
    checkMinutesDiff(item?.burnTime, 15)
  )
  if (updatedArray.length === 0) {
    localStorage.removeItem('burnCardData')
    return []
  }
  const constantArray = updatedArray?.flatMap((obj: BurnedCard) => obj?.tokenIds)
  const burnCardDetails = JSON?.stringify(updatedArray)
  localStorage.setItem('burnCardData', burnCardDetails)
  return constantArray
}

export const scarcityPriority: Record<string, number> = {
  bronze: 4,
  silver: 3,
  gold: 2,
  black: 1,
}

export const scarcitySortComparator = (a: [string, CardInterface], b: [string, CardInterface]) => {
  const getDescriptionScarcity = (item: [string, CardInterface]) =>
    scarcityPriority[item[1].cardData.description.split(' ')[0]]

  const priorityA = getDescriptionScarcity(a)
  const priorityB = getDescriptionScarcity(b)

  return priorityA - priorityB
}
