import { useContext } from 'react'
import { AppConfigContext, CountryIsoCode } from 'contexts/AppConfigContext'
import { formatPrice } from 'utils/utils'
import { AvailableCurrency } from 'interfaces/currency'
import useCurrencyDetails from './useCurrencyDetails'

const BASE_CURRENCY_SYMBOL = '$'

export const countryCurrenciesMap: Record<CountryIsoCode, AvailableCurrency> = {
  [CountryIsoCode.INDIA]: 'INR',
  [CountryIsoCode.USA]: 'USD',
  [CountryIsoCode.AUSTRALIA]: 'AUD',
  [CountryIsoCode.UNITED_KINGDOM]: 'GBP',
}

const useCurrencyConversion = (
  price: number,
  config?: {
    localize?: boolean
    currency?: AvailableCurrency
    hasFixedExchangeRate?: boolean
    hasCeilingValue?: boolean
  }
) => {
  const {
    localize = false,
    currency,
    hasFixedExchangeRate = false,
    hasCeilingValue = true,
  } = config || {}

  const { preferredCurrency } = useContext(AppConfigContext)
  const preferredCurrencyDetails = useCurrencyDetails(
    currency || preferredCurrency,
    hasFixedExchangeRate
  )

  if (!preferredCurrencyDetails) {
    return [`${BASE_CURRENCY_SYMBOL} ${price}`, BASE_CURRENCY_SYMBOL, `price`]
  }

  if (preferredCurrency === 'AUD' && currency === 'USD') {
    preferredCurrencyDetails.symbol = 'US$'
  }

  const convertedPrice = formatPrice(
    price,
    preferredCurrencyDetails.conversionRate,
    preferredCurrencyDetails.selectedCurrency === 'INR' && hasCeilingValue
  )

  const localizePrice = (localize: boolean) => {
    return localize
      ? convertedPrice.toLocaleString(preferredCurrencyDetails.locale)
      : convertedPrice
  }

  const localizedPrice = localizePrice(localize)

  return [
    `${preferredCurrencyDetails.symbol}${localizedPrice}`,
    preferredCurrencyDetails.symbol,
    `${convertedPrice}`,
    `${localizePrice(true)}`,
  ]
}

export default useCurrencyConversion
