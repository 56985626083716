const LeftArrowIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  opacity?: number
  fill?: string
}> = ({ className = '', width = '25', height = '17', opacity = 1, fill = 'white' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 25 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={opacity}
      d="M8.86719 0.84375L9.96094 1.9375C10.1797 2.21094 10.1797 2.59375 9.90625 2.86719L5.53125 7.07812H24.3438C24.7266 7.07812 25 7.40625 25 7.73438V9.26562C25 9.64844 24.7266 9.92188 24.3438 9.92188H5.53125L9.90625 14.1875C10.1797 14.4609 10.1797 14.8438 9.96094 15.1172L8.86719 16.2109C8.59375 16.4297 8.21094 16.4297 7.9375 16.2109L0.71875 8.99219C0.5 8.71875 0.5 8.33594 0.71875 8.0625L7.9375 0.84375C8.21094 0.625 8.59375 0.625 8.86719 0.84375Z"
      fill={fill}
    />
  </svg>
)

export default LeftArrowIcon
