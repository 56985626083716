import { customStyled, FlexBox, TextField, theme, ThemeType } from '@rario/shared-components'

export const ModalWrapper = customStyled(FlexBox)<{ isModalOpen: boolean }>`
  position: absolute;
  width: 85%;
  -moz-transition: left .5s;
  -ms-transition: left .5s;
  -o-transition: left .5s;
  -webkit-transition: left .5s;
  transition: left .5s;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  height: 26px;
  border: 1px solid linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
  top: -15px;
  left: ${({ isModalOpen = false }) => (isModalOpen ? '20%' : 'calc(100% - 8px)')};
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-end;
  z-index: 10;
  margin-top: 15px;

  .modal-before-content span {
    transform: ${({ isModalOpen = false }) => (isModalOpen ? 'none' : 'rotate(-90deg)')};
  }
`

export const ModalContentWrapper = customStyled(FlexBox)<{ isModalOpen: boolean }>`
  position: relative;
  height: fit-content;
  width: 100%;
  background-color: ${theme.colors.widgetBG};
  border-radius: 4px;
  top: -8px;

  padding: 15px;

  a {
    margin: 10px auto 10px 12px; 
  }
`

export const HeadingContainer = customStyled(TextField)<{ theme?: ThemeType }>`
  fontWeight: 600;
  font-size: 16px;
  line-height: 22px;
  font-family: ${({ theme }) => theme.fonts.primary} !important;
  letter-spacing: ${({ theme }) => theme.letterSpacings.primary};
  padding-bottom: 0;
  margin-left: 5px;
`
