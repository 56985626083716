import { useState, useEffect } from 'react'
import debounce from 'utils/debounce'
import { isBrowser } from 'utils/utils'

const BREAKPOINTS = [576, 767, 992, 1200]

const MAX_WIDTH = 1440

export type BreakpointKey = 'desktop' | 'pad1' | 'pad2' | 'mobile'

const getSize = (breakpoints: number[]) => {
  const innerWidth = isBrowser() ? window.innerWidth : MAX_WIDTH
  return {
    mobile: innerWidth < breakpoints[1],
    pad1: innerWidth >= breakpoints[1] && innerWidth < breakpoints[2],
    pad2: innerWidth >= breakpoints[2] && innerWidth < breakpoints[3],
    desktop: innerWidth >= breakpoints[3],
  }
}

function useBreakpoints(breakpoints: number[] = BREAKPOINTS) {
  const [device, setDevice] = useState(getSize(breakpoints))

  useEffect(() => {
    window.addEventListener(
      'resize',
      debounce(() => setDevice(getSize(breakpoints)), 200)
    )
    return () => {
      window.removeEventListener(
        'resize',
        debounce(() => setDevice(getSize(breakpoints)), 200)
      )
    }
  }, [setDevice, getSize])

  return device
}

export default useBreakpoints
