import { parse } from 'cookie'
declare const window: Window &
  typeof globalThis & {
    analytics: any
  }

type EventType = 'track' | 'click'

export const trackEvent = (name: string, type: EventType, events: { [key: string]: any } = {}) => {
  const userData = localStorage.getItem('ajs_user_traits')
  const cookies = parse(document.cookie || '')
  const payload = {
    source: window.navigator.platform,
    rario_visitor: 1,
    isLoggedIn: userData ? 'true' : 'false',
    platform: 'rario',
    app_platform: cookies['app-platform'],
    ...getUserDataFromLocalStorage(),
    utm_source: cookies?.utm_source,
    utm_campaign: cookies?.utm_campaign,
    utm_content: cookies?.utm_content,
    utm_medium: cookies?.utm_medium,
    ...(getAppWebViewDataFromLocalStorage() || {}),
    ...events,
  }
  window?.analytics?.track(name, {
    type,
    ...payload,
  })
  trackGTMEvents(name, payload)
}

const trackGTMEvents = (name: string, events: { [key: string]: any } = {}) => {
  ;(window as any).dataLayer?.push({
    event: name,
    ...events,
  })
}

const getAppWebViewDataFromLocalStorage = () => {
  if (typeof window !== 'undefined') {
    const appWebViewData = localStorage.getItem('appWebViewData')
    if (appWebViewData) {
      const appWebViewParsedData = JSON.parse(appWebViewData)
      const appsflyerIdIntegrations = {
        integrations: {
          AppsFlyer: {
            appsFlyerId: appWebViewParsedData?.appsFlyerUID,
          },
        },
      }
      return {
        ...appWebViewParsedData,
        ...appsflyerIdIntegrations,
      }
    }
    return null
  }
  return null
}

const getUserDataFromLocalStorage = () => {
  if (typeof window !== 'undefined') {
    const userData = localStorage.getItem('ajs_user_traits')
    if (userData) {
      return JSON.parse(userData)
    }
    return null
  }
  return null
}

export const detectMob = (navigator: any) => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}

export const getBrowser = (navigator: any) => {
  let sBrowser
  const sUsrAg = navigator.userAgent

  // The order matters here, and this may report false positives for unlisted browsers.

  if (sUsrAg.indexOf('Firefox') > -1) {
    sBrowser = 'Mozilla Firefox'
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
    sBrowser = 'Samsung Internet'
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
    sBrowser = 'Opera'
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf('Trident') > -1) {
    sBrowser = 'Microsoft Internet Explorer'
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf('Edge') > -1) {
    sBrowser = 'Microsoft Edge'
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf('Chrome') > -1) {
    sBrowser = 'Google Chrome or Chromium'
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf('Safari') > -1) {
    sBrowser = 'Apple Safari'
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = 'unknown'
  }
  return sBrowser
}
