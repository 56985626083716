const CricrushBadge: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  color?: string
}> = ({ className = '', width = '33', height = '28' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 33 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M32.9889 -9.72748e-05L26.3815 0.0169373C26.3815 0.0169373 25.1404 -0.0216866 24.877 1.18894L19.4805 27.9919H27.2174L32.5921 2.05651L32.9889 -9.72748e-05Z"
      fill="#FFF100"
    />
    <path
      d="M23.1349 -9.72748e-05L16.5275 0.0169373C16.5275 0.0169373 15.2864 -0.0216866 15.023 1.18894L9.62646 27.9919H17.3634L22.7381 2.05651L23.1349 -9.72748e-05Z"
      fill="#C5E93B"
    />
    <path
      d="M13.5084 3.62396e-05L6.90108 0.0170689C6.90108 0.0170689 5.65995 -0.0215549 5.39651 1.18927L0 28H7.73695L13.1117 2.05723L13.5084 3.62396e-05Z"
      fill="#085250"
    />
  </svg>
)

export default CricrushBadge
