const ChevronRight: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
  color?: string
}> = ({ className = '', width = '26', height = '34', fill = '#993029', color = 'white' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 26 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26 0L23.1569 2.36922C21.1171 4.06909 18.5459 5.00001 15.8906 5.00001H12C5.37258 5.00001 0 10.3726 0 17V17C0 23.6274 5.37258 29 12 29H15.8906C18.5459 29 21.1171 29.9309 23.1569 31.6308L26 34V0Z"
      fill={fill}
    />
    <path
      d="M10.75 11.75L16 17L10.75 22.25"
      stroke={color}
      strokeWidth="1.63478"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ChevronRight
