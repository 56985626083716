const BlogIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '18', height = '18', fill = '#FF4F44' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 14.5H7.625V15.875H0.75V14.5ZM0.75 10.375H7.625V11.75H0.75V10.375ZM15.875 7.625H2.125C1.76033 7.625 1.41059 7.48013 1.15273 7.22227C0.894866 6.96441 0.75 6.61467 0.75 6.25V2.125C0.75 1.76033 0.894866 1.41059 1.15273 1.15273C1.41059 0.894866 1.76033 0.75 2.125 0.75H15.875C16.2397 0.75 16.5894 0.894866 16.8473 1.15273C17.1051 1.41059 17.25 1.76033 17.25 2.125V6.25C17.25 6.61467 17.1051 6.96441 16.8473 7.22227C16.5894 7.48013 16.2397 7.625 15.875 7.625ZM2.125 2.125V6.25H15.875V2.125H2.125ZM15.875 17.25H11.75C11.3853 17.25 11.0356 17.1051 10.7777 16.8473C10.5199 16.5894 10.375 16.2397 10.375 15.875V11.75C10.375 11.3853 10.5199 11.0356 10.7777 10.7777C11.0356 10.5199 11.3853 10.375 11.75 10.375H15.875C16.2397 10.375 16.5894 10.5199 16.8473 10.7777C17.1051 11.0356 17.25 11.3853 17.25 11.75V15.875C17.25 16.2397 17.1051 16.5894 16.8473 16.8473C16.5894 17.1051 16.2397 17.25 15.875 17.25ZM11.75 11.75V15.875H15.875V11.75H11.75Z"
      fill={fill}
    />
  </svg>
)

export default BlogIcon
