import { customStyled, FlexBox } from '@rario/shared-components'

export const ShareButtonsContainer = customStyled(FlexBox)<{ isUserProfilePage?: boolean }>`
  position: absolute;
  height: 0;
   & .st-btn{
       display:block !important;
       margin-right: 13px !important;
       z-index: 1 !important
   }
   & .st-center{
       display: flex !important;
       flex-direction: row !important;
   }
  //  & .telegram:hover {
  //    top: -2px
  //    background-color: red;
  //    transition: opacity 0.2s ease-in, top 0.2s ease-in;
  //  }
   & .container::before{
    content: "";
    position: absolute;
    bottom: 58%;
    rotate: 65deg;
    left: 98%;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
  }
  & .container{
    transform: scale(0.8);
  }
`
export const Container = customStyled(FlexBox)`
padding-inline:4px;
  & .shareicon{
    position: relative;
    top: 0px;
    opacity: 1;
  }
  & .shareicon:hover {
    top: -5px;
    opacity: 0.8;
    transition: opacity 0.2s ease-in, top 0.2s ease-in;
  }
  & .shareicon:not(:hover) {
    top: 0px;
    opacity: 1;
    transition: opacity 0.2s ease-in, top 0.2s ease-in;
  }
`
export const Icons = customStyled(FlexBox)`
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -o-user-select: none !important;
  user-select: none !important;
`
