import { customStyled, ThemeType } from '@rario/shared-components'
import {
  position,
  PositionProps,
  background,
  alignSelf,
  AlignSelfProps,
  BackgroundProps,
  variant,
  height,
  width,
  HeightProps,
  WidthProps,
  SpaceProps,
  space,
  TextAlignProps,
  textAlign,
  FontWeightProps,
  fontWeight,
  color,
  ColorProps,
  FontSizeProps,
  fontSize,
  lineHeight,
  LineHeightProps,
  MinHeightProps,
  minHeight,
  BorderRadiusProps,
  borderRadius,
} from 'styled-system'

export type TagVaraints =
  | 'basic'
  | 'orange'
  | 'inverse'
  | 'big'
  | 'diagonal'
  | 'comingSoon'
  | 'ProgressLabel'
  | 'soldout'

export type TagsType = { variant?: TagVaraints; children?: React.ReactNode } & PositionProps &
  AlignSelfProps &
  BackgroundProps &
  HeightProps &
  WidthProps &
  SpaceProps &
  TextAlignProps &
  FontWeightProps &
  ColorProps &
  FontSizeProps &
  LineHeightProps &
  MinHeightProps &
  BorderRadiusProps

export const TagWrapper = customStyled.div<{ theme?: ThemeType } & TagsType>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  padding: 3px 10px;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 4px;
  ${color};
  ${position};
  ${alignSelf};
  ${space};
  ${textAlign};
  ${fontWeight};
  ${borderRadius}
  
  ${({ theme }) =>
    variant({
      variants: {
        basic: {
          background: 'rgba(146, 54, 178, 0.5);',
        },
        orange: {
          background: '#FF3D3D',
          color: '#fff',
          right: [25, null, null, '0'],
        },
        inverse: {
          background:
            'linear-gradient(180deg, #10FFC6 0%, rgba(16, 212, 255, 0.75) 100%), linear-gradient(180deg, #FFE70E 0%, rgba(192, 255, 14, 0.7) 100%)',
        },
        new: {
          background: '#FF3D3D',
        },
        big: {
          fontSize: ['17.41px', null, null, '33px'],
          lineHeight: ['14px', null, null, '41px'],
          background: '#00011B',
          border: `5.62909px solid ${theme.colors.secondaryHighlight}`,
          boxSizing: 'border-box',
          textAlign: 'center',
          width: ['140px', null, null, '100%'],
          left: ['50%', null, null, '50%'],
          transform: 'translate(-50%)',
          height: ['44px', null, null, '85px'],
          top: '20%',
          color: theme.colors.secondaryHighlight,
          px: ['6px', null, null, '3px'],
          py: ['0px', null, null, '10px'],
          display: 'flex',
          justifyContent: 'center',
          cursor: 'pointer',
        },
        diagonal: {
          background: '#00011B',
          border: `5.62909px solid ${theme.colors.secondaryHighlight}`,
          boxSizing: 'border-box',
          textAlign: 'center',
          width: ['100%'],
          // height: ['85px'],
          top: '50%',
          transform: 'translate(0,-50%) rotate(-15deg)',
          color: theme.colors.secondaryHighlight,
        },
        comingSoon: {
          background:
            'radial-gradient(79.55% 79.55% at 39.95% 8.68%, #FF773C 0%, #FF0000 100%), linear-gradient(180deg, #10FFC6 0%, #10FFFF 100%), #00011B',
          border: `5.62909px solid ${theme.colors.white}`,
          boxSizing: 'border-box',
          textAlign: 'center',
          width: ['100%'],
          // height: ['85px'],
          top: '50%',
          transform: 'translate(0,-50%) rotate(-15deg)',
          color: theme.colors.white,
        },
        ProgressLabel: {
          background: '#fff',
          boxSizing: 'border-box',
          textAlign: 'center',
          borderRadius: 2,
          color: theme.colors.darkGreen,
          zIndex: 999,
        },
        soldout: {
          position: 'absolute',
          padding: '5px 6px',
          background: theme.colors.white,
          borderRadius: '4px',
          color: theme.colors.primary,
          fontWeight: theme.fontWeights[4],
          bottom: '30%',
          fontSize: '40px',
          textAlign: 'center',
          lineHeight: '47px',
          height: '57px',
          width: '219px',
          left: '0px',
        },
      },
    })};
  ${background};
  ${fontSize};
  ${lineHeight};
  ${minHeight};
  ${height};
  ${width};
  ${borderRadius}
  > span{
    ${variant({
      variants: {
        big: {
          fontSize: ['17.41px', null, null, '33px'],
          lineHeight: ['14px', null, null, '24px'],
        },
      },
    })}
  }
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      ${variant({
        variants: {
          ProgressLabel: {
            right: ' auto',
            left: '50%',
            transform: 'translate(-50%)',
          },
        },
      })}
    }
`
