const HelpIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '20', height = '18', fill = '#FF4F44' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 4.99951H18C18.5523 4.99951 19 5.44723 19 5.99951V16.9995L15.6665 14.2303C15.487 14.0812 15.2612 13.9995 15.0278 13.9995H7C6.44772 13.9995 6 13.5518 6 12.9995V9.99951M13 0.999512C13.5523 0.999512 14 1.44723 14 1.99951V8.99951C14 9.5518 13.5523 9.99951 13 9.99951H4.97217C4.73875 9.99951 4.51304 10.0812 4.3335 10.2303L1 12.9995V1.99951C1 1.44723 1.44772 0.999512 2 0.999512H13Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default HelpIcon
