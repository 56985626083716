import { FlexBox, theme, trackEvent } from '@rario/shared-components'
import DescriptionField from 'components/DescriptionField'
import HeadingContent from 'components/HeadingContent/HeadingContent'
import { HowItWorksProps } from 'interfaces/cmsContentTypes'
import { useEffect } from 'react'

const HowItWorks: React.FunctionComponent<{
  howItWorksData: HowItWorksProps[]
  isBurnEarn?: boolean
}> = ({ howItWorksData }) => {
  useEffect(() => {
    trackEvent('Section Viewed', 'track', {
      sub_category: 'burn-how_it_works',
      category: 'burn',
      view_type: 'bottom_sheet',
    })
  }, [])
  return (
    <FlexBox width={'100%'} flexDirection={'column'} px={'15px'} pt={'30px'} mb={'25px'}>
      {howItWorksData?.map((item, index) => {
        return (
          <FlexBox key={`burn-${index}`} flexDirection={'column'} width={'100%'} mt={'15px'}>
            <HeadingContent heading={item?.title} />
            <FlexBox flexDirection={'column'} mt={'12px'} ml={'15px'}>
              {item?.description && (
                <DescriptionField
                  listItemStyles={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: theme.colors.greyishWhite,
                    opacity: 0.8,
                    fontFamily: theme.fonts.secondary,
                  }}
                  description={item?.description}
                  bulletStyles={{
                    height: '3px',
                    minWidth: '3px',
                    borderRadius: '50%',
                    background: theme.colors.whites[9],
                    mt: '8px',
                    mr: '8px',
                  }}
                  paragraphStyles={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: theme.colors.greyishWhite,
                    opacity: 0.8,
                    fontFamily: theme.fonts.secondary,
                  }}
                />
              )}
            </FlexBox>
          </FlexBox>
        )
      })}
    </FlexBox>
  )
}
export default HowItWorks
