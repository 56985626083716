import { FlexBox, Loader, Modal, theme, trackEvent } from '@rario/shared-components'
import BorderedQuestionMark from 'assets/svg/BorderedQuestionMark'
import { CircularBlurredWrapper } from 'components/PackBox/PackBox.styles'
import { AppConfigContext } from 'contexts/AppConfigContext'
import { CampaignData } from 'interfaces/burnEarn'
import { HowItWorksProps } from 'interfaces/cmsContentTypes'
import { useRouter } from 'next/router'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { UpdateTheHowItWorksData } from 'utils/burnEarn'
import HowItWorks from './HowItWorks'

const HowBurnEarnWorks: FunctionComponent = () => {
  const [showBurnEarnModal, setShowBurnEarnModal] = useState<boolean>(false)
  const router = useRouter()
  const { campaignData } = useContext(AppConfigContext)
  const [selectedCampaign, setSelectedCampaign] = useState<CampaignData>()
  const [howItWorksData, setHowItWorksData] = useState<HowItWorksProps[]>([])

  const getBurnEarnData = async () => {
    setShowBurnEarnModal(true)
    if (campaignData.length > 0) {
      const data = campaignData.find(
        (campaign: CampaignData) => campaign.id === Number(router.query?.campaignId)
      )
      setSelectedCampaign(data)
    }
    trackEvent('Section Interacted', 'click', {
      category: 'burn_banner',
      sub_category: 'burn_banner-vault_tag',
      click_action: 'burn_banner_vault_tag_click',
      click_type: 'link',
      click_text: 'See How It Works',
    })
  }

  useEffect(() => {
    setHowItWorksData(UpdateTheHowItWorksData(selectedCampaign as CampaignData) as [])
  }, [selectedCampaign])

  return (
    <>
      <CircularBlurredWrapper
        className="about-cards"
        mt={'5px'}
        onClick={() => campaignData && getBurnEarnData()}
      >
        <BorderedQuestionMark />
      </CircularBlurredWrapper>
      <Modal
        visible={showBurnEarnModal}
        isOutsideClick
        onClick={() => {
          setShowBurnEarnModal(false)
        }}
        background={'#11151C'}
        showCloseIcon
        noPadding
        variant="fullScreen"
        modalPosition="flex-end"
        bodyBackground={theme.colors.whites[3]}
        desktopTop={'-35px'}
        crossMarkHeight={18}
        crossMarkWidth={18}
        showTopLine
        desktopRight="15px"
        top="-35px"
        right="15px"
        closeCallBackModal={() =>
          trackEvent('Section Interacted', 'click', {
            category: 'burn',
            sub_category: 'burn-how_it_works_bottom_sheet_close_icon',
            click_action: 'close_how_it_works_bottom_sheet',
            click_type: 'icon',
          })
        }
      >
        {howItWorksData ? (
          <FlexBox maxHeight={'85vh'} overflowY={'auto'}>
            <HowItWorks howItWorksData={howItWorksData as HowItWorksProps[]} />
          </FlexBox>
        ) : (
          <Loader height="55vh" />
        )}
      </Modal>
    </>
  )
}

export default HowBurnEarnWorks
