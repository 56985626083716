const WalletTimerIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
}> = ({ className = '', width = '156', height = '157' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 156 157"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="78"
      cy="78.2795"
      r="77"
      fill="white"
      fillOpacity="0.13"
      stroke="#E6E6E7"
      strokeWidth="2"
    />
    <path
      d="M98.8012 63.0313H58.9295C57.9544 63.0313 57.1959 62.2665 57.1959 61.2832C57.1959 60.4092 57.9544 59.5351 58.9295 59.5351H102.268V57.787C102.268 54.9463 99.8848 52.5426 97.0675 52.5426H57.1959C53.2954 52.5426 50.2617 55.7111 50.2617 59.5351V94.4975C50.2617 98.4308 53.2954 101.49 57.1959 101.49H98.8012C102.593 101.49 105.735 98.4308 105.735 94.4975V70.0238C105.735 66.1998 102.593 63.0313 98.8012 63.0313ZM95.3341 85.7569C93.384 85.7569 91.867 84.2273 91.867 82.2606C91.867 80.4033 93.384 78.7644 95.3341 78.7644C97.1758 78.7644 98.8012 80.4033 98.8012 82.2606C98.8012 84.2273 97.1758 85.7569 95.3341 85.7569Z"
      fill="white"
    />
    <rect x="94" y="83" width="26.9989" height="26" rx="13" fill="white" />
    <rect x="94" y="83" width="26.9989" height="26" rx="13" stroke="#30333A" strokeWidth="2" />
    <path
      d="M104.928 86.5H110.071V88.2143H104.928V86.5ZM113.525 91.9771L114.742 90.76C114.374 90.3229 113.971 89.9114 113.534 89.5514L112.317 90.7686C110.951 89.67 109.252 89.0712 107.499 89.0714C105.453 89.0714 103.491 89.8842 102.045 91.3309C100.598 92.7776 99.7852 94.7398 99.7852 96.7857C99.7852 101.046 103.231 104.5 107.499 104.5C108.952 104.501 110.375 104.091 111.604 103.319C112.834 102.546 113.821 101.442 114.45 100.133C115.08 98.8248 115.327 97.3649 115.164 95.9219C115 94.4789 114.432 93.1115 113.525 91.9771ZM108.357 97.6429H106.642V92.5H108.357V97.6429Z"
      fill="#11151C"
    />
  </svg>
)

export default WalletTimerIcon
