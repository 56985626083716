import { D3LogoLink } from 'constant'

const PrivacyIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
  isD3?: boolean
}> = ({ className = '', width = '20', height = '31', fill = '#36B286', isD3 }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 12H13M7 9H13M1 3V13.8C1 14.9201 1 15.4801 1.21799 15.908C1.40973 16.2843 1.71547 16.5902 2.0918 16.782C2.51962 17 3.08009 17 4.2002 17H15.8002C16.9203 17 17.48 17 17.9078 16.782C18.2841 16.5902 18.5905 16.2843 18.7822 15.908C19.0002 15.4801 19.0002 14.9201 19.0002 13.8L19.0002 6.20002C19.0002 5.07992 19.0002 4.51986 18.7822 4.09204C18.5905 3.71572 18.2841 3.40973 17.9078 3.21799C17.48 3 16.9201 3 15.8 3H1ZM1 3C1 1.89543 1.89543 1 3 1H6.67452C7.1637 1 7.40858 1 7.63875 1.05526C7.84282 1.10425 8.03785 1.18508 8.2168 1.29474C8.41863 1.41842 8.5916 1.59135 8.9375 1.93726L10.0002 3"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    {isD3 && <image width="15" height="15" y="11" x="2" xlinkHref={D3LogoLink} />}
  </svg>
)

export default PrivacyIcon
