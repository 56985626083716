import React, { FunctionComponent, useState, useEffect } from 'react'
import { HeaderWrapper, IconWrapper } from './Header.styles'
import { FlexBox, TextField, theme, trackEvent } from '@rario/shared-components'
import MobileHeader from './MobileHeader'
import GridMenuIcon from 'assets/svg/GridMenuIcon'
import usePageControl from 'hooks/usePageControl'
import BackButton from 'components/BackButton/BackButton'
import { getAppWebViewDataFromLocalStorage } from 'utils/utils'
import WalletBalanceBox from 'components/RarioWallet/WalletBalanceBox'
import WalletIcon from 'assets/svg/WalletIcon'
import { useRouter } from 'next/router'
import ShareButtonWrapper from 'components/ShareButtons/ShareButtonContainer'
import HowBurnEarnWorks from 'components/BurnEarn/HowBurnEarnWorks'
import HeaderLogo from './HeaderLogo'

declare global {
  /* eslint-disable */
  interface Window {
    ReactNativeWebView: any
  }
}

const Header: FunctionComponent = () => {
  const {
    isHomeLandingPage,
    backButtonControl,
    isReferEarn,
    headerControl,
    isAddFundsPage,
    isWithdrawFundsPage,
    isSachinLandingPage,
    isTrumpsPage,
    isBurnEarnPage,
  } = usePageControl()
  const { push } = useRouter()
  const [statusBarHeight, setStatusBarHeight] = useState(24)
  const mobileAppBaseConfigData = getAppWebViewDataFromLocalStorage()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const showWallet = isAddFundsPage || isWithdrawFundsPage || isReferEarn

  const handleMobileMenu = (showMenu: boolean) => {
    setIsMenuOpen(showMenu)
    if (showMenu) {
      trackEvent('Section Interacted', 'click', {
        sub_category: 'homepage-more_menu_icon',
        click_type: 'icon',
        is_menu_open: true,
        click_action: 'others',
        category: 'menu',
      })
    }
  }

  useEffect(() => {
    if (mobileAppBaseConfigData?.statusbarHeight) {
      setStatusBarHeight(Number(mobileAppBaseConfigData.statusbarHeight))
    }
  }, [mobileAppBaseConfigData?.statusbarHeight])

  return (
    <>
      {!headerControl ? (
        <HeaderWrapper pt={statusBarHeight}>
          <FlexBox
            px={15}
            mx="auto"
            width="100%"
            height="45%"
            alignItems="center"
            position={'relative'}
          >
            {!backButtonControl && <BackButton />}
            {isTrumpsPage && (
              <TextField
                fontFamily={theme.fonts.primary}
                fontWeight={600}
                fontSize={'18px'}
                lineHeight={'22px'}
                ml={'12px'}
                p={0}
              >
                Rario Arcade
              </TextField>
            )}
            {(isHomeLandingPage || isSachinLandingPage) && (
              <FlexBox onClick={() => isSachinLandingPage && push('/')}>
                <HeaderLogo />
              </FlexBox>
            )}

            <FlexBox alignItems={'center'} ml="auto">
              {showWallet && <WalletBalanceBox />}
              {isBurnEarnPage && <HowBurnEarnWorks />}
              {(isHomeLandingPage || isSachinLandingPage || isTrumpsPage) && (
                <FlexBox alignItems={'center'}>
                  <FlexBox
                    p={'12px'}
                    className="wallet-icon"
                    onClick={() => {
                      trackEvent('Section Interacted', 'click', {
                        sub_category: 'homepage-wallet_redirection',
                        click_type: 'icon',
                        click_action: 'wallet_redirection_icon',
                        view_type: 'constrained',
                        category: 'wallet',
                      })
                      push(`/wallet${isTrumpsPage ? '' : '?referer=home'}`)
                    }}
                    cursor="pointer"
                  >
                    <WalletIcon fill="white" />
                  </FlexBox>

                  <IconWrapper
                    justifyContent={'center'}
                    alignItems="center"
                    ml={'10px'}
                    onClick={() => handleMobileMenu(!isMenuOpen)}
                    className={'side-nav-icon'}
                  >
                    <GridMenuIcon />
                  </IconWrapper>
                </FlexBox>
              )}
              <ShareButtonWrapper />
            </FlexBox>

            <MobileHeader
              handleMobileMenu={(state: boolean) => handleMobileMenu(state)}
              isMenuOpen={isMenuOpen}
            />
          </FlexBox>
        </HeaderWrapper>
      ) : (
        <FlexBox pt={statusBarHeight}></FlexBox>
      )}
    </>
  )
}

export default Header
