const HomeNavIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  isActive?: boolean
  fill?: string
  opacity?: number
}> = ({
  className = '',
  width = '25',
  height = '24',
  isActive = false,
  fill = 'white',
  opacity = 1,
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={opacity}>
      <path
        d="M20.5 16.7997V11.4517V11.451C20.5 10.9171 20.5 10.6501 20.4351 10.4016C20.3775 10.1812 20.2827 9.97226 20.1548 9.78384C20.0104 9.57121 19.8096 9.39532 19.4074 9.04346L14.6074 4.84346C13.8608 4.19017 13.4875 3.86358 13.0674 3.73933C12.6972 3.62986 12.3028 3.62986 11.9326 3.73933C11.5127 3.86351 11.1398 4.18982 10.394 4.84239L10.3928 4.84346L5.59277 9.04346L5.59182 9.0443C5.19032 9.39561 4.98944 9.57138 4.84521 9.78384C4.7173 9.97227 4.62255 10.1812 4.56497 10.4016C4.5 10.6502 4.5 10.9174 4.5 11.4517V16.7997C4.5 17.9198 4.5 18.4798 4.71799 18.9076C4.90973 19.2839 5.21547 19.5899 5.5918 19.7817C6.01944 19.9996 6.57962 19.9996 7.69879 19.9996H7.7002H17.3002H17.3016C18.4208 19.9996 18.9801 19.9996 19.4078 19.7817C19.7841 19.5899 20.0905 19.2839 20.2822 18.9076C20.5 18.4802 20.5 17.9209 20.5 16.803V16.7997Z"
        fill="url(#paint0_linear_1204_1069)"
        fillOpacity="0.25"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    {isActive && (
      <defs>
        <linearGradient
          id="paint0_linear_1204_1069"
          x1="12.5"
          y1="21.4996"
          x2="12"
          y2="4.99957"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill} />
          <stop offset="1" stopColor={fill} stopOpacity="0" />
        </linearGradient>
      </defs>
    )}
  </svg>
)

export default HomeNavIcon
