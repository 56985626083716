const ChevronRight: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  opacity?: number
  fill?: string
}> = ({ className = '', width = '32', height = '32', opacity = 1, fill = 'white' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    opacity={opacity}
    viewBox="0 0 12 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M2.79688 1.25L11.375 10.2031C11.5625 10.4375 11.7031 10.7188 11.7031 11C11.7031 11.2812 11.5625 11.5625 11.375 11.75L2.79688 20.7031C2.375 21.1719 1.625 21.1719 1.20312 20.75C0.734375 20.3281 0.734375 19.625 1.15625 19.1562L9.03125 10.9531L1.15625 2.79688C0.734375 2.375 0.734375 1.625 1.20312 1.20312C1.625 0.78125 2.375 0.78125 2.79688 1.25Z"
        fill={fill}
      />
    </g>
  </svg>
)

export default ChevronRight
