import { TextField, theme } from '@rario/shared-components'
import { AppConfigContext } from 'contexts/AppConfigContext'
import usePreferredCurrency from 'hooks/usePreferredCurrency'
import React, { useContext } from 'react'

const CurrencyConversionNote: React.FunctionComponent<{ note: string }> = ({ note }) => {
  const showApproxValue = !usePreferredCurrency('USD')
  const { preferredCurrency } = useContext(AppConfigContext)

  if (!showApproxValue) return null

  return (
    <TextField
      width={'100%'}
      p={'0'}
      mt={'8px'}
      mb={'5px'}
      fontWeight={400}
      fontSize={'12px'}
      lineHeight={'16px'}
      color={theme.colors.dimGray}
    >
      {note.replace(/{{preferredCurrency}}/g, preferredCurrency || '')}
    </TextField>
  )
}

export default CurrencyConversionNote
