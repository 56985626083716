import { FlexBox, trackEvent } from '@rario/shared-components'
import ChevronLeft from 'assets/svg/ChevronLeft'
import LeftArrowIcon from 'assets/svg/LeftArrowIcon'
import { ModalContext } from 'components/ModalManager/ModalProvider'
import { IN_APP_PURCHASE_COOKIE } from 'constant'
import usePageControl from 'hooks/usePageControl'
import { CancelType } from 'interfaces/purchaseFlow'
import { useRouter } from 'next/router'
import { FunctionComponent, useContext } from 'react'
import { useCookies } from 'react-cookie'
import { REFERER } from 'utils/urlUtils'
import { getFormattedEventName } from 'utils/utils'

const BackButton: FunctionComponent<{ isCheveron?: boolean }> = ({ isCheveron = false }) => {
  const router = useRouter()
  const { setCancelPaymentModal } = useContext(ModalContext)
  const {
    isPaymentPage,
    isAddFundsPage,
    isBurnEarnListing,
    isPackPdp,
    isPickPdp,
    isArcadeGamePage,
  } = usePageControl()
  const [cookies] = useCookies([IN_APP_PURCHASE_COOKIE.ORDER_ID])
  const redirectToHomePage = router.query?.referer === REFERER.HOME
  const category = getFormattedEventName(router?.pathname, false)
  const homePageUrl = router.query.league ? `/?league=${router.query.league}` : '/'
  const showCancelPaymentConsentModal =
    isPaymentPage || (isAddFundsPage && cookies?.[IN_APP_PURCHASE_COOKIE.ORDER_ID])

  const redirectUrl = cookies?.[IN_APP_PURCHASE_COOKIE.URL]

  const onClick = () => {
    if (showCancelPaymentConsentModal) {
      setCancelPaymentModal({ show: true, type: CancelType.BACK })
    } else if (redirectToHomePage) {
      router.push(homePageUrl)
    } else if (redirectUrl) {
      if (router.query?.transaction_status === 'continue') {
        redirectToBuyPage()
      } else {
        router.replace(redirectUrl as string)
      }
    } else if (isBurnEarnListing) {
      router.push('/profile/cards')
    } else if (router.query?.transaction_status === 'failed') {
      redirectToBuyPage()
    } else if (isArcadeGamePage) {
      router.push('/arcade?referer=home')
    } else {
      router.back()
    }

    if (!isPaymentPage) {
      window?.ReactNativeWebView?.postMessage('custom_click')
    }

    trackEvent('Section Interacted', 'click', {
      sub_category: `${category}-back_button`,
      current_screen: router.asPath,
      click_type: 'icon',
      click_action: 'others',
      category,
    })
  }

  const redirectToBuyPage = () => {
    if (isPackPdp) {
      router.push('/packs')
    } else if (isPickPdp) {
      router.push('/picks')
    } else {
      router.push('/marketplace')
    }
  }

  return (
    <FlexBox cursor="pointer" onClick={onClick}>
      {isCheveron ? <ChevronLeft height="20px" /> : <LeftArrowIcon />}
    </FlexBox>
  )
}

export default BackButton
