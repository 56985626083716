import { FlexBox, Modal, TextField, theme, trackEvent } from '@rario/shared-components'
import DownArrowIcon from 'assets/svg/DownArrowIcon'
import EmptyRoundFailIcon from 'assets/svg/EmptyRoundFailIcon'
import { AppConfigContext } from 'contexts/AppConfigContext'
import { useContext, useEffect, useState } from 'react'

import CurrencyItem from './CurrencyItem'
import { BottomSheetHeading, CurrencyContainer } from 'components/CurrencyToggle/Currency.styles'
import useCurrencyDetails from 'hooks/useCurrencyDetails'
import CurrencyConversionNote from 'components/CurrencyConversionNote'
import { AvailableCurrency } from 'interfaces/currency'
import { supportedCurrenciesArray } from 'constant/currency'

const CurrencyToggle: React.FunctionComponent<{ source: 'wallet_screen' | 'more_menu' }> = ({
  source,
}) => {
  const { preferredCurrency, setPreferredCurrency } = useContext(AppConfigContext)
  const preferredCurrencyDetails = useCurrencyDetails(preferredCurrency)
  const [showCurrencyModal, setShowCurrencyModal] = useState<boolean>(false)

  useEffect(() => {
    if (showCurrencyModal) {
      trackEvent('Section Viewed', 'track', {
        category: 'preferred_currency',
        selected_currency: preferredCurrency,
        type: 'track',
        sub_category: 'preferred_currency_bottom_sheet',
        view_type: 'constrained',
      })
    }
  }, [showCurrencyModal])

  const onCurrencySelect = (selectedCurrency: AvailableCurrency) => {
    trackEvent('Section Interacted', 'click', {
      category: source,
      click_text: selectedCurrency,
      previous_selected_currency: preferredCurrency,
      preferred_currency: selectedCurrency,
      click_type: 'link',
      sub_category: `${source}-preferred_currency_link`,
      type: 'click',
      view_type: 'constrained',
    })

    setPreferredCurrency?.(selectedCurrency)
  }

  const trackToggleClick = () => {
    trackEvent('Section Interacted', 'click', {
      click_text: preferredCurrency,
      click_type: 'link',
      selected_currency: preferredCurrency,
      type: 'click',
      category: source,
      sub_category: `${source}-preferred_currency_link`,
      view_type: 'constrained',
    })
  }

  const trackCloseModal = () => {
    trackEvent('Section Interacted', 'click', {
      click_type: 'icon',
      selected_currency: preferredCurrency,
      category: source,
      sub_category: `${source}-preferred_currency_link_close_icon`,
      view_type: 'constrained',
    })
  }

  return (
    <>
      <CurrencyContainer
        border={`1px solid ${theme.colors.whites[4]}`}
        onClick={() => {
          trackToggleClick()
          setShowCurrencyModal(true)
        }}
      >
        <TextField
          content={`${preferredCurrencyDetails?.symbol}${preferredCurrencyDetails?.selectedCurrency}`}
          fontWeight={600}
          fontSize={'14px'}
          lineHeight={'22px'}
          pb="0"
          px="5px"
        />
        <DownArrowIcon height="6.5px" width="8.5px" />
      </CurrencyContainer>
      <Modal
        visible={showCurrencyModal}
        showCloseIcon={true}
        background="#11151C"
        showTopLine
        onClick={() => {
          setShowCurrencyModal(!showCurrencyModal)
          trackCloseModal()
        }}
        isOutsideClick={false}
        variant={'fullScreen'}
        bodyBackground={theme.colors.whites[3]}
        modalPosition="flex-end"
        desktopTop={'-20%'}
        top={'-20%'}
        noPadding
      >
        <FlexBox padding={'18px'} flexDirection={'column'}>
          <FlexBox my="12px">
            <BottomSheetHeading content={'Preferred Currency'} />
          </FlexBox>
          <FlexBox>
            {supportedCurrenciesArray.map((item, index) => {
              return (
                <FlexBox key={`currency_${preferredCurrencyDetails?.symbol}_${index}`}>
                  <CurrencyItem
                    isSelectedCurrency={
                      preferredCurrencyDetails?.selectedCurrency === item.selectedCurrency
                    }
                    listItem={item}
                    setCurrency={onCurrencySelect}
                  />
                </FlexBox>
              )
            })}
          </FlexBox>
          <FlexBox pt="18px">
            <FlexBox mt="4px">
              <EmptyRoundFailIcon fill={theme.colors.pending} />
            </FlexBox>
            <TextField
              fontSize={'14px'}
              lineHeight={'18px'}
              pl="5px"
              fontFamily={'16px'}
              fontWeight={400}
              width="70%"
              content="For INR: Amt will be rounded off to the nearest Rupee"
              color={theme.colors.pending}
            />
          </FlexBox>
          <CurrencyConversionNote note="Note: The amount in {{preferredCurrency}} may vary time to time based on the USD conversion rate, as USD is the base currency for this platform." />
        </FlexBox>
      </Modal>
    </>
  )
}
export default CurrencyToggle
