const EmptyRoundFailIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  color?: string
  fill?: string
}> = ({ className, width = '14px', height = '14px', fill = '#FF4F44' }) => (
  <svg
    width={width}
    className={className}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.02905 9.01247H7.6772C7.6772 8.65451 7.38701 8.36432 7.02905 8.36432V9.01247ZM7.02905 9.0708L7.02889 9.71895C7.20082 9.719 7.36572 9.65073 7.4873 9.52917C7.60889 9.40762 7.6772 9.24273 7.6772 9.0708H7.02905ZM6.97095 9.07079H6.3228C6.3228 9.42869 6.61289 9.71885 6.97079 9.71894L6.97095 9.07079ZM6.97095 9.01247V8.36432C6.61298 8.36432 6.3228 8.65451 6.3228 9.01247H6.97095ZM7.64815 4.92914C7.64815 4.57118 7.35796 4.28099 7 4.28099C6.64204 4.28099 6.35185 4.57118 6.35185 4.92914H7.64815ZM6.35185 7.26247C6.35185 7.62043 6.64204 7.91062 7 7.91062C7.35796 7.91062 7.64815 7.62043 7.64815 7.26247H6.35185ZM7 11.6019C4.45847 11.6019 2.39815 9.54153 2.39815 7H1.10185C1.10185 10.2575 3.74254 12.8981 7 12.8981V11.6019ZM2.39815 7C2.39815 4.45847 4.45847 2.39815 7 2.39815V1.10185C3.74254 1.10185 1.10185 3.74254 1.10185 7H2.39815ZM7 2.39815C9.54153 2.39815 11.6019 4.45847 11.6019 7H12.8981C12.8981 3.74254 10.2575 1.10185 7 1.10185V2.39815ZM11.6019 7C11.6019 9.54153 9.54153 11.6019 7 11.6019V12.8981C10.2575 12.8981 12.8981 10.2575 12.8981 7H11.6019ZM6.3809 9.01247V9.0708H7.6772V9.01247H6.3809ZM7.02921 8.42266L6.97111 8.42264L6.97079 9.71894L7.02889 9.71895L7.02921 8.42266ZM7.61909 9.07079V9.01247H6.3228V9.07079H7.61909ZM6.97095 9.66062H7.02905V8.36432H6.97095V9.66062ZM6.35185 4.92914V7.26247H7.64815V4.92914H6.35185Z"
      fill={fill}
    />
  </svg>
)

export default EmptyRoundFailIcon
