import { TagWrapper, TagsType } from './Tag.styles'
import { theme } from '@rario/shared-components'

const Tag: React.FunctionComponent<TagsType> = ({
  variant = 'basic',
  children,
  color,
  ...props
}) => {
  return (
    <TagWrapper
      variant={variant}
      fontWeight={theme.fontWeights[7]}
      color={color as string}
      {...props}
    >
      {children}
    </TagWrapper>
  )
}

export default Tag
