const CancelWithOutLineIcon: React.FunctionComponent<{ fill?: string }> = ({
  fill = '#FF4F44',
}) => (
  <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="37" cy="37" r="37" fill="url(#paint0_linear_10806_29159)" fillOpacity="0.25" />
    <circle cx="37" cy="37" r="36.5256" stroke={fill} strokeOpacity="0.6" strokeWidth="0.948718" />
    <path
      d="M45.8053 44.2897C46.3477 44.8925 46.3477 45.7966 45.8053 46.339C45.2025 46.9418 44.2984 46.9418 43.756 46.339L36.6437 39.1665L29.4712 46.339C28.8685 46.9418 27.9644 46.9418 27.4219 46.339C26.8192 45.7966 26.8192 44.8925 27.4219 44.2897L34.5944 37.1172L27.4219 29.9447C26.8192 29.342 26.8192 28.4379 27.4219 27.8954C27.9644 27.2927 28.8685 27.2927 29.4109 27.8954L36.6437 35.1282L43.8162 27.9557C44.3587 27.353 45.2628 27.353 45.8053 27.9557C46.408 28.4982 46.408 29.4023 45.8053 30.005L38.6327 37.1172L45.8053 44.2897Z"
      fill="#FF4F44"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10806_29159"
        x1="42.0716"
        y1="-21.5116"
        x2="42.0716"
        y2="103.256"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#864F0F" stopOpacity="0.6" />
        <stop offset="1" stopColor="#C38337" stopOpacity="0.6" />
      </linearGradient>
    </defs>
  </svg>
)

export default CancelWithOutLineIcon
