import { customStyled, FlexBox, ThemeType } from '@rario/shared-components'

export const StickyButtonWrapper = customStyled(FlexBox)`
    
`

export const StickyBar = customStyled.div<{ theme?: ThemeType }>`
    z-index: 9999;
    bottom: 0px;
    width: 100%;
    position: fixed;
    margin: 0;
    -webkit-user-drag: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
      width: ${({ theme }) => theme.maxDesktopLayoutWidth}px!important;
    }
  `

export const StickyBarInner = customStyled(FlexBox)`
    backdrop-filter: blur(20px);
    @media (max-width: 380px) {
      background-size: contain;
    }
`

export const PickPriceContainer = customStyled(FlexBox)<{ theme?: ThemeType }>`
    margin-top: 10px;
    margin-left: 5px;
    height: 40px;
    width: fit-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background: ${({ theme }) => theme.colors.blacks[1]};
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(9px);
    border-radius: 33px;
    border: 1px solid ${({ theme }) => theme.colors.darkGrey};
`
