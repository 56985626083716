import { customStyled, FlexBox, TextField, ThemeType } from '@rario/shared-components'

export const BuyPacksCarousel = customStyled(FlexBox)<{
  theme?: ThemeType
  isHomeLandingPage?: boolean
}>`
    width: ${({ isHomeLandingPage }) => (isHomeLandingPage ? '320px' : '360px')};
    height: 190px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.whites[4]};
    position: relative;
    box-shadow: inset 19.4989px -19.4989px 19.4989px rgba(13, 17, 21, 0.1);
    backdrop-filter: blur(9.66887px);
    @media (max-width: 360px){
        width: 320px;
    }
    @media (max-width: 340px){
        width: ${({ isHomeLandingPage }) => (isHomeLandingPage ? '320px' : '330px')}; 
    }
    -webkit-tap-highlight-color: transparent;
`

export const PackBoxContainer = customStyled(FlexBox)<{ theme?: ThemeType }>`
    border-radius: 5px;
    mask-image: linear-gradient(225deg, transparent 12px, white 12px);
    transform: translateZ(50%);
    & .card{
        border-radius: 5px;
        position: absolute;
        top: 0%;  
        left: 0%;
        width: 100%; 
        height: 100%;
        transform-style: preserve-3d;
        -webkit-backface-visibility: hidden
    }
    & .card .frontFace{
        border-radius: 5px;
        position: relative;
        width: 100%; 
        height: 100%;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        transform: translateZ(10px) scale(1, 1);
        transition: 0.5s;
        z-index: 1;
        background: linear-gradient(225deg, ${({ theme }) =>
          theme.colors.whites[4]} 19px, #101318 19px);
    }
    & .card.isFlipped .frontFace{
        border-radius: 5px;
        transform: translateZ(-10px) scale(-1, 1);
    }
    & .card .backFace{
        border-radius: 5px;
        position: absolute;
        top: 0;  
        left: 0;
        width: 100%;  
        height: 100%;
        backface-visibility: hidden;
        webkit-backface-visibility: hidden;
        transform: translateZ(-10px) scale(-1, 1);
        transition: 0.5s;
        background: linear-gradient(225deg, ${({ theme }) =>
          theme.colors.whites[4]} 19px, #101318 19px);
    }
    & .card.isFlipped .backFace{
        border-radius: 5px;
        transform: translateZ(10px) scale(1, 1);
        z-index: 2;
    }
    
`

export const PackBackgroundImageContainer = customStyled(FlexBox)<{ theme?: ThemeType }>`
    width: 90px;
    height: 110px;
    border-radius: 4px;
    mask-image: linear-gradient(220deg, transparent 10px, white 10px);
`

export const PackTitleContainer = customStyled(TextField)<{ truncate?: boolean }>`
  white-space: ${({ truncate }) => (truncate ? 'nowrap' : 'inherit')};
  overflow: hidden;
  text-overflow: ellipsis;
`

export const PackSubTitleContainer = customStyled(TextField)<{ truncate?: boolean }>`
  white-space: ${({ truncate }) => (truncate ? 'nowrap' : 'inherit')};
  overflow: hidden;
  text-overflow: ellipsis;
`

export const PackSummaryToolTip = customStyled(FlexBox)<{ theme?: ThemeType }>`   

    cursor: pointer;
     &:hover .tooltiptext {
        visibility: visible;
    }
     & .tooltiptext{
        visibility: hidden;
        position: absolute;
        z-index: 999999999999;
    }
    & .tooltiptext::before{
      content: "";
      position: absolute;
      bottom: 100%;
      left: 35%;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent ${({ theme }) => theme.gradients.tooltipBG} transparent;
    }
`

export const BonusTextWrapper = customStyled(FlexBox)<{
  theme?: ThemeType
  left?: string
}>`
    position: absolute;
    top: 100%;
    left: ${({ left = 0 }) => left};
    width: fit-content;
    height: 30px;
    background: linear-gradient(180deg, rgba(20, 32, 70, 0) 0%, #142046 43.23%);
    border-radius: 0px 0px 4px 4px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
`
export const CircularBlurredWrapper = customStyled(FlexBox)<{ theme?: ThemeType }>`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 40px;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(9px);
    border-radius: 33px;
    border: 1px solid ${({ theme }) => theme.colors.whites[3]};
    background: #000000;
`

export const BuyPageWidgetWrapper = customStyled(FlexBox)<{ theme?: ThemeType }>`
    position: absolute;
    background: linear-gradient(97.58deg, rgba(255, 79, 68, 0.4) 49.25%, rgba(255, 79, 68, 0) 125.89%),
                linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)),
                linear-gradient(180deg, rgba(255, 255, 255, 0.1) 4.17%, rgba(255, 255, 255, 0) 95.83%);
    backdrop-filter: blur(20px);
    border-radius: 100px;
    transition: width 0.5s, right 0.5s, top 0.5s;
    opacity: 1;
    top: 2px;
    right: -4px;
`
export const BuyPageWidgetTextWrapper = customStyled(TextField)<{ theme?: ThemeType }>`
    font-weight: 400;
    font-size: 12px!important;
    line-height: 16px!important;
    white-space: pre;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.white};
`

export const PackRibbonContainer = customStyled.div<{ theme?: ThemeType }>`
    background: ${({ theme }) => theme.colors.white};
    box-shadow: -2px 2px 0px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    position: absolute;
    left: 0%;
    z-index: 99;
    display: flex;
    bottom: 21px;
    width: auto;
    height: 24px;
    justify-content: center;
    align-items: center;
    clip-path: polygon(0% 0%, 100% 0, 95%  calc(100% - 30px), 100% 99%, 0% 100%);
    &::after{
    content: "";
    position: absolute;
    bottom: 2px;
    opacity: 0.5;
    z-index: 0;
    left: 3.2px;
    width: 0;
    height: 0;
    border-top: 8px solid white;
    border-left: 7px solid transparent;
    border-radius: 0px 9px 5px 30px;
}
    
@media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    height: auto;
    padding: 0.4rem 2%;
    left: 0%;
    bottom: 21px;
}
`

export const PackIconWrapper = customStyled.span<{ isLastIcon?: boolean }>`
  position: absolute;
  height: 36px;
  z-index: 9999;
  bottom: 75px;
`

export const BuyPacksBannerWrapper = customStyled(FlexBox)<{
  theme?: ThemeType
}>`
  width: 100%;
  height: 100px;
  border-radius: 5px;
`

export const PackQuantityWrapper = customStyled(FlexBox)`
  height: 45px;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(13.5px);
  border-radius: 5px;
`

export const PackLaunchWrapper = customStyled(FlexBox)<{ theme?: ThemeType }>`
  position: relative;
  background: rgb(38, 42, 30);
  background-clip: padding-box;
  border-radius: 5px, 5px, 5px, 0px;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    background: linear-gradient(260.3deg, #685415 11.71%, rgba(104, 84, 21, 0) 56.44%),linear-gradient(0deg, rgba(242, 191, 39, 0.08), rgba(242, 191, 39, 0.08)),linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  }
`

export const ViewAllPackContainer = customStyled(FlexBox)<{ theme?: ThemeType }>`
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    width: 160px;
    height: 190px; 
    margin-bottom: 0px;
    @media (max-width: 768px) {
      margin-bottom: 30px;
      overflow: hidden;
    }
    border-radius: 5px;
    mask-image: linear-gradient(225deg, transparent 12px, white 12px);
`
