const PanCardIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '18', height = '14', fill = 'none' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 9.75H14.5C14.9062 9.75 15.25 9.4375 15.25 9C15.25 8.59375 14.9062 8.25 14.5 8.25H11.5C11.0625 8.25 10.75 8.59375 10.75 9C10.75 9.4375 11.0625 9.75 11.5 9.75ZM6.5 9C7.59375 9 8.5 8.125 8.5 7C8.5 5.90625 7.59375 5 6.5 5C5.375 5 4.5 5.90625 4.5 7C4.5 8.125 5.375 9 6.5 9ZM16 0H2C0.875 0 0 0.90625 0 2V12C0 13.125 0.875 14 2 14H16C17.0938 14 18 13.125 18 12V2C18 0.90625 17.0938 0 16 0ZM16.5 12C16.5 12.2812 16.25 12.5 16 12.5H10C10 11.125 8.875 10 7.5 10H5.5C4.09375 10 3 11.125 3 12.5H2C1.71875 12.5 1.5 12.2812 1.5 12V4H16.5V12ZM11.5 7.25H14.5C14.9062 7.25 15.25 6.9375 15.25 6.5C15.25 6.09375 14.9062 5.75 14.5 5.75H11.5C11.0625 5.75 10.75 6.09375 10.75 6.5C10.75 6.9375 11.0625 7.25 11.5 7.25Z"
      fill={fill}
    />
  </svg>
)

export default PanCardIcon
