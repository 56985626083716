import { useUserAgent } from '@rario/shared-components'
import { VideoType, VideoWrapper } from './Video.styles'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { isBrowser } from 'utils/utils'

export interface VideoProps extends VideoType {
  autoPlay?: boolean
  src?: string | string[]
  controls?: boolean
  mediaType?: string | string[]
  playsinline?: boolean
  loop?: boolean
  onClick?: () => void
  poster?: string
  play?: boolean
  muted?: boolean
  preview?: boolean
  transform?: string
  children?: React.ReactNode
  onEnd?: () => void
  onCatchCallback?: () => void
  objectFit?: string
  playOutsideViewport?: boolean
}

const Video: React.FunctionComponent<VideoProps> = ({
  src,
  autoPlay = true,
  mediaType = 'video/mp4',
  playsinline = false,
  width = 'auto',
  height = 'auto',
  onClick: onCickHandler,
  poster,
  children,
  muted = false,
  controls = true,
  loop = false,
  preview = false,
  onEnd,
  onCatchCallback,
  objectFit,
  play = true,
  playOutsideViewport = false,
  ...rest
}) => {
  const [videoWrapperEl, setVidoWrapperEl] = useState<HTMLVideoElement | null>(null)

  const [localPlayState, setLocalPLayState] = useState<boolean>(autoPlay)

  const observer = useRef<IntersectionObserver | null>(
    isBrowser()
      ? new IntersectionObserver(
          (entries) => {
            entries.forEach((entry: any) => {
              if (entry.isIntersecting || playOutsideViewport) {
                setLocalPLayState(true)
              } else {
                setLocalPLayState(false)
              }
            })
          },
          {
            rootMargin: '0px',
            threshold: [0.25, 0.75],
          }
        )
      : null
  )

  useEffect(() => {
    const currentObserver = observer.current
    if (videoWrapperEl) {
      currentObserver?.observe(videoWrapperEl)
      if (localPlayState && play) {
        // videoWrapperEl.load()
        videoWrapperEl.play().catch((_e) => {
          onCatchCallback && onCatchCallback()
        })
      } else {
        videoWrapperEl.pause()
      }
    }

    return () => {
      if (videoWrapperEl) {
        currentObserver?.unobserve(videoWrapperEl)
      }
    }
  }, [videoWrapperEl, localPlayState, play])

  useEffect(() => {
    if (videoWrapperEl) {
      videoWrapperEl.addEventListener('ended', () => {
        if (onEnd) {
          onEnd()
        }
      })
    }

    return () => {
      if (videoWrapperEl) {
        videoWrapperEl.removeEventListener('ended', () => {})
      }
    }
  }, [videoWrapperEl])

  const onClickLocalHandler = useCallback(() => {
    if (onCickHandler) {
      onCickHandler()
    }
  }, [])

  useEffect(() => {
    if (videoWrapperEl) {
      videoWrapperEl.load()
      videoWrapperEl.play().catch((_e) => {
        onCatchCallback && onCatchCallback()
      })
    }
  }, [src])
  const [mediaindex, setMediaindex] = useState(0)
  const { browserName } = useUserAgent()

  useEffect(() => {
    setMediaindex(browserName === 'safari' ? 0 : 1)
  }, [browserName])

  return (
    <VideoWrapper
      ref={setVidoWrapperEl}
      poster={poster}
      controls={controls}
      height={height as string}
      width={width as string}
      playsInline={playsinline}
      muted={muted}
      loop={loop}
      onClick={onClickLocalHandler}
      objectFit={objectFit}
      {...rest}
    >
      {Array.isArray(src) ? (
        <source src={src[mediaindex]} type={mediaType[mediaindex]} />
      ) : (
        <source src={src} type={mediaType as string} />
      )}
      <p>Your browser doesn’t support mp4 or webm html5 videos.</p>
      {children}
    </VideoWrapper>
  )
}

export default Video
