const KYCIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '22', height = '18', fill = '#36B286' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0039 8.16481V4.19995V4.19667C17.0039 3.07875 17.0039 2.51945 16.7861 2.09204C16.5944 1.71572 16.288 1.40973 15.9117 1.21799C15.4839 1 14.9242 1 13.8041 1H4.2041C3.084 1 2.52353 1 2.0957 1.21799C1.71938 1.40973 1.41364 1.71572 1.22189 2.09204C1.00391 2.51986 1.00391 3.07985 1.00391 4.19995V8.1648C1.00391 14.7331 5.9717 17.6778 8.07786 18.6312C8.30114 18.7322 8.41306 18.7828 8.66578 18.8262C8.82518 18.8536 9.18337 18.8536 9.34277 18.8262C9.59406 18.7831 9.70514 18.7328 9.92589 18.6329L9.92969 18.6312C12.0358 17.6778 17.0039 14.7331 17.0039 8.16481Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default KYCIcon
