import customStyled from '../../theme/customStyled'
import { ThemeType } from '../../theme'

export const ListWrapper = customStyled.ul<{ theme?: ThemeType }>`
  list-style: none;
  position: absolute;
  background: ${({ theme }) => theme.colors.charcoal};
  width: 100%;
  // border: ${({ theme }) => `1px solid ${theme.colors.whiteSmoke}`}; 
  top: 100%;
  z-index: 9999;
  overflow: hidden;
  left: 0;
`
