import { customStyled } from '@rario/shared-components'
import { space, SpaceProps, HeightProps, WidthProps, width, height } from 'styled-system'

export type VideoType = SpaceProps & HeightProps & WidthProps

export const VideoWrapper = customStyled.video<
  SpaceProps & { transform?: string; objectFit?: string }
>`
  ${space};
  ${width};
  ${height};
  transform: ${({ transform = '' }) => transform};
  object-fit: ${({ objectFit = 'fill' }) => objectFit}
`
