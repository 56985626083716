const RarioNavIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
  opacity?: number
}> = ({ className = '', width = '24', height = '24', fill = 'white', opacity = 1 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17.1835 13.6391L20.768 10.2201C21.1646 9.84187 21.1646 9.20898 20.768 8.83075L17.1348 5.36532C16.9562 5.19501 16.7189 5.1 16.4722 5.1H2.64984C1.78359 5.1 1.3604 6.15678 1.98725 6.75468L5.55759 10.1601C5.73615 10.3304 5.97343 10.4254 6.22018 10.4254H12.4769L9.77002 12.9358C9.36433 13.3121 9.35985 13.9525 9.76023 14.3344L15.3172 19.6347C15.4958 19.805 15.733 19.9 15.9798 19.9H21.3502C22.2164 19.9 22.6396 18.8432 22.0128 18.2453L17.1835 13.6391Z"
      fill="url(#paint0_linear_1259_53099)"
      fillOpacity="0.25"
      stroke={fill}
      strokeWidth="1.8"
      opacity={opacity}
    />
    <defs>
      <linearGradient
        id="paint0_linear_1259_53099"
        x1="12.3958"
        y1="17.4706"
        x2="11.6312"
        y2="1.77352"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={fill} />
        <stop offset="1" stopColor={fill} stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default RarioNavIcon
