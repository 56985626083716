import { PaymentMethodEnum, SalesOrderGroupResult } from 'interfaces'
import { PurchaseSummaryContextProps } from 'interfaces/purchaseFlow'
import { createContext, ReactNode, useMemo, useState } from 'react'

const PurchaseSummaryContext = createContext<undefined | PurchaseSummaryContextProps>(undefined)

export const PurchaseSummaryProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [orderGroup, setOrderGroup] = useState<SalesOrderGroupResult | undefined>(undefined)
  const [selectedPG, setSelectedPG] = useState<PaymentMethodEnum | undefined>()
  const [isEnoughWalletBalance, setIsEnoughWalletBalance] = useState<boolean>(false)
  const [useBonusDiscount, setUseBonusDiscount] = useState<boolean>(false)
  const [isCheckoutValid, setCheckoutValid] = useState<boolean>(true)

  const value = useMemo(
    () => ({
      isLoading,
      setIsLoading,
      orderGroup,
      setOrderGroup,
      selectedPG,
      setSelectedPG,
      isEnoughWalletBalance,
      setIsEnoughWalletBalance,
      useBonusDiscount,
      setUseBonusDiscount,
      isCheckoutValid,
      setCheckoutValid,
    }),
    [
      isLoading,
      setIsLoading,
      orderGroup,
      setOrderGroup,
      selectedPG,
      setSelectedPG,
      isEnoughWalletBalance,
      setIsEnoughWalletBalance,
      useBonusDiscount,
      setUseBonusDiscount,
      isCheckoutValid,
      setCheckoutValid,
    ]
  )

  return <PurchaseSummaryContext.Provider value={value}>{children}</PurchaseSummaryContext.Provider>
}

export default PurchaseSummaryContext
