import { TextField, theme, trackEvent } from '@rario/shared-components'
import EmptyWalletIcon from 'assets/svg/EmptyWalletIcon'
import { WalletBoxWrapper } from 'components/Header/Header.styles'
import useCurrencyConversion from 'hooks/useCurrencyConversion'
import useUserWallet from 'hooks/useUserWallet'
import { useRouter } from 'next/router'

const WalletBalanceBox: React.FunctionComponent<{
  closeMobileMenuModal?: () => void
}> = ({ closeMobileMenuModal }) => {
  const router = useRouter()
  const { walletBalance } = useUserWallet()

  const [formattedWalletBalance] = useCurrencyConversion(walletBalance?.closingBalanceUSD || 0)

  return (
    <WalletBoxWrapper
      cursor="pointer"
      flexDirection="row"
      onClick={() => {
        trackEvent('Section Interacted', 'click', {
          sub_category: 'wallet-header_balance_box',
          click_type: 'link',
          category: 'wallet',
          click_action: 'wallet_balance_box_icon',
          view_type: 'constrained',
        })
        router.push('/wallet?referer=home')
        closeMobileMenuModal && closeMobileMenuModal()
      }}
      alignItems="center"
      justifyContent="space-around"
      height={40}
      paddingX="15px"
      border={`1px solid ${theme.colors.whites[3]}`}
      ml="10px"
    >
      <EmptyWalletIcon fill={theme.colors.white} />
      {walletBalance && (
        <TextField
          color={theme.colors.white}
          fontWeight="500"
          fontSize={'12px'}
          pb={0}
          lineHeight="16px"
          pl={'6px'}
        >
          {formattedWalletBalance}
        </TextField>
      )}
    </WalletBoxWrapper>
  )
}

export default WalletBalanceBox
