import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import {
  CarouselMain,
  CarousleWrapper,
  CraouselContainer,
  CraouselDots,
  CarouselSlider,
  CraouselViewport,
  CraouselSeparateSlider,
  AnimatedCarouselSlider,
  CarouselCustomDots,
  ThumbsViewport,
  ThumbsWrapper,
} from './Carousel.styles'
import React, { useCallback, useEffect, useState } from 'react'
import {
  AmbassadorCardLeftArrow,
  AmbassadorCardRightArrow,
  DotButton,
  LatestSaleCustomCarouselLeftArrow,
  LatestSaleCustomCarouselRightArrow,
  NextButton,
  PackRevealCustomCarouselLeftArrow,
  PackRevealCustomCarouselRightArrow,
  PrevButton,
  Thumb,
} from './CarouselButtons'
import { FlexBox } from '@rario/shared-components'

export enum CustomArrowsEnum {
  // eslint-disable-next-line no-unused-vars
  PACK_REVEAL = 'pack reveal',
  // eslint-disable-next-line no-unused-vars
  LATEST_CARD_SALE = 'latest card sale',
  // eslint-disable-next-line no-unused-vars
  PICKS = 'picks',
  // eslint-disable-next-line no-unused-vars
  AMBASSADOR = 'ambassador',
  // eslint-disable-next-line no-unused-vars
  TESTIMONIAL = 'testimonial',
}

interface CarouselThumbInterface {
  image: string
  name: string
}

interface GlobalCarouselProps {
  autoPlay?: boolean
  arrows?: boolean
  customArrows?:
    | CustomArrowsEnum.PACK_REVEAL
    | CustomArrowsEnum.LATEST_CARD_SALE
    | CustomArrowsEnum.PICKS
    | CustomArrowsEnum.AMBASSADOR
    | CustomArrowsEnum.TESTIMONIAL
  showDots?: boolean
  showSlider?: boolean
  sliderHighlightColor?: string
  showSeparateSlider?: boolean
  loop?: boolean
  slidesToScroll?: number
  slidesToScrollMobile?: number
  children: React.ReactNode
  dragFree?: boolean
  sidesFadeEffect?: boolean
  speed?: number
  sideSpacing?: boolean
  isInfoScreen?: boolean
  reInitCarousel?: boolean
  totalSlides?: number
  nextCallbackRef?: any
  setActiveIndex?: (i: number) => void
  showAnimatedSlider?: boolean
  stopOnLastSnap?: boolean
  stopOnInteraction?: boolean
  isPackReveal?: boolean
  thumbs?: CarouselThumbInterface[]
  showCustomDots?: boolean
  jumpToNextSlide?: boolean
}

const GlobalCarousel: React.FunctionComponent<GlobalCarouselProps> = ({
  children,
  loop = false,
  autoPlay = false,
  showDots = false,
  slidesToScroll = 1,
  slidesToScrollMobile = 1,
  arrows = true,
  customArrows,
  dragFree = false,
  sidesFadeEffect = true,
  speed = 10,
  showSlider = false,
  sliderHighlightColor = 'white',
  showSeparateSlider = false,
  sideSpacing = false,
  isInfoScreen = false,
  reInitCarousel = false,
  totalSlides = 0,
  setActiveIndex,
  nextCallbackRef = null,
  showAnimatedSlider = false,
  stopOnLastSnap = false,
  stopOnInteraction = false,
  isPackReveal = false,
  thumbs = [],
  showCustomDots = false,
  jumpToNextSlide = false,
}) => {
  const [viewportRef, embla]: any = useEmblaCarousel(
    {
      skipSnaps: false,
      slidesToScroll,
      loop,
      dragFree,
      containScroll: 'trimSnaps',
      speed,
      breakpoints: {
        '(max-width: 768px)': { slidesToScroll: slidesToScrollMobile },
      },
    },
    autoPlay ? [Autoplay({ stopOnLastSnap, stopOnInteraction })] : []
  )

  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
  })

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [scrollSnaps, setScrollSnaps] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
  const scrollTo = useCallback((index: number) => embla && embla.scrollTo(index), [embla])

  if (nextCallbackRef) {
    nextCallbackRef.current = scrollNext
  }

  useEffect(() => {
    if (setActiveIndex) {
      setActiveIndex(selectedIndex)
    }
  }, [selectedIndex])

  const onThumbClick = useCallback(
    (index: number) => {
      if (!embla || !emblaThumbsApi) return
      if (emblaThumbsApi.clickAllowed()) embla.scrollTo(index)
    },
    [embla, emblaThumbsApi]
  )

  const onSelect = useCallback(() => {
    if (!embla && !emblaThumbsApi) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
    emblaThumbsApi?.scrollTo(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    embla.on('select', onSelect)
    onSelect()
    totalSlides > 0
      ? setScrollSnaps(embla.scrollSnapList().slice(0, totalSlides))
      : setScrollSnaps(embla.scrollSnapList())
  }, [embla, onSelect, setScrollSnaps])

  useEffect(() => {
    if (!embla) return
    embla.scrollTo(0)
    embla.reInit()
    totalSlides > 0
      ? setScrollSnaps(embla.scrollSnapList().slice(0, totalSlides))
      : setScrollSnaps(embla.scrollSnapList())
  }, [embla, reInitCarousel])

  useEffect(() => {
    if (!embla) return
    if (jumpToNextSlide) {
      scrollNext()
    }
  }, [embla, jumpToNextSlide])

  return (
    <CarousleWrapper>
      {!customArrows && arrows && (
        <>
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </>
      )}
      {customArrows === CustomArrowsEnum.PACK_REVEAL && (prevBtnEnabled || nextBtnEnabled) && (
        <>
          <PackRevealCustomCarouselLeftArrow onClick={scrollPrev} enabled={prevBtnEnabled} />
          <PackRevealCustomCarouselRightArrow onClick={scrollNext} enabled={nextBtnEnabled} />
        </>
      )}
      {customArrows === CustomArrowsEnum.LATEST_CARD_SALE && (prevBtnEnabled || nextBtnEnabled) && (
        <>
          <LatestSaleCustomCarouselLeftArrow
            onClick={scrollPrev}
            enabled={prevBtnEnabled}
            isLatestSaleWidget={true}
          />
          <LatestSaleCustomCarouselRightArrow
            onClick={scrollNext}
            enabled={nextBtnEnabled}
            isLatestSaleWidget={true}
          />
        </>
      )}
      {customArrows === CustomArrowsEnum.PICKS && (prevBtnEnabled || nextBtnEnabled) && (
        <>
          <LatestSaleCustomCarouselLeftArrow
            onClick={scrollPrev}
            enabled={prevBtnEnabled}
            isLatestSaleWidget={false}
            isPicks
          />
          <LatestSaleCustomCarouselRightArrow
            onClick={scrollNext}
            enabled={nextBtnEnabled}
            isLatestSaleWidget={false}
            isPicks
          />
        </>
      )}
      {customArrows === CustomArrowsEnum.AMBASSADOR && (prevBtnEnabled || nextBtnEnabled) && (
        <>
          <AmbassadorCardLeftArrow
            onClick={scrollPrev}
            enabled={prevBtnEnabled}
            ambassadorsSection
            testimonialSection={false}
          />
          <AmbassadorCardRightArrow
            onClick={scrollNext}
            enabled={nextBtnEnabled}
            ambassadorsSection
            testimonialSection={false}
          />
        </>
      )}

      {customArrows === CustomArrowsEnum.TESTIMONIAL && (prevBtnEnabled || nextBtnEnabled) && (
        <>
          <AmbassadorCardLeftArrow
            onClick={scrollPrev}
            enabled={prevBtnEnabled}
            ambassadorsSection={false}
            testimonialSection
          />
          <AmbassadorCardRightArrow
            onClick={scrollNext}
            enabled={nextBtnEnabled}
            ambassadorsSection={false}
            testimonialSection
          />
        </>
      )}

      {!!thumbs.length && (
        <ThumbsViewport ref={emblaThumbsRef}>
          <ThumbsWrapper>
            {thumbs?.map((thumb: CarouselThumbInterface, index: number) => {
              return (
                <Thumb
                  onClick={() => onThumbClick(index)}
                  selected={selectedIndex === index}
                  imgSrc={thumb?.image}
                  name={thumb?.name}
                  key={`${thumb?.name}-${index}`}
                />
              )
            })}
          </ThumbsWrapper>
        </ThumbsViewport>
      )}
      <CarouselMain
        leftFade={prevBtnEnabled && sidesFadeEffect}
        rightFade={nextBtnEnabled && sidesFadeEffect}
      >
        <CraouselViewport ref={viewportRef}>
          <CraouselContainer
            selectedIndex={selectedIndex}
            sideSpacing={sideSpacing}
            isLastSlide={selectedIndex === totalSlides - 1}
          >
            {children}
          </CraouselContainer>
        </CraouselViewport>
      </CarouselMain>
      {showCustomDots && (
        <CarouselCustomDots absolutePosition={isPackReveal}>
          {scrollSnaps.map((_, index: number) => (
            <DotButton
              key={`carousel-dots-${index}`}
              selected={index === selectedIndex}
              onClick={() => null}
            />
          ))}
        </CarouselCustomDots>
      )}
      {showDots && (
        <CraouselDots>
          {scrollSnaps.map((_, index: number) => (
            <DotButton
              key={`carousel-dots-${index}`}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </CraouselDots>
      )}

      {showSlider && (
        <CarouselSlider
          position={isInfoScreen || isPackReveal ? 'absolute' : 'initial'}
          sliderHighlightColor={sliderHighlightColor}
          isInfoScreen={isInfoScreen}
          isPackReveal={isPackReveal}
        >
          {scrollSnaps.map((_, index: number) => (
            <DotButton
              key={`carousel-slider-${index}`}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </CarouselSlider>
      )}

      {showAnimatedSlider && (
        <AnimatedCarouselSlider
          position={isInfoScreen ? 'absolute' : 'initial'}
          sliderHighlightColor={sliderHighlightColor}
          isInfoScreen={isInfoScreen}
        >
          {scrollSnaps.map((_, index: number) => (
            <FlexBox
              minWidth={'35px'}
              background={index === selectedIndex ? 'white' : 'transparent'}
              key={`animated-carousel-slider-${index}`}
              mr="6px"
              maxHeight={'1px'}
            >
              <DotButton
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
                wasSelected={index < selectedIndex}
              />
            </FlexBox>
          ))}
        </AnimatedCarouselSlider>
      )}

      {showSeparateSlider && (
        <CraouselSeparateSlider>
          {scrollSnaps.map((_, index: number) => (
            <DotButton
              key={`carousel-separate-slider-${index}`}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </CraouselSeparateSlider>
      )}
    </CarousleWrapper>
  )
}

export default GlobalCarousel
