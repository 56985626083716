import { FunctionComponent, useRef, useEffect, useState } from 'react'
import { FlexBox, TextField, theme, trackEvent } from '@rario/shared-components'
import HelpCircleIconTag from 'assets/svg/HelpCircleIconTag'
import ChevronRightTag from 'assets/svg/ChevronRightTag'
import { HeadingContainer, ModalContentWrapper, ModalWrapper } from './HeadingContent.styles'

import debounce from 'utils/debounce'
import usePageControl from 'hooks/usePageControl'
import { useCustomUser } from 'contexts/CustomUserProvider'
import { getWelcomeState } from 'utils/utils'
import { WelcomeStateEnum } from 'interfaces/homePage'
import useOnClickOutside from 'helpers/hooks/useClickOutSide.hooks'

const HeadingContent: FunctionComponent<{
  heading?: string
  subHeading?: string
  rightIcon?: JSX.Element
  markerLeftPosition?: string
  children?: React.ReactNode
  widgetParentId?: string
  category?: string
}> = ({
  heading,
  subHeading,
  rightIcon,
  markerLeftPosition = '-15px',
  children,
  widgetParentId = '',
  category = '',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { isHomeLandingPage } = usePageControl()
  const [userClosedModal, setUserClosedModal] = useState(false)
  const { user } = useCustomUser()
  const userState = getWelcomeState(user)

  function isInViewport() {
    if (widgetParentId && !userClosedModal) {
      const element = document.querySelector(`#${widgetParentId}`)

      if (element) {
        const rect = element?.getBoundingClientRect()
        if (!rect) return false

        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        )
      }
    }
    return false
  }

  const scrollHandler = debounce(() => {
    if (isInViewport()) {
      setIsModalOpen(true)
    } else {
      setIsModalOpen(false)
    }
  }, 500)

  useEffect(() => {
    if (isHomeLandingPage && userState === WelcomeStateEnum.NEW_USER) {
      document.addEventListener('scroll', scrollHandler, { passive: true })
    }

    return () => document.removeEventListener('scroll', scrollHandler)
  }, [isInViewport])

  const ModalRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(ModalRef, () => setIsModalOpen(false))

  const trackHelperSection = (showModalOpen: boolean) => {
    trackEvent('Section Interacted', 'click', {
      sub_category: category + '_helper_widget',
      category,
      click_type: 'icon',
      heading,
      isModalOpen: showModalOpen,
    })
  }

  return (
    <FlexBox flexDirection="column">
      <FlexBox position={'relative'} alignItems="center">
        <FlexBox
          height={'22px'}
          position="absolute"
          left={markerLeftPosition}
          width="6px"
          borderRadius={'0px 10px 10px 0px'}
          background={theme.colors.secondaryBG}
        />
        <HeadingContainer content={heading} />
        {rightIcon && <FlexBox ml="5px">{rightIcon}</FlexBox>}
      </FlexBox>
      {subHeading && (
        <TextField
          pt="3px"
          ml={'5px'}
          fontWeight="400"
          fontSize={'12px'}
          lineHeight="16px"
          color={theme.colors.whites[9]}
          content={subHeading}
        />
      )}
      {children && (
        <ModalWrapper isModalOpen={isModalOpen} ref={ModalRef}>
          <FlexBox
            onClick={() => {
              setUserClosedModal(true)
              setIsModalOpen((val) => !val)
              trackHelperSection(!isModalOpen)
            }}
            className={`modal-before-content`}
            width={'28px'}
            height="100%"
            alignItems={'center'}
            cursor={'pointer'}
            zIndex={9999990}
          >
            <FlexBox position={'absolute'}>
              {isModalOpen ? (
                <ChevronRightTag fill={theme.colors.widgetBG} />
              ) : (
                <HelpCircleIconTag fill={theme.colors.widgetBG} />
              )}
            </FlexBox>
          </FlexBox>
          <ModalContentWrapper isModalOpen={isModalOpen}>{children}</ModalContentWrapper>
        </ModalWrapper>
      )}
    </FlexBox>
  )
}
export default HeadingContent
