import ChevronLeft from 'assets/svg/ChevronLeft'
import ChevronRight from 'assets/svg/ChevronRight'
import LeftArrowIcon from 'assets/svg/LeftArrowIcon'
import RightArrowIcon from 'assets/svg/RightArrowIcon'
import React from 'react'

import {
  AmbassadorArrowWrapper,
  LatestSaleArrowWrapper,
  PackRevealArrowWrapper,
  ThumbButton,
  ThumbContainer,
} from './Carousel.styles'
import { Image, theme } from '@rario/shared-components'

export const DotButton = ({ selected, onClick, wasSelected = false }) => (
  <span
    className={`embla__dot ${selected ? 'is-selected' : ''} ${wasSelected ? 'was-selected' : ''}`}
    onClick={onClick}
  />
)

export const PrevButton = ({ enabled, onClick }) => (
  <button
    aria-label="Carousel Prev Button"
    className="embla__button embla__button--prev"
    onClick={onClick}
    disabled={!enabled}
  >
    <LeftArrowIcon
      className="embla__button__svg"
      opacity={!enabled ? '0.3' : '1'}
      fill="white"
      width="25"
      height="17"
    />
  </button>
)

export const NextButton = ({ enabled, onClick }) => (
  <button
    aria-label="Carousel Next Button"
    className="embla__button embla__button--next"
    onClick={onClick}
    disabled={!enabled}
  >
    <RightArrowIcon
      className="embla__button__svg"
      opacity={!enabled ? '0.3' : '1'}
      fill="white"
      width="25"
      height="17"
    />
  </button>
)
export const PackRevealCustomCarouselLeftArrow = ({ enabled, onClick }) => {
  return (
    <PackRevealArrowWrapper
      aria-label="Left Arrow"
      className="embla__button embla__button--prev"
      onClick={onClick}
      disabled={!enabled}
      border={!enabled ? `1px solid ${theme.colors.whites[5]}` : `1px solid ${theme.colors.white}`}
    >
      <ChevronLeft
        className="embla__button__svg"
        opacity={!enabled ? '0.3' : '1'}
        fill="white"
        width="28"
        height="28"
      />
    </PackRevealArrowWrapper>
  )
}

export const PackRevealCustomCarouselRightArrow = ({ enabled, onClick }) => {
  return (
    <PackRevealArrowWrapper
      aria-label="Right Arrow"
      className="embla__button embla__button--next"
      onClick={onClick}
      disabled={!enabled}
      pos="right"
      border={!enabled ? `1px solid ${theme.colors.whites[5]}` : `1px solid ${theme.colors.white}`}
    >
      <ChevronRight
        className="embla__button__svg"
        opacity={!enabled ? '0.3' : '1'}
        fill="white"
        width="28"
        height="28"
      />
    </PackRevealArrowWrapper>
  )
}
export const LatestSaleCustomCarouselLeftArrow = ({
  enabled,
  onClick,
  isLatestSaleWidget,
  isPicks = false,
}) => {
  return (
    <LatestSaleArrowWrapper
      aria-label="Left Arrow"
      className={`embla__button embla__button--prev ${isPicks && 'picks'}`}
      onClick={onClick}
      disabled={!enabled}
      border={
        !enabled ? `1px solid ${theme.colors.whites[0]}` : `1px solid ${theme.colors.whites[6]}`
      }
      background={!enabled ? `transparent` : `${theme.colors.whites[5]} !important;`}
      isLatestSaleWidget={isLatestSaleWidget}
    >
      <ChevronLeft
        className="embla__button__svg"
        opacity={!enabled ? '0' : '1'}
        fill={!enabled ? 'none' : 'white'}
        width="28"
        height="28"
      />
    </LatestSaleArrowWrapper>
  )
}
export const LatestSaleCustomCarouselRightArrow = ({
  enabled,
  onClick,
  isLatestSaleWidget,
  isPicks = false,
}) => {
  return (
    <LatestSaleArrowWrapper
      aria-label="Right Arrow"
      className={`embla__button embla__button--next ${isPicks && 'picks'}`}
      onClick={onClick}
      disabled={!enabled}
      pos="right"
      border={
        !enabled ? `1px solid ${theme.colors.whites[0]}` : `1px solid ${theme.colors.whites[6]}`
      }
      background={!enabled ? `transparent` : `${theme.colors.whites[5]} !important;`}
      isLatestSaleWidget={isLatestSaleWidget}
    >
      <ChevronRight
        className="embla__button__svg"
        opacity={!enabled ? '0' : '1'}
        fill={!enabled ? 'none' : 'white'}
        width="28"
        height="28"
      />
    </LatestSaleArrowWrapper>
  )
}
// export const PackRevealCustomCarouselLeftArrow = ({ enabled, onClick }) => {

//   return (
//     <CustomArrowWrapper
//       className="embla__button embla__button--prev"
//       onClick={onClick}
//       disabled={!enabled}
//       border={!enabled ? `1px solid ${theme.colors.whites[5]}` : `1px solid ${theme.colors.white}`}
//     >
//       <ChevronLeft
//         className="embla__button__svg"
//         opacity={!enabled ? '0.3' : '1'}
//         fill="white"
//         width="28"
//         height="28"
//       />
//     </CustomArrowWrapper>
//   )
// }

// export const PackRevealCustomCarouselRightArrow = ({ enabled, onClick }) => {

//   return (
//     <CustomArrowWrapper
//       className="embla__button embla__button--next"
//       onClick={onClick}
//       disabled={!enabled}
//       pos="right"
//       border={!enabled ? `1px solid ${theme.colors.whites[5]}` : `1px solid ${theme.colors.white}`}
//     >
//       <ChevronRight
//         className="embla__button__svg"
//         opacity={!enabled ? '0.3' : '1'}
//         fill="white"
//         width="28"
//         height="28"
//       />
//     </CustomArrowWrapper>
//   )
// }

export const AmbassadorCardLeftArrow = ({
  enabled,
  onClick,
  ambassadorsSection = false,
  testimonialSection = false,
}) => {
  return (
    <AmbassadorArrowWrapper
      aria-label="Carousel Prev Button"
      className={`embla__button embla__button--prev ${testimonialSection && 'testimonial'}`}
      onClick={onClick}
      disabled={!enabled}
      border={
        !enabled ? `1px solid ${theme.colors.whites[0]}` : `1px solid ${theme.colors.secondaryBG}`
      }
      background={!enabled ? `transparent` : `${theme.colors.secondaryBG} !important;`}
      ambassadorSection={ambassadorsSection}
    >
      <ChevronLeft
        className="embla__button__svg"
        opacity={!enabled ? '0' : '1'}
        fill={!enabled ? 'none' : theme.colors.primaryText}
        width="14"
        height="14"
      />
    </AmbassadorArrowWrapper>
  )
}

export const AmbassadorCardRightArrow = ({
  enabled,
  onClick,
  ambassadorsSection = false,
  testimonialSection = false,
}) => {
  return (
    <AmbassadorArrowWrapper
      aria-label="Carousel Next Button"
      className={`embla__button embla__button--next ${testimonialSection && 'testimonial'}`}
      onClick={onClick}
      disabled={!enabled}
      pos="right"
      border={
        !enabled ? `1px solid ${theme.colors.whites[0]}` : `1px solid ${theme.colors.secondaryBG}`
      }
      background={!enabled ? `transparent` : `${theme.colors.secondaryBG} !important;`}
      ambassadorSection={ambassadorsSection}
    >
      <ChevronRight
        className="embla__button__svg"
        opacity={!enabled ? '0' : '1'}
        fill={!enabled ? 'none' : theme.colors.primaryText}
        width="14"
        height="14"
      />
    </AmbassadorArrowWrapper>
  )
}

export const Thumb = ({ selected, onClick, imgSrc, name }) => {
  return (
    <ThumbContainer
      className={`embla-thumbs__slide ${selected ? ' embla-thumbs__slide--selected' : ''}`}
      selected={selected}
    >
      <ThumbButton
        onClick={onClick}
        className="embla-thumbs__slide__button"
        type="button"
        aria-label="Thumb"
      >
        <Image
          className="embla-thumbs__slide__img"
          src={imgSrc}
          name={name}
          height={'90px'}
          width={'59px'}
        />
      </ThumbButton>
    </ThumbContainer>
  )
}
