import { FlexBox, TextField, theme } from '@rario/shared-components'
import useCurrencyConversion from 'hooks/useCurrencyConversion'
import { SideMenuItemsEnum } from 'interfaces'
import { IconWrapperSquare } from './Header.styles'

const MobileHeaderItem: React.FunctionComponent<{
  name: string
  subtitle: string
  onItemClick: () => void
  getActiveIcon: Function
  icon?: React.ReactElement
}> = ({ name, subtitle, onItemClick, getActiveIcon, icon }) => {
  const [formattedRefererBonus] = useCurrencyConversion(10, { hasFixedExchangeRate: true })
  const title =
    name === SideMenuItemsEnum.REFER_EARN ? `${name} **${formattedRefererBonus}**` : name

  return (
    <FlexBox py={'12.5px'} onClick={() => onItemClick()}>
      <IconWrapperSquare>{icon && getActiveIcon(icon)}</IconWrapperSquare>
      <FlexBox flexDirection={'column'} pl="10px" width={'80%'}>
        <TextField
          fontSize={16}
          lineHeight={'22px'}
          fontWeight="600"
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          highlightColor={theme.colors.secondary}
        >
          {title}
        </TextField>
        <TextField fontSize={'12px'} lineHeight={'16px'} fontWeight="400" opacity={0.6}>
          {subtitle}
        </TextField>
      </FlexBox>
    </FlexBox>
  )
}

export default MobileHeaderItem
