const RarioBadge: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  color?: string
}> = ({ className = '', width = '42', height = '30', color = 'white' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 42 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.5757 17.6287L39.1094 8.13559L30.939 0H0L8.17044 8.13559H26.8538L17.32 17.6287L29.7443 30H42L29.5757 17.6287Z"
      fill={color}
    />
  </svg>
)

export default RarioBadge
