const D3NavIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
  opacity?: number
}> = ({ className = '', width = '25', height = '24', fill = 'white', opacity = 1 }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 23 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={opacity}>
      <path
        d="M16.3724 15.5C13.7637 15.5 12 13.9182 12 13.9182L13.4957 11.432C13.4957 11.432 14.78 12.259 15.3259 12.3721C15.3259 12.3721 17.8007 13.0514 18.2559 10.721C18.3466 10.0009 18.1411 9.44887 17.9054 9.20442C17.6083 8.89682 17.1379 8.72673 16.4772 8.72673L14.1031 8.64321L17.7654 3.1594H17.4733H13.665L12.2478 0.5H23L18.9539 6.35557C19.3055 6.40752 19.7154 6.49715 19.9783 6.63567C20.3429 6.82715 20.6562 7.10318 20.911 7.45559C21.1618 7.80291 21.3491 8.23986 21.468 8.75423C21.5848 9.26146 21.614 9.8624 21.5556 10.5418C21.4851 11.3586 21.3068 12.0767 21.0258 12.6776C20.7438 13.2806 20.3802 13.7929 19.9461 14.2014C19.5099 14.6118 19.0043 14.9205 18.4453 15.1191C17.8913 15.3156 16.9838 15.498 16.3734 15.498L16.3724 15.5Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8405 2.95383C10.7016 2.47398 10.463 2.05077 10.1247 1.68212C9.78649 1.31451 9.3457 1.02516 8.80429 0.815096C8.26289 0.605032 7.61129 0.5 6.84949 0.5H1.2208L2.04201 1.85821L1.0416 14.1418L0 15.5H5.62869C6.39049 15.5 7.05934 15.3919 7.63524 15.1746C8.21114 14.9573 8.69984 14.6618 9.09847 14.288C9.4971 13.9132 9.80565 13.4828 10.0232 12.9967C10.2407 12.5107 10.372 11.9979 10.416 11.4594L10.9843 4.48195C11.0284 3.9434 10.9795 3.43369 10.8405 2.95486V2.95383ZM7.15516 11.4594C7.1245 11.8404 6.96927 12.1627 6.68946 12.4252C6.40966 12.6878 6.04936 12.8196 5.60761 12.8196H4.41077L5.20036 3.12168H6.3972C6.8399 3.12168 7.17912 3.25348 7.41485 3.51606C7.65153 3.77864 7.75406 4.10095 7.7234 4.48195L7.15516 11.4594Z"
        fill={fill}
      />
    </g>
  </svg>
)

export default D3NavIcon
