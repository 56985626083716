import ErrorInternalServer from 'components/CustomError/ErrorInternalServer'
import React from 'react'

class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_error: any) {
    return { hasError: true }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log('print', { error, errorInfo })
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      return <ErrorInternalServer />
    }

    // Return children components in case of no error
    return this.props.children
  }
}

export default ErrorBoundary
