import styled from '@emotion/styled'
import { margin, MarginProps } from 'styled-system'

export const LoaderContainer = styled.div<
  {
    height?: string
  } & MarginProps
>`
  width: 100%;
  height: ${({ height = '100vh' }) => height};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${margin};
`

export const CircularProgress = styled.div`
  height: 100px;
  width: 90px;
  position: relative;
`

export const Inner = styled.div`
  position: absolute;
  z-index: 6;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  margin: -40px 0 0 -40px;
  // background: #191919;
  border-radius: 100%;
`

export const ProgressNumber = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  font-size: 18px;
  font-weight: 500;
  color: #4158d0;
`
