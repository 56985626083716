import { ReactNode, useContext, useEffect, useMemo, useState, createContext } from 'react'
import { getAuthToken } from 'api/Auth.api'

export const CustomAuthContext = createContext<{
  accessToken: string
  isAuthLoading: boolean
}>({
  accessToken: '',
  isAuthLoading: true,
})

export const useCustomAuth = () => useContext(CustomAuthContext)

const CustomAuthProvider = ({ children }: { children: ReactNode }) => {
  const [accessToken, setAccessToken] = useState<string>('')
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(true)

  useEffect(() => {
    getAuthToken()
      .then((res: { accessToken: string }) => {
        setAccessToken(res.accessToken)
        setIsAuthLoading(false)
      })
      .catch(() => {
        setIsAuthLoading(false)
      })
  }, [])

  const value = useMemo(
    () => ({
      accessToken,
      isAuthLoading,
    }),
    [accessToken, isAuthLoading]
  )

  return <CustomAuthContext.Provider value={value}>{children}</CustomAuthContext.Provider>
}

export default CustomAuthProvider
