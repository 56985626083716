import { FlexBox, customStyled, ThemeType } from '@rario/shared-components'

export const TableWrapper = customStyled.table<{ theme?: ThemeType }>`
  border-collapse: collapse;
  width: 100%;
  tr:nth-of-type(2n) {
    background: ${({ theme }) => theme.colors.darkGunmetal};
  }
`

export const TableRow = customStyled.tr<{
  theme?: ThemeType
  addBorder?: boolean
}>`
border-bottom: ${({ theme, addBorder }) =>
  addBorder ? `1px solid ${theme.colors.whites[4]}` : 'none'}};
`

export const TableRowData = customStyled.td<{
  position?: boolean
  isPlayerPool?: boolean
  showCursor?: boolean
  isOpen?: boolean
}>`
  position:${({ position = false }) => (position ? 'relative' : 'static')};
  padding: 15px;
  span {
    display: ${({ isPlayerPool = false }) => (isPlayerPool ? 'flex' : 'inline')};
    height: ${({ isPlayerPool = false, isOpen = false }) =>
      isPlayerPool && !isOpen ? '15px' : 'auto'};
    transition: height .4s  ease;
    overflow: hidden;
  }
  cursor: ${({ showCursor = false }) => (showCursor ? 'pointer' : 'auto')};
`

export const Drops = customStyled(FlexBox)<{ isOpen?: boolean }>`
transform:${({ isOpen = false }) => (isOpen ? 'rotateZ(0deg)' : 'rotateZ(180deg)')};
transition:transform .4s;
position: absolute;
left: 10px;
top: 22px;
`
