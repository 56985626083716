import { UTM_COOKIES } from 'constant'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'

const useD3Link = (path = '/d3/game/app/arena') => {
  const [d3Link, setD3Link] = useState(path)
  const [cookies] = useCookies([
    UTM_COOKIES.UTM_SOURCE,
    UTM_COOKIES.UTM_CAMPAIGN,
    UTM_COOKIES.UTM_CONTENT,
    UTM_COOKIES.UTM_MEDIUM,
    UTM_COOKIES.UTM_REF,
  ])

  useEffect(() => {
    const utm_source = cookies?.utm_source?.toString() || 'rario'
    const utm_campaign = cookies?.utm_campaign?.toString() || 'R2D3'
    const utm_medium = cookies?.utm_medium?.toString() || 'homepage'
    const utm_id = cookies?.utm_id?.toString() || '1'
    const utm_ref = cookies?.utm_ref?.toString() || ''
    // const currentDateTime = new Date().getTime()

    const queryParams = new URLSearchParams()
    // queryParams.append('nocache', currentDateTime.toString())
    queryParams.append('utm_source', utm_source)
    queryParams.append('utm_campaign', utm_campaign)
    queryParams.append('utm_medium', utm_medium)
    queryParams.append('utm_id', utm_id)
    queryParams.append('utm_ref', utm_ref)
    setD3Link((path) => (path += `?${queryParams.toString()}`))
  }, [cookies])

  return d3Link
}

export default useD3Link
