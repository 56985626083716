const HeaderTelegramIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  viewBox?: string
  fill?: string
}> = ({ width = '24', height = '24', viewBox = '0 0 24 24', fill = 'none' }) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM17.894 8.221L15.924 17.501C15.779 18.159 15.387 18.319 14.84 18.009L11.84 15.799L10.394 17.193C10.3231 17.2847 10.2322 17.359 10.1282 17.4101C10.0242 17.4613 9.90989 17.4879 9.794 17.488H9.789L10.002 14.434L15.562 9.412C15.802 9.199 15.508 9.078 15.189 9.291L8.32 13.617L5.36 12.693C4.72 12.49 4.702 12.053 5.495 11.739L17.061 7.281C17.599 7.085 18.067 7.409 17.893 8.222L17.894 8.221Z"
      fill="#039BE5"
    />
  </svg>
)

export default HeaderTelegramIcon
