import API_CONSTANTS from './constants'
import { doGet, doPost } from 'api'
import {
  formDataSocialLoginType,
  googleAuthTokenFormDataType,
  googleAuthRecaptchaValidatePayload,
  LoginSignupPayload,
  LoginSignupFormData,
  ResetPasswordFormType,
  verificationResultType,
  AuthTokenType,
} from 'interfaces/auth'

export const getAuthToken = () => {
  return doGet(API_CONSTANTS.AUTH_TOKEN, { isAbsUrl: true })
}

export const userLogin = (formdata: LoginSignupFormData, redirectTo?: string) => {
  return doPost(
    API_CONSTANTS.AUTH_V2_LOGIN,
    { redirectTo, isAbsUrl: true },
    {
      headers: {
        'X-DREAMAUTH-AUTHORIZATION': process.env.DREAM_AUTH_KEY || '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formdata),
    }
  )
}

export const userSignup = (formdata: LoginSignupFormData) => {
  return doPost(
    API_CONSTANTS.AUTH_V2_SIGNUP,
    { isAbsUrl: true },
    {
      headers: {
        'X-DREAMAUTH-AUTHORIZATION': process.env.DREAM_AUTH_KEY || '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formdata),
    }
  )
}

export const validateRecaptcha = (event: googleAuthRecaptchaValidatePayload, referer: string) => {
  return doPost(
    API_CONSTANTS.GOOGLE_AUTH_VALIDATE_CAPTCHA,
    {
      isAbsUrl: true,
      apiKey: process.env.RECAPTCHA_ENTERPRISE_API_KEY || '',
    },
    {
      headers: {
        Referer: referer,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ event }),
    }
  )
}

export const userLoginCallback = (
  formdata: LoginSignupPayload,
  ip: string | string[] | undefined
): Promise<AuthTokenType> => {
  return doPost(
    API_CONSTANTS.DREAM_AUTH_LOGIN,
    { isAbsUrl: true },
    {
      headers: {
        'X-DREAMAUTH-AUTHORIZATION': process.env.DREAM_AUTH_KEY || '',
        'Content-Type': 'application/json',
        'x-user-ip': ip,
      },
      body: JSON.stringify(formdata),
    }
  )
}

export const userSignupCallback = (
  formdata: LoginSignupPayload,
  ip: string | string[] | undefined
): Promise<AuthTokenType> => {
  return doPost(
    API_CONSTANTS.DREAM_AUTH_SIGNUP,
    { isAbsUrl: true },
    {
      headers: {
        'X-DREAMAUTH-AUTHORIZATION':
          typeof window === 'undefined' ? process.env.DREAM_AUTH_KEY : '',
        'Content-Type': 'application/json',
        'x-user-ip': ip,
      },
      body: JSON.stringify(formdata),
    }
  )
}

export const getRefreshedToken = (formdata: { refreshToken: string }) => {
  return doPost(
    API_CONSTANTS.DREAM_AUTH_REFRESH_TOKEN,
    { isAbsUrl: true },
    {
      headers: {
        'X-DREAMAUTH-AUTHORIZATION':
          typeof window === 'undefined' ? process.env.DREAM_AUTH_KEY : '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formdata),
    }
  )
}

export const verifyEmailResetPasswordStep1 = (formdata: { email: string; action: string }) => {
  return doPost(
    API_CONSTANTS.VERIFY_EMAIL_RESET_PASSWORD,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formdata),
    }
  )
}

export const verifyEmailResetPasswordStep2 = (token: string) => {
  return doGet(
    API_CONSTANTS.VERIFY_EMAIL_RESET_PASSWORD_TOKEN,
    { token },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

export const verifyEmailTokenStep3 = (formdata: verificationResultType, token: string) => {
  return doPost(
    API_CONSTANTS.VERIFY_EMAIL_TOKEN_END,
    { token },
    {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formdata),
    }
  )
}

export const ResetPasswordStep3 = (formdata: ResetPasswordFormType, token: string) => {
  return doPost(
    API_CONSTANTS.RESET_PASSWORD_TOKEN_END,
    { token },
    {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formdata),
    }
  )
}

export const googleAuthToken = (data: googleAuthTokenFormDataType) => {
  return doPost(
    API_CONSTANTS.GOOGLE_AUTH_TOKEN,
    { isAbsUrl: true },
    {
      body: JSON.stringify(data),
    }
  )
}

export const googleAuth = (data: { code: string }) => {
  return doPost(
    API_CONSTANTS.GOOGLE_AUTH,
    { isAbsUrl: true },
    {
      body: JSON.stringify(data),
    }
  )
}

export const socialLoginCallback = (data: formDataSocialLoginType, userIp?: string) => {
  return doPost(
    API_CONSTANTS.DREAM_AUTH_SOCIAL_LOGIN,
    { isAbsUrl: true },
    {
      headers: {
        'X-DREAMAUTH-AUTHORIZATION':
          typeof window === 'undefined' ? process.env.DREAM_AUTH_KEY : '',
        'Content-Type': 'application/json',
        'x-user-ip': userIp,
      },
      body: JSON.stringify(data),
    }
  )
}

export const logoutUser = (formData: { refreshToken: string }, accessToken: string) => {
  return doPost(
    API_CONSTANTS.DREAM_AUTH_LOGOUT,
    { isAbsUrl: true },
    {
      headers: {
        'X-DREAMAUTH-AUTHORIZATION':
          typeof window === 'undefined' ? process.env.DREAM_AUTH_KEY : '',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    }
  )
}
