import { customStyled, FlexBox, ThemeType } from '@rario/shared-components'

export const ErrorMessageBoxContainer = customStyled.div<{ theme?: ThemeType }>`
    position:absolute;
    right:0;
    left:0;
    bottom:-64px;
    @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
        position:unset;
        margin-top:10px;
    } 
    
`
export const PhoneVerificationForm = customStyled.form<{ theme?: ThemeType }>`
flex-direction:column;
    & .active  {
        border: 1px solid ${({ theme }) => theme.colors.whites[8]};
    }
    & .error {
        border: 1px solid ${({ theme }) => theme.colors.atomicTangerine};
    }
`

export const PhoneVerificationFormContainer = customStyled.form<{ theme?: ThemeType }>`
flex-direction:column;
    & .active  {
        border: 1px solid ${({ theme }) => theme.colors.whites[8]};
    }
    & .error {
        border: 1px solid ${({ theme }) => theme.colors.atomicTangerine};
    }
`

export const MobileInputBox = customStyled.input<{ theme?: ThemeType }>`
width:100%;
height:35px;
font-weight: 600;
font-size: 14px;
line-height: 20px;
background:${({ theme }) => theme.colors.chineseBlack};
font-family: ${({ theme }) => theme.fonts.primary};
letter-spacing: ${({ theme }) => theme.letterSpacings.primary};
padding: 20px 10px;
color:${({ theme }) => theme.colors.white};
border: 1px solid ${({ theme }) => theme.colors.whites[4]};
border-radius: 5px;
// &:focus { border: 2px solid ${({ theme }) => theme.colors.lightMint}; + label{
//     opacity:1;
// } }
&.success{
    border: 1px solid ${({ theme }) => theme.colors.whites[8]};

  }
  &.error{
    border: 1px solid ${({ theme }) => theme.colors.atomicTangerine};
}
 &:disabled {
        color: ${({ theme }) => theme.colors.whites[8]}
    }
&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

`
export const CountryWrapper = customStyled(FlexBox)`
justify-items:center;
align-items:center;
align-content:center;
border-radius: 5px;
margin-bottom:10px;
justify-content:center;
position:relative;
`
export const ModalFooter = customStyled(FlexBox)<{ theme?: ThemeType; showBlur?: boolean }>`
    backdrop-filter:${({ showBlur }) => (showBlur ? 'blur(20px)' : 'none')} ;
    z-index:99;
    background: rgba(255, 255, 255, 0.04);
    border-top: 1.4px solid;
    border-image-slice: 1;
    border-image-source:${({ theme }) => theme.gradients.lightGreyBorder};
    @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    transform:translateX(-50%);
    } 
`
export const CountrySearchInput = customStyled.input<{ theme?: ThemeType }>`
background:transparent;
border:none;
font-weight: 600;
font-size: 14px;
line-height: 20px;
font-family: ${({ theme }) => theme.fonts.primary};
letter-spacing: ${({ theme }) => theme.letterSpacings.primary};
color:white;
width: 100%;
height: 100%;
padding:12.5px;
&:-webkit-autofill {
    -webkit-background-clip: text;
}
`
export const InputWrapper = customStyled(FlexBox)<{ theme?: ThemeType }>`
flex-direction:row;
align-items:center;
padding-inline:10px;
border: 1px solid ${({ theme }) => theme.colors.whites[4]};
&:focus-within { border: 1px solid ${({ theme }) => theme.colors.whites[10]} !important;}
// width:50%;
justify-content:space-between;
cursor:pointer;
height:42px;
border-radius:5px;
&.errors{
    border: 1px solid ${({ theme }) => theme.colors.tartOrange} !important;
}
&.success{
  border: 1px solid ${({ theme }) => theme.colors.whites[8]};
}
`
export const CountryListWrapper = customStyled.div<{ theme?: ThemeType; isKyc?: boolean }>`
flex-direction:column;
display:flex;
padding-top:23px;
height:${({ isKyc = false }) => (isKyc ? '335px' : '248px')};
overflow-y:scroll;
width:100%;
background:${({ theme }) => theme.colors.gunMetalGrey};
&.countrylist{
    // padding:20px;
    overflow:scroll;
    overflow-x:hidden;
    }
/* width */
&.countrylist::-webkit-scrollbar {
  width: 7px;
  background:${({ theme }) => theme.colors.gunMetalGrey};
}
/* Track */
&.countrylist::-webkit-scrollbar-track {
  border-radius: 10px;
  border-radius: 15px;
  margin: 22px;
 box-shadow: inset 7px 10px 12px black;
}
 
/* Handle */
&.countrylist::-webkit-scrollbar-thumb {
  background:${({ theme }) => theme.colors.white};
  height:50px;
  border-radius: 5px;
}


`
export const ModalContainer = customStyled(FlexBox)<{ theme?: ThemeType }>`
height:63vh;
min-height:300px;
padding:16px 0px;
z-index:999999;
flex-direction:column;
width:100%;
overflow-y:auto;
@media(max-height:697px) {
    height:70vh;
}
`
