import { Browser, getBrowserName } from 'shared-components/common/utils'
import { isBrowser } from '../utils'

const REGEX = /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i

const useUserAgent = (userAgent = ''): { isMobile: boolean; browserName: Browser } => {
  let ua = userAgent
  if (!ua) {
    ua = isBrowser() ? navigator.userAgent : ''
  }
  return { isMobile: Boolean(ua.match(REGEX)), browserName: getBrowserName(ua) }
}

export default useUserAgent
