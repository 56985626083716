import {
  GTInnerCircleBenefitsInterface,
  IPLTeamPageType,
  ModalSectionType,
} from 'interfaces/rarioLanding'
import { getCDNUrl } from 'utils/utils'

export const IPL_BASE_PATH = '/innercircle/indiat20'

export const IPLTeamFirstFold = {
  GT: {
    image: getCDNUrl('/App/images/gt-landing/gt-first-fold-bg-v2.png'),
    subHeading: 'Own ultra-rare official fan badges of your favourite Titans and unlock',
    backgroundImage: getCDNUrl('/App/images/gt-landing/gt-ff-bottom-bg-v3.png'),
    name: 'GT-landing-image',
    video: getCDNUrl('/App/videos/indiat20-landing/gt-ff-video.mp4'),
  },
  RCB: {
    image: getCDNUrl('/App/images/rcb-landing/rcb-first-fold-bg.png'),
    subHeading: 'Own ultra-rare official fan badges of your favourite Challengers and unlock',
    backgroundImage: getCDNUrl('/App/images/rcb-landing/rcb-ff-bottom-bg.png'),
    name: 'RCB-landing-image',
    video: getCDNUrl('/App/videos/indiat20-landing/rcb-ff-video.mp4'),
  },
  KKR: {
    image: getCDNUrl('/App/images/kkr-landing/kkr-first-fold-bg.png'),
    subHeading: 'Own ultra-rare official fan badges of your favourite Knight Riders and unlock',
    backgroundImage: getCDNUrl('/App/images/kkr-landing/kkr-ff-bottom-bg.png'),
    name: 'KKR-landing-image',
    video: getCDNUrl('/App/videos/indiat20-landing/kkr-ff-video.mp4'),
  },
  PBKS: {
    image: getCDNUrl('/App/images/pbks-landing/pbks-first-fold-bg.png'),
    subHeading: 'Own ultra-rare official fan badges of your favourite Punjab Kings and unlock',
    backgroundImage: getCDNUrl('/App/images/pbks-landing/pbks-ff-bottom-bg.png'),
    name: 'PBKS-landing-image',
    video: getCDNUrl('/App/videos/indiat20-landing/pbks-ff-video.mp4'),
  },
}

export const getHeadingTickerData = (iplTeam: IPLTeamPageType) => {
  return [
    '5-STAR HOSPITALITY',
    'VIP MATCH TICKETS',
    iplTeam === 'RCB' ? 'PLAYER JERSEYS' : 'MATCH-WORN JERSEYS',
    'SIGNED MERCHANDISE',
    'CELEBRITY WATCH PARTIES',
    '5-STAR HOSPITALITY',
  ]
}

// INNER CIRCLE BENEFITS DATA
export const GTInnerCircleBenefitsData: GTInnerCircleBenefitsInterface[] = [
  {
    image: getCDNUrl('/App/images/gt-landing/benefits-match-ticket.png'),
    name: 'MATCH-TICKETS',
    heading: 'Grand Stand Tickets',
  },
  {
    image: getCDNUrl('/App/images/gt-landing/benefits-gt-gear.png'),
    name: 'TEAM-GEAR',
    heading: 'Exclusive Titans’ Gear',
  },
  {
    image: getCDNUrl('/App/images/gt-landing/benefits-meet-&-greet-v2.png'),
    name: 'MEET-&-GREET',
    heading: 'Meet & Greet',
  },
  {
    image: getCDNUrl('/App/images/gt-landing/benefits-fan-badges-v2.png'),
    name: 'FAN-BADGES',
    heading: '2 Fan Badges',
  },
  {
    image: getCDNUrl('/App/images/gt-landing/benefits-superfan.png'),
    name: 'SUPERFAN-BENEFITS',
    heading: '1 Superfan Benefit',
  },
]
export const RCBInnerCircleBenefitsData: GTInnerCircleBenefitsInterface[] = [
  {
    image: getCDNUrl('/App/images/rcb-landing/benefits-match-ticket.png'),
    name: 'MATCH-TICKETS',
    heading: 'Grand Stand Tickets',
  },
  {
    image: getCDNUrl('/App/images/rcb-landing/benefits-rcb-gear.png'),
    name: 'TEAM-GEAR',
    heading: 'Exclusive Challenger’s Gear',
  },
  {
    image: getCDNUrl('/App/images/rcb-landing/benefits-meet-&-greet-v1.png'),
    name: 'MEET-&-GREET',
    heading: 'Meet & Greet',
  },
  {
    image: getCDNUrl('/App/images/rcb-landing/benefits-fan-badges.png'),
    name: 'FAN-BADGES',
    heading: '2 Fan Badges',
  },
  {
    image: getCDNUrl('/App/images/rcb-landing/benefits-superfan-v1.png'),
    name: 'SUPERFAN-BENEFITS',
    heading: '1 Superfan Benefit',
  },
]
export const KKRInnerCircleBenefitsData: GTInnerCircleBenefitsInterface[] = [
  {
    image: getCDNUrl('/App/images/kkr-landing/benefits-match-ticket.png'),
    name: 'MATCH-TICKETS',
    heading: 'Grand Stand Tickets',
  },
  {
    image: getCDNUrl('/App/images/kkr-landing/benefits-kkr-gear.png'),
    name: 'TEAM-GEAR',
    heading: 'Exclusive KKR<br /> Gear',
  },
  {
    image: getCDNUrl('/App/images/kkr-landing/benefits-meet-&-greet.png'),
    name: 'MEET-&-GREET',
    heading: 'Meet & Greet',
  },
  {
    image: getCDNUrl('/App/images/kkr-landing/benefits-fan-badges.png'),
    name: 'FAN-BADGES',
    heading: '2 Fan Badges',
  },
  {
    image: getCDNUrl('/App/images/kkr-landing/benefits-superfan.png'),
    name: 'SUPERFAN-BENEFITS',
    heading: '1 Superfan Benefit',
  },
]
export const PBKSInnerCircleBenefitsData: GTInnerCircleBenefitsInterface[] = [
  {
    image: getCDNUrl('/App/images/pbks-landing/benefits-match-ticket.png'),
    name: 'MATCH-TICKETS',
    heading: 'Grand Stand Tickets',
  },
  {
    image: getCDNUrl('/App/images/pbks-landing/benefits-pbks-gear.png'),
    name: 'TEAM-GEAR',
    heading: 'Exclusive <br /> PBKS Gear',
  },
  {
    image: getCDNUrl('/App/images/pbks-landing/benefits-meet-&-greet.png'),
    name: 'MEET-&-GREET',
    heading: 'Meet & Greet',
  },
  {
    image: getCDNUrl('/App/images/pbks-landing/benefits-fan-badges.png'),
    name: 'FAN-BADGES',
    heading: '2 Fan Badges',
  },
  {
    image: getCDNUrl('/App/images/pbks-landing/benefits-superfan.png'),
    name: 'SUPERFAN-BENEFITS',
    heading: '1 Superfan Benefit',
  },
]

// SUPERFANS DATA
export const GTInnerCircleSuperfansData: GTInnerCircleBenefitsInterface[] = [
  {
    image: getCDNUrl('/App/images/gt-landing/superfan-paid-trip-v2.png'),
    name: 'PAID-TRIP',
    heading: 'All-expenses <br /> paid trip',
  },
  {
    image: getCDNUrl('/App/images/gt-landing/superfan-vip-box.png'),
    name: 'VIP-BOX',
    heading: 'VIP Box <br /> Hospitality',
  },
  {
    image: getCDNUrl('/App/images/gt-landing/superfan-signed-merch.png'),
    name: 'SIGNED-MERCH',
    heading: 'Signed Titans’<br />Merch',
  },
  {
    image: getCDNUrl('/App/images/gt-landing/superfan-practice-session.png'),
    name: 'PRACTICE-SESSION',
    heading: 'Watch Practice<br /> sessions',
  },
  {
    image: getCDNUrl('/App/images/gt-landing/superfan-watch-parties.png'),
    name: 'WATCH-PARTIES',
    heading: 'Watch <br /> Parties',
  },
]
export const RCBInnerCircleSuperfansData: GTInnerCircleBenefitsInterface[] = [
  {
    image: getCDNUrl('/App/images/rcb-landing/superfan-paid-trip-v2.png'),
    name: 'PAID-TRIP',
    heading: 'All-expenses <br /> paid trip',
  },
  {
    image: getCDNUrl('/App/images/rcb-landing/superfan-vip-box.png'),
    name: 'VIP-BOX',
    heading: 'VIP Box <br /> Hospitality',
  },
  {
    image: getCDNUrl('/App/images/rcb-landing/superfan-signed-merch-v1.png'),
    name: 'SIGNED-MERCH',
    heading: 'Signed RCB <br /> Merch',
  },
  {
    image: getCDNUrl('/App/images/rcb-landing/superfan-practice-session-v1.png'),
    name: 'PRACTICE-SESSION',
    heading: 'Watch Practice<br /> sessions',
  },
  {
    image: getCDNUrl('/App/images/rcb-landing/superfan-watch-parties-v1.png'),
    name: 'WATCH-PARTIES',
    heading: 'Watch <br /> Parties',
  },
]

export const KKRInnerCircleSuperfansData: GTInnerCircleBenefitsInterface[] = [
  {
    image: getCDNUrl('/App/images/kkr-landing/superfan-paid-trip-v2.png'),
    name: 'PAID-TRIP',
    heading: 'All-expenses <br /> paid trip',
  },
  {
    image: getCDNUrl('/App/images/kkr-landing/superfan-vip-box.png'),
    name: 'VIP-BOX',
    heading: 'VIP Box <br /> Hospitality',
  },
  {
    image: getCDNUrl('/App/images/kkr-landing/superfan-signed-merch.png'),
    name: 'SIGNED-MERCH',
    heading: 'Signed KKR <br /> Merch',
  },
  {
    image: getCDNUrl('/App/images/kkr-landing/superfan-practice-session.png'),
    name: 'PRACTICE-SESSION',
    heading: 'Watch Practice<br /> sessions',
  },
  {
    image: getCDNUrl('/App/images/kkr-landing/superfan-watch-parties.png'),
    name: 'WATCH-PARTIES',
    heading: 'Watch <br /> Parties',
  },
]

export const PBKSInnerCircleSuperfansData: GTInnerCircleBenefitsInterface[] = [
  {
    image: getCDNUrl('/App/images/pbks-landing/superfan-paid-trip-v2.png'),
    name: 'PAID-TRIP',
    heading: 'All-expenses <br /> paid trip',
  },
  {
    image: getCDNUrl('/App/images/pbks-landing/superfan-vip-box.png'),
    name: 'VIP-BOX',
    heading: 'VIP Box <br /> Hospitality',
  },
  {
    image: getCDNUrl('/App/images/pbks-landing/superfan-signed-merch-v2.png'),
    name: 'SIGNED-MERCH',
    heading: 'Signed PBKS <br /> Merch',
  },
  {
    image: getCDNUrl('/App/images/pbks-landing/superfan-practice-session.png'),
    name: 'PRACTICE-SESSION',
    heading: 'Watch Practice<br /> sessions',
  },
  {
    image: getCDNUrl('/App/images/pbks-landing/superfan-watch-parties.png'),
    name: 'WATCH-PARTIES',
    heading: 'Watch <br /> Parties',
  },
]

const SuperfanBenfits: { [key: string]: GTInnerCircleBenefitsInterface[] } = {
  GT: GTInnerCircleSuperfansData,
  RCB: RCBInnerCircleSuperfansData,
  KKR: KKRInnerCircleSuperfansData,
  PBKS: PBKSInnerCircleSuperfansData,
}

const InnerCircleBenefits: { [key: string]: GTInnerCircleBenefitsInterface[] } = {
  GT: GTInnerCircleBenefitsData,
  RCB: RCBInnerCircleBenefitsData,
  KKR: KKRInnerCircleBenefitsData,
  PBKS: PBKSInnerCircleBenefitsData,
}

const InnerCircleBenefitsSubheading: { [key: string]: string } = {
  GT: 'Your journey to becoming a GT Superfan starts by owning **1 of the 250** Fan Badge Packs. Welcome to the Inner Circle.',
  RCB: 'Your journey to becoming a RCB Superfan starts by owning **1 of the 250** Fan Badge Packs. Welcome to the Inner Circle.',
  KKR: 'Your journey to becoming a KKR Superfan starts by owning **1 of the 250** Fan Badge Packs. Welcome to the Inner Circle.',
  PBKS: 'Your journey to becoming a PBKS Superfan starts by owning **1 of the 250** Fan Badge Packs. Welcome to the Inner Circle.',
}

const InnerCircleBenefitsBackground: { [key: string]: string } = {
  GT: getCDNUrl('/App/images/gt-landing/gt-super-fan-bg-v3.png'),
  RCB: getCDNUrl('/App/images/rcb-landing/rcb-super-fan-bg-v3.png'),
  KKR: getCDNUrl('/App/images/kkr-landing/kkr-super-fan-bg-v2.png'),
  PBKS: getCDNUrl('/App/images/pbks-landing/pbks-super-fan-bg-v2.png'),
}

const BenefitsHighlightColor: {
  [key: string]: {
    borderColor: string
    backgroundColor: string
  }
} = {
  GT: {
    backgroundColor:
      'linear-gradient(180deg, rgba(210, 164, 87, 0.3) 23.53%, rgba(210, 164, 87, 0.21) 100%)',
    borderColor: '#E2B872',
  },
  RCB: { backgroundColor: 'rgba(104, 37, 37, 0.4)', borderColor: '#A53632' },
  KKR: {
    backgroundColor: 'linear-gradient(180deg, #3A2967 0%, rgba(58, 41, 103, 0.3) 100%)',
    borderColor: '#9579D3',
  },
  PBKS: { backgroundColor: 'rgba(139, 33, 33, 0.4);', borderColor: '#A53632' },
}

export const getBenefitsData = (iplTeam: IPLTeamPageType, section: string) => {
  const IPLLandingBenefits: {
    [key: string]: {
      heading: string
      subHeading: string
      label: string
      background: string
      showCount: boolean
      content: GTInnerCircleBenefitsInterface[]
      highlightColor: { borderColor: string; backgroundColor: string }
    }
  } = {
    SUPERFAN: {
      heading: 'SUPERFAN BENEFITS',
      subHeading:
        'Own a pack of fan badges and then enter contests to level up to a superfan. Unlock one of these ultimate fan experiences giving you rare access to your favourite team.',
      label: 'ULTIMATE',
      background: '',
      showCount: false,
      content: SuperfanBenfits[iplTeam],
      highlightColor: BenefitsHighlightColor[iplTeam],
    },
    'INNER-CIRCLE': {
      heading: 'GUARANTEED BENEFITS',
      subHeading: InnerCircleBenefitsSubheading[iplTeam],
      label: 'PREMIUM',
      background: InnerCircleBenefitsBackground[iplTeam],
      showCount: true,
      content: InnerCircleBenefits[iplTeam],
      highlightColor: BenefitsHighlightColor[iplTeam],
    },
  }
  return IPLLandingBenefits[section]
}

// INNERCIRCLE MEMBER STEPS DATA
export const InnerCircleMemberStepData = [
  {
    heading: 'Join the Waitlist',
    subheading: 'Only 250 packs will be made available, so get your name on the list!',
  },
  {
    heading: 'Participate in Contests',
    subheading: 'The more contests you ace, the higher your chances of being shortlisted.',
  },
  {
    heading: 'Enter the Inner Circle',
    subheading: 'Get shortlisted to buy the pack and grab one on the day of the drop.',
  },
  {
    heading: 'Enjoy the Benefits',
    subheading: `Congrats! It's smooth sailing from here on out! We will take it up from here.`,
  },
]

const updatedFaq: { [key: string]: { title: string; description: string } } = {
  GT: {
    title: 'What is the GT Inner Circle program?',
    description:
      'The GT Inner Circle is an exclusive program for passionate, loyal fans of the club. Being a part of the Inner Circle gives fans special privileges and “money can’t buy experiences” – which includes hassle free access to match tickets, signed merchandise, goodie box with authentic team swag, meet and greets and much more!',
  },
  RCB: {
    title: 'What is the RCB Inner Circle program?',
    description:
      'The RCB Inner Circle is an exclusive program for passionate, loyal fans of the club. Being a part of the Inner Circle gives fans special privileges and “money can’t buy experiences” – which includes hassle free access to match tickets, signed merchandise, goodie box with authentic team swag, meet and greets and much more!',
  },
  KKR: {
    title: 'What is the KKR Inner Circle program?',
    description:
      'The KKR Inner Circle is an exclusive program for passionate, loyal fans of the club. Being a part of the Inner Circle gives fans special privileges and “money can’t buy experiences” – which includes hassle free access to match tickets, signed merchandise, goodie box with authentic team swag, meet and greets and much more!',
  },
  PBKS: {
    title: 'What is the PBKS Inner Circle program?',
    description:
      'The PBKS Inner Circle is an exclusive program for passionate, loyal fans of the club. Being a part of the Inner Circle gives fans special privileges and “money can’t buy experiences” – which includes hassle free access to match tickets, signed merchandise, goodie box with authentic team swag, meet and greets and much more!',
  },
}

// FAQ DATA
export const getFaqData = (iplTeam: string) => {
  return [
    {
      title: updatedFaq[iplTeam].title,
      description: updatedFaq[iplTeam].description,
    },
    {
      title: 'What are Fan Badges?',
      description:
        'Fan Badges are officially licensed digital collectibles. These collectibles are minted on Rario’s proprietary private blockchain. The collectibles have a proof of ownership, and a scarcity threshold, which means they are exclusive and limited. Fan Badges are the newest category of collectibles launched by Rario, in addition to Player Cards and Moment Cards.',
    },
    {
      title: 'How do I join the Inner Circle program?',
      description:
        'To be part of the Inner Circle, you need to own Fan Badges. Fan Badges are sold in packs of 2 on the Rario website and app. Sign up now to join the waitlist to buy an Inner Circle pack. Make sure you buy as soon as the packs go live as we only have 250 packs for the season!',
    },

    {
      title: 'Can I get a Fan Badge of my favourite player?',
      description:
        'The Fan Badges you get in the pack are randomly allocated, which means you cannot choose which player you get. However, when in future we launch trading for Fan Badges on the Rario Marketplace, you can choose any player listed for sale by fellow fans.',
    },
    {
      title: 'Can I buy more than one Inner Circle pack?',
      description: ' You can buy up to two Inner Circle packs',
    },
    {
      title: 'How can I get the Superfan Benefits?',
      description:
        'All fans who buy an Inner Circle pack will receive the guaranteed benefits, but only a chosen few will receive the premium superfan benefits. To qualify, you can complete different tasks - answer fun questions about your favourite team and players, share about the program on Social Media, and refer your friends and followers to sign up as well. The more you do, the higher your chances of levelling up!',
    },
    {
      title: 'Which match tickets will I get?',
      description: `You will get tickets for your team's first home game immediately after you get a hold of the Fan Badges. However, if you want to attend a different home game, you can let us know your preference. Please note that ticket availability for the game of your choice is subject to constraints and cannot be guaranteed.`,
    },
    {
      title: 'How will I receive my match tickets?',
      description: `You can collect the match tickets on the matchday itself. We will have a team member stationed near the stadium who will hand over the tickets to you. The tickets will be available for collection 4 hours before the match start time and be available till 45 minutes before the match starts. This has been planned in a way that gives you the optimum, hassle-free experience.`,
    },
    {
      title: 'What will I get as a part of the official team goodie box? When will I get it?',
      description: `A Goodie Box with delightfully curated official team swag will be on your way, within a day after you purchase the Inner Circle pack. The box contains:<br /><br /><ul style="list-style-type:disc; margin-left:12px;"><li>Sipper</li><li>Coffee Mug</li><li>Notebook</li><li>Coasters</li><li>Key Chain</li><li>Most importantly – an official certificate of Fandom!</li></ul>`,
    },
    {
      title: 'Can Fan Badges be used in D3?',
      description: `Fan Badges are not usable in D3, our strategy game. Player cards for D3 are sold separately.`,
    },
    {
      title: 'Can I sell my Fan Badges on the Rario marketplace?',
      description: `No, you can not currently buy or sell Fan Badges on the Rario marketplace.`,
    },
  ]
}

const GTModalSectionData: { [key: string]: { image: string; showFaq: boolean } } = {
  'MATCH-TICKETS': {
    image: getCDNUrl('/App/images/gt-landing/match-tickets-modal-img-v2.png'),
    showFaq: false,
  },
  'TEAM-GEAR': {
    image: getCDNUrl('/App/images/gt-landing/titans-gear-modal-img-v2.png'),
    showFaq: false,
  },
  'MEET-&-GREET': {
    image: getCDNUrl('/App/images/gt-landing/meet-and-greet-modal-img-v3.png'),
    showFaq: false,
  },
  'FAN-BADGES': {
    image: getCDNUrl('/App/images/gt-landing/fan-badges-modal-img-v2.png'),
    showFaq: false,
  },
  'SUPERFAN-BENEFITS': {
    image: getCDNUrl('/App/images/gt-landing/titans-gear-modal-img-v2.png'),
    showFaq: false,
  },
  'PAID-TRIP': {
    image: getCDNUrl('/App/images/gt-landing/paid-trip-modal-img-v3.png'),
    showFaq: false,
  },
  'VIP-BOX': {
    image: getCDNUrl('/App/images/gt-landing/vip-box-modal-img-v3.png'),
    showFaq: false,
  },
  'SIGNED-MERCH': {
    image: getCDNUrl('/App/images/gt-landing/signed-merch-modal-img-v2.png'),
    showFaq: false,
  },
  'OFFICIAL-MERCH': {
    image: getCDNUrl('/App/images/gt-landing/official-merch-modal-img-v2.png'),
    showFaq: false,
  },
  'RARIO-MERCH': {
    image: getCDNUrl('/App/images/gt-landing/rario-merch-modal-img-v2.png'),
    showFaq: false,
  },
  'WATCH-PARTIES': {
    image: getCDNUrl('/App/images/gt-landing/watch-parties-modal-img-v2.png'),
    showFaq: false,
  },
  'MATCH-WORN-JERSEYS': {
    image: getCDNUrl('/App/images/gt-landing/match-worn-jersey-modal-img-v2.png'),
    showFaq: false,
  },
  'PRACTICE-SESSION': {
    image: getCDNUrl('/App/images/gt-landing/practice-session-modal-img.png'),
    showFaq: false,
  },
}

const RCBModalSectionData: { [key: string]: { image: string; showFaq: boolean } } = {
  'MATCH-TICKETS': {
    image: getCDNUrl('/App/images/rcb-landing/match-tickets-modal-img-v3.png'),
    showFaq: false,
  },
  'TEAM-GEAR': {
    image: getCDNUrl('/App/images/rcb-landing/rcb-gear-modal-img.png'),
    showFaq: false,
  },
  'MEET-&-GREET': {
    image: getCDNUrl('/App/images/rcb-landing/meet-and-greet-modal-img-v1.png'),
    showFaq: false,
  },
  'FAN-BADGES': {
    image: getCDNUrl('/App/images/rcb-landing/fan-badges-modal-img-v1.png'),
    showFaq: false,
  },
  'SUPERFAN-BENEFITS': {
    image: getCDNUrl('/App/images/rcb-landing/rcb-gear-modal-img.png'),
    showFaq: false,
  },
  'PAID-TRIP': {
    image: getCDNUrl('/App/images/rcb-landing/paid-trip-modal-img-v3.png'),
    showFaq: false,
  },
  'VIP-BOX': {
    image: getCDNUrl('/App/images/rcb-landing/vip-box-modal-img-v2.png'),
    showFaq: false,
  },
  'SIGNED-MERCH': {
    image: getCDNUrl('/App/images/rcb-landing/signed-merch-modal-img-v1.png'),
    showFaq: false,
  },
  'OFFICIAL-MERCH': {
    image: getCDNUrl('/App/images/rcb-landing/official-merch-modal-img.png'),
    showFaq: false,
  },
  'RARIO-MERCH': {
    image: getCDNUrl('/App/images/rcb-landing/rario-merch-modal-img.png'),
    showFaq: false,
  },
  'WATCH-PARTIES': {
    image: getCDNUrl('/App/images/rcb-landing/watch-parties-modal-img-v1.png'),
    showFaq: false,
  },
  'MATCH-WORN-JERSEYS': {
    image: getCDNUrl('/App/images/rcb-landing/match-worn-jersey-modal-img.png'),
    showFaq: false,
  },
  'PRACTICE-SESSION': {
    image: getCDNUrl('/App/images/rcb-landing/practice-session-modal-img-v1.png'),
    showFaq: false,
  },
}

const KKRModalSectionData: { [key: string]: { image: string; showFaq: boolean } } = {
  'MATCH-TICKETS': {
    image: getCDNUrl('/App/images/kkr-landing/match-tickets-modal-img-v2.png'),
    showFaq: false,
  },
  'TEAM-GEAR': {
    image: getCDNUrl('/App/images/kkr-landing/kkr-gear-modal-img-v2.png'),
    showFaq: false,
  },
  'MEET-&-GREET': {
    image: getCDNUrl('/App/images/kkr-landing/meet-and-greet-modal-img.png'),
    showFaq: false,
  },
  'FAN-BADGES': {
    image: getCDNUrl('/App/images/kkr-landing/fan-badges-modal-img.png'),
    showFaq: false,
  },
  'SUPERFAN-BENEFITS': {
    image: getCDNUrl('/App/images/kkr-landing/kkr-gear-modal-img.png'),
    showFaq: false,
  },
  'PAID-TRIP': {
    image: getCDNUrl('/App/images/kkr-landing/paid-trip-modal-img-v2.png'),
    showFaq: false,
  },
  'VIP-BOX': {
    image: getCDNUrl('/App/images/kkr-landing/vip-box-modal-img-v2.png'),
    showFaq: false,
  },
  'SIGNED-MERCH': {
    image: getCDNUrl('/App/images/kkr-landing/signed-merch-modal-img.png'),
    showFaq: false,
  },
  'OFFICIAL-MERCH': {
    image: getCDNUrl('/App/images/kkr-landing/official-merch-modal-img.png'),
    showFaq: false,
  },
  'RARIO-MERCH': {
    image: getCDNUrl('/App/images/kkr-landing/rario-merch-modal-img.png'),
    showFaq: false,
  },
  'WATCH-PARTIES': {
    image: getCDNUrl('/App/images/kkr-landing/watch-parties-modal-img.png'),
    showFaq: false,
  },
  'MATCH-WORN-JERSEYS': {
    image: getCDNUrl('/App/images/kkr-landing/match-worn-jersey-modal-img.png'),
    showFaq: false,
  },
  'PRACTICE-SESSION': {
    image: getCDNUrl('/App/images/kkr-landing/practice-session-modal-img.png'),
    showFaq: false,
  },
}

const PBKSModalSectionData: { [key: string]: { image: string; showFaq: boolean } } = {
  'MATCH-TICKETS': {
    image: getCDNUrl('/App/images/pbks-landing/match-tickets-modal-img-v2.png'),
    showFaq: false,
  },
  'TEAM-GEAR': {
    image: getCDNUrl('/App/images/pbks-landing/pbks-gear-modal-img-v2.png'),
    showFaq: false,
  },
  'MEET-&-GREET': {
    image: getCDNUrl('/App/images/pbks-landing/meet-and-greet-modal-img.png'),
    showFaq: false,
  },
  'FAN-BADGES': {
    image: getCDNUrl('/App/images/pbks-landing/fan-badges-modal-img.png'),
    showFaq: false,
  },
  'SUPERFAN-BENEFITS': {
    image: getCDNUrl('/App/images/pbks-landing/pbks-gear-modal-img.png'),
    showFaq: false,
  },
  'PAID-TRIP': {
    image: getCDNUrl('/App/images/pbks-landing/paid-trip-modal-img-v2.png'),
    showFaq: false,
  },
  'VIP-BOX': {
    image: getCDNUrl('/App/images/pbks-landing/vip-box-modal-img-v2.png'),
    showFaq: false,
  },
  'SIGNED-MERCH': {
    image: getCDNUrl('/App/images/pbks-landing/signed-merch-modal-img-v2.png'),
    showFaq: false,
  },
  'OFFICIAL-MERCH': {
    image: getCDNUrl('/App/images/pbks-landing/official-merch-modal-img.png'),
    showFaq: false,
  },
  'RARIO-MERCH': {
    image: getCDNUrl('/App/images/pbks-landing/rario-merch-modal-img.png'),
    showFaq: false,
  },
  'WATCH-PARTIES': {
    image: getCDNUrl('/App/images/pbks-landing/watch-parties-modal-img.png'),
    showFaq: false,
  },
  'MATCH-WORN-JERSEYS': {
    image: getCDNUrl('/App/images/pbks-landing/match-worn-jersey-modal-img.png'),
    showFaq: false,
  },
  'PRACTICE-SESSION': {
    image: getCDNUrl('/App/images/pbks-landing/practice-session-modal-img.png'),
    showFaq: false,
  },
}

export const getModalSectionData = (iplTeam: IPLTeamPageType, section: ModalSectionType) => {
  const ModalSectionData = {
    GT: GTModalSectionData[section],
    RCB: RCBModalSectionData[section],
    KKR: KKRModalSectionData[section],
    PBKS: PBKSModalSectionData[section],
  }
  return ModalSectionData[iplTeam]
}

export const FanBadgesBackground = {
  GT: getCDNUrl('/App/images/gt-landing/fan-badges-banner-v2.png'),
  RCB: getCDNUrl('/App/images/rcb-landing/fan-badges-banner-v1.png'),
  KKR: getCDNUrl('/App/images/kkr-landing/fan-badges-banner.png'),
  PBKS: getCDNUrl('/App/images/pbks-landing/fan-badges-banner.png'),
}

export const BuyPacksBannerImage = {
  GT: {
    image: getCDNUrl('/App/images/gt-landing/gt-packs-banner-v4.png'),
    heading: `Buy a Pack, Enter the Titans' Inner Circle.`,
  },
  RCB: {
    image: getCDNUrl('/App/images/rcb-landing/rcb-packs-banner-v5.png'),
    heading: 'Buy a Pack, Enter the RCB Inner Circle.',
  },
  KKR: {
    image: getCDNUrl('/App/images/kkr-landing/kkr-packs-banner-v2.png'),
    heading: 'Buy a Pack, Enter the KKR Inner Circle.',
  },
  PBKS: {
    image: getCDNUrl('/App/images/pbks-landing/pbks-packs-banner-v2.png'),
    heading: 'Buy a Pack, Enter the PBKS Inner Circle.',
  },
}

export const ContestPageLink = {
  GT: `${IPL_BASE_PATH}/gt/level-up`,
  RCB: `${IPL_BASE_PATH}/rcb/level-up`,
  KKR: `${IPL_BASE_PATH}/kkr/level-up`,
  PBKS: `${IPL_BASE_PATH}/pbks/level-up`,
}

export const ReferralFirstFoldImage = {
  GT: getCDNUrl('/App/images/gt-contest/gt-refer-and-rise-v1.png'),
  RCB: getCDNUrl('/App/images/rcb-contest/rcb-refer-and-rise.png'),
  KKR: getCDNUrl('/App/images/kkr-contest/kkr-refer-and-rise.png'),
  PBKS: getCDNUrl('/App/images/pbks-contest/pbks-refer-and-rise.png'),
}

export const QuizCompleteModalHeading: { [key: string]: string } = {
  GT: `You are now one step closer to owning a Fan Badges Pack and being part of the Titans' Inner Circle.`,
  RCB: `You are now one step closer to owning a Fan Badges Pack and being part of the Challengers' Inner Circle.`,
  KKR: `You are now one step closer to owning a Fan Badges Pack and being part of the KKR Inner Circle.`,
  PBKS: `You are now one step closer to owning a Fan Badges Pack and being part of the Kings' Inner Circle.`,
}

export const IPLCategoryTeamsData = [
  {
    name: 'GT',
    image: getCDNUrl('/App/images/indiat20-landing/gt-team-banner.png'),
    link: `${IPL_BASE_PATH}/gt`,
  },
  {
    name: 'RCB',
    image: getCDNUrl('/App/images/indiat20-landing/rcb-team-banner.png'),
    link: `${IPL_BASE_PATH}/rcb`,
  },
  {
    name: 'KKR',
    image: getCDNUrl('/App/images/indiat20-landing/kkr-team-banner.png'),
    link: `${IPL_BASE_PATH}/kkr`,
  },
  {
    name: 'PBKS',
    image: getCDNUrl('/App/images/indiat20-landing/pbks-team-banner.png'),
    link: `${IPL_BASE_PATH}/pbks`,
  },
]
export const CategoryLandingFFHeading = [
  'FAN EXPERIENCES',
  '5-STAR HOSPITALITY',
  'VIP MATCH TICKETS',
  'MATCH-WORN JERSEYS',
  'SIGNED MERCHANDISE',
  'CELEBRITY WATCH PARTIES',
  'FAN EXPERIENCES',
]
