import {
  RouteChildren,
  MobileRoutes,
  SidenavBottomType,
  MobileRouteProps,
  ThemeEnum,
} from 'interfaces'
import CommunityNavIcon from 'assets/svg/CommunityNavIcon'
import D3NavIcon from 'assets/svg/D3NavIcon'
import BuyNavIcon from 'assets/svg/BuyNavIcon'
import ExploreIcon from 'assets/svg/ExploreIcon'
// import UserActivityIcon from 'assets/svg/UserActivityIcon'
import KYCIcon from 'assets/svg/KYCIcon'
import WithdrawBankIcon from 'assets/svg/WithdrawBankIcon'
import HelpIcon from 'assets/svg/HelpIcon'
import TermsIcon from 'assets/svg/TermsIcon'
import LockIcon from 'assets/svg/LockIcon'
import PrivacyIcon from 'assets/svg/PrivacyIcon'
import UserSmallIcon from 'assets/svg/UserSmallIcon'
import FAQIcon from 'assets/svg/FAQIcon'
import BlogIcon from 'assets/svg/BlogIcon'
import QueryIcon from 'assets/svg/QueryIcon'
import PanCardIcon from 'assets/svg/PanCardIcon'
import AboutUsIcon from 'assets/svg/AboutUsIcon'
import VaultNavIcon from 'assets/svg/VaultNavIcon'
import CurrencyIcon from 'assets/svg/CurrencyIcon'
import HomeNavIcon from 'components/NavigationBar/HomeNavIcon'
import { PLATFORM_ORIGIN } from 'config'
import { getPlatformName } from 'utils/platform'

const platform = PLATFORM_ORIGIN === ThemeEnum.RARIO ? 'Rario' : 'Cricrush'
export const MOBILE_ROUTES: MobileRoutes[] = [
  {
    title: '',
    menu: [
      {
        name: 'Community',
        subtitle: 'Engage with fellow members',
        link: '/community',
        icon: <CommunityNavIcon />,
        cr: false,
      },
      {
        name: 'About Us',
        subtitle: `Get to know the ${getPlatformName()} story`,
        link: '/about-us?referer=home',
        icon: <AboutUsIcon />,
      },
      {
        name: 'Explore Leagues',
        subtitle: 'All the exciting leagues hosted on D3',
        link: '/leagues?referer=home',
        icon: <ExploreIcon />,
        cr: false,
      },
      {
        name: 'Rario Blog',
        subtitle: 'All the exciting leagues hosted on D3',
        link: '/blog',
        icon: <BlogIcon />,
        newTab: false,
        cr: false,
      },
    ],
  },
  {
    title: 'Account',
    menu: [
      {
        name: 'Profile',
        subtitle: 'Edit your personal information',
        link: '/edit-profile',
        icon: <UserSmallIcon />,
      },
      // {
      //   name: 'Activity',
      //   subtitle: 'Track record of all your moves',
      //   link: '/user-activity?referer=home',
      //   icon: <UserActivityIcon />,
      // },
      // {
      //   name: 'My Orders',
      //   subtitle: 'Download invoices for all your orders',
      //   link: '/my-orders',
      //   icon: <BoxedHandBagIcon />,
      // },
      {
        name: 'Pan Card',
        subtitle: 'Submit/review your PAN Card details',
        link: '/pan?referer=home',
        icon: <PanCardIcon />,
        cr: false,
      },
    ],
  },
  {
    title: 'Payments & Wallet',
    menu: [
      {
        name: 'Preferred Currency',
        subtitle: `${
          PLATFORM_ORIGIN === ThemeEnum.RARIO ? 'Rario App' : 'CricRush'
        } in your currency`,
        link: '/',
        icon: <CurrencyIcon />,
      },
      {
        name: 'KYC',
        subtitle: 'Submit/review your KYC for a smooth experience',
        link: '/kyc?referer=home',
        icon: <KYCIcon />,
      },
      {
        name: 'Withdrawal Bank Accounts',
        subtitle: 'Add or remove bank accounts for withdrawals',
        link: '/manage-bank-accounts?referer=home',
        icon: <WithdrawBankIcon />,
      },
    ],
  },
  {
    title: 'Policies',
    menu: [
      {
        name: `Terms of Use ${platform}`,
        subtitle: "Know more about the platform's workings",
        link: '/terms-of-use?referer=home',
        icon: <TermsIcon />,
      },
      {
        name: 'Terms of Use D3',
        subtitle: 'Know more about the D3’s workings',
        link: '/terms-of-use/d3?referer=home',
        icon: <TermsIcon isD3 />,
      },
      {
        name: `Privacy Policy ${platform}`,
        subtitle: `Learn how we manage and secure data that is gathered with ${platform}`,
        link: '/privacy-policy?referer=home',
        icon: <PrivacyIcon />,
      },
      {
        name: 'Privacy Policy D3',
        subtitle: 'Learn how we manage and secure data that is gathered with D3',
        link: '/privacy-policy/d3?referer=home',
        icon: <PrivacyIcon isD3 />,
      },
      {
        name: `AML-CFT ${platform}`,
        subtitle: `Read ${platform} Anti-Money Laundering and Countering the Financing of Terrorism Policy`,
        link: '/aml-cft-policy?referer=home',
        icon: <LockIcon />,
      },
      {
        name: 'AML-CFT D3',
        subtitle:
          'Read D3’s Anti-Money Laundering and Countering the Financing of Terrorism Policy',
        link: '/aml-cft-policy/d3?referer=home',
        icon: <LockIcon isD3 />,
      },

      {
        name: 'Trade Promotion Terms for D3 Australia Users',
        subtitle: 'Trade Promotion details about the game',
        link: '/trade-promotion-d3?referer=home',
        icon: <TermsIcon isD3 />,
      },
    ],
  },
  {
    title: 'Help & Support',
    menu: [
      {
        name: 'FAQs',
        subtitle: 'All the basics: covered!',
        link: '/faqs?referer=home',
        icon: <FAQIcon />,
      },
      {
        name: 'Raise A Query',
        subtitle: 'Register your concern for a prompt response',
        link: '/contact-us?referer=home',
        icon: <QueryIcon />,
      },
      {
        name: 'Track Previous Queries',
        subtitle: "Reach out to us, we're happy to help!",
        link: 'https://rario.freshdesk.com/support/home',
        newTab: true,
        icon: <HelpIcon />,
      },
    ],
  },
]

export const SIDENAV_BOTTOM_TRUSTED: SidenavBottomType[] = [
  {
    name: 'AICPA SOC',
    logo: 'https://assets.rario.com/RARIO/App/images/menu/aicpa-soc.png',
    width: '32px',
    height: '33px',
  },
  {
    name: 'BQC',
    logo: 'https://assets.rario.com/RARIO/App/images/menu/bqc.png',
    width: '49px',
    height: '27px',
  },
  {
    name: 'IAF',
    logo: 'https://assets.rario.com/RARIO/App/images/menu/iaf.png',
    width: '47px',
    height: '29px',
  },
  {
    name: 'ISO Certofied',
    logo: 'https://assets.rario.com/RARIO/App/images/menu/iso.png',
    width: '33px',
    height: '33px',
  },
]

export const NAVIGATION_BAR_ROUTES: RouteChildren[] = [
  {
    name: 'Home',
    link: '/',
    icon: <HomeNavIcon />,
  },
  {
    name: 'Buy',
    link: '/buy',
    icon: <BuyNavIcon />,
    className: 'buy-navigation',
  },
  {
    name: 'Play',
    link: '/d3/game/app/arena',
    icon: <D3NavIcon />,
    className: 'd3-navigation',
  },
  // {
  //   name: 'Community',
  //   link: '/community',
  //   icon: <CommunityNavIcon />,
  //   className: 'community-navigation',
  // },
  {
    name: 'Vault',
    link: '/profile/cards',
    icon: <VaultNavIcon />,
    className: 'profile-navigation',
  },
]

export const HOME_LANDING_MENU: MobileRouteProps[] = [
  {
    id: 1,
    name: 'Compliance',
    newTab: false,
    submenu: true,
    inactive: false,
    compliance: true,
    child: [
      {
        name: `Terms Of Use ${platform}`,
        link: '/terms-of-use',
        newTab: false,
      },
      {
        name: 'Terms Of Use D3',
        link: '/terms-of-use/d3',
        newTab: false,
      },
      {
        name: `Privacy Policy ${platform}`,
        link: '/privacy-policy',
        newTab: false,
      },
      {
        name: 'Privacy Policy D3',
        link: '/privacy-policy/d3',
        newTab: false,
      },
      {
        name: `AML-CFT ${platform}`,
        link: '/aml-cft-policy',
        newTab: false,
      },
      {
        name: 'AML-CFT D3',
        link: '/aml-cft-policy/d3',
        newTab: false,
      },
      {
        name: 'Trade Promotion Terms for D3 Australia Users',
        link: '/trade-promotion-d3',
        newTab: false,
      },
    ],
  },
  {
    id: 2,
    newTab: false,
    name: 'Blog',
    link: '/blog',
    cr: false,
  },
  {
    id: 3,
    newTab: false,
    name: 'About Us',
    link: '/about-us',
  },
  {
    id: 4,
    newTab: false,
    name: 'Explore Leagues',
    link: '/leagues',
    cr: false,
  },
  {
    id: 5,
    newTab: false,
    name: 'Help & Support',
    link: 'https://rario.freshdesk.com/support/home',
  },
  {
    id: 6,
    newTab: false,
    name: 'Contact Us',
    link: '/contact-us',
  },
  {
    id: 7,
    newTab: false,
    name: 'Community',
    link: '/community',
    cr: false,
  },
  {
    id: 8,
    name: 'Follow us',
    newTab: false,
    submenu: true,
    inactive: false,
    compliance: true,
    child: [
      {
        name: 'Twitter',
        link: 'https://www.twitter.com/rariohq',
        newTab: true,
      },
      {
        name: 'Facebook',
        link: 'https://www.facebook.com/rariohqglobal',
        newTab: true,
      },
      {
        name: 'Linkedin',
        link: 'https://www.linkedin.com/company/rariohq/',
        newTab: true,
      },
      {
        name: 'Instagram',
        link: 'https://www.instagram.com/rariohq',
        newTab: true,
      },
      {
        name: 'Youtube',
        link: 'https://www.youtube.com/channel/UCqo65RCtVWBvICDMF6COhLA/feature',
        newTab: true,
      },
      {
        name: 'Telegram',
        link: 'https://t.me/rariohq',
        newTab: true,
      },
    ],
  },
  {
    id: 9,
    newTab: false,
    name: 'FAQs',
    link: '/faqs',
  },
]
