/* eslint-disable */
export enum REFERER {
  HOME = 'home',
}

type AppendHomeReferer = (url: string, appendRefererInLink?: boolean) => string

export const appendHomeAsReferer: AppendHomeReferer = (url, appendReferer = true) => {
  if (!appendReferer) {
    return url
  }

  if (url.indexOf('?') !== -1) {
    return `${url}&referer=${REFERER.HOME}`
  }

  return `${url}?referer=${REFERER.HOME}`
}
