import styled from '@emotion/styled'
import { minHeight, MinHeightProps, height, HeightProps } from 'styled-system'

export const InfiniteScrollViewWrapper = styled.div<HeightProps>`
  overflow: auto;
  flex: auto;
  width: 100%;
  padding-bottom: 80px;
  ${height};

  @media (min-height: 790px) and (max-height: 844px) {
    height: ${({ height }) => height || 'auto'};
  }

  @media (max-height: 790px) {
    height: ${({ height }) => height || 'auto'};
  }
`

export const RowItemWrapper = styled.div<MinHeightProps>`
  width: 100%;
  ${minHeight}
`
