import {
  PropsWithRef,
  RefAttributes,
  useState,
  forwardRef,
  ChangeEvent,
  MouseEvent,
  ForwardRefExoticComponent,
  LegacyRef,
  KeyboardEvent,
  FocusEvent,
} from 'react'

import { InputWrapper, InputType, Separator, InputTypes, IconWrapper } from './Input.style'
import { TypographyProps } from 'styled-system'

import Arrow from 'assets/svg/Arrow'

interface InputProps {
  name: string
  customId?: string
  label?: string
  placeholder?: string
  height?: number | string | number[] | string[] | null
  width?: number | string | number[] | string[] | null
  onChange?(event: Event | ChangeEvent): void
  onClick?(event: MouseEvent): void
  onBlur?(): void
  onKeyDown?(event: KeyboardEvent<HTMLDivElement>): void
  variant?: string
  value?: string | number
  type: string
  children?: any
  isDropDown?: boolean
  border?: number
  onDropDownClick?(): void
  paddingLeft?: number | string | number[] | string[] | null
  autofocus?: boolean
  ref?: LegacyRef<HTMLInputElement>
  onFocus?(event: FocusEvent<HTMLInputElement>): void
}

const Input: ForwardRefExoticComponent<
  PropsWithRef<InputProps & TypographyProps & InputTypes> & RefAttributes<HTMLElement>
> = forwardRef(
  (
    {
      name,
      customId = name,
      placeholder,
      height = 'auto',
      width = 'auto',
      onChange,
      variant = '',
      type = 'input',
      value,
      onClick,
      onBlur = () => undefined,
      onKeyDown = () => undefined,
      isDropDown,
      onDropDownClick,
      children,
      border = 0,
      background,
      paddingLeft,
      autofocus = false,
      onFocus = () => undefined,
    },
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const [isOpen, setOpen] = useState(false)
    return (
      <InputWrapper height={height} width={width}>
        <InputType
          ref={ref}
          onClick={onClick}
          value={value}
          type={type}
          autoComplete="off"
          id={`${customId}`}
          variant={variant}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          onFocus={onFocus}
          name={name}
          border={border}
          background={background}
          paddingLeft={paddingLeft}
          autoFocus={autofocus}
          maxWidth={width}
        />
        {isDropDown && <Separator />}
        {isDropDown && (
          <IconWrapper
            onClick={() => {
              if (onDropDownClick) {
                onDropDownClick()
              }
              setOpen(!isOpen)
            }}
            isOpen={isOpen}
          >
            <Arrow />
          </IconWrapper>
        )}
        {children}
      </InputWrapper>
    )
  }
)

export default Input
