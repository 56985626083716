// DO NOT DESTRUCTURE process.env issue: https://github.com/vercel/next.js/issues/19420

const config = {
  api: {
    // baseUrl: process.env.REACT_APP_BASEURL,
  },
  auth: {
    timeout: 30,
  },
  POLLING_INTERVAL: 10000,
  MAX_POLLING_API_CALLS: 30,
  RARIO_WEBSITE_URL: process.env.RARIO_WEBSITE_URL,
  SKIP_MARKETPLACE_KYC: true,
  SHOW_MOMENTS_TABS_ON_MARKETPLACE: false,
  PLATFORM_ORIGIN: process.env.PLATFORM_ORIGIN,
  IS_INAI_ENABLED: process.env.IS_INAI_ENABLED,
  IS_VPN_CHECK_ENABLED: process.env.IS_VPN_CHECK_ENABLED,
  IS_CASHFREE_ENABLED: process.env.IS_CASHFREE_ENABLED,
  CASHFREE_SANDBOX: process.env.CASHFREE_SANDBOX,
  CURRENT_APP_VERSION: process.env.CURRENT_APP_VERSION,
  IS_BURN_EARN_ENABLED: process.env.IS_BURN_EARN_ENABLED,
}

export const PLATFORM_ORIGIN = config.PLATFORM_ORIGIN || 'RARIO'
export const IS_INAI_ENABLED = config.IS_INAI_ENABLED
export const IS_VPN_CHECK_ENABLED = config.IS_VPN_CHECK_ENABLED
export const IS_CASHFREE_ENABLED = config.IS_CASHFREE_ENABLED
export const CASHFREE_SANDBOX = config.CASHFREE_SANDBOX
export const CURRENT_APP_VERSION = config.CURRENT_APP_VERSION || '1.6'
export const IS_TYPESENSE_ENABLED = process.env.IS_TYPESENSE_ENABLED || ''
export const IS_RECAPTCHA_DISABLED = process.env.IS_RECAPTCHA_DISABLED === 'TRUE'
export const IS_BURN_EARN_ENABLED = process.env.IS_BURN_EARN_ENABLED || ''

export default config
