import { PLATFORM_ORIGIN } from 'config'
import { ThemeEnum } from 'interfaces'

export const isPlatformCR = () => {
  return PLATFORM_ORIGIN === ThemeEnum.CR
}

export const getPlatformName = () => {
  return isPlatformCR() ? 'CricRush' : 'Rario'
}
