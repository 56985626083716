import CricrushNavIcon from 'assets/svg/CricrushNavIcon'
import RarioNavIcon from 'assets/svg/RarioNavIcon'
import { isPlatformCR } from 'utils/platform'

const HomeNavIcon: React.FunctionComponent<{ fill?: string; opacity?: number }> = ({
  fill,
  opacity,
}) => {
  return isPlatformCR() ? (
    <CricrushNavIcon fill={fill} opacity={opacity} />
  ) : (
    <RarioNavIcon fill={fill} opacity={opacity} />
  )
}

export default HomeNavIcon
