const PauseIcon: React.FunctionComponent<{ fill?: string }> = ({ fill = 'white' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 63.1 320 384.9">
    <path
      fill={fill}
      d="M272 63.1l-32 0c-26.51 0-48 21.49-48 47.1v288c0 26.51 21.49 48 48 48L272 448c26.51 0 48-21.49 48-48v-288C320 85.49 298.5 63.1 272 63.1zM80 63.1l-32 0c-26.51 0-48 21.49-48 48v288C0 426.5 21.49 448 48 448l32 0c26.51 0 48-21.49 48-48v-288C128 85.49 106.5 63.1 80 63.1z"
    />
  </svg>
)

export default PauseIcon
