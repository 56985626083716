const QueryIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '20', height = '18', fill = '#FF4F44' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33789 12.9891C8.15862 15.325 10.3838 16.9995 13.0001 16.9995C14.1808 16.9995 15.2818 16.6585 16.21 16.0695L17.4821 16.4935C17.9706 16.6563 18.2152 16.7378 18.3777 16.6799C18.519 16.6295 18.6301 16.5182 18.6805 16.3768C18.7384 16.2144 18.6572 15.9701 18.4944 15.4816L18.0703 14.2095L18.2123 13.9739C18.7138 13.0972 19.0004 12.0818 19.0004 10.9995C19.0004 7.68581 16.3137 4.99951 13 4.99951L12.7754 5.00365L12.6621 5.00966M7.00006 12.9996C5.81935 12.9996 4.7183 12.6585 3.79004 12.0696L2.51807 12.4936C2.02952 12.6565 1.785 12.7378 1.62256 12.6799C1.4812 12.6295 1.36981 12.5182 1.3194 12.3768C1.26147 12.2144 1.34301 11.9701 1.50586 11.4816L1.92989 10.2095C1.34094 9.28121 1 8.18022 1 6.99951C1 3.6858 3.68629 0.999512 7 0.999512C10.3137 0.999512 13 3.6858 13 6.99951C13 10.3132 10.3138 12.9996 7.00006 12.9996Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default QueryIcon
