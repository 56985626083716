import { PLATFORM_ORIGIN } from 'config'
import { baseThemeAus, baseThemeIN, maxLayoutWidthAus, maxLayoutWidthIN } from './baseTheme'
import customStyled from './customStyled'
// import { parse } from 'cookie'

const theme = {
  ...(PLATFORM_ORIGIN === 'CR' ? baseThemeAus : baseThemeIN),
  maxLayoutWidth: PLATFORM_ORIGIN === 'CR' ? maxLayoutWidthAus : maxLayoutWidthIN,
}

export { theme, customStyled }
export default theme

export type ThemeType = typeof theme
