import { VideoWrapper } from './YoutubeEmbed.styles'

const YoutubeEmbed: React.FunctionComponent<{
  embedId?: string
  borderRadius: string
  autoplay: boolean
  width: string
  height: string
  showTill: number
}> = ({
  embedId,
  borderRadius = '0px',
  autoplay = false,
  width = '853',
  height = '480',
  showTill = 1921,
}) => {
  return (
    <VideoWrapper borderRadius={borderRadius} showTill={showTill}>
      {/* <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${embedId}?autoplay=${autoplay ? '1' : '0'}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      /> */}
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${embedId}&autoplay=${autoplay ? '1' : '0'}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </VideoWrapper>
  )
}

export default YoutubeEmbed
