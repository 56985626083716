import { FlexBox, useClickOutSide, FLexBoxProps, trackEvent } from '@rario/shared-components'
import { useState, useRef, useEffect } from 'react'
import ShareButtons from 'components/ShareButtons/ShareButtons'
import ShareIcon from 'assets/svg/ShareIcon'
import usePageControl from 'hooks/usePageControl'
import { Icons } from './ShareButtons.styles'

const ShareButtonContainer: React.FunctionComponent<
  FLexBoxProps & {
    text?: string
    ShareWrapperProps?: FLexBoxProps
    isPick?: boolean
  }
> = ({ text, isPick, ShareWrapperProps, ...flexBoxProps }) => {
  const [showShareButtons, setShowShareButtons] = useState(false)
  const [currentPage, setCurrentPage] = useState('none')
  const containerRef = useRef<HTMLDivElement>(null)
  useClickOutSide(containerRef, () => setShowShareButtons(false))

  const shareVariants: Record<string, { text: string; shareText?: string }> = {
    // trialCard: {
    //   text: 'Want to give D3 a shot? Get special Trial Cards like this and enter a free trial game to check out the coolest new cricket strategy game around!',
    //   shareText: '',
    // },
    pick: {
      text: 'Check out this hand-picked single Player Card sold directly by Rario! Would make a great addition the the team, right?',
      shareText: '',
    },
    card: {
      text: 'Check out this Digital Player Card only on the Rario Fan Marketplace',
      shareText: '',
    },
    pack: {
      text: 'Check out this cool pack of Digital Player Cards I just got on Rario. Own one now and invest in your cricket fandom!',
      shareText: '',
    },
    none: {
      text: '',
    },
  }

  const { isPackPdp, isPickPdp, isCardPdp, isTrialCardPdp } = usePageControl()
  useEffect(() => {
    if (isPackPdp) setCurrentPage('pack')
    if (isPickPdp) {
      setCurrentPage('pick')
      return
    }
    if (isCardPdp) setCurrentPage('card')
  }, [])

  return (
    <>
      {(isPackPdp || isPickPdp || isCardPdp) && !isTrialCardPdp && (
        <FlexBox
          {...flexBoxProps}
          ref={containerRef}
          alignItems="flex-end"
          position={'relative'}
          pb="2px"
        >
          <FlexBox
            position={'relative'}
            onClick={() => {
              setShowShareButtons((showShareButtons) => !showShareButtons)
              trackEvent('Section Interacted', 'click', {
                sub_category: 'listings-share_button',
                category: 'share',
                click_type: 'icon',
                click_action: 'click_share_button',
              })
            }}
          >
            <Icons>
              <ShareIcon />
            </Icons>
          </FlexBox>
          <ShareButtons
            showShareButtons={showShareButtons}
            text={shareVariants[currentPage].text}
            ShareWrapperProps={ShareWrapperProps}
            isPick={isPick}
          />
        </FlexBox>
      )}
    </>
  )
}

export default ShareButtonContainer
