const EmptyWalletIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  color?: string
  fill?: string
}> = ({ className, width = '24px', height = '24px', fill = '#36B286' }) => (
  <svg
    width={width}
    className={className}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 20H5C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V9C3 8.46957 3.21071 7.96086 3.58579 7.58579C3.96086 7.21071 4.46957 7 5 7H19C19.5304 7 20.0391 7.21071 20.4142 7.58579C20.7893 7.96086 21 8.46957 21 9V18C21 18.5304 20.7893 19.0391 20.4142 19.4142C20.0391 19.7893 19.5304 20 19 20Z"
      stroke={fill}
      strokeWidth="1.5"
    />
    <path
      d="M16.5 14C16.3674 14 16.2402 13.9473 16.1464 13.8536C16.0527 13.7598 16 13.6326 16 13.5C16 13.3674 16.0527 13.2402 16.1464 13.1464C16.2402 13.0527 16.3674 13 16.5 13C16.6326 13 16.7598 13.0527 16.8536 13.1464C16.9473 13.2402 17 13.3674 17 13.5C17 13.6326 16.9473 13.7598 16.8536 13.8536C16.7598 13.9473 16.6326 14 16.5 14Z"
      fill="#36B286"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 7V5.60301C17.9999 5.29651 17.9294 4.99413 17.7939 4.71922C17.6583 4.44431 17.4614 4.20423 17.2184 4.01752C16.9753 3.8308 16.6926 3.70245 16.3921 3.64236C16.0915 3.58228 15.7812 3.59208 15.485 3.671L4.485 6.604C4.05905 6.71751 3.68254 6.96857 3.41399 7.31813C3.14544 7.6677 2.9999 8.09619 3 8.537V9"
      stroke={fill}
      strokeWidth="1.5"
    />
  </svg>
)

export default EmptyWalletIcon
