import { getCDNUrl } from 'shared-components/utils'

const baseThemeAus = {
  breakpoints: ['576px', '767px', '992px', '1200px', '1441px', '1920px'],
  space: [0, 4, 8, 16, 24, 32, 64, 128, 256, 512],
  fontSizes: [10, 12, 14, 16, 18, 24, 24, 28, 32, 36, 48, 80, 96],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
    primary: '0.02em',
  },
  fonts: {
    serif: 'athelas, georgia, times, serif',
    sansSerif: '"Roboto", sans-serif',
    primary: '"BT Brik", sans-serif',
    secondary: '"Rubik", sans-serif',
  },
  borders: [0, '1px solid', '2px solid', '4px solid', '8px solid', '16px solid', '32px solid'],
  radii: [0, 2, 4, 8, 16, 9999, '100%'],
  width: [16, 24, 32, 64, 128, 256],
  heights: [16, 24, 32, 64, 128, 256],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  colors: {
    // Copy Typography

    /**
     * From this point down,
     * review color naming in a more generic way:
     * this way we can replace theme.
     * E.g: Primary: '#0E5AA7', cta: '#EE2527',
     */
    successBannerBG: '#206B50',
    lightMonoGrey: '#686B70',
    darkAtomicTangerine: '#362623',
    etonBlue: '#83C2AF',
    wolfram: '#B4B5B7',
    mobileModalFooter: 'rgba(255, 255, 255, 0.04)',
    cyanBlue: '#4E77C7',
    lightSerenity: 'rgba(54, 77, 154, 0.1)',
    carbon: '#343434',
    serenity: '#4E77C7',
    monoGrey: '#4F5358',
    lightBrown: '#BDA46B',
    graniteGray: '#666666',
    atomicTangerine: '#FF996D',
    dimatomicTangerine: '#FF996D0D',
    brown: '#31261d',
    black: '#000',
    blue: '#0E5AA7',
    lightGray: 'rgb(167, 174, 187)',
    mint: '#36B286',
    lightMint: 'rgba(54, 178, 134, 0.6)',
    primary: '#C4E93A',
    lightPrimary: 'rgba(255, 79, 68, 0.6)',
    purpureus: 'rgba(146, 54, 178, 0.5)',
    secondary: '#C4E93A',
    lightSecondary: '#184D3F',
    red: '#EE2527',
    silver: '#C4C4C4',
    tintedGray: '#9B9D9F',
    tartOrange: '#FF4F44',
    secondaryBorder: '#C4E93A',
    jet: '#343434',
    white: '#fff',
    warningBG: 'rgba(54, 178, 134, 0.2)',
    primaryBorder: 'rgb(255, 255, 255, 0.06)',
    yellow: '#ffd900',
    transparent: 'transparent',
    secondaryHighlight: '#FF3D3D',
    charcoal: '#212127',
    whiteSmoke: '#EFEFEF',
    blackGray: '#1C1C1C',
    darkGreen: '#00011B',
    richDarkGray: '#292D33',
    CTALightText: '#5ECDAE',
    maize: '#F2C94C',
    primaryText: '#000',
    darkSecondary: '#C4E93A99',
    success: '#36B286',
    pending: '#FF996D',
    failure: '#FF4F44',
    customWhite: '#ffffff59',
    darkGold: '#9F642F',
    darkRaspberry: '#842857',
    richBlack: '#04080F',
    richBlackDark: '#080B10',
    richBlackLight: '#040910',
    eerieBlack: '#13181E',
    brightGray: '#EBEBEB',
    spanishGray: '#9F9D9B',
    dimGray: '#686B70',
    lightBlack: '#222222',
    darkGrey: '#4f5357',
    grey: '#9c9d9f',
    lightGreen: '#36B286',
    chineseBlack: '#11151C',
    darkGunmetal: '#1D2228',
    anthracite: '#2A2B2C',
    japaneseCarmine: '#9B332F',
    philippineBlue: 'rgba(68, 120, 255, 0.6)',
    signalGreen: '#226E57',
    lightViolet: 'rgba(136, 136, 255, 0.5)',
    lightGrey: '#CCCCCC',
    gunMetalGrey: '#262C37',
    platinum: '#E7E7E6',
    blueberry: '#4E8AFF',
    darkCharcoal: '#333333',
    greyishWhite: '#E6E6E7',
    harvestGold: '#E2B872',
    mustard: '#C2A340',
    lightSecondaryBorder: '#BCC75F',
    lightSecondaryBG: '#C4E93A15',
    footerBG: '#285F2A',
    primaryHighlight: '#BCC75F',
    widgetBG: '#4F5358',
    secondaryBG: '#BCC75F',
    ctaGrayBG: 'rgba(29, 34, 40, 1)',
    graySectionBG: 'rgba(17, 21, 28, 1)',
    graySmallSectionBg: 'rgba(39, 45, 55, 1)',
    redSmallSectionBg: 'rgba(255,79,68,.2)',
    secondaryVariants: [
      'rgba(196, 233, 58,.0125)',
      'rgba(196, 233, 58,.025)',
      'rgba(196, 233, 58,.05)',
      'rgba(196, 233, 58,.1)',
      'rgba(196, 233, 58,.2)',
      'rgba(196, 233, 58,.3)',
      'rgba(196, 233, 58,.4)',
      'rgba(196, 233, 58,.5)',
      'rgba(196, 233, 58,.6)',
      'rgba(196, 233, 58,.7)',
      'rgba(196, 233, 58,.8)',
      'rgba(196, 233, 58,.9)',
    ],
    blacks: [
      'rgba(0,0,0,.0125)',
      'rgba(0,0,0,.025)',
      'rgba(0,0,0,.05)',
      'rgba(0,0,0,.1)',
      'rgba(0,0,0,.2)',
      'rgba(0,0,0,.3)',
      'rgba(0,0,0,.4)',
      'rgba(0,0,0,.5)',
      'rgba(0,0,0,.6)',
      'rgba(0,0,0,.7)',
      'rgba(0,0,0,.8)',
      'rgba(0,0,0,.9)',
    ],
    whites: [
      'rgba(255,255,255,.0125)',
      'rgba(255,255,255,.025)',
      'rgba(255,255,255,.05)',
      'rgba(255,255,255,.1)',
      'rgba(255,255,255,.2)',
      'rgba(255,255,255,.3)',
      'rgba(255,255,255,.4)',
      'rgba(255,255,255,.5)',
      'rgba(255,255,255,.6)',
      'rgba(255,255,255,.7)',
      'rgba(255,255,255,.8)',
      'rgba(255,255,255,.9)',
    ],
    stepsContainer: {
      background: 'rgba(188, 199, 95, 0.15)',
      border: '#C4E93A',
      content: '#C4E93A',
    },
  },
  maxLayoutWidth: '1280px',
  maxDesktopLayoutWidth: 400,

  button: {
    primaryOutline: {
      active: {
        textColor: '#fff',
        borderColor: '#CCE85D',
        bgColor: '#202210',
      },
      disabled: { textColor: '#000', borderColor: '#2c2a14', bgColor: '#363217' },
    },
    primaryFilled: {
      active: {
        textColor: '#000',
        borderColor: '#BCC75F',
        bgColor: '#CCE85D',
      },
      disabled: { textColor: '#000', borderColor: '#2c2a14', bgColor: '#363217' },
    },
    secondaryOutline: {
      active: {
        textColor: '#fff',
        borderColor: '#CCE85D',
        bgColor: '#202210',
      },
      disabled: { textColor: '#000', borderColor: '#2c2a14', bgColor: '#363217' },
    },
    secondaryFilled: {
      active: {
        textColor: '#000',
        borderColor: '#BCC75F',
        bgColor: '#CCE85D',
      },
      disabled: { textColor: '#000', borderColor: '#2c2a14', bgColor: '#363217' },
    },
    loader: {
      white: getCDNUrl('/App/images/button/white_loader-1.gif'),
      black: getCDNUrl('/App/images/button/black_loader-1.gif'),
      dynamicWB: getCDNUrl('/App/images/button/black_loader-1.gif'),
    },
    success: {
      white: getCDNUrl('/App/images/button/white_success_animation-1.gif'),
      black: getCDNUrl('/App/images/button/black_success_animation-1.gif'),
      dynamicWB: getCDNUrl('/App/images/button/black_success_animation-1.gif'),
    },
    info: {
      textColor: '#fff',
      bgColor: '#C4E93A',
    },
    primary: {
      textColor: '#fff',
      bgColor: '#226E57',
    },
    secondary: {
      textColor: '#000',
      bgColor: '#C4E93A',
    },
    disabled: {
      textColor: '#FF4F44',
      bgColor: '#1D2228',
    },
    radio: {
      borderColor: '#C4E93A',
      bgColor: '#C4E93A50',
    },
  },

  gradients: {
    containerBG: 'linear-gradient(142.2deg, rgba(23, 26, 30, 0.8) 0%, rgba(14, 17, 20, 0.8) 100%)',
    red: 'linear-gradient(134.84deg, rgba(255, 255, 255, 0.5) 22.64%, rgba(255, 255, 255, 0) 74.93%), #E14237',
    greenRow:
      'linear-gradient(180deg, rgba(14, 61, 44, 0.15) -29.07%, rgba(54, 178, 134, 0.15) 139.53%)',
    gold: 'linear-gradient(180deg, #F1C153 0%, #D49F17 100%), linear-gradient(180deg, #F4E7E7 0%, #676060 100%)',
    black:
      'linear-gradient(180deg, #585858 0%, #1B1A17 100%), linear-gradient(180deg, #F4E7E7 0%, #676060 100%)',
    silver: 'linear-gradient(180deg, #F4E7E7 0%, #676060 100%)',
    bronze: 'linear-gradient(180deg, #967444 0%, #C3985B 100%)',
    greenCircle:
      'linear-gradient(180deg, rgba(21, 77, 57, 0.27) 0%, rgba(54, 178, 134, 0.27) 100%)',
    greenCircleLight:
      'linear-gradient(180deg, rgba(108, 204, 170, 0.15) 0%, rgba(54, 178, 134, 0.15) 100%)',
    greyCircle:
      'linear-gradient(180deg, rgba(208, 208, 208, 0.15) 0%, rgba(183, 183, 183, 0.15) 100%)',
    blackOverlay:
      'linear-gradient(90deg ,#040910 0%,rgb(0,0,0,0) 15%,rgb(0,0,0,0) 85%,#040910 100%)',
    greyCardDark:
      'linear-gradient(180deg, rgba(0, 0, 0, 0) 4%, rgb(26 26 28 / 100%) 29%, rgb(18,19,25) 100%),radial-gradient(31.73% 41.45% at 19.74% 81.41%, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0) 100%)',
    primaryBoder:
      ' linear-gradient(to right,rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 0.3) 26%, rgba(255, 255, 255, 0.3) 86%, rgba(255, 255, 255, 0) 94%);',
    primaryLightBorder:
      'linear-gradient(to right,rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 3%, rgba(255, 255, 255, 0.3) 92%, rgba(255, 255, 255, 0) 57%)',
    success:
      'linear-gradient(180deg, rgba(14, 61, 44, 0.15) -29.07%, rgba(54, 178, 134, 0.15) 139.53%)',
    pending:
      'linear-gradient(180deg, rgba(134, 79, 15, 0.15) -29.07%, rgba(195, 131, 55, 0.15) 139.53%)',
    failure:
      'linear-gradient(180deg, rgba(134, 79, 15, 0.15) -29.07%, rgba(195, 131, 55, 0.15) 139.53%)',
    tooltipBg: '#2B2D24',
    labelContainerBackground:
      'linear-gradient(90deg, rgba(196, 233, 58, 0.40) 0.03%, rgba(31, 31, 31, 0.00) 109.53%)',
    tagContainerBackground: 'linear-gradient(180deg, #C4E93A -9.82%, rgba(104, 37, 37, 0.60) 93%)',
    carouselThumbBackground:
      'linear-gradient(180deg, rgba(255, 79, 68, 0.00) 46.88%, rgba(191, 222, 57, 0.20) 100%)',
    carouselThumbBorder: 'linear-gradient(180deg, #CD413A00 0%, #BFDE39 100%)',
  } as { [key: string]: string },
}

export default baseThemeAus

export const maxLayoutWidthAus = baseThemeAus.space[3] * 5 + parseFloat(baseThemeAus.breakpoints[3]) // = 1232px
