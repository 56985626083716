import Head from 'next/head'
import { getCDNUrl } from 'utils/utils'

type Props = {
  title?: string
  description?: string
  keywords?: string
  cannonicalUrl?: string
}

const SEO: React.FC<Props> = ({ title, description, keywords, cannonicalUrl = '' }) => (
  <Head>
    <title>{title}</title>
    <meta name="description" content={description} />
    {keywords && <meta name="keywords" content={keywords} />}
    <meta name="og:title" content={title} />
    <meta charSet="utf-8" />
    <meta
      name="viewport"
      content="width=device-width,initial-scale=1,maximum-scale=3,shrink-to-fit=no"
    />
    {cannonicalUrl && <link rel="canonical" href={cannonicalUrl} />}
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href={getCDNUrl('/images/favicons/v1/apple-touch-icon.png', true)}
    />
    <link
      rel="icon shortcut"
      type="image/png"
      sizes="32x32"
      href={getCDNUrl('/images/favicons/v1/favicon-32x32.png', true)}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href={getCDNUrl('/images/favicons/v1/favicon-16x16.png', true)}
    />
    <link rel="sitemap" type="application/xml" title="Sitemap" href="/sitemap-0.xml" />
    <link rel="manifest" href="/manifest.json" />
    <link rel="mask-icon" href={getCDNUrl('/images/favicons/v1/safari-pinned-tab.svg', true)} />
    <meta name="msapplication-TileColor" content="#da532c" />
    <meta name="theme-color" content="#ffffff" />
    <meta name="facebook-domain-verification" content="4va6oxnu840b14izv9zmi893ixy96r" />
  </Head>
)

export default SEO
