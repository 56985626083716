const Error500Banner: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  viewBox?: string
}> = ({ width = '231', height = '141', viewBox = '0 0 231 141' }) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.9964 100.273C21.7017 100.273 17.3473 99.7962 12.9333 98.8418C8.51925 97.8874 4.64206 96.4857 1.30171 94.6365L8.4596 79.0681C11.1438 80.619 13.9473 81.7821 16.8701 82.5576C19.7929 83.2734 22.5666 83.6313 25.1912 83.6313C27.5771 83.6313 29.5456 83.2137 31.0964 82.3786C32.6473 81.5435 33.4228 80.3207 33.4228 78.7102C33.4228 77.8155 33.1543 77.04 32.6175 76.3839C32.0807 75.6681 31.1263 75.1313 29.7543 74.7734C28.4421 74.4155 26.5035 74.2365 23.9386 74.2365H5.77539L8.90697 36.2102H50.5122V52.1365H16.3333L26.9807 43.0997L25.1017 67.3471L14.4543 58.3102H29.0386C35.2421 58.3102 40.1929 59.205 43.8912 60.9944C47.6491 62.7839 50.3631 65.1997 52.0333 68.2418C53.7631 71.2243 54.628 74.5646 54.628 78.2628C54.628 81.9611 53.614 85.5102 51.5859 88.9102C49.6175 92.2506 46.5157 94.9944 42.2807 97.1418C38.1052 99.2295 32.6771 100.273 25.9964 100.273Z"
      fill="url(#paint0_linear_978_44246)"
    />
    <path
      d="M199.43 100.273C193.883 100.273 188.962 98.9909 184.667 96.426C180.432 93.8611 177.092 90.133 174.646 85.2418C172.26 80.3506 171.067 74.4453 171.067 67.526C171.067 60.6067 172.26 54.7014 174.646 49.8102C177.092 44.919 180.432 41.1909 184.667 38.626C188.962 36.0611 193.883 34.7786 199.43 34.7786C204.978 34.7786 209.869 36.0611 214.104 38.626C218.399 41.1909 221.739 44.919 224.125 49.8102C226.57 54.7014 227.793 60.6067 227.793 67.526C227.793 74.4453 226.57 80.3506 224.125 85.2418C221.739 90.133 218.399 93.8611 214.104 96.426C209.869 98.9909 204.978 100.273 199.43 100.273ZM199.43 83.3628C200.802 83.3628 202.025 82.8857 203.099 81.9313C204.172 80.9769 205.007 79.3365 205.604 77.0102C206.26 74.6839 206.588 71.5225 206.588 67.526C206.588 63.4699 206.26 60.3085 205.604 58.0418C205.007 55.7155 204.172 54.0751 203.099 53.1207C202.025 52.1664 200.802 51.6892 199.43 51.6892C198.058 51.6892 196.835 52.1664 195.762 53.1207C194.688 54.0751 193.823 55.7155 193.167 58.0418C192.57 60.3085 192.272 63.4699 192.272 67.526C192.272 71.5225 192.57 74.6839 193.167 77.0102C193.823 79.3365 194.688 80.9769 195.762 81.9313C196.835 82.8857 198.058 83.3628 199.43 83.3628Z"
      fill="url(#paint1_linear_978_44246)"
    />
    <path
      d="M109.591 105.671C129.26 110.099 148.795 97.7433 153.223 78.0737C157.651 58.4041 145.295 38.8692 125.626 34.4413C105.956 30.0134 86.4212 42.3692 81.9933 62.0388C77.5654 81.7084 89.9213 101.243 109.591 105.671Z"
      fill="url(#paint2_linear_978_44246)"
    />
    <path
      d="M136.515 38.7997C136.019 38.5348 135.58 38.1966 135.084 37.9316L134.52 38.6641C134.239 39.0303 134.306 39.5488 134.672 39.8306C135.039 40.1124 135.557 40.0449 135.839 39.6786L136.515 38.7997Z"
      fill="#1D2228"
    />
    <path
      d="M134.206 41.8017C134.488 41.4354 134.42 40.917 134.054 40.6352C133.688 40.3534 133.169 40.4209 132.887 40.7871L131.309 42.838C131.027 43.2043 131.095 43.7227 131.461 44.0045C131.827 44.2863 132.346 44.2188 132.628 43.8526L134.206 41.8017Z"
      fill="#1D2228"
    />
    <path
      d="M121.522 58.2773C121.803 57.911 121.736 57.3926 121.37 57.1108C121.003 56.829 120.485 56.8965 120.203 57.2627L118.625 59.3136C118.343 59.6799 118.411 60.1983 118.777 60.4801C119.143 60.7619 119.662 60.6944 119.943 60.3281L121.522 58.2773Z"
      fill="#1D2228"
    />
    <path
      d="M115.155 66.5614C115.437 66.1952 115.37 65.6768 115.003 65.395C114.637 65.1131 114.119 65.1807 113.837 65.5469L112.202 67.671C111.921 68.0373 111.988 68.5557 112.354 68.8375C112.721 69.1193 113.239 69.0518 113.521 68.6856L115.155 66.5614Z"
      fill="#1D2228"
    />
    <path
      d="M118.317 62.454C118.598 62.0878 118.531 61.5694 118.165 61.2875C117.798 61.0057 117.28 61.0733 116.998 61.4395L115.42 63.4904C115.138 63.8566 115.206 64.375 115.572 64.6569C115.938 64.9387 116.457 64.8711 116.738 64.5049L118.317 62.454Z"
      fill="#1D2228"
    />
    <path
      d="M127.898 49.9999C128.18 49.6337 128.112 49.1152 127.746 48.8334C127.38 48.5516 126.861 48.6192 126.58 48.9854L125.001 51.0363C124.72 51.4025 124.787 51.9209 125.153 52.2028C125.52 52.4846 126.038 52.417 126.32 52.0508L127.898 49.9999Z"
      fill="#1D2228"
    />
    <path
      d="M131.06 45.9003C131.342 45.5341 131.274 45.0156 130.908 44.7338C130.542 44.452 130.023 44.5195 129.741 44.8858L128.163 46.9367C127.881 47.3029 127.949 47.8213 128.315 48.1031C128.681 48.385 129.2 48.3174 129.482 47.9512L131.06 45.9003Z"
      fill="#1D2228"
    />
    <path
      d="M124.683 54.1806C124.965 53.8143 124.898 53.2959 124.531 53.0141C124.165 52.7323 123.647 52.7998 123.365 53.1661L121.787 55.2169C121.505 55.5832 121.572 56.1016 121.939 56.3834C122.305 56.6652 122.823 56.5977 123.105 56.2315L124.683 54.1806Z"
      fill="#1D2228"
    />
    <path
      d="M111.948 70.7323C112.23 70.3661 112.162 69.8477 111.796 69.5659C111.43 69.284 110.911 69.3516 110.629 69.7178L109.051 71.7687C108.769 72.1349 108.837 72.6534 109.203 72.9352C109.569 73.217 110.088 73.1494 110.37 72.7832L111.948 70.7323Z"
      fill="#1D2228"
    />
    <path
      d="M95.8994 90.2231C95.5332 89.9413 95.0148 90.0088 94.733 90.375L93.1548 92.4259C92.873 92.7922 92.9405 93.3106 93.3068 93.5924C93.673 93.8742 94.1914 93.8067 94.4732 93.4405L96.0514 91.3896C96.3332 91.0233 96.2657 90.5049 95.8994 90.2231Z"
      fill="#1D2228"
    />
    <path
      d="M102.428 83.1122C102.71 82.746 102.642 82.2276 102.276 81.9457C101.91 81.6639 101.391 81.7315 101.109 82.0977L99.5313 84.1486C99.2495 84.5148 99.317 85.0332 99.6832 85.3151C100.049 85.5969 100.568 85.5293 100.85 85.1631L102.428 83.1122Z"
      fill="#1D2228"
    />
    <path
      d="M105.579 79.0097C105.861 78.6434 105.793 78.125 105.427 77.8432C105.061 77.5614 104.542 77.6289 104.26 77.9952L102.682 80.046C102.4 80.4123 102.468 80.9307 102.834 81.2125C103.2 81.4943 103.719 81.4268 104.001 81.0606L105.579 79.0097Z"
      fill="#1D2228"
    />
    <path
      d="M108.794 74.8339C109.076 74.4677 109.008 73.9492 108.642 73.6674C108.276 73.3856 107.757 73.4531 107.476 73.8194L105.897 75.8703C105.616 76.2365 105.683 76.7549 106.049 77.0367C106.416 77.3186 106.934 77.251 107.216 76.8848L108.794 74.8339Z"
      fill="#1D2228"
    />
    <path
      d="M99.2125 87.286C99.4943 86.9198 99.4268 86.4014 99.0606 86.1196C98.6944 85.8378 98.1759 85.9053 97.8941 86.2715L96.316 88.3224C96.0341 88.6886 96.1017 89.2071 96.4679 89.4889C96.8341 89.7707 97.3526 89.7032 97.6344 89.3369L99.2125 87.286Z"
      fill="#1D2228"
    />
    <path
      d="M92.8976 95.4911C93.1794 95.1249 93.1119 94.6065 92.7456 94.3246C92.3794 94.0428 91.861 94.1104 91.5792 94.4766L91.1283 95.0626C91.5114 95.474 91.8945 95.8854 92.334 96.2236L92.8976 95.4911Z"
      fill="#1D2228"
    />
    <path
      d="M133.545 37.2089C133.05 36.9439 132.497 36.7522 132.002 36.4873L131.889 36.6338C131.607 37 131.675 37.5185 132.041 37.8003C132.407 38.0821 132.925 38.0146 133.207 37.6483L133.545 37.2089Z"
      fill="#1D2228"
    />
    <path
      d="M125.267 47.9784C125.549 47.6122 125.481 47.0938 125.115 46.812C124.749 46.5301 124.23 46.5977 123.948 46.9639L122.37 49.0148C122.088 49.381 122.156 49.8995 122.522 50.1813C122.888 50.4631 123.407 50.3955 123.689 50.0293L125.267 47.9784Z"
      fill="#1D2228"
    />
    <path
      d="M96.5807 85.2587C96.8625 84.8925 96.795 84.374 96.4287 84.0922C96.0625 83.8104 95.5441 83.8779 95.2623 84.2442L93.6841 86.2951C93.4023 86.6613 93.4698 87.1797 93.8361 87.4615C94.2023 87.7434 94.7207 87.6758 95.0025 87.3096L96.5807 85.2587Z"
      fill="#1D2228"
    />
    <path
      d="M115.685 60.4276C115.967 60.0614 115.899 59.543 115.533 59.2612C115.167 58.9794 114.649 59.0469 114.367 59.4131L112.789 61.464C112.507 61.8302 112.574 62.3487 112.941 62.6305C113.307 62.9123 113.825 62.8448 114.107 62.4785L115.685 60.4276Z"
      fill="#1D2228"
    />
    <path
      d="M93.2676 88.1948C92.9014 87.913 92.3829 87.9805 92.1011 88.3467L90.523 90.3976C90.2412 90.7638 90.3087 91.2823 90.6749 91.5641C91.0412 91.8459 91.5596 91.7784 91.8414 91.4121L93.4196 89.3612C93.7014 88.995 93.6338 88.4766 93.2676 88.1948Z"
      fill="#1D2228"
    />
    <path
      d="M102.942 76.9823C103.224 76.6161 103.156 76.0977 102.79 75.8159C102.424 75.534 101.905 75.6016 101.624 75.9678L100.045 78.0187C99.7636 78.3849 99.8312 78.9034 100.197 79.1852C100.564 79.467 101.082 79.3995 101.364 79.0332L102.942 76.9823Z"
      fill="#1D2228"
    />
    <path
      d="M99.7882 81.081C100.07 80.7147 100.002 80.1963 99.6363 79.9145C99.27 79.6327 98.7516 79.7002 98.4698 80.0664L96.8916 82.1173C96.6098 82.4836 96.6774 83.002 97.0436 83.2838C97.4098 83.5656 97.9282 83.4981 98.2101 83.1319L99.7882 81.081Z"
      fill="#1D2228"
    />
    <path
      d="M106.155 72.8066C106.437 72.4403 106.369 71.9219 106.003 71.6401C105.637 71.3583 105.118 71.4258 104.836 71.792L103.258 73.8429C102.977 74.2091 103.044 74.7276 103.41 75.0094C103.777 75.2912 104.295 75.2237 104.577 74.8574L106.155 72.8066Z"
      fill="#1D2228"
    />
    <path
      d="M118.9 56.2519C119.182 55.8856 119.114 55.3672 118.748 55.0854C118.382 54.8036 117.863 54.8711 117.582 55.2373L116.003 57.2882C115.722 57.6545 115.789 58.1729 116.155 58.4547C116.522 58.7365 117.04 58.669 117.322 58.3028L118.9 56.2519Z"
      fill="#1D2228"
    />
    <path
      d="M109.316 68.703C109.598 68.3368 109.53 67.8184 109.164 67.5366C108.798 67.2547 108.279 67.3223 107.998 67.6885L106.419 69.7394C106.138 70.1056 106.205 70.6241 106.571 70.9059C106.938 71.1877 107.456 71.1202 107.738 70.7539L109.316 68.703Z"
      fill="#1D2228"
    />
    <path
      d="M112.524 64.5292C112.805 64.163 112.738 63.6445 112.372 63.3627C112.005 63.0809 111.487 63.1485 111.205 63.5147L109.571 65.6388C109.289 66.005 109.356 66.5235 109.723 66.8053C110.089 67.0871 110.607 67.0196 110.889 66.6533L112.524 64.5292Z"
      fill="#1D2228"
    />
    <path
      d="M122.051 52.1493C122.333 51.7831 122.266 51.2647 121.899 50.9828C121.533 50.701 121.015 50.7686 120.733 51.1348L119.155 53.1857C118.873 53.5519 118.941 54.0704 119.307 54.3522C119.673 54.634 120.191 54.5664 120.473 54.2002L122.051 52.1493Z"
      fill="#1D2228"
    />
    <path
      d="M131.574 39.7704C131.856 39.4042 131.788 38.8858 131.422 38.6039C131.056 38.3221 130.537 38.3897 130.255 38.7559L128.677 40.8068C128.395 41.173 128.463 41.6914 128.829 41.9733C129.195 42.2551 129.714 42.1875 129.996 41.8213L131.574 39.7704Z"
      fill="#1D2228"
    />
    <path
      d="M128.421 43.8739C128.702 43.5077 128.635 42.9893 128.269 42.7075C127.902 42.4256 127.384 42.4932 127.102 42.8594L125.524 44.9103C125.242 45.2765 125.31 45.795 125.676 46.0768C126.042 46.3586 126.561 46.2911 126.842 45.9248L128.421 43.8739Z"
      fill="#1D2228"
    />
    <path
      d="M89.9302 93.8984L90.212 93.5321C90.4939 93.1659 90.4263 92.6475 90.0601 92.3657C89.6939 92.0838 89.1754 92.1514 88.8936 92.5176L88.8373 92.5909C89.2204 93.0023 89.6035 93.4137 89.9302 93.8984Z"
      fill="#1D2228"
    />
    <path
      d="M145.034 45.9287L144.64 46.4414C144.358 46.8077 144.425 47.3261 144.792 47.6079C145.158 47.8897 145.676 47.8222 145.958 47.456L146.127 47.2362C145.744 46.8248 145.361 46.4134 145.034 45.9287Z"
      fill="#1D2228"
    />
    <path
      d="M106.011 98.0004C105.645 97.7186 105.126 97.7861 104.844 98.1524L103.266 100.203C102.984 100.57 103.052 101.088 103.418 101.37C103.784 101.652 104.303 101.584 104.585 101.218L106.163 99.1669C106.445 98.8007 106.377 98.2822 106.011 98.0004Z"
      fill="#1D2228"
    />
    <path
      d="M128.428 70.2362C128.71 69.87 128.643 69.3516 128.276 69.0698C127.91 68.788 127.392 68.8555 127.11 69.2217L125.532 71.2726C125.25 71.6388 125.317 72.1573 125.684 72.4391C126.05 72.7209 126.568 72.6534 126.85 72.2871L128.428 70.2362Z"
      fill="#1D2228"
    />
    <path
      d="M131.643 66.0624C131.925 65.6962 131.857 65.1778 131.491 64.8959C131.125 64.6141 130.607 64.6817 130.325 65.0479L128.747 67.0988C128.465 67.465 128.532 67.9834 128.899 68.2653C129.265 68.5471 129.783 68.4795 130.065 68.1133L131.643 66.0624Z"
      fill="#1D2228"
    />
    <path
      d="M144.325 49.5761C144.607 49.2099 144.539 48.6914 144.173 48.4096C143.807 48.1278 143.288 48.1953 143.006 48.5616L141.428 50.6124C141.146 50.9787 141.214 51.4971 141.58 51.7789C141.946 52.0607 142.465 51.9932 142.747 51.627L144.325 49.5761Z"
      fill="#1D2228"
    />
    <path
      d="M134.789 61.9579C135.071 61.5917 135.003 61.0733 134.637 60.7914C134.271 60.5096 133.753 60.5772 133.471 60.9434L131.893 62.9943C131.611 63.3605 131.678 63.8789 132.045 64.1608C132.411 64.4426 132.929 64.375 133.211 64.0088L134.789 61.9579Z"
      fill="#1D2228"
    />
    <path
      d="M141.156 53.6796C141.438 53.3134 141.37 52.7949 141.004 52.5131C140.638 52.2313 140.119 52.2988 139.837 52.6651L138.259 54.716C137.977 55.0822 138.045 55.6006 138.411 55.8824C138.777 56.1643 139.296 56.0967 139.578 55.7305L141.156 53.6796Z"
      fill="#1D2228"
    />
    <path
      d="M138.005 57.7812C138.286 57.4149 138.219 56.8965 137.853 56.6147C137.486 56.3329 136.968 56.4004 136.686 56.7666L135.108 58.8175C134.826 59.1838 134.894 59.7022 135.26 59.984C135.626 60.2658 136.145 60.1983 136.426 59.8321L138.005 57.7812Z"
      fill="#1D2228"
    />
    <path
      d="M122.062 78.5058C122.343 78.1395 122.276 77.6211 121.91 77.3393C121.543 77.0575 121.025 77.125 120.743 77.4912L119.165 79.5421C118.883 79.9084 118.951 80.4268 119.317 80.7086C119.683 80.9904 120.202 80.9229 120.484 80.5567L122.062 78.5058Z"
      fill="#1D2228"
    />
    <path
      d="M125.259 74.3388C125.541 73.9726 125.473 73.4541 125.107 73.1723C124.741 72.8905 124.222 72.958 123.94 73.3243L122.306 75.4484C122.024 75.8146 122.092 76.3331 122.458 76.6149C122.824 76.8967 123.343 76.8291 123.624 76.4629L125.259 74.3388Z"
      fill="#1D2228"
    />
    <path
      d="M112.531 90.8886C112.813 90.5224 112.746 90.0039 112.379 89.7221C112.013 89.4403 111.495 89.5078 111.213 89.8741L109.635 91.9249C109.353 92.2912 109.421 92.8096 109.787 93.0914C110.153 93.3732 110.671 93.3057 110.953 92.9395L112.531 90.8886Z"
      fill="#1D2228"
    />
    <path
      d="M118.893 82.6132C119.175 82.247 119.107 81.7285 118.741 81.4467C118.375 81.1649 117.856 81.2324 117.574 81.5987L115.996 83.6496C115.714 84.0158 115.782 84.5342 116.148 84.816C116.514 85.0978 117.033 85.0303 117.315 84.6641L118.893 82.6132Z"
      fill="#1D2228"
    />
    <path
      d="M109.317 95.0605C109.598 94.6942 109.531 94.1758 109.165 93.894C108.798 93.6122 108.28 93.6797 107.998 94.0459L106.42 96.0968C106.138 96.463 106.206 96.9815 106.572 97.2633C106.938 97.5451 107.457 97.4776 107.738 97.1114L109.317 95.0605Z"
      fill="#1D2228"
    />
    <path
      d="M115.685 86.79C115.967 86.4237 115.899 85.9053 115.533 85.6235C115.167 85.3417 114.649 85.4092 114.367 85.7754L112.789 87.8263C112.507 88.1925 112.574 88.711 112.941 88.9928C113.307 89.2746 113.825 89.2071 114.107 88.8408L115.685 86.79Z"
      fill="#1D2228"
    />
    <path
      d="M102.996 103.268C103.278 102.902 103.211 102.384 102.844 102.102C102.478 101.82 101.96 101.888 101.678 102.254L101.34 102.693C101.835 102.958 102.388 103.15 102.883 103.415L102.996 103.268Z"
      fill="#1D2228"
    />
    <path
      d="M143.878 44.6913C143.494 44.2799 143.111 43.8684 142.672 43.5303L141.996 44.4092C141.714 44.7755 141.781 45.2939 142.147 45.5757C142.514 45.8575 143.032 45.79 143.314 45.4238L143.878 44.6913Z"
      fill="#1D2228"
    />
    <path
      d="M125.782 68.2089C126.064 67.8427 125.996 67.3242 125.63 67.0424C125.264 66.7606 124.745 66.8281 124.463 67.1944L122.885 69.2453C122.603 69.6115 122.671 70.1299 123.037 70.4117C123.403 70.6936 123.922 70.626 124.204 70.2598L125.782 68.2089Z"
      fill="#1D2228"
    />
    <path
      d="M122.631 72.3105C122.912 71.9442 122.845 71.4258 122.479 71.144C122.112 70.8622 121.594 70.9297 121.312 71.2959L119.678 73.4201C119.396 73.7863 119.463 74.3047 119.83 74.5865C120.196 74.8684 120.714 74.8008 120.996 74.4346L122.631 72.3105Z"
      fill="#1D2228"
    />
    <path
      d="M128.997 64.0302C129.279 63.664 129.211 63.1455 128.845 62.8637C128.479 62.5819 127.96 62.6494 127.678 63.0157L126.1 65.0665C125.818 65.4328 125.886 65.9512 126.252 66.233C126.618 66.5148 127.137 66.4473 127.419 66.0811L128.997 64.0302Z"
      fill="#1D2228"
    />
    <path
      d="M138.527 51.6552C138.809 51.289 138.742 50.7705 138.376 50.4887C138.009 50.2069 137.491 50.2744 137.209 50.6407L135.631 52.6915C135.349 53.0578 135.417 53.5762 135.783 53.858C136.149 54.1398 136.668 54.0723 136.949 53.7061L138.527 51.6552Z"
      fill="#1D2228"
    />
    <path
      d="M116.262 80.5839C116.544 80.2177 116.476 79.6992 116.11 79.4174C115.744 79.1356 115.225 79.2031 114.943 79.5694L113.365 81.6203C113.083 81.9865 113.151 82.5049 113.517 82.7867C113.883 83.0686 114.402 83.001 114.684 82.6348L116.262 80.5839Z"
      fill="#1D2228"
    />
    <path
      d="M141.681 47.5497C141.963 47.1835 141.895 46.6651 141.529 46.3832C141.163 46.1014 140.644 46.169 140.362 46.5352L138.784 48.5861C138.502 48.9523 138.57 49.4707 138.936 49.7526C139.302 50.0344 139.821 49.9668 140.103 49.6006L141.681 47.5497Z"
      fill="#1D2228"
    />
    <path
      d="M132.151 59.9286C132.432 59.5624 132.365 59.044 131.999 58.7621C131.632 58.4803 131.114 58.5479 130.832 58.9141L129.254 60.965C128.972 61.3312 129.04 61.8497 129.406 62.1315C129.772 62.4133 130.291 62.3457 130.572 61.9795L132.151 59.9286Z"
      fill="#1D2228"
    />
    <path
      d="M135.374 55.7548C135.655 55.3886 135.588 54.8701 135.222 54.5883C134.855 54.3065 134.337 54.374 134.055 54.7403L132.477 56.7912C132.195 57.1574 132.263 57.6758 132.629 57.9576C132.995 58.2395 133.514 58.1719 133.796 57.8057L135.374 55.7548Z"
      fill="#1D2228"
    />
    <path
      d="M119.416 76.4823C119.697 76.1161 119.63 75.5977 119.264 75.3159C118.897 75.034 118.379 75.1016 118.097 75.4678L116.519 77.5187C116.237 77.8849 116.305 78.4034 116.671 78.6852C117.037 78.967 117.556 78.8994 117.838 78.5332L119.416 76.4823Z"
      fill="#1D2228"
    />
    <path
      d="M106.672 93.037C106.954 92.6708 106.887 92.1524 106.521 91.8705C106.154 91.5887 105.636 91.6563 105.354 92.0225L103.776 94.0734C103.494 94.4396 103.562 94.958 103.928 95.2399C104.294 95.5217 104.813 95.4541 105.094 95.0879L106.672 93.037Z"
      fill="#1D2228"
    />
    <path
      d="M113.047 84.7607C113.328 84.3944 113.261 83.876 112.895 83.5942C112.528 83.3124 112.01 83.3799 111.728 83.7461L110.15 85.797C109.868 86.1632 109.936 86.6817 110.302 86.9635C110.668 87.2453 111.187 87.1778 111.468 86.8115L113.047 84.7607Z"
      fill="#1D2228"
    />
    <path
      d="M103.367 95.976C103.001 95.6942 102.482 95.7617 102.2 96.128L100.622 98.1789C100.34 98.5451 100.408 99.0635 100.774 99.3453C101.14 99.6271 101.659 99.5596 101.941 99.1934L103.519 97.1425C103.8 96.7763 103.733 96.2578 103.367 95.976Z"
      fill="#1D2228"
    />
    <path
      d="M109.885 88.8612C110.167 88.495 110.099 87.9766 109.733 87.6948C109.367 87.413 108.848 87.4805 108.566 87.8467L106.988 89.8976C106.706 90.2638 106.774 90.7823 107.14 91.0641C107.506 91.3459 108.025 91.2784 108.307 90.9121L109.885 88.8612Z"
      fill="#1D2228"
    />
    <path
      d="M100.365 101.241C100.647 100.875 100.579 100.356 100.213 100.075C99.8467 99.7928 99.3283 99.8604 99.0464 100.227L98.4265 101.032C98.9223 101.297 99.3618 101.635 99.8576 101.9L100.365 101.241Z"
      fill="#1D2228"
    />
    <path
      d="M96.2712 99.5989L140.741 41.8078C140.448 41.5823 140.155 41.3569 139.862 41.1314C139.569 40.906 139.276 40.6805 138.983 40.4551L94.5133 98.2462C94.8063 98.4716 95.0993 98.6971 95.4655 98.9789C95.6289 99.2212 95.9219 99.4467 96.2712 99.5989Z"
      fill="#1D2228"
    />
    <rect
      x="132.553"
      y="21.0469"
      width="25.6541"
      height="3.06382"
      rx="0.714286"
      transform="rotate(-30 132.553 21.0469)"
      fill="url(#paint3_linear_978_44246)"
    />
    <rect
      x="134.666"
      y="16.9473"
      width="17.8947"
      height="8.05263"
      rx="0.714286"
      transform="rotate(-30 134.666 16.9473)"
      fill="url(#paint4_linear_978_44246)"
    />
    <rect
      x="85.3457"
      y="13.4053"
      width="25.6541"
      height="3.06382"
      rx="0.714286"
      transform="rotate(15 85.3457 13.4053)"
      fill="url(#paint5_linear_978_44246)"
    />
    <rect
      x="89.7383"
      y="12"
      width="17.8947"
      height="8.05263"
      rx="0.714286"
      transform="rotate(15 89.7383 12)"
      fill="url(#paint6_linear_978_44246)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_978_44246"
        x1="-9.88655"
        y1="49.1467"
        x2="108.391"
        y2="67.4493"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000405011" stopColor="white" />
        <stop offset="0.188133" stopColor="#9B9D9F" />
        <stop offset="0.503335" stopColor="#4F5358" />
        <stop offset="0.963542" stopColor="#686B70" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_978_44246"
        x1="156.889"
        y1="49.1467"
        x2="287.152"
        y2="71.4636"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.000405011" stopColor="white" />
        <stop offset="0.188133" stopColor="#9B9D9F" />
        <stop offset="0.503335" stopColor="#4F5358" />
        <stop offset="0.963542" stopColor="#686B70" stopOpacity="0.8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_978_44246"
        x1="109.504"
        y1="39.5756"
        x2="130.849"
        y2="107.011"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.344736" stopColor="white" />
        <stop offset="1" stopColor="#CCCCCC" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_978_44246"
        x1="145.38"
        y1="21.0469"
        x2="145.38"
        y2="24.1107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CD413A" />
        <stop offset="1" stopColor="#370B08" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_978_44246"
        x1="143.613"
        y1="16.9473"
        x2="143.613"
        y2="24.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CD413A" />
        <stop offset="1" stopColor="#370B08" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_978_44246"
        x1="98.1727"
        y1="13.4053"
        x2="98.1727"
        y2="16.4691"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CD413A" />
        <stop offset="1" stopColor="#370B08" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_978_44246"
        x1="98.6856"
        y1="12"
        x2="98.6856"
        y2="20.0526"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CD413A" />
        <stop offset="1" stopColor="#370B08" />
      </linearGradient>
    </defs>
  </svg>
)

export default Error500Banner
