import { D3LogoLink } from 'constant'

const TermsIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
  isD3?: boolean
}> = ({ className = '', width = '17', height = '30', fill = '#36B286', isD3 }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 14.9999H11M5 11.9999H11M14.9996 6.99988H10.5996C10.0396 6.99988 9.75981 6.99986 9.5459 6.89087C9.35774 6.795 9.20487 6.64206 9.10899 6.45389C9 6.23998 9 5.95993 9 5.39988V0.999878M15 15.7999V7.65387C15 7.19036 15.0002 6.9586 14.9502 6.7392C14.9059 6.54463 14.8328 6.35776 14.7332 6.18486C14.6208 5.9899 14.4632 5.81985 14.1483 5.47974L10.9502 2.02576C10.6002 1.64776 10.425 1.45875 10.2168 1.3233C10.0322 1.20324 9.82865 1.11452 9.61515 1.06065C9.37428 0.999878 9.11721 0.999878 8.60207 0.999878H4.2002C3.08009 0.999878 2.51962 0.999878 2.0918 1.21787C1.71547 1.40961 1.40973 1.71559 1.21799 2.09192C1 2.51974 1 3.07979 1 4.19989V15.7999C1 16.92 1 17.48 1.21799 17.9079C1.40973 18.2842 1.71547 18.5901 2.0918 18.7819C2.51962 18.9998 3.08009 18.9999 4.2002 18.9999H11.8002C12.9203 18.9999 13.48 18.9998 13.9078 18.7819C14.2841 18.5901 14.5905 18.2842 14.7822 17.9078C15.0002 17.48 15 16.92 15 15.7999Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    {isD3 && <image width="16" height="16" y="13" xlinkHref={D3LogoLink} />}
  </svg>
)

export default TermsIcon
