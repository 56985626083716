import { getCurrencyDetails } from 'constant/currency'
import { AppConfigContext } from 'contexts/AppConfigContext'
import { AvailableCurrency } from 'interfaces/currency'
import { useContext } from 'react'

const useCurrencyDetails = (
  currency: AvailableCurrency | undefined,
  hasFixedExchangeRate?: boolean
) => {
  const { USDToINRExchangeRate, USDToGBPExchangeRate, USDToAUDExchangeRate } =
    useContext(AppConfigContext)

  const exchangeRate: Record<AvailableCurrency, number> = {
    USD: 1,
    INR: USDToINRExchangeRate,
    AUD: USDToAUDExchangeRate,
    GBP: USDToGBPExchangeRate,
  }

  if (!currency) return null

  const defaultCurrencyDetails = getCurrencyDetails(currency)

  if (hasFixedExchangeRate) return defaultCurrencyDetails

  return {
    ...defaultCurrencyDetails,
    conversionRate: exchangeRate[defaultCurrencyDetails.selectedCurrency],
  }
}

export default useCurrencyDetails
