const BuyNavIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  isActive?: boolean
  fill?: string
  opacity?: number
}> = ({
  className = '',
  width = '25',
  height = '24',
  isActive = false,
  fill = 'white',
  opacity = 1,
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={opacity}>
      <path
        d="M20.8786 11.7261L19.9453 17.3261C19.7869 18.276 19.7077 18.751 19.4707 19.1074C19.2617 19.4216 18.9685 19.6704 18.6242 19.8248C18.2337 20 17.7525 20 16.7898 20H16.7893H8.21089H8.21033C7.24763 20 6.76641 20 6.37593 19.8248C6.03166 19.6704 5.7381 19.4216 5.52916 19.1074C5.29213 18.751 5.21271 18.276 5.05438 17.3261L4.12105 11.7261L4.1207 11.724C3.90727 10.4434 3.80051 9.80285 3.98595 9.30359C4.14877 8.86522 4.46053 8.49781 4.86617 8.26514C5.3284 8 5.97768 8 7.2773 8H8.49995C8.49995 5.79086 10.2908 4 12.5 4C14.7091 4 16.5 5.79086 16.5 8H17.7224C19.022 8 19.672 8 20.1343 8.26514C20.5399 8.49781 20.851 8.86522 21.0138 9.30359C21.1992 9.80266 21.0925 10.4429 20.8792 11.7225L20.8786 11.7261Z"
        fill="url(#paint0_linear_1307_2523)"
        fillOpacity="0.25"
      />
      <path
        d="M16.5 8C16.5 5.79086 14.7091 4 12.5 4C10.2908 4 8.49995 5.79086 8.49995 8M20.8786 11.7261L19.9453 17.3261C19.7869 18.276 19.7077 18.751 19.4707 19.1074C19.2617 19.4216 18.9685 19.6704 18.6242 19.8248C18.2336 20 17.7523 20 16.7893 20H8.21089C7.24782 20 6.76649 20 6.37593 19.8248C6.03166 19.6704 5.7381 19.4216 5.52916 19.1074C5.29213 18.751 5.21271 18.276 5.05438 17.3261L4.12105 11.7261C3.90739 10.4441 3.80041 9.80313 3.98595 9.30359C4.14877 8.86522 4.46053 8.49781 4.86617 8.26514C5.3284 8 5.97768 8 7.2773 8H17.7224C19.022 8 19.672 8 20.1343 8.26514C20.5399 8.49781 20.851 8.86522 21.0138 9.30359C21.1993 9.80313 21.0923 10.4441 20.8786 11.7261Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    {isActive && (
      <defs>
        <linearGradient
          id="paint0_linear_1307_2523"
          x1="12.4999"
          y1="21.4686"
          x2="12.0544"
          y2="5.31167"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill} />
          <stop offset="1" stopColor={fill} stopOpacity="0" />
        </linearGradient>
      </defs>
    )}
  </svg>
)

export default BuyNavIcon
