import { customStyled, FlexBox, ThemeType } from '@rario/shared-components'

export const GridContainer = customStyled.div<{ theme?: ThemeType }>`
  display: grid;
  grid-template-columns: 0px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "thumbnails image";
  grid-area: images;
  @media (width: ${({ theme }) => theme.breakpoints[2]}) {
    grid-template-rows: 1fr .2fr;
    grid-template-areas: "thumbnails image image"
  };
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
        "image image"
        "thumbnails thumbnails";
  };
`

export const MainVideo = customStyled(FlexBox)`
  grid-area: image;
  align-items: center;
  justify-content: space-evenly;
  min-width: 250px; 
  transition: 1s transform;
  // transform: rotate3d(1, 1, 0, 180deg);
  > img {
    flex: 1;
  }
`
