import { Dispatch, SetStateAction } from 'react'
import {
  CTA,
  HomePageSection,
  Description,
  IntroSection,
  HeroCard,
  SocialCard,
  Card,
  InvestersCard,
  HeroBanner,
  VideoStream,
  FAQ,
  ScrollingStrip,
  PreviewCard,
  Testimonial,
  ScarcityCard,
  InTheClubSection,
  NotificationBanner,
  CricRushBanner,
  AppHeroCard,
  HowItWorksProps,
  Media,
  FormattingSupportingText,
} from './cmsContentTypes'

type SocialSection = HomePageSection<SocialCard[]>
export type InvestersSection = HomePageSection<InvestersCard[]>
export type CricketLeaguesSection = HomePageSection<Card[]>
export type LatestUpdatesSection = HomePageSection<Card[]>
export type BigMovesSection = HomePageSection<Card[]>
export type LatestStreamsSection = HomePageSection<VideoStream[]>
export type FaqSection = HomePageSection<FAQ[]>
export type OwnTheGameSection = HomePageSection<PreviewCard[]>
export type NavigationSection = HomePageSection<Card[]>
export type TestimonialSection = HomePageSection<Testimonial[]>
export type SeeHowItWorksSection = HomePageSection<HowItWorksProps[]>

export interface HomePageData {
  title: string
  notificationBanner: NotificationBanner
  clubIntroSection: IntroSection
  packsIntroSection: IntroSection
  heroCard: HeroCard
  heroCardLoggedIn: HeroCard
  cricketLeaguesSection: CricketLeaguesSection
  cardsIntroSection: IntroSection
  learnMoreCta: CTA
  socialSection: SocialSection
  latestUpdatesSection: LatestUpdatesSection
  investersSection: InvestersSection
  bigMovesSection: BigMovesSection
  latestStreamsSection: LatestStreamsSection
  navigationSection: NavigationSection
  ownTheGameSection: OwnTheGameSection
  playTheGameSection: OwnTheGameSection
  trueFanLivingSection: HeroCard
  testimonials: TestimonialSection
  heroBanner: HeroBanner
  secondaryBanner: CricRushBanner
  firstFoldBanner: CricRushBanner
  faqSection: FaqSection
  scrollingStrip: ScrollingStrip
  inTheClubSection: InTheClubSection
  referAndEarnSection: IntroSection
  startEarningLoggedInCta: CTA
  startEarningLoggedOutCta: CTA
}

export type HomeBannerProps = {
  heroCard: HeroCard
}

export type WhatsNextProps = {
  sectionData: IntroSection
  textCTA?: CTA
  isReferAndEarnSection?: boolean
}

export interface WhatsNextMobileCardProps {
  sectionData: IntroSection
  isReferAndEarnSection?: boolean
}

export interface SocialMediaSectionProps {
  sectionData: SocialSection
}

export interface InvestersSectionProps {
  sectionData: InvestersSection
}

export interface SignupRevolutionProps {
  banner: HeroBanner
}

export interface FaqProps {
  question: string
  answer: Description | string
  pageSource?: string
}

export interface OwnTheGameProps {
  component: string
  sectionData: OwnTheGameSection
}

export interface OwnTheGameThumbProps {
  onMouseEnter: Dispatch<SetStateAction<ScarcityCard>>
  heading: string
  component: string
  subHeading: string
  playersData: ScarcityCard[]
  selectedIndex: number
  selectedText: string
}

export interface TestimonialCardProps {
  item: Testimonial
  index: number
}

export interface MobileHomePage {
  name: string
  pageContents: AppHeroCard[]
}

export type learnAboutD3Item = {
  title: string
  imgUrl: string
  bgImg?: string
  imageWidth: string
  imageHeight: string
  redirectUrl: string
}

export enum WelcomeStateEnum {
  // eslint-disable-next-line no-unused-vars
  NEW_USER = 'new_user',
  // eslint-disable-next-line no-unused-vars
  NO_PURCHASE = 'no_purchase',
  // eslint-disable-next-line no-unused-vars
  NO_GAMEPLAY = 'no_gameplay',
  // eslint-disable-next-line no-unused-vars
  NO_USER_TYPE = 'no_user_type',
}

export enum WidgetIdsEnum {
  // eslint-disable-next-line no-unused-vars
  PACKS_WIDGET = 'latestPacks',
  // eslint-disable-next-line no-unused-vars
  PICKS_WIDGET = 'picksWidget',
  // eslint-disable-next-line no-unused-vars
  MARKETPLACE_WIDGET = 'marketplaceWidget',
  // eslint-disable-next-line no-unused-vars
  HOW_RARIO_WORKS_WIDGET = 'howRarioWorksWidget',
  // eslint-disable-next-line no-unused-vars
  PLAY_BIG_WIN_BIG_WIDGET = 'playBigWinBig',
}

export type CTAVariant = 'Single Primary CTA' | 'Two Primary CTAs' | 'One Primary One Secondary CTA'

export enum CTAVariantEnum {
  // eslint-disable-next-line no-unused-vars
  SINGLE_PRIMARY_CTA = 'Single Primary CTA',
  // eslint-disable-next-line no-unused-vars
  TWO_PRIMARY_CTA = 'Two Primary CTAs',
  // eslint-disable-next-line no-unused-vars
  ONE_PRIMARY_ONE_SECONDARY_CTA = 'One Primary One Secondary CTA',
}

interface FirstFoldBannerMedia {
  isVideo: boolean
  imageAlternateText: string
  title: string
  video: Media
  videoUrl?: string
  hyperlinkUrl?: string
  newTab?: boolean
}

export interface FirstFoldData {
  title: string
  backgroundImage?: Media
  bannerMedia?: FirstFoldBannerMedia
  bottomText?: FormattingSupportingText
  cta1: CTA
  cta2?: CTA
  ctaVariation: CTAVariant[]
  footerImage?: Media
  heading1: FormattingSupportingText
  heading2: FormattingSupportingText
  heading3?: FormattingSupportingText
  ticker?: ScrollingStrip & {
    backgroundImage: Media
  }
  headingIcon?: Media
  bottomImage1?: Media
  bottomImage2?: Media
  bottomImage3?: Media
}
