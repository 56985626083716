import { trackEvent } from '@rario/shared-components'
import { IApiException } from 'api/Api.exception'
import { eventDataProps } from 'interfaces'

export const trackApiErrorResponse = (
  errorResponse: IApiException,
  eventData: { subCategory: string; category: string }
) => {
  trackEvent('Error Viewed', 'track', {
    ...eventData,
    error_code: errorResponse?.statusCode,
    error_message: errorResponse?.message,
    display_type: 'toast',
    view_type: 'constrained',
  })
}

export const trackAmountQuickOptions = (eventData: eventDataProps) => {
  trackEvent('Section Interacted ', 'click', {
    ...eventData,
    clicK_action: 'payment',
    click_type: 'text_box',
    view_type: 'constrained',
  })
}

export const trackMenuClicks = (eventData: eventDataProps) => {
  trackEvent('Section Interacted', 'click', {
    click_type: 'icon_text',
    category: 'menu',
    click_action: 'app-header',
    ...eventData,
  })
}

export const trackTabClick = (eventData: eventDataProps) => {
  trackEvent('Section Interacted', 'click', {
    click_type: 'tab',
    click_action: 'tab_click_changed_manually',
    category: 'payment',
    view_type: 'constrained',
    ...eventData,
  })
}
