const WithdrawBankIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '22', height = '18', fill = '#36B286' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 17H21M11 17H19V11.368C19 10.8427 19 10.58 18.937 10.335C18.8812 10.1178 18.7891 9.91163 18.6649 9.72496C18.5247 9.51434 18.3295 9.33869 17.9387 8.98758L15.6387 6.92127C14.8827 6.24208 14.5045 5.9025 14.0771 5.77368C13.7007 5.66021 13.2991 5.66021 12.9227 5.77368C12.4953 5.9025 12.1174 6.24208 11.3614 6.92127L11 7.24597M11 17V4.19995C11 3.07985 11.0002 2.51986 10.7822 2.09204C10.5905 1.71572 10.2841 1.40973 9.90779 1.21799C9.47996 1 8.9203 1 7.8002 1H6.2002C5.08009 1 4.51962 1 4.0918 1.21799C3.71547 1.40973 3.40973 1.71572 3.21799 2.09204C3 2.51986 3 3.07985 3 4.19995V17H11Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default WithdrawBankIcon
