import { customStyled, FlexBox, theme, ThemeType } from '@rario/shared-components'

export const CarousleWrapper = customStyled(FlexBox)`
  flex-direction: column;
  position: relative;
  
  & .embla__button{
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: -70px;
    border: 0;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    fill: #1bcacd;
    padding: 0;
  }
  & .embla__button:disabled {
    cursor: default;
  }
  
  & .embla__button--prev {
    right: 70px;
  }
  
  & .embla__button--next {
    right: 27px;
  }
    }
`
export const CarouselDot = customStyled(FlexBox)`
  transform: rotate(-45deg);
`

export const CarouselMain = customStyled.div<{ leftFade: boolean; rightFade: boolean }>`
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  -webkit-mask-image:${(props) =>
    !props.leftFade && props.rightFade
      ? 'linear-gradient(270deg,transparent 0%,black 4%)'
      : props.leftFade && !props.rightFade
      ? 'linear-gradient(270deg,black 0%,black 95%,transparent 100%)'
      : props.leftFade && props.rightFade
      ? 'linear-gradient(270deg,transparent 0%,black 4%,black 95%,transparent)'
      : 'none'};
  mask-image:${(props) =>
    !props.leftFade && props.rightFade
      ? 'linear-gradient(270deg,transparent 0%,black 4%)'
      : props.leftFade && !props.rightFade
      ? 'linear-gradient(270deg,black 0%,black 95%,transparent 100%)'
      : props.leftFade && props.rightFade
      ? 'linear-gradient(270deg,transparent 0%,black 4%,black 95%,transparent)'
      : 'none'};
`

export const CraouselViewport = customStyled.div`
  overflow: hidden;
  width: 100%;  
  &.is-draggable {
      cursor: move;
      cursor: grab;
    }
    &.is-dragging {
      cursor: grabbing;
    }
`
export const CraouselContainer = customStyled.div<{
  selectedIndex?: number
  sideSpacing?: boolean
  isLastSlide?: boolean
}>`
  display: flex;  
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;

  ${({ sideSpacing = false }) =>
    sideSpacing &&
    `margin-left: 28%;
    margin-right: 28%;
`}
  
  .embla__slide {
    opacity: 0.4;
    transform: scale(0.87);
    transition: 0.2s ease-in-out 0s;
    pointer-events: none;

    .embla__slide_button {
      opacity: 0;
    }
  }

  .embla__slide${({ selectedIndex }) => selectedIndex} {
    opacity: 1;
    transform: scale(1);
    transition: 0.2s ease-in-out 0s;
    pointer-events: auto;
    z-index: 999999;

    .embla__slide_button {
      opacity: 1;
    }
  }

  .info__slide {
    opacity: 0;
  }

  .info__slide${({ selectedIndex }) => selectedIndex} {
    pointer-events: auto;
    animation: textDisplay 3.6s linear forwards 0.6s;
    @keyframes textDisplay {
      0% {
        opacity: 0;
        transform: translateY(5%);
      }
      10% {
        opacity: 0.3;
        transform: translateY(0%);
      }
      20% {
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      95% {
        opacity: ${({ isLastSlide }) => (isLastSlide ? 1 : 0.8)};
      }
      100% {
        opacity: ${({ isLastSlide }) => (isLastSlide ? 1 : 0.2)};
      }
    }

  }

`

export const CraouselDots = customStyled.div`
  display: flex;
  list-style: none;
  justify-content: center;
  padding-top: 10px;
  & .embla__dot{
    background-color: transparent;
    cursor: pointer;
    position: relative;
    padding: 0;
    outline: 0;
    border: 0;
    width: 30px;
    height: 30px;
    margin-left: 7.5px;
    display: flex;
    align-items: center;
  }
  & .embla__dot:after {
    border: 2px solid #FFFFFF;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    content: "";
    transform: rotate(-45deg);
    opacity: 0.3;
  }
  & .embla__dot.is-selected:after {
    background: #FFFFFF;
    opacity: 1;
  }
`
export const CarouselCustomDots = customStyled.div<{ absolutePosition?: boolean }>`
  display: flex;
  list-style: none;
  justify-content: center;
  position: ${({ absolutePosition = false }) => (absolutePosition ? 'absolute' : 'initial')};
  ${({ absolutePosition = false }) => absolutePosition && `top: 345px;`}
  transform: ${({ absolutePosition = false }) => (absolutePosition ? 'translate(-50%)' : 'none')};
  ${({ absolutePosition = false }) => absolutePosition && `left: 50%;`}
  & .embla__dot{
    background-color: transparent;
    position: relative;
    padding: 0;
    outline: 0;
    border: 0;
    margin-left: 5px;
    display: flex;
    align-items: center;
  }
  & .embla__dot:after {
    background: #FFFFFF;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    content: "";
    opacity: 0.3;
    transition: 0.2s linear 0s;
  }
  & .embla__dot.is-selected:after {
    background: #FFFFFF;
    opacity: 1;
    border-radius: 100px !important;
    width: 16px;
  }
`

export const CraouselSeparateSlider = customStyled.div<{ theme?: ThemeType }>`
  display: flex;
  list-style: none;
  justify-content: center;
  padding-top: 10px;
  & .embla__dot{
    margin: 0px;
    cursor: pointer;
    position: relative;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    z-index: 9;
    padding: 0;
    border: 1px solid #686868;
    background: #686868;
    width: 35px;
    border-radius: 4px;
    margin-right: 3px;
  }
  & .embla__dot.is-selected {
    border: 1.5px solid white;
    background: #FFFFFF;
    border-radius: 2px;
    z-index: 99;
    width: 35px;
  }
`

export const PackRevealArrowWrapper = customStyled.button<{
  border?: string
  pos?: string
}>`
    flex-direction: row;
    align-items: center;
    padding: 15px;
    gap: 15px;
    width: 68px !important;
    height: 68px !important;
    border: ${({ border }) => border || 'solid 1px white'} !important;
    border-radius: 5px !important;
    position:absolute;
    top: 40% !important;
    left:${({ pos }) => (pos === 'right' ? 'auto' : '10px')};
    right:${({ pos }) => (pos !== 'right' ? 'auto' : '10px')};
    z-index:9999;
    cursor:pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
`
export const LatestSaleArrowWrapper = customStyled.button<{
  background?: string
  border?: string
  pos?: string
  isLatestSaleWidget: boolean
  theme?: ThemeType
}>`
    flex-direction: row;
    align-items: center;
    padding: 20px;
    gap: 15px;
    width: 56px !important;
    height: 56px !important;
    border: ${({ border }) => border || 'solid 1px white'} !important;
    background: ${({ background }) => background || 'rgba(255, 255, 255, 0.5)!'} !important;
    border-radius: 50% !important;
    position:absolute;
    top:${({ isLatestSaleWidget }) => (isLatestSaleWidget ? '17% !important' : ' 45% !important')};
    transform: translate(-50%);
    left:${({ pos, isLatestSaleWidget }) =>
      pos === 'right' ? 'auto' : isLatestSaleWidget ? '30px !important' : '50px !important'};
    right:${({ pos, isLatestSaleWidget }) =>
      pos !== 'right' ? 'auto' : isLatestSaleWidget ? '-27px !important' : '0px!important'};
    z-index:9999;
    cursor:pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    &.picks{
      @media(max-width:${({ theme }) => theme.breakpoints[1]}) {
        left:${({ pos }) => (pos === 'right' ? 'auto' : '30px !important')};
        right:${({ pos }) => (pos !== 'right' ? 'auto' : '-30px!important')};
        }
      }
    }
   
`

export const AmbassadorArrowWrapper = customStyled.button<{
  background?: string
  border?: string
  pos?: string
  ambassadorSection: boolean
  theme?: ThemeType
}>`
   flex-direction: row;
    align-items: center;
    width: 40px !important;
    height: 40px !important;
    border: ${({ border }) => border || 'solid 1px white'} !important;
    background: ${({ background }) => background || 'rgba(255, 255, 255, 0.5)!'} !important;
    border-radius: 50% !important;
    position:absolute;
    top:${({ ambassadorSection }) => (ambassadorSection ? '55% !important' : ' 45% !important')};
    transform: translate(-50%);
    left:${({ pos, ambassadorSection }) =>
      pos === 'right' ? 'auto' : ambassadorSection ? '34px !important' : '23px !important'};
    right:${({ pos, ambassadorSection }) =>
      pos !== 'right' ? 'auto' : ambassadorSection ? '-5px !important' : '-16px!important'};
    z-index:9999;
    cursor:pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    &.testimonial{
      @media(max-width:${({ theme }) => theme.breakpoints[1]}) {
        left:${({ pos }) => (pos === 'right' ? 'auto' : '25px !important')};
        right:${({ pos }) => (pos !== 'right' ? 'auto' : '-15px!important')};
        }
      }
      & .embla__button__svg {
      margin-top: 1px;
      margin-left: 1px;
    }
`

export const AnimatedCarouselSlider = customStyled(FlexBox)<{
  theme?: ThemeType
  sliderHighlightColor?: string
  isInfoScreen: boolean
}>`
  list-style: none;
  justify-content: center;
  padding-top: 10px;
  bottom: 110px;
  @media(max-width:${({ theme }) => theme.breakpoints[0]}) {
    bottom: 130px;
  }
  @media(max-width: 33 ) {
    bottom: 145px;
  }
  @media(max-width: 285px) {
    bottom: 155px;
  }
  left: 50%;
  transform: ${({ isInfoScreen = false }) => (isInfoScreen ? 'translate(-50%)' : 'none')};
  & .embla__dot{
    cursor: pointer;
    position: relative;
    left: -1px;
    right: -1px;
    display: flex;
    justify-content: center;
    z-index: 9;
    padding: 0;
    border-top: 1px solid #686868;
    border-bottom: transparent;
    background: #686868;
    width: 35px;
  }
  & .embla__dot.is-selected {
    border-top: 1px solid ${({ sliderHighlightColor }) => sliderHighlightColor};
    border-bottom: transparent;
    background: ${({ sliderHighlightColor }) => sliderHighlightColor};
    border-radius: 2px;
    z-index: 9;

    animation: progressBar 4s ease-out;

    @keyframes progressBar {
        from { width: 0; }
    }
  }
  & .embla__dot.was-selected {
     background: ${({ sliderHighlightColor }) => sliderHighlightColor};
      border-top: 1px solid ${({ sliderHighlightColor }) => sliderHighlightColor};
      border-bottom: transparent;
      border-radius: 2px;
      z-index: 9;
  }
`

export const CarouselSlider = customStyled(FlexBox)<{
  theme?: ThemeType
  sliderHighlightColor?: string
  isInfoScreen: boolean
  isPackReveal: boolean
}>`
  list-style: none;
  justify-content: center;
  padding-top: 10px;
  ${({ isPackReveal = false }) => (isPackReveal ? `top: 345px;` : 'bottom: 110px;')}
  @media(max-width:${({ theme }) => theme.breakpoints[0]}) {
    ${({ isPackReveal = false }) => (isPackReveal ? `top: 345px;` : 'bottom : 120px;')}
  }
  left: 50%;
  transform: ${({ isInfoScreen = false, isPackReveal = false }) =>
    isInfoScreen || isPackReveal ? 'translate(-50%)' : 'none'};
  & .embla__dot{
    margin-right: 0px;
    cursor: pointer;
    position: relative;
    bottom: 10px;
    left: -1px;
    right: -1px;
    display: flex;
    justify-content: center;
    z-index: 9;
    padding: 0;
    border: 1px solid #686868;
    background: #686868;
    width: 35px;
    margin-right: 2px;
  }
  & .embla__dot.is-selected {
    border: 1.5px solid ${({ sliderHighlightColor }) => sliderHighlightColor};
    background: ${({ sliderHighlightColor }) => sliderHighlightColor};
    border-radius: 2px;
    z-index: 9;
    width: 35px;
  }
`

export const ThumbsViewport = customStyled.div`
  margin: 15px; 
  width: 100%;
  overflow: hidden;
`

export const ThumbsWrapper = customStyled.div`
  width: 92%;
  display: flex;
`
export const ThumbContainer = customStyled.div<{ selected: boolean }>`
  margin: 0px 2px; 
  background: ${({ selected = false }) =>
    selected ? `${theme.gradients.carouselThumbBackground} !important` : 'transparent'};
  transform: ${({ selected = false }) => (selected ? `scale(1)` : 'scale(0.9)')};
  transition: 0.2s linear 0s;
  border: ${({ selected = false }) => (selected ? '1px' : '0px')} solid;
  border-image-slice: 1;
  border-image-source: ${theme.gradients.carouselThumbBorder};
  cursor: pointer;
  opacity: ${({ selected = false }) => (selected ? '1' : '0.7')};
  border-top: none;
`

export const ThumbButton = customStyled.button`
  background: transparent !important;
  border: none !important;
`
