const RarioLogo: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  isBeta?: boolean
}> = ({ className = '', width = '111', height = '26', isBeta = true }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 111 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    {isBeta && (
      <g opacity="0.7">
        <path
          d="M84.4128 21.2593L83.4713 20.2944H78.8644L77.6626 25.9999H82.4698L83.8219 25.035L84.0923 23.7345L83.4213 23.0465L84.1524 22.5347L84.4128 21.2593ZM82.0492 22.5514H80.1964L80.3867 21.6033H82.3196L82.5099 21.7879L82.3997 22.3081L82.0492 22.5514ZM82.1393 24.4728L81.8289 24.691H79.7457L79.946 23.7345H81.9691L82.2395 24.0114L82.1393 24.4728Z"
          fill="white"
        />
        <path
          d="M91.4206 21.6369L91.701 20.2944H86.0725L84.8707 25.9999H90.4992L90.7796 24.6574H86.9538L87.1441 23.7848H90.4591L90.7295 22.5095H87.4045L87.5948 21.6369H91.4206Z"
          fill="white"
        />
        <path
          d="M98.6045 20.2944H92.6155L92.3351 21.6369H94.4282L93.5068 25.9999H95.3095L96.2309 21.6369H98.3241L98.6045 20.2944Z"
          fill="white"
        />
        <path
          d="M102.301 25.9999H104.134L102.752 20.2944H100.919L97.1331 25.9999H99.066L99.6769 24.9931H102.111L102.301 25.9999ZM100.428 23.7513L101.52 21.9473L101.86 23.7513H100.428Z"
          fill="white"
        />
      </g>
    )}

    <path
      d="M18.2188 10.0751L24.0917 4.64964L19.0586 0H0L5.03304 4.64964H16.5421L10.6692 10.0751L18.3227 17.1456H25.8723L18.2188 10.0751Z"
      fill="#EE332A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.0288 4.00975L82.2268 1.64551H66.1888L68.081 4.00975L64.531 15.7384H69.3784L70.3019 12.6927H73.7348L75.7485 15.7384H80.8302L78.875 12.6927L82.0736 10.4757L84.0288 4.00975ZM77.7623 8.69196H71.5093L72.0409 6.93771L71.0273 5.64621H78.6858L77.7623 8.69196ZM48.9563 4.00986L47.1543 1.64561H31.1164L33.013 4.00986L29.4585 15.7385H34.3059L35.2295 12.6928H38.6623L40.6761 15.7385H45.7578L43.8071 12.6928L47.0057 10.4758L48.9563 4.00986ZM42.6943 8.69207H36.4413L36.9729 6.93781L35.9593 5.64632H43.6179L42.6943 8.69207ZM52.9799 1.64551H62.1026L63.4001 15.7342H58.814L58.7284 14.7919H52.3267L51.6509 15.7384H46.5107L54.8765 4.00975L52.9799 1.64551ZM57.9985 6.83674L54.4936 11.7419H58.449L57.9985 6.83674ZM93.007 5.64621L94.2189 1.64551H92.7322H87.8848H86.3576L85.1638 5.64621H86.6729L84.8304 11.7419H83.3257L82.1319 15.7426H83.6185H88.466H89.9751L91.187 11.7419H89.6778L91.5204 5.64621H93.007ZM99.6696 1.64551H108.175L111 4.57347L108.549 12.6969L103.922 15.7426H95.3988L92.6327 12.6927L95.0925 4.56926L99.6696 1.64551ZM97.755 11.7419H103.963L105.815 5.64621H98.21L99.2191 6.93771L97.755 11.7419Z"
      fill="white"
    />
  </svg>
)

export default RarioLogo
