import { AppConfigContext } from 'contexts/AppConfigContext'
import { AvailableCurrency } from 'interfaces/currency'
import { useContext } from 'react'

const usePreferredCurrency = (currency: AvailableCurrency) => {
  const { preferredCurrency } = useContext(AppConfigContext)
  return currency === preferredCurrency
}

export default usePreferredCurrency
