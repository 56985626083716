// eslint-disable no-useless-escape

export const TOTAL_POLLS = 60
export const POLL_INTERVAL = 5000
export const REDIRECT_INTERVAL = 1000

export const MAX_WITHDRAWAL_LIMIT = 5000

export const WITHDRAW_AMOUNT_OPTIONS: { id: number; percent: 100 | 50 | 20 | 10 }[] = [
  {
    id: 1,
    percent: 100,
  },
  {
    id: 2,
    percent: 50,
  },
  {
    id: 3,
    percent: 20,
  },
  {
    id: 4,
    percent: 10,
  },
]

export const NAME_REGEX = /^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/

export const CITY_AND_STATE_REGEX = /^(?=.*[a-zA-Z])[a-zA-Z0-9\s]{0,50}$/

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const IDENTICAL_LETTERS = /([a-zA-Z0-9])\1\1/gi

export const LOWERCASE_LETTERS = /[a-z]/g

export const NUMBERS = /[0-9]/g

export const UPPERCASE_LETTERS = /[A-Z]/g

export const SPECIAL_CHARACTERS = /[^\w\s]/g

export const WITHDRAWAL_FEE = 0

export const MINIMUM_WITHDRAWABLE_BALANACE = 0.01
