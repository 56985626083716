const FAQIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '10', height = '16', fill = '#FF996D' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0C2.243 0 0 2.243 0 5H2C2 3.346 3.346 2 5 2C6.654 2 8 3.346 8 5C8 6.069 7.546 6.465 6.519 7.255C6.137 7.549 5.706 7.881 5.293 8.293C3.981 9.604 3.995 10.897 4 11V13H6V10.991C6 10.967 6.023 10.39 6.707 9.707C7.027 9.387 7.389 9.109 7.738 8.84C8.798 8.024 10 7.1 10 5C10 2.243 7.757 0 5 0ZM4 14H6V16H4V14Z"
      fill={fill}
    />
  </svg>
)

export default FAQIcon
