import { FlexBox, TextField, ThemeType, customStyled, theme } from '@rario/shared-components'

export const DescriptionText = customStyled(TextField)`
font-size:12px !important;
line-height:14px !important;
padding-bottom: 0px;
align-self:center;
opacity:0.5;
font-weight:400px !important;
margin-right: 5px;
`
export const CurrencyContainer = customStyled(FlexBox)`
padding-inline: 6px;
border-radius:33px;
align-items:center;
background: rgba(255, 255, 255, 0.04);
align-items: center;
justify-content: center;
height: 34px;
`
export const Container = customStyled(FlexBox)<{
  isSelectedCurrency?: boolean
  theme?: ThemeType
}>`
width:75px;
height:90px;
margin-right:8px;
flex-direction:column;
padding:8px 12px;
border-radius:5px;
align-items:center;
background:${({ isSelectedCurrency = false }) =>
  isSelectedCurrency ? theme.colors.lightSecondaryBG : 'rgba(255, 255, 255, 0.05)'};
  
border:${({ isSelectedCurrency = false, theme }) =>
  isSelectedCurrency
    ? `1px solid ${theme.colors.lightSecondaryBorder}`
    : `1px solid ${theme.colors.whites[4]}`};
`

export const BottomSheetHeading = customStyled(TextField)<{
  theme?: ThemeType
}>`  
font-size:16px;
line-height:22px !important;
text-align:start;
font-family:${({ theme }) => theme.fonts.primary} !important;
letter-spacing: ${({ theme }) => theme.letterSpacings.primary};
align-self:center;
opacity:0.5;
font-weight:400px !important;
`
