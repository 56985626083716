import { customStyled } from '@rario/shared-components'
import { SpaceProps, HeightProps, WidthProps } from 'styled-system'

export type VideoType = SpaceProps & HeightProps & WidthProps

export const VideoWrapper = customStyled.div<{
  borderRadius: any
  showTill: number
}>`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '0px')};
  @media (max-width: ${({ showTill = 1921 }) => showTill}px){
     width: 100%;
 }
  & iframe{
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`
