import { customStyled, FlexBox, TextField, ThemeType } from '@rario/shared-components'
import {
  PaddingProps,
  padding,
  display,
  DisplayProps,
  PositionProps,
  position,
  margin,
  WidthProps,
  width,
} from 'styled-system'

export const MaxWidthLayout = customStyled.main<
  PaddingProps & DisplayProps & PositionProps & { theme?: ThemeType }
>`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 500px;
  // padding-left: 15px;
  // padding-right: 15px;
  ${padding};
  ${display};
  ${position};
  ${margin};
  overflow: hidden;
  margin: auto;
`

export const FullWidth = customStyled(FlexBox)`
    width: 100%;
    // position: relative;
    // left: 50%;
    // right: 50%;
    // margin-left: -50vw;
    // margin-right: -50vw;
    // top: -40px;
    // margin-bottom: -40px;
    &.market-tabs{
      margin-bottom: 0px;
    }
    // @media screen and (min-width: 2100px) {
    //   & .home-first-fold{
    //     height: 60vh;
    // }
    // @media screen and (min-width: 2500px) {
    //   & .home-first-fold{
    //     height: 50vh;
    // }
    // @media screen and (min-width: 3500px) {
    //   & .home-first-fold{
    //     height: 40vh;
    // }
    // @media screen and (min-width: 4000px) {
    //   & .home-first-fold{
    //     height: 30vh;
    //   }
    // }
`

export const GutterSpaceRow = customStyled(FlexBox)<WidthProps>`
    max-width: calc(1256px);
    margin: 0px auto;
    ${width};
`

export const CustomGutterSpaceRow = customStyled(FlexBox)<
  WidthProps & { theme?: ThemeType; margin?: string | string[] }
>`
    max-width: calc(1256px);
    margin: ${({ margin = '0px auto' }) => (typeof margin === 'string' ? margin : margin[3])};
    ${width};
    @media screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
      margin: ${({ margin = '0px auto' }) => (typeof margin === 'string' ? margin : margin[0])};
    }
    @media screen and (max-width: 1300px) {
      width:90%;
    }
`

export const BackdropFilter = customStyled(FlexBox)<{ filterBlur: string }>`
backdrop-filter: blur(${({ filterBlur = '20px' }) => filterBlur});
-webkit-backdrop-filter: blur(${({ filterBlur = '20px' }) => filterBlur});
`

export const ToastWrapper = customStyled.div<{ theme?: ThemeType }>`
& .Toastify__close-button {
  align-self: auto;
  margin-right:27px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
      align-self:flex-start;
      margin:auto;
  }
}
& .Toastify__toast-theme--colored {
  margin: 19px;
}
& .Toastify__toast-icon{
  margin-left:24px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    margin-left:auto;
  }
}
& .Toastify__toast-body > div:last-child{
  font-size:18px;
  line-height:22px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    font-size:14px;
    line-height:17px;
  }
}
& .Toastify__toast--default {
  & .Toastify__close-button {
    display: none;
  }
  background: #501F20 !important;
}
& .Toastify__toast--success {
  background: #31A07A !important;
}
& .Toastify__toast--error {
  background: #CD7C5A !important;
}
& .Toastify__toast--warning {
  background: #501F20 !important;
}
& .Toastify__toast--info {
  background: #0F2C28 !important;
}
`

export const BackgroundOverlay = customStyled(FlexBox)<{ theme?: ThemeType }>`
  background: linear-gradient(180deg, rgba(4, 9, 15, 0.8) -2.03%, rgba(2, 8, 13, 0.368) 14.53%, rgba(82, 82, 82, 0) 23.66%),
  radial-gradient(165.03% 45.51% at 0% 103.49%, #040910 0%, rgba(4, 9, 16, 0.74) 36.93%, rgba(4, 9, 16, 0.1) 100%);
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    background: radial-gradient(165.03% 45.51% at 0% 103.49%, #040910 0%, rgba(4, 9, 16, 0.74) 36.93%, rgba(4, 9, 16, 0.1) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(180deg, rgba(4, 9, 15, 0.8) -2.03%, rgba(2, 8, 13, 0.368) 14.53%, rgba(82, 82, 82, 0) 23.66%);
  }
  `
export const CookieConsentWrapper = customStyled(FlexBox)<{ theme?: ThemeType }>`
  transform: translateX(-50%);
  & #subContentBtn {
    margin-bottom: 5px;
    font-weight: 800;
    margin-left: 10px;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: 12px;
    line-height: 16px;
  }
  & > div {
    text-align: left !important;
    padding-right: 10px;
    align-items: flex-start !important;
    position: relative !important; 
    border-radius: 4px 4px 0px 0px;
  }

  & button {
    top: 22px;
    right: 15px;
    font-weight: 900;
  }
`
export const CookieConsentTextWrapper = customStyled(TextField)<{ theme?: ThemeType }>`
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 400;
  & a {
    font-weight: 800;
    font-family: ${({ theme }) => theme.fonts.secondary};
  }
`
