const ShareIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ width = '46', height = '46' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_bd_2816_51161)">
      <rect
        x="8"
        y="6"
        width="30"
        height="30"
        rx="15"
        fill="white"
        fillOpacity="0.02"
        shapeRendering="crispEdges"
      />
      <path
        d="M20.9167 22.0417L25.0833 24.125M25.0833 17.875L20.9167 19.9583M27.1667 27.25C26.0161 27.25 25.0833 26.3173 25.0833 25.1667C25.0833 24.0161 26.0161 23.0833 27.1667 23.0833C28.3173 23.0833 29.25 24.0161 29.25 25.1667C29.25 26.3173 28.3173 27.25 27.1667 27.25ZM18.8333 23.0833C17.6827 23.0833 16.75 22.1506 16.75 21C16.75 19.8494 17.6827 18.9167 18.8333 18.9167C19.9839 18.9167 20.9167 19.8494 20.9167 21C20.9167 22.1506 19.9839 23.0833 18.8333 23.0833ZM27.1667 18.9167C26.0161 18.9167 25.0833 17.9839 25.0833 16.8333C25.0833 15.6827 26.0161 14.75 27.1667 14.75C28.3173 14.75 29.25 15.6827 29.25 16.8333C29.25 17.9839 28.3173 18.9167 27.1667 18.9167Z"
        stroke="white"
        strokeWidth="1.38889"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="8.41667"
        y="6.41667"
        width="29.1667"
        height="29.1667"
        rx="14.5833"
        stroke="url(#paint0_linear_2816_51161)"
        strokeWidth="0.833333"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_bd_2816_51161"
        x="-7"
        y="-9"
        width="60"
        height="60"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2816_51161" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.66667" />
        <feGaussianBlur stdDeviation="3.75" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_2816_51161"
          result="effect2_dropShadow_2816_51161"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_2816_51161"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2816_51161"
        x1="10.7165"
        y1="2.625"
        x2="43.5553"
        y2="6.41876"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.05" />
        <stop offset="0.732287" stopColor="white" stopOpacity="0.05" />
        <stop offset="1" stopColor="white" stopOpacity="0.15" />
      </linearGradient>
    </defs>
  </svg>
)

export default ShareIcon
