const UserSmallIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
  opacity?: number
}> = ({ className = '', width = '19', height = '20', fill = 'white', opacity = 1 }) => (
  <>
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={opacity}
    >
      <path
        d="M9.5 14C13.9183 14 17.5 16.2386 17.5 19H9.5H1.5C1.5 16.2386 5.08172 14 9.5 14Z"
        fill="url(#paint0_linear_115_10339)"
        fillOpacity="0.25"
      />
      <path
        d="M4.5 6C4.5 8.76142 6.73858 11 9.5 11C12.2614 11 14.5 8.76142 14.5 6C14.5 3.23858 12.2614 1 9.5 1C6.73858 1 4.5 3.23858 4.5 6Z"
        fill="url(#paint1_linear_115_10339)"
        fillOpacity="0.25"
      />
      <path
        d="M9.5 14C13.9183 14 17.5 16.2386 17.5 19H9.5H1.5C1.5 16.2386 5.08172 14 9.5 14Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 6C4.5 8.76142 6.73858 11 9.5 11C12.2614 11 14.5 8.76142 14.5 6C14.5 3.23858 12.2614 1 9.5 1C6.73858 1 4.5 3.23858 4.5 6Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_115_10339"
          x1="9.5"
          y1="20.6521"
          x2="8.89354"
          y2="2.48206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill} />
          <stop offset="1" stopColor={fill} stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_115_10339"
          x1="9.5"
          y1="20.6521"
          x2="8.89354"
          y2="2.48206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill} />
          <stop offset="1" stopColor={fill} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  </>
)

export default UserSmallIcon
