import { FLexBoxProps, trackEvent } from '@rario/shared-components'
import SignoutIcon from 'assets/svg/SignoutIcon'
import { IconWrapper } from 'components/Header/Header.styles'
import { useCustomUser } from 'contexts/CustomUserProvider'
import { logoutTrackingPayloadTypes } from 'interfaces'
import dynamic from 'next/dynamic'
import React, { FC, useState } from 'react'
const LogoutModal = dynamic(() => import('components/Header/LogoutModal'))

const LogoutButton: FC<{
  flexBoxStyles?: FLexBoxProps
  trackingPayload?: logoutTrackingPayloadTypes
}> = ({
  flexBoxStyles = {
    ml: 10,
    width: 40,
    zIndex: 1,
    alignSelf: 'flex-end',
    justifyContent: 'center',
    alignItems: 'center',
  },
  trackingPayload,
}) => {
  const { user } = useCustomUser()
  const [showSignOutModal, setShowSignOutModal] = useState<boolean>(false)
  return (
    <>
      <IconWrapper
        {...flexBoxStyles}
        onClick={() => {
          trackingPayload && trackEvent('Section Interacted', 'click', trackingPayload)
          setShowSignOutModal(true)
        }}
      >
        <SignoutIcon />
      </IconWrapper>
      {user && showSignOutModal && (
        <LogoutModal
          closeSignoutModal={() => {
            setShowSignOutModal(false)
          }}
        />
      )}
    </>
  )
}

export default LogoutButton
