import { FlexBox, Modal, TextField, theme } from '@rario/shared-components'
import WalletTimerIcon from 'assets/svg/WalletTimerIcon'
import { ModalContext } from 'components/ModalManager/ModalProvider'
import usePageControl from 'hooks/usePageControl'
import { useRouter } from 'next/router'
import { useContext } from 'react'

const DisableAddFundsModal = () => {
  const { setShowDisableAddFundsModal } = useContext(ModalContext)
  const { isAddFundsPage } = usePageControl()
  const { push } = useRouter()

  return (
    <Modal
      onClick={() => {
        setShowDisableAddFundsModal(false)
        isAddFundsPage && push('/')
      }}
      bodyBackground={theme.colors.whites[0]}
      visible
      background="#11151C"
      showCloseIcon={true}
      modalPosition={'flex-end'}
      variant={'fullScreen'}
      desktopTop={'-50px'}
      top={'-50px'}
    >
      <FlexBox flexDirection="column" alignItems="center" my="40px" mx="10px">
        <WalletTimerIcon />

        <TextField
          fontFamily={theme.fonts.primary}
          fontSize="18px"
          fontWeight={700}
          lineHeight="26px"
          textTransform="uppercase"
          textAlign="center"
          pb="0px"
          mt="40px"
          mb="15px"
        >
          Wallet under fine-tuning
        </TextField>

        <TextField
          fontSize="14px"
          fontWeight={400}
          lineHeight="20px"
          color={theme.colors.whites[10]}
          textAlign="center"
          pb="0px"
        >
          We're giving your wallet a little upgrade to keep it extra awesome and make sure it
          follows all the rules. This means topping up will be on hold for a bit, but it'll be back
          up and running in no time!
        </TextField>
      </FlexBox>
    </Modal>
  )
}

export default DisableAddFundsModal
