import { Image, TextField, theme } from '@rario/shared-components'
import { Container } from 'components/CurrencyToggle/Currency.styles'
import { AvailableCurrency, CurrencyListItem } from 'interfaces/currency'
import { getCDNUrl } from 'utils/utils'

const CurrencyItem: React.FunctionComponent<{
  isSelectedCurrency: boolean
  listItem: CurrencyListItem
  setCurrency: (currency: AvailableCurrency) => void
}> = ({ isSelectedCurrency, listItem, setCurrency }) => {
  return (
    <Container
      isSelectedCurrency={isSelectedCurrency}
      onClick={() => setCurrency(listItem?.selectedCurrency)}
    >
      <Image
        src={getCDNUrl(listItem?.imageUrl)}
        height={'50px'}
        width={'50px'}
        name={`currency_${listItem.symbol}`}
      />

      <TextField
        fontSize={'16px'}
        lineHeight={'22px'}
        fontWeight={isSelectedCurrency ? 600 : 400}
        content={listItem.selectedCurrency}
        fontFamily={theme.fonts.primary}
        letterSpacing={theme.letterSpacings.primary}
        color={isSelectedCurrency ? theme.colors.white : theme.colors.wolfram}
      />
    </Container>
  )
}
export default CurrencyItem
