const debounce = (func: any, wait: number, immediate = false) => {
  let timeout: NodeJS.Timeout | null | number

  return function (...args: any) {
    // @ts-ignore
    const context: any = this
    const later = () => {
      timeout = null
      if (!immediate) {
        func.apply(context, args)
      }
    }
    const callNow = immediate && !timeout
    // @ts-ignore
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) {
      func.apply(context, args)
    }
  }
}

export default debounce
