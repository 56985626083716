import { FrameCardTokenizedEvent } from 'frames-react'
import { PaymentMethodEnum, SalesOrderGroupResult, WalletBalanceProps } from 'interfaces'

export interface PurchaseSummaryProps {
  orderId: string
  disableModalOutsideClick?: (toggle: boolean) => void
  xp?: number
}

export interface PurchasePaymentFormProps {
  onChangePG: (paymentMethod: string, useBonus: boolean) => void
  onBonusDiscountToggle: (bonusFlag: boolean) => void
  checkoutPayment: (e: FrameCardTokenizedEvent) => void
  walletBalance?: WalletBalanceProps
  isCheckoutScriptLoading: boolean
  setIsCheckoutScriptLoading: Function
}

export interface PurchaseBonusProps {
  bonusBalance: number
  redeemableBalance: number
  onBonusDiscountToggle: (toggle: boolean) => void
}

export interface UserProfileDetails {
  firstName: string
  lastName: string
}

export type PurchaseSummaryContextProps = {
  isLoading: boolean
  setIsLoading: Function
  orderGroup: SalesOrderGroupResult | undefined
  setOrderGroup: Function
  selectedPG: PaymentMethodEnum | undefined
  setSelectedPG: Function
  isEnoughWalletBalance: boolean
  setIsEnoughWalletBalance: Function
  useBonusDiscount: boolean
  setUseBonusDiscount: Function
  isCheckoutValid: boolean
  setCheckoutValid: Function
}

export enum CancelType {
  // eslint-disable-next-line no-unused-vars
  BACK = 'history-back',
  // eslint-disable-next-line no-unused-vars
  CROSS = 'cross-mark',
}
