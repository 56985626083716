import { useEffect, useRef, useState } from 'react'
import { FlexBox, theme, Image } from '@rario/shared-components'
import SwipeButtonArrowIcon from 'assets/svg/SwipeButtonArrowIcon'
import {
  ContainerText,
  SliderArrow,
  SliderButton,
  SliderContainer,
  SlidingButtonContainer,
  Wrapper,
} from './SwipableButton.styles'
import { getCDNUrl } from '../../utils'

const loaderGif = getCDNUrl('/App/images/button/white_loader.gif')
const successGif = getCDNUrl('/App/images/button/white_success_animation.gif')

const SwipableButton: React.FunctionComponent<{
  loading?: boolean
  onSwipeSuccess: () => void
  showSuccessAnimation?: boolean
  disabled?: boolean
}> = ({ onSwipeSuccess, loading = false, showSuccessAnimation = false, disabled = false }) => {
  const sliderRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const [showText, setShowText] = useState(true)

  useEffect(() => {
    setUnlocked(loading)
  }, [loading])

  const isTouchDevice = 'ontouchstart' in document.documentElement

  const [unlocked, setUnlocked] = useState(false)

  const containerWidth = useRef<number>(0)
  const sliderLeft = useRef<number>(0)
  const startX = useRef<number>(0)
  const isDragging = useRef<boolean>(false)

  useEffect(() => {
    const body: HTMLBodyElement | null = document.querySelector('body')
    body?.classList.add('noselect')

    return () => body?.classList.remove('noselect')
  }, [])

  useEffect(() => {
    if (isTouchDevice) {
      document.addEventListener(
        'touchend',
        () => {
          document.removeEventListener('touchmove', onDrag)
          stopDrag()
        },
        { passive: true }
      )
    } else {
      document.addEventListener('mouseup', () => {
        document.removeEventListener('mousemove', onDrag)
        stopDrag()
      })
    }

    if (containerRef?.current) {
      containerWidth.current = containerRef.current.clientWidth - 50
    }

    return () => {
      if (isTouchDevice) {
        document.removeEventListener('touchmove', onDrag)
        document.removeEventListener('touchend', stopDrag)
      } else {
        document.removeEventListener('mousemove', onDrag)
        document.removeEventListener('mouseup', stopDrag)
      }
    }
  }, [])

  const onDrag = (e: any) => {
    if (unlocked || disabled) return

    if (isDragging.current) {
      if (isTouchDevice) {
        sliderLeft.current = Math.min(
          Math.max(0, e.touches[0].clientX - startX.current),
          containerWidth.current
        )
      } else {
        sliderLeft.current = Math.min(
          Math.max(0, e.clientX - startX.current),
          containerWidth.current
        )
      }

      updateSliderStyles()
    }
  }

  const updateSliderStyles = () => {
    if (unlocked || disabled) return

    if (sliderRef?.current) {
      sliderRef.current.style.left = sliderLeft.current + 50 + 'px'
    }
  }

  const stopDrag = () => {
    if (unlocked || disabled) return

    if (isDragging.current) {
      isDragging.current = false
      if (sliderLeft.current > containerWidth.current * 0.9) {
        sliderLeft.current = containerWidth.current
        // Call succcess related function
        onSuccess()
        onSwipeSuccess()
      } else {
        sliderLeft.current = 0
        setShowText(true)
        // Drag failure related function call
      }
      updateSliderStyles()
    }
  }

  const startDrag = (e: any) => {
    if (unlocked || disabled) return

    if (!isTouchDevice) {
      document.addEventListener('mousemove', onDrag)
    } else {
      document.addEventListener('touchmove', onDrag, { passive: true })
    }

    isDragging.current = true
    setShowText(false)

    if (isTouchDevice) {
      startX.current = e.touches[0].clientX
    } else {
      startX.current = e.clientX
    }
  }

  const onSuccess = () => {
    // callback for success
    if (!unlocked) {
      sliderLeft.current = 0
    }

    if (containerRef.current) {
      containerRef.current.style.width = containerRef.current.clientWidth + 'px'
    }
  }

  return (
    <Wrapper className="ReactSwipeButton" disabled={disabled}>
      {/* Wrapper for creating edges for container */}
      <FlexBox position="absolute" width="100%" height="100%">
        <FlexBox width="50%" height="100%" mb="-45px" className="left-twisted-edge-container">
          <FlexBox
            width="100%"
            flex={1}
            background={`linear-gradient(45deg, transparent 7px, ${
              disabled ? '#1D2228' : theme.colors.chineseBlack
            } 7px)`}
            margin="1px 0 1px 1px"
          ></FlexBox>
        </FlexBox>
        <FlexBox width="50%" height="100%" mb="-45px" className="right-twisted-edge-container">
          <FlexBox
            width="100%"
            flex={1}
            background={`linear-gradient(225deg, transparent 7px, ${
              disabled ? '#1D2228' : theme.colors.chineseBlack
            } 7px)`}
            margin="1px 1px 1px -1px"
          ></FlexBox>
        </FlexBox>
      </FlexBox>

      {/* Swipe button container */}
      <SliderContainer
        ref={containerRef}
        className={unlocked ? 'rsbContainerUnlocked' : ''}
        disabled={disabled}
      >
        {!unlocked && (
          <SlidingButtonContainer
            className="rsbcSlider"
            ref={sliderRef}
            unlocked={unlocked}
            {...(isTouchDevice
              ? {
                  onTouchStart: (e) => {
                    startDrag(e)
                  },
                }
              : {
                  onMouseDown: (e) => {
                    startDrag(e)
                  },
                })}
          >
            <SliderArrow className="rsbcSliderArrow">
              <FlexBox height={'22px'} width={'27px'} mr="10px">
                <SwipeButtonArrowIcon fill={disabled ? '#686B70' : '#11151C'} />
              </FlexBox>
            </SliderArrow>

            <SliderButton className="rsbcSliderCircle"></SliderButton>

            {/* Wrapper for edges for sliding buttons */}
            <FlexBox
              height="32px"
              width="25px"
              margin="5px"
              position="absolute"
              top="0"
              right="24px"
              className="left-twisted-edge-button"
            />

            {/* Wrapper for edges for sliding buttons */}
            <FlexBox
              height="32px"
              width="25px"
              margin="5px"
              position="absolute"
              top="0"
              right="0"
              className="right-twisted-edge-button"
            />
          </SlidingButtonContainer>
        )}

        {showText && !loading && (
          <ContainerText className="rsbcText" disabled={disabled}>
            SWIPE TO BUY
          </ContainerText>
        )}

        <FlexBox
          as="span"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          height="100%"
          position={'relative'}
          zIndex={2}
        >
          {loading && (
            <FlexBox position={'absolute'} left={`calc(50% - 50px)`}>
              <Image src={loaderGif} width="100px" height="100px" name="loader" />
            </FlexBox>
          )}

          {showSuccessAnimation && (
            <FlexBox position={'absolute'} left={`calc(50% - 50px)`}>
              <Image src={successGif} width="100px" height="100px" name="loader animation" />
            </FlexBox>
          )}
        </FlexBox>
      </SliderContainer>
    </Wrapper>
  )
}

export default SwipableButton
