const RarioCoinIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '17', height = '16', fill }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="mask0_430_31044" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
      <circle cx="9" cy="9.00006" r="9" fill={fill} />
    </mask>
    <g mask="url(#mask0_430_31044)">
      <circle cx="9" cy="9.00006" r="9" fill={fill} />
      <circle cx="8.99968" cy="9.00004" r="7.32243" fill={fill} stroke="#B6A845" />
      <circle cx="9.00037" cy="9.00007" r="7.31776" fill={fill} />
      <path
        d="M10.9389 10.6536L13.0009 8.63081L11.2337 6.89725H4.54199L6.30916 8.63081H10.3501L8.28811 10.6536L10.9753 13.2898H13.6261L10.9389 10.6536Z"
        fill="#364D9A"
      />
      <path
        d="M10.5609 4.71033L12.3281 6.44389H9.6773H5.63631L3.86914 4.71033H10.5609Z"
        fill="#364D9A"
      />
    </g>
  </svg>
)

export default RarioCoinIcon
