import { useEffect, useRef, useState } from 'react'
import { isBrowser } from 'utils/utils'

const useInView = (ref: HTMLElement | null | undefined): [boolean] => {
  if (!isBrowser()) {
    return [false]
  }
  const [inView, setInView] = useState<boolean>(false)
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0]
        if (first && first.isIntersecting) {
          setInView(true)
        }
      },
      { rootMargin: '10px', threshold: 0.25 }
    )
  )

  useEffect(() => {
    const currentElement = ref
    const currentObserver = observer.current
    if (currentElement) {
      currentObserver.observe(currentElement)
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement)
      }
    }
  }, [ref])
  return [inView]
}

export default useInView
