const SwipeButtonArrowIcon: React.FunctionComponent<{
  width?: string
  height?: string
  fill?: string
}> = ({ width = '26', height = '13', fill = '#11151C' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.625 12.875C1.37891 12.875 1.16016 12.793 0.996094 12.6289C0.640625 12.3008 0.640625 11.7266 0.996094 11.3984L5.61719 6.75L0.996094 2.12891C0.640625 1.80078 0.640625 1.22656 0.996094 0.898438C1.32422 0.542969 1.89844 0.542969 2.22656 0.898438L7.47656 6.14844C7.83203 6.47656 7.83203 7.05078 7.47656 7.37891L2.22656 12.6289C2.0625 12.793 1.84375 12.875 1.625 12.875ZM10.375 12.875C10.1289 12.875 9.91016 12.793 9.74609 12.6289C9.39062 12.3008 9.39062 11.7266 9.74609 11.3984L14.3672 6.75L9.74609 2.12891C9.39062 1.80078 9.39062 1.22656 9.74609 0.898438C10.0742 0.542969 10.6484 0.542969 10.9766 0.898438L16.2266 6.14844C16.582 6.47656 16.582 7.05078 16.2266 7.37891L10.9766 12.6289C10.8125 12.793 10.5938 12.875 10.375 12.875ZM19.125 12.875C18.8789 12.875 18.6602 12.793 18.4961 12.6289C18.1406 12.3008 18.1406 11.7266 18.4961 11.3984L23.1172 6.75L18.4961 2.12891C18.1406 1.80078 18.1406 1.22656 18.4961 0.898438C18.8242 0.542969 19.3984 0.542969 19.7266 0.898438L24.9766 6.14844C25.332 6.47656 25.332 7.05078 24.9766 7.37891L19.7266 12.6289C19.5625 12.793 19.3438 12.875 19.125 12.875Z"
      fill={fill}
    />
  </svg>
)

export default SwipeButtonArrowIcon
