import { Button, FlexBox, TextField, theme, trackEvent } from '@rario/shared-components'
import { IApiException } from 'api/Api.exception'
import { cancelUserOrderGroup } from 'api/Order.api'
import CancelWithOutLineIcon from 'assets/svg/CancelWithOutLineIcon'
import { emitCustomEvent } from 'components/CustomEvents'
import { ModalContext } from 'components/ModalManager/ModalProvider'
import Toast from 'components/Toast'
import { IN_APP_PURCHASE_COOKIE } from 'constant'
import { DEFAULT_TOAST_MESSAGE } from 'constant/toolTipMessages'
import { useCustomAuth } from 'contexts/CustomAuthProvider'
import { CancelType } from 'interfaces/purchaseFlow'
import { useRouter } from 'next/router'
import { useContext, useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { trackApiErrorResponse } from 'utils/trackEventUtils'

const CancelPurchase: React.FunctionComponent = () => {
  const { accessToken } = useCustomAuth()

  const router = useRouter()
  const path = useRef<string>(router.asPath)
  const { redirect_to, orderId, order_id } = router.query
  const { paymentSummaryModal, setPaymentSummaryModal, cancelPaymentModal, setCancelPaymentModal } =
    useContext(ModalContext)
  const [cookies] = useCookies([IN_APP_PURCHASE_COOKIE.ORDER_ID])
  const [disableCancel, setDisableCancel] = useState<boolean>(false)

  const commonEventData = {
    order_id: orderId || paymentSummaryModal?.orderId || null,
    redirect_link: redirect_to || null,
    category: 'purchase',
  }

  const trackCancelButton = (status: string, ctaText: string) => {
    trackEvent('Section Interacted', 'click', {
      sub_category: 'purchase-cancel_order_cta',
      click_text: ctaText,
      status,
      click_action: 'close_bottom_sheet',
      click_type: 'button',
      ...commonEventData,
    })
  }

  const closeModals = async () => {
    if (cancelPaymentModal.type === CancelType.BACK) {
      setTimeout(() => {
        redirect_to ? router.replace(redirect_to as string) : router.back()
      }, 300)
    }
    setCancelPaymentModal({ show: false })
    paymentSummaryModal?.orderId && setPaymentSummaryModal(undefined)
    trackCancelButton('initated', 'YES, CANCEL')
    setDisableCancel(false)
    if (router.query?.modal) {
      const query = { ...router.query }
      delete query?.order_id
      delete query.modal
      router.replace({
        pathname: router.pathname,
        query,
      })
    }
  }

  const cancelOrder = async () => {
    setDisableCancel(true)

    const cancelOrderId =
      paymentSummaryModal?.orderId ??
      (orderId as string) ??
      order_id ??
      cookies?.[IN_APP_PURCHASE_COOKIE.ORDER_ID]
    try {
      await cancelUserOrderGroup(cancelOrderId, accessToken)
      closeModals()
      trackCancelButton('success', 'YES, CANCEL')
      Toast({
        message: 'Order has been cancelled successfully!',
        type: 'success',
        options: {
          icon: false,
        },
      })
    } catch (e) {
      const error = e as IApiException
      const errorResponse = { message: DEFAULT_TOAST_MESSAGE }
      emitCustomEvent('errorCodes', errorResponse)
      trackApiErrorResponse(error, {
        subCategory: 'purchase-summary_bottom_sheet',
        category: 'purchase',
      })
      closeModals()
    }
  }

  useEffect(() => {
    if (router.asPath !== path.current) {
      setCancelPaymentModal({ show: false })
    }

    trackEvent('Section Viewed', 'track', {
      sub_category: 'purchase-cancel_order_bottom_sheet',
      type: 'bottom_sheet',
      ...commonEventData,
    })
  }, [router])

  return (
    <FlexBox p={'70px 24px 59px'} flexDirection="column">
      <FlexBox flexDirection={'column'} alignItems={'center'} mb={'54px'}>
        <CancelWithOutLineIcon />
        <TextField
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontSize={'24px'}
          fontWeight={theme.fontWeights[5]}
          color={theme.colors.white}
          lineHeight={'29px'}
          pb={0}
          mt={'28px'}
          textAlign={'center'}
        >
          Are you sure you want to cancel your purchase?
        </TextField>
      </FlexBox>
      <FlexBox flexDirection={'column'} alignItems={'center'} justifyContent={'flex-end'}>
        <TextField
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontSize={'14px'}
          fontWeight={theme.fontWeights[5]}
          color={theme.colors.primary}
          lineHeight={'28px'}
          opacity={disableCancel ? '0.5' : '1'}
          pb={0}
          mb={'23px'}
          onClick={() => !disableCancel && cancelOrder()}
          cursor={disableCancel ? 'not-allowed' : 'pointer'}
        >
          YES, CANCEL
        </TextField>
        <Button
          onClick={() => {
            setCancelPaymentModal({ show: false })
            trackCancelButton('null', 'NO, CONTINUE PURCHASE')
          }}
          variant={'dynamic'}
          name={'NO, CONTINUE PURCHASE'}
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontSize={'15px '}
          fontWeight={theme.fontWeights[5]}
          disabled={disableCancel}
        />
      </FlexBox>
    </FlexBox>
  )
}
export default CancelPurchase
