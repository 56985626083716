import React, { useRef, useEffect, useState } from 'react'
import { InfiniteScrollViewWrapper, RowItemWrapper } from './InfiniteScrollView.style'
import { InfiniteScroll } from './InfiniteScrollView.interface'
import { isBrowser } from 'utils/utils'
import { Loader } from '@rario/shared-components'

const InfiniteScrollView: React.FunctionComponent<InfiniteScroll> = ({
  onScrollEndHandler,
  testId = 'scrollable-list-view',
  hasMore = true,
  children,
  height = 'auto',
}) => {
  if (!isBrowser()) {
    return null
  }
  const loader = useRef(onScrollEndHandler)
  const [element, setElement] = useState<HTMLElement | null>(null)
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0]
        if (first && first.isIntersecting && loader.current) {
          loader.current()
        }
      },
      { threshold: 0.25 }
    )
  )

  useEffect(() => {
    const currentElement = element
    const currentObserver = observer.current
    if (currentElement) {
      currentObserver.observe(currentElement)
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement)
      }
    }
  }, [element])
  if (!children) {
    return null
  }
  return (
    <InfiniteScrollViewWrapper data-testid={testId} height={height}>
      {React.Children.map(children, (child, rowIndex: number) => {
        return <RowItem key={rowIndex}>{child}</RowItem>
      })}
      {hasMore && (
        <li
          style={{ height: '40px', display: 'flex', justifyContent: 'center', marginTop: '5px' }}
          data-testid="load-more-element"
          ref={setElement}
        >
          <Loader height="80px" bg="transparent" mt="-20px" />
        </li>
      )}
    </InfiniteScrollViewWrapper>
  )
}

const RowItem: React.FunctionComponent<{
  children: React.ReactChild | string | null | any
}> = ({ children }) => {
  const [inView, setInView] = useState<boolean>()
  const [element, setElement] = useState<HTMLElement | null>(null)
  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0]
        if (first && first.isIntersecting) {
          setInView(true)
        }
      },
      { rootMargin: '10px', threshold: 0.25 }
    )
  )
  useEffect(() => {
    const currentElement = element
    const currentObserver = observer.current
    if (currentElement) {
      currentObserver.observe(currentElement)
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement)
      }
    }
  }, [element])
  return (
    <RowItemWrapper minHeight={!inView ? '30vh' : '0px'} ref={setElement}>
      {inView && children}
    </RowItemWrapper>
  )
}
export default InfiniteScrollView
