import { FlexBox, theme, TextField } from '@rario/shared-components'
import { useCallback } from 'react'
import { SortByItem, SortByProps } from 'interfaces'

const SortByFilter: React.FunctionComponent<SortByProps> = ({
  onSelect,
  items,
  selectedValue,
  disableFilter,
}) => {
  const onOptionClicked = useCallback(
    (item: SortByItem) => {
      if (onSelect) {
        onSelect(item.value, item.label)
      }
    },
    [onSelect]
  )

  return (
    <>
      {items &&
        items.map((item, index) => {
          return (
            <FlexBox
              key={index}
              border={`1px solid ${theme.colors.whites[5]}`}
              borderRadius="20px"
              cursor="pointer"
              background={
                item.value === selectedValue ? `${theme.colors.whites[9]}` : `transparent`
              }
              mr={'15px'}
              onClick={() => {
                !disableFilter && onOptionClicked(item)
              }}
            >
              <TextField
                whiteSpace="pre"
                p={'8px 13px'}
                fontSize="12px"
                lineHeight={'16px'}
                color={
                  item.value === selectedValue
                    ? `${theme.colors.chineseBlack}`
                    : `${theme.colors.whites[7]}`
                }
              >
                {item.label}
              </TextField>
            </FlexBox>
          )
        })}
    </>
  )
}

export default SortByFilter
