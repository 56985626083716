const ExploreIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '20', height = '21', fill = 'white' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00391 19V3M17.0039 13.6867V2.31323C11.1857 6.8626 6.82209 -2.23608 1.00391 2.31329V13.6867C6.82209 9.13728 11.1857 18.236 17.0039 13.6867Z"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ExploreIcon
