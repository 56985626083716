const HeaderTwitterIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
  opacity?: string
}> = ({ fill = 'white', width = '12', height = '11', opacity = '0.8' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={opacity}
      d="M10.7578 3.0625C10.7578 3.17969 10.7578 3.27344 10.7578 3.39062C10.7578 6.64844 8.29688 10.375 3.77344 10.375C2.36719 10.375 1.07812 9.97656 0 9.27344C0.1875 9.29688 0.375 9.32031 0.585938 9.32031C1.73438 9.32031 2.78906 8.92188 3.63281 8.26562C2.55469 8.24219 1.64062 7.53906 1.33594 6.55469C1.5 6.57812 1.64062 6.60156 1.80469 6.60156C2.01562 6.60156 2.25 6.55469 2.4375 6.50781C1.3125 6.27344 0.46875 5.28906 0.46875 4.09375V4.07031C0.796875 4.25781 1.19531 4.35156 1.59375 4.375C0.914062 3.92969 0.492188 3.17969 0.492188 2.33594C0.492188 1.86719 0.609375 1.44531 0.820312 1.09375C2.03906 2.57031 3.86719 3.55469 5.90625 3.67188C5.85938 3.48438 5.83594 3.29688 5.83594 3.10938C5.83594 1.75 6.9375 0.648438 8.29688 0.648438C9 0.648438 9.63281 0.929688 10.1016 1.42188C10.6406 1.30469 11.1797 1.09375 11.6484 0.8125C11.4609 1.39844 11.0859 1.86719 10.5703 2.17188C11.0625 2.125 11.5547 1.98438 11.9766 1.79688C11.6484 2.28906 11.2266 2.71094 10.7578 3.0625Z"
      fill={fill}
    />
  </svg>
)

export default HeaderTwitterIcon
