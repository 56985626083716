import { ListWrapper } from './List.styles'

interface ListProps {
  listItems?: { name: string; type: string }[]
  onClick?(event: React.MouseEvent<HTMLUListElement>): void
  children?: React.ReactNode
}

const List: React.FunctionComponent<ListProps> = ({ children, onClick }) => {
  return <ListWrapper onClick={onClick}>{children}</ListWrapper>
}

export default List
