const CricrushLogo: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
}> = ({ className = '', width = '118', height = '35' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 2004 452"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1156.34 373H1132.3L1126 408.7H1133.88L1136.08 396.1H1152.3L1159.18 390.22L1161.17 378.88L1156.34 373ZM1149.68 389.065H1137.34L1138.92 380.035H1151.46L1152.83 381.662L1151.88 387.175L1149.68 389.065Z"
      fill="white"
    />
    <path
      d="M1193.04 373H1175.61L1167.32 380.035L1163.54 401.665L1169.31 408.7H1186.74L1195.04 401.665L1198.82 380.035L1193.04 373ZM1184.06 401.665H1174.09L1171.88 398.988L1174.67 383.132L1178.29 380.035H1188.26L1190.47 382.712L1187.69 398.568L1184.06 401.665Z"
      fill="white"
    />
    <path
      d="M1243.93 373L1234.06 396.52L1231.91 373H1225.51L1215.06 396.625L1213.48 373H1205.5L1208.71 408.7H1216.27L1226.71 385.18L1228.81 408.7H1236.32L1252.12 373H1243.93Z"
      fill="white"
    />
    <path
      d="M1285.93 380.035L1287.19 373H1258.53L1252.23 408.7H1280.89L1282.15 401.665H1261.36L1262.62 394.315H1280.79L1282.05 387.28H1263.88L1265.14 380.035H1285.93Z"
      fill="white"
    />
    <path
      d="M1321.81 389.59L1323.7 378.88L1318.87 373H1294.82L1288.52 408.7H1296.4L1298.71 395.47H1306.58L1311.89 408.7H1320.18L1314.77 395.47H1314.93L1321.81 389.59ZM1299.97 388.487L1301.44 380.035H1313.99L1315.35 381.662L1314.51 386.545L1312.25 388.487H1299.97Z"
      fill="white"
    />
    <path
      d="M1360.48 380.035L1361.74 373H1333.07L1326.77 408.7H1355.44L1356.7 401.665H1335.91L1337.17 394.315H1355.33L1356.59 387.28H1338.43L1339.69 380.035H1360.48Z"
      fill="white"
    />
    <path
      d="M1392.73 373H1369.37L1363.07 408.7H1386.43L1394.73 401.665L1398.51 380.035L1392.73 373ZM1383.76 401.665H1372.21L1375.99 380.035H1387.96L1390.16 382.712L1387.38 398.568L1383.76 401.665Z"
      fill="white"
    />
    <path
      d="M1449.86 378.932L1444.98 373H1421.61L1415.31 408.7H1439.73L1446.71 402.767L1448.13 394.84L1444.45 390.378L1448.44 386.965L1449.86 378.932ZM1438.31 387.437H1426.92L1428.28 379.93H1440.25L1441.56 381.505L1440.88 385.232L1438.31 387.437ZM1439.36 399.775L1437 401.77H1424.4L1425.76 394.157H1437.99L1439.94 396.52L1439.36 399.775Z"
      fill="white"
    />
    <path
      d="M1489.62 373H1480.12L1469.78 385.653L1463.69 373H1455.08L1464.95 392.688L1462.11 408.7H1469.99L1472.82 392.688L1489.62 373Z"
      fill="white"
    />
    <path
      d="M1586.73 419.422L1612.24 394.424L1590.38 373H1507.62L1529.48 394.424H1579.45L1553.95 419.422L1587.19 452H1619.97L1586.73 419.422Z"
      fill="#EE332A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1720.21 391.475L1712.38 380.582H1642.74L1650.98 391.475L1635.54 445.516H1656.59L1660.6 431.483H1675.51L1684.25 445.516H1706.32L1697.85 431.483L1711.74 421.267L1720.21 391.475ZM1693.02 413.049H1665.86L1668.17 404.966L1663.77 399.015H1697.03L1693.02 413.049ZM1872.51 391.475L1864.68 380.582H1795.04L1803.25 391.475L1787.84 445.516H1808.89L1812.9 431.483H1827.8L1836.55 445.516H1858.62L1850.13 431.483L1864.02 421.267L1872.51 391.475ZM1845.29 413.049H1818.14L1820.45 404.966L1816.05 399.015H1849.3L1845.29 413.049ZM1737.68 380.582H1777.3L1782.93 445.497H1763.02L1762.64 441.155H1734.84L1731.91 445.516H1709.59L1745.92 391.475L1737.68 380.582ZM1759.47 404.501L1744.25 427.102H1761.43L1759.47 404.501ZM1911.5 399.015L1916.76 380.582H1910.3H1889.25H1882.62L1877.44 399.015H1883.99L1875.99 427.102H1869.46L1864.27 445.535H1870.73H1891.78H1898.33L1903.59 427.102H1897.04L1905.04 399.015H1911.5ZM1940.42 380.582H1977.36L1989.62 394.072L1978.98 431.502L1958.89 445.536H1921.88L1909.87 431.483L1920.55 394.053L1940.42 380.582ZM1932.11 427.102H1959.07L1967.11 399.015H1934.09L1938.47 404.966L1932.11 427.102Z"
      fill="white"
    />
    <path
      d="M374.782 84.4982H311.735V63.1381H258.339V181.308H311.735V146.86H374.782V204.049C374.782 223.343 358.595 239.53 339.301 239.53H230.782C211.149 239.53 195.301 223.681 195.301 204.049V40.4071C195.301 20.7744 211.149 4.92578 230.782 4.92578H339.301C358.595 4.92578 374.782 20.7744 374.782 40.4071V84.5073V84.4982Z"
      fill="white"
    />
    <path
      d="M584.59 239.53H515.693L490.202 159.601H458.504V239.53H395.457V4.91699H539.457C558.751 4.91699 574.938 21.1038 574.938 40.3983V112.403C574.938 132.035 558.751 147.884 539.457 147.884V156.841C551.512 156.841 562.197 164.766 565.296 176.136L584.59 239.521V239.53ZM511.9 98.6289V63.1476H458.504V98.6289H511.9Z"
      fill="white"
    />
    <path d="M658.303 239.53H595.256V4.91699H658.303V239.53Z" fill="white" />
    <path
      d="M858.805 84.4982H795.758V63.1381H742.362V181.308H795.758V146.86H858.805V204.049C858.805 223.343 842.618 239.53 823.324 239.53H714.806C695.173 239.53 679.324 223.681 679.324 204.049V40.4071C679.324 20.7744 695.173 4.92578 714.806 4.92578H823.324C842.618 4.92578 858.805 20.7744 858.805 40.4071V84.5073V84.4982Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1786 31.944C13.1786 31.944 12.877 35.7645 12.877 52.2529C12.877 108.592 39.4468 155.927 74.8641 177.607C127.693 142.949 141.896 79.7367 141.896 8.43601C141.896 8.43601 132.126 4.92627 107.539 4.92627C61.8853 4.91713 13.1786 31.944 13.1786 31.944Z"
      fill="#004E27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.531 8.58308C66.4473 8.58308 26.131 29.0474 17.8137 33.4072C17.7314 36.1035 17.6309 42.7939 17.6309 52.2537C17.6309 114.597 54.1998 155.316 74.7829 166.613C80.797 162.088 87.8348 156.467 94.9731 148.854C92.4505 143.708 89.3155 138.891 85.6595 134.504L82.3051 140.335L89.9187 145.545L80.797 146.934L81.4643 156.129L74.6915 149.868L67.9188 156.129L68.5861 146.934L59.4644 145.545L67.078 140.335L62.4806 132.338L71.3006 135.034L74.6915 126.452L78.0825 135.034L84.453 133.087C73.9421 121.096 59.4461 112.504 42.9394 109.232C43.9082 108.867 44.9136 108.492 45.9556 108.126C68.7871 111.773 88.0999 125.556 98.821 144.549C99.9178 143.269 101.005 141.944 102.093 140.564C90.4762 122.613 70.9076 110 48.1766 107.368C49.3099 106.993 50.489 106.618 51.6863 106.262C73.9055 107.943 93.3645 119.149 105.758 135.656C106.499 134.614 107.23 133.554 107.961 132.457C95.3387 117.029 76.3276 106.737 54.7939 105.375C56.2106 104.991 57.673 104.635 59.1628 104.297C79.6637 105.019 98.1538 113.711 111.224 127.302C125.537 103.547 137.108 67.7734 137.108 11.4622C132.419 10.4019 122.96 8.55566 107.531 8.55566V8.58308Z"
      fill="#76AF43"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.1507 86.2995L120.582 9.06692C120.006 9.02122 119.403 8.97552 118.8 8.93896L48.5957 80.0477L70.0288 13.5089C69.2976 13.6917 68.5665 13.8745 67.8444 14.0573L38.9804 76.9859L36.4944 24.6688C35.2239 25.1989 34.0083 25.7199 32.8475 26.2226L28.9722 77.2783L17.6387 51.0558C17.6387 51.4489 17.6387 51.8419 17.6387 52.244C17.6387 63.7969 18.9 74.6003 21.0753 84.6086L28.241 91.9114L27.2265 75.4047L33.725 90.4673L38.0847 76.2729L38.7976 91.5824L47.7365 79.9746L43.13 94.4797L56.8033 85.3581L45.2322 99.4336L62.9088 94.6077L49.2629 103.172L50.5516 103.382L135.06 50.3521C135.16 49.4746 135.252 48.5972 135.352 47.7106L57.1507 86.2995Z"
      fill="#FFF129"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.6436 102.797C99.3219 102.797 113.361 107.906 119.75 110.813C122.391 104.79 124.841 98.1722 127.016 90.8877L56.4007 98.7024L49.2441 103.19L60.0384 104.991C66.1256 103.674 72.7612 102.788 79.6436 102.788V102.797Z"
      fill="#FBB713"
    />
    <path
      d="M28.579 116.9L28.5059 116.781C28.5607 116.745 34.5199 113.226 43.7604 109.753C52.288 106.545 65.4312 102.724 79.6439 102.724C93.8565 102.724 105.062 105.439 111.963 107.715C119.439 110.182 123.616 112.687 123.662 112.705L123.589 112.824C123.589 112.824 119.384 110.301 111.917 107.842C105.025 105.567 93.8382 102.861 79.653 102.861C71.8201 102.861 59.2526 104.077 43.8244 109.881C34.5931 113.354 28.6521 116.864 28.5881 116.9H28.579Z"
      fill="#EA008A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.8832 145.919L62.6992 144.823L68.695 140.71L65.0756 134.403L72.0311 136.533L74.7 129.769L77.3688 136.533L84.3243 134.403L80.7049 140.71L86.7007 144.823L79.5167 145.919L80.0377 153.176L74.7 148.241L69.3622 153.176L69.8832 145.919Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.0579 42.9487L41.4396 51.5311L50.2597 48.8257L45.6623 56.8232L53.2759 62.0329L44.1542 63.4314L44.8306 72.6261L38.0579 66.3653L31.2852 72.6261L31.9524 63.4314L22.8398 62.0329L30.4443 56.8232L25.8469 48.8257L34.6669 51.5311L38.0579 42.9487Z"
      fill="#004E27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.2406 62.3988L26.0566 61.302L32.0524 57.1891L28.433 50.8825L35.3794 53.0121L38.0574 46.2485L40.7263 53.0121L47.6818 50.8825L44.0532 57.1891L50.0581 61.302L42.865 62.3988L43.3951 69.6559L38.0574 64.7204L32.7105 69.6559L33.2406 62.3988Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.7004 16.5889L78.0914 25.1621L86.9023 22.4659L82.314 30.4633L89.9184 35.6731L80.8059 37.0623L81.4731 46.2663L74.7004 40.0054L67.9277 46.2663L68.5949 37.0623L59.4824 35.6731L67.0869 30.4633L62.4986 22.4659L71.3095 25.1621L74.7004 16.5889Z"
      fill="#004E27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.8832 36.039L62.6992 34.933L68.695 30.8292L65.0756 24.5226L72.0311 26.6522L74.7 19.8887L77.3688 26.6522L84.3243 24.5226L80.7049 30.8292L86.7007 34.933L79.5167 36.039L80.0377 43.2869L74.7 38.3514L69.3622 43.2869L69.8832 36.039Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.844 24.833L120.226 33.4063L129.046 30.71L124.449 38.7074L132.062 43.9172L122.941 45.3065L123.617 54.5104L116.844 48.2495L110.072 54.5104L110.739 45.3065L101.617 43.9172L109.231 38.7074L104.633 30.71L113.453 33.4063L116.844 24.833Z"
      fill="#004E27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.027 44.2831L104.834 43.1863L110.839 39.0733L107.22 32.7668L114.166 34.8964L116.844 28.1328L119.513 34.8964L126.468 32.7668L122.84 39.0733L128.845 43.1863L121.651 44.2831L122.182 51.5311L116.844 46.5955L111.497 51.5311L112.027 44.2831Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.79 75.7061L97.1298 82.9266H104.725L98.583 87.4052L100.932 94.6166L94.79 90.1563L88.6388 94.6166L90.9877 87.396L84.8457 82.9266L92.441 82.9358L94.79 75.7061Z"
      fill="#004E27"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.413 86.9303L88.5742 84.1426H93.3178L94.7802 79.6274L96.2518 84.1426H101.005L97.1566 86.9394L98.619 91.4454L94.7894 88.6577L90.9415 91.4454L92.413 86.9303Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.4052 115.292C36.5327 112.641 48.1222 107.267 62.4079 104.516L51.6502 102.724L68.3123 92.268L47.6195 97.9165L61.174 81.428L44.6947 92.4234L50.0507 75.5785L39.5581 89.2153L38.7263 71.301L33.5897 87.9905L26.0584 70.5332L27.2374 89.6723L20.748 83.0732C23.1884 94.9277 26.9267 105.695 31.4144 115.301L31.4052 115.292Z"
      fill="white"
    />
    <path
      d="M29.5404 224.158H31.6974L37.7481 239.248H35.5088L34.0555 235.446H27.0361L25.5828 239.248H23.4258L29.5404 224.158ZM30.5458 226.187L27.6941 233.663H33.3975L30.5458 226.187Z"
      fill="#004E27"
    />
    <path
      d="M50.954 233.004C50.954 237.245 48.9249 239.512 45.3969 239.512C41.8689 239.512 39.8398 237.245 39.8398 233.004V224.166H41.8872V232.73C41.8872 235.628 42.7463 237.62 45.3969 237.62C48.0475 237.62 48.9067 235.628 48.9067 232.73V224.166H50.954V233.004Z"
      fill="#004E27"
    />
    <path
      d="M61.1279 226.433C60.0859 226.003 59.5101 225.802 58.4407 225.802C57.2891 225.802 55.9364 226.232 55.9364 227.813C55.9364 230.71 62.0876 230.043 62.0876 235.015C62.0876 238.233 59.7295 239.512 56.8595 239.512C55.8541 239.512 54.867 239.211 53.9256 238.882L54.1267 236.871C54.8213 237.172 56.0552 237.602 57.0972 237.602C58.2671 237.602 59.9031 236.907 59.9031 235.134C59.9031 231.762 53.752 232.694 53.752 227.996C53.752 225.318 55.7627 223.892 58.3493 223.892C59.2908 223.892 60.4241 224.066 61.4021 224.431L61.1188 226.424L61.1279 226.433Z"
      fill="#004E27"
    />
    <path
      d="M67.7363 226.058H63.2852V224.157H74.2439V226.058H69.7928V239.247H67.7454V226.058H67.7363Z"
      fill="#004E27"
    />
    <path
      d="M76.1641 224.157H79.6738C82.5072 224.157 85.2309 224.633 85.2309 228.243C85.2309 230.08 83.9422 231.378 82.2787 231.679V231.725C82.9185 231.981 83.2201 232.31 83.6771 233.242L86.3825 239.256H84.0427L81.8491 234.028C81.2093 232.557 80.533 232.557 79.4453 232.557H78.2114V239.256H76.1641V224.166V224.157ZM78.2114 230.647H79.9571C81.904 230.647 83.0556 229.742 83.0556 228.288C83.0556 226.927 82.0685 226.058 80.1856 226.058H78.2206V230.637L78.2114 230.647Z"
      fill="#004E27"
    />
    <path
      d="M93.3568 224.157H95.5138L101.564 239.247H99.3252L97.8719 235.445H90.8525L89.3992 239.247H87.2422L93.3568 224.157ZM94.3622 226.186L91.5105 233.663H97.2139L94.3622 226.186Z"
      fill="#004E27"
    />
    <path
      d="M103.611 224.157H105.658V237.346H111.645V239.247H103.602V224.157H103.611Z"
      fill="#004E27"
    />
    <path d="M113.883 224.157H115.93V239.247H113.883V224.157Z" fill="#004E27" />
    <path
      d="M124.12 224.157H126.277L132.328 239.247H130.089L128.636 235.445H121.616L120.163 239.247H118.006L124.12 224.157ZM125.126 226.186L122.274 233.663H127.978L125.126 226.186Z"
      fill="#004E27"
    />
    <path
      d="M26.4785 185.559H33.7996C39.7132 185.559 45.4073 186.5 45.4073 193.657C45.4073 197.295 42.7111 199.872 39.2379 200.466V200.549C40.5632 201.06 41.2121 201.709 42.1535 203.547L47.8112 215.456H42.9304L38.3513 205.091C37.026 202.176 35.6093 202.176 33.3426 202.176H30.7743V215.456H26.4877V185.559H26.4785ZM30.7652 198.41H34.4029C38.4701 198.41 40.8739 196.609 40.8739 193.739C40.8739 191.043 38.8174 189.325 34.8781 189.325H30.7652V198.41Z"
      fill="#004E27"
    />
    <path d="M52.2891 185.559H56.5757V215.456H52.2891V185.559Z" fill="#004E27" />
    <path
      d="M88.1094 185.559H92.396V198.711L105.165 185.559H110.859L96.8106 199.699L111.974 215.465H105.722L92.396 200.814V215.465H88.1094V185.568V185.559Z"
      fill="#004E27"
    />
    <path
      d="M114.086 185.559H132.284L130.437 189.325H118.363V198.062H129.048V201.828H118.363V211.681H130.83V215.447H114.086V185.55V185.559Z"
      fill="#004E27"
    />
    <path
      d="M141.176 189.325H132.338L134.184 185.559H154.749V189.325H145.454V215.456H141.167V189.325H141.176Z"
      fill="#004E27"
    />
    <path
      d="M20.0348 211.635C18.6729 211.992 17.11 212.202 15.8121 212.202C9.59694 212.202 4.54255 207.532 4.54255 200.512C4.54255 193.493 9.12167 188.822 16.0223 188.822C17.3659 188.822 18.6729 189.032 19.9159 189.453L21.9176 186.089C19.9616 185.34 17.896 185.047 15.803 185.047C6.6813 185.047 0 191.171 0 200.384C0 209.597 6.29742 215.977 15.7664 215.977C17.4207 215.977 19.9982 215.858 22.1918 215.054L20.0348 211.635Z"
      fill="#004E27"
    />
    <path
      d="M81.941 211.635C80.5792 211.992 79.0162 212.202 77.7184 212.202C71.5032 212.202 66.4488 207.532 66.4488 200.512C66.4488 193.493 71.0279 188.822 77.9286 188.822C79.2721 188.822 80.5792 189.032 81.8222 189.453L83.8238 186.089C81.8679 185.34 79.8023 185.047 77.7092 185.047C68.5876 185.047 61.9062 191.171 61.9062 200.384C61.9062 209.597 68.2037 215.977 77.6727 215.977C79.327 215.977 81.9045 215.858 84.098 215.054L81.941 211.635Z"
      fill="#004E27"
    />
    <path
      d="M1169.35 5.00879H1274.97C1297.28 5.00879 1315.1 10.5842 1328.42 21.744C1341.74 32.9039 1348.4 48.4144 1348.4 68.2664C1348.4 74.0702 1347.75 80.5413 1346.45 87.6796C1342.77 105.082 1336.16 119.925 1326.63 132.191C1317.09 144.466 1305.17 153.835 1290.88 160.306L1312.65 238.964H1243.76L1224.92 170.35H1202.5L1188.52 238.964H1121.26L1169.35 5.00879ZM1238.89 115.465C1250.37 115.465 1259.14 113.454 1265.21 109.442C1271.27 105.42 1275.4 98.1723 1277.56 87.6887C1278.42 82.7806 1278.86 79.4354 1278.86 77.6439C1278.86 70.9535 1276.64 66.3196 1272.2 63.7512C1267.75 61.192 1260.44 59.9033 1250.26 59.9033H1225.24L1213.87 115.465H1238.89Z"
      fill="white"
    />
    <path
      d="M1371.06 222.567C1355.56 208.29 1347.82 188.758 1347.82 163.998C1347.82 155.516 1348.8 146.596 1350.75 137.218L1378.04 5.00879H1445.3L1418.33 136.551C1416.81 144.813 1416.05 150.837 1416.05 154.63C1416.05 163.779 1418.49 170.305 1423.36 174.207C1428.24 178.11 1435.98 180.066 1446.6 180.066C1457.22 180.066 1466.09 178.503 1472.59 175.377C1479.09 172.252 1484.13 167.572 1487.7 161.32C1491.27 155.078 1494.14 146.815 1496.31 136.551L1523.28 5.00879H1590.54L1563.25 137.218C1556.1 171.584 1542.5 197.971 1522.47 216.379C1502.42 234.787 1473.24 243.991 1434.9 243.991C1407.82 243.991 1386.53 236.853 1371.05 222.567H1371.06Z"
      fill="white"
    />
    <path
      d="M1592.42 239.476C1578.44 236.46 1567.45 232.502 1559.43 227.594L1572.11 166.347C1582.51 173.037 1593.93 178.064 1606.39 181.41C1618.85 184.755 1630.81 186.427 1642.3 186.427C1661.15 186.427 1670.57 180.852 1670.57 169.692C1670.57 165.232 1668.35 161.384 1663.91 158.148C1659.47 154.913 1650.64 150.06 1637.42 143.589C1621.39 135.783 1609.64 127.356 1602.17 118.317C1594.69 109.277 1590.95 98.6292 1590.95 86.3542C1590.95 82.3418 1591.49 77.3148 1592.58 71.2916C1597.13 49.6482 1608.5 32.3554 1626.7 19.4132C1644.9 6.47108 1668.08 0 1696.24 0C1710.53 0 1722.99 1.16991 1733.61 3.50974C1744.22 5.84957 1754.18 9.59694 1763.51 14.7245L1751.16 73.9696C1734.48 63.2576 1716.28 57.9016 1696.57 57.9016C1687.25 57.9016 1679.61 59.4096 1673.66 62.4167C1667.7 65.4237 1664.72 69.8383 1664.72 75.6331C1664.72 79.6546 1666.83 83.1095 1671.06 86.0069C1675.28 88.9134 1682.8 92.8162 1693.64 97.7243L1699.81 100.402C1716.71 108.217 1728.67 116.077 1735.72 124.002C1742.76 131.926 1746.28 141.797 1746.28 153.624C1746.28 159.876 1745.52 166.457 1744 173.376C1739.45 195.915 1728.51 213.317 1711.18 225.592C1693.85 237.867 1668.62 244 1635.46 244C1620.73 244 1606.38 242.492 1592.41 239.485L1592.42 239.476Z"
      fill="white"
    />
    <path
      d="M1793.42 5.00879H1860.68L1842.81 91.0248H1918.53L1936.4 5.00879H2003.66L1955.56 238.964H1888.3L1906.5 149.265H1830.78L1812.59 238.964H1745.33L1793.42 5.00879Z"
      fill="white"
    />
    <path
      d="M1151.29 4.91699L1095.91 5.06323C1095.91 5.06323 1085.51 4.73419 1083.29 14.8887L1038.07 239.512H1102.91L1147.95 22.1549L1151.28 4.91699H1151.29Z"
      fill="#FFF100"
    />
    <path
      d="M1068.71 4.91699L1013.33 5.06323C1013.33 5.06323 1002.93 4.73419 1000.71 14.8887L955.49 239.512H1020.33L1065.37 22.1549L1068.7 4.91699H1068.71Z"
      fill="#C5E93B"
    />
    <path
      d="M988.029 4.91699L932.65 5.06323C932.65 5.06323 922.249 4.73419 920.037 14.8887L874.812 239.576H939.651L984.693 22.1641L988.02 4.92613L988.029 4.91699Z"
      fill="#085250"
    />
    <path
      d="M128.078 90.7604C134.906 68.0659 138.388 41.3133 137.575 10.8133C77.9641 -2.55846 18.5362 32.283 18.5362 32.283C18.5362 32.283 14.3501 65.3696 26.2229 101.235L128.088 90.7604H128.078Z"
      fill="url(#paint0_radial_28_6098)"
    />
    <path
      d="M111.716 7.78773C61.9124 6.8646 18.5343 32.2828 18.5343 32.2828C18.5343 32.2828 14.3482 65.3694 26.221 101.235L77.4869 95.9608L111.716 7.78773Z"
      fill="url(#paint1_linear_28_6098)"
    />
    <mask
      id="mask0_28_6098"
      // style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="17"
      y="7"
      width="121"
      height="95"
    >
      <path
        d="M128.078 90.7599C134.906 68.0654 138.388 41.3128 137.575 10.8128C77.9641 -2.55895 18.5362 32.2825 18.5362 32.2825C18.5362 32.2825 14.3501 65.3691 26.2229 101.234L128.088 90.7599H128.078Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_28_6098)">
      <path
        opacity="0.2"
        d="M54.475 3.66699L42.5 3.24463L39.576 86.1472L51.551 86.5696L54.475 3.66699Z"
        fill="url(#paint2_linear_28_6098)"
      />
      <path
        opacity="0.25"
        d="M105.248 -1.4997L86.9785 -8.19971L47.6628 99.0037L65.9319 105.704L105.248 -1.4997Z"
        fill="url(#paint3_linear_28_6098)"
      />
      <path
        opacity="0.29"
        d="M150.094 43.3723L138.859 26.1582L47.4925 85.7877L58.727 103.002L150.094 43.3723Z"
        fill="url(#paint4_linear_28_6098)"
      />
    </g>
    <path
      d="M97.1957 93.9401L131.48 77.9178C131.818 76.4554 132.147 74.9838 132.458 73.494C132.741 72.1505 133.006 70.7886 133.262 69.4268L50.125 98.7751L97.1957 93.9401Z"
      fill="url(#paint5_linear_28_6098)"
    />
    <path
      d="M29.9082 100.969L39.6514 99.9635L48.4989 99.0586L55.628 98.3274L63.4884 97.5231L136.407 41.4221C136.498 40.2157 136.581 38.9909 136.654 37.7662C136.654 37.7022 136.654 37.6382 136.663 37.5742C136.727 36.4683 136.791 35.3532 136.846 34.229L29.9082 100.969Z"
      fill="url(#paint6_linear_28_6098)"
    />
    <path
      d="M56.7139 98.2909L112.449 7.22957C110.411 7.19301 108.391 7.19301 106.38 7.22957C104.141 7.28441 101.92 7.37581 99.7265 7.53119L28.6543 101.581L56.7139 98.2817V98.2909Z"
      fill="url(#paint7_linear_28_6098)"
    />
    <path
      d="M48.8268 98.9119L74.5558 11.5522C72.3439 12.0275 70.1777 12.5302 68.039 13.0603C67.8471 13.106 67.6551 13.1517 67.4632 13.1974C67.2347 13.2523 66.9971 13.3071 66.7686 13.3711C66.3024 13.4899 65.8363 13.6087 65.3793 13.7367C65.3701 13.7367 65.361 13.7367 65.3519 13.7367C62.9389 14.3765 60.5899 15.0346 58.3141 15.7201L25.127 97.7876C25.4743 98.9301 25.849 100.082 26.2237 101.233L48.8177 98.9119H48.8268Z"
      fill="url(#paint8_linear_28_6098)"
    />
    <path
      d="M45.0518 99.2966L40.9388 21.7808C38.928 22.5759 37.0269 23.362 35.2538 24.1297C35.2081 24.148 35.1624 24.1663 35.1167 24.1846C35.0984 24.1937 35.071 24.2028 35.0527 24.212C33.7091 24.7878 32.4295 25.3545 31.2322 25.9029C28.9929 26.9174 27.0187 27.868 25.3278 28.6997L22.6406 88.6577C23.6277 92.7799 24.8159 96.9842 26.2235 101.234L45.0518 99.2966Z"
      fill="url(#paint9_linear_28_6098)"
    />
    <path
      d="M26.2238 101.234L42.7123 99.5431L18.2629 35.1431C18.0253 38.1775 15.6306 70.4324 26.2238 101.234Z"
      fill="url(#paint10_linear_28_6098)"
    />
    <mask
      id="mask1_28_6098"
      // style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="18"
      y="63"
      width="59"
      height="39"
    >
      <path
        d="M18.5625 63.5967C19.5588 74.6834 21.7523 87.7627 26.2126 101.235L68.5123 96.8844L76.0528 69.9307L18.5625 63.5967Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_28_6098)">
      <path
        d="M35.9395 135.93C50.972 135.93 63.1582 123.744 63.1582 108.711C63.1582 93.6789 50.972 81.4927 35.9395 81.4927C20.907 81.4927 8.7207 93.6789 8.7207 108.711C8.7207 123.744 20.907 135.93 35.9395 135.93Z"
        fill="url(#paint11_radial_28_6098)"
      />
      <path
        d="M42.2477 97.5386C44.2616 83.3703 43.5687 71.5541 40.7001 71.1464C37.8314 70.7386 33.8733 81.8937 31.8594 96.0619C29.8455 110.23 30.5384 122.046 33.407 122.454C36.2756 122.862 40.2337 111.707 42.2477 97.5386Z"
        fill="url(#paint12_radial_28_6098)"
      />
      <path
        d="M46.3821 101.699C52.323 88.1655 54.9882 76.2502 52.3351 75.0856C49.682 73.921 42.7152 83.948 36.7744 97.4816C30.8336 111.015 28.1683 122.93 30.8214 124.095C33.4746 125.26 40.4413 115.233 46.3821 101.699Z"
        fill="url(#paint13_radial_28_6098)"
      />
      <path
        d="M47.0448 105.519C58.7195 92.8497 66.6129 81.1315 64.6751 79.3459C62.7374 77.5603 51.7023 86.3835 40.0276 99.053C28.3529 111.723 20.4595 123.441 22.3972 125.226C24.335 127.012 35.37 118.189 47.0448 105.519Z"
        fill="url(#paint14_radial_28_6098)"
      />
      <path
        d="M35.9366 120.676C38.0041 120.308 37.6842 108.793 35.2221 94.9576C32.7601 81.1217 29.0882 70.2038 27.0208 70.5717C24.9533 70.9396 25.2732 82.454 27.7353 96.2899C30.1973 110.126 33.8692 121.044 35.9366 120.676Z"
        fill="url(#paint15_radial_28_6098)"
      />
      <path
        d="M37.9237 107.908C39.7594 106.553 36.8614 99.5114 31.4507 92.1806C26.04 84.8497 20.1656 80.0052 18.3298 81.3602C16.494 82.7151 19.3921 89.7563 24.8028 97.0872C30.2135 104.418 36.0879 109.262 37.9237 107.908Z"
        fill="url(#paint16_radial_28_6098)"
      />
      <path
        d="M53.9267 92.3696L63.2494 82.3979L44.2383 94.7277L42.584 100.166L53.9267 92.3696Z"
        fill="url(#paint17_linear_28_6098)"
      />
      <path
        d="M53.9264 92.3696L46.916 105.705L47.3365 105.522L51.6322 104.133L63.2491 82.3979L53.9264 92.3696Z"
        fill="url(#paint18_linear_28_6098)"
      />
      <path
        d="M51.3862 77.4346L47.5748 98.0817L42.1914 101.235L46.5969 86.9858L51.3862 77.4346Z"
        fill="url(#paint19_linear_28_6098)"
      />
      <path
        d="M51.3856 77.4346L46.5963 86.9858L37.4746 101.582L37.7945 92.2687L51.3856 77.4346Z"
        fill="url(#paint20_linear_28_6098)"
      />
      <path
        d="M41.4048 68.8433V100.623L35.1074 104.672L38.2059 84.6828L41.4048 68.8433Z"
        fill="url(#paint21_linear_28_6098)"
      />
      <path
        d="M41.4046 68.8433L38.2056 84.6828L34.6959 102.561L30.9668 94.3254L41.4046 68.8433Z"
        fill="url(#paint22_linear_28_6098)"
      />
      <path
        d="M27.7227 70.0493L29.9437 84.3351L32.6217 103.611L36.7621 98.0815V94.5078L27.7227 70.0493Z"
        fill="url(#paint23_linear_28_6098)"
      />
      <path
        d="M29.9437 84.335L27.7227 70.0493L26.7812 101.235L31.4792 102.56L29.9437 84.335Z"
        fill="url(#paint24_linear_28_6098)"
      />
      <path
        d="M21.681 88.7318L16.7637 76.3472L30.9671 95.5228L31.2322 104.672L25.666 97.0491L21.681 88.7318Z"
        fill="url(#paint25_linear_28_6098)"
      />
      <path
        d="M54.721 91.4829L47.3359 105.522L27.42 113.995L21.0586 87.3699L29.6593 99.4712V82.1602L34.0739 98.6852L38.4976 82.5258L38.4336 99.0508L47.4548 85.1215L43.095 99.4712L54.721 91.4829Z"
        fill="white"
      />
    </g>
    <path
      d="M136.285 39.7321C136.924 30.4459 137.144 20.7575 136.87 10.6396C77.2589 -2.73214 17.8218 32.1002 17.8218 32.1002C17.8218 32.1002 16.2772 44.3203 17.7304 61.9604C43.432 51.55 83.666 35.354 136.285 39.7229V39.7321Z"
      fill="url(#paint26_linear_28_6098)"
    />
    <path
      d="M136.285 39.7321C136.924 30.4459 137.144 20.7575 136.87 10.6396C77.2589 -2.73214 17.8218 32.1002 17.8218 32.1002C17.8218 32.1002 16.2772 44.3203 17.7304 61.9604C43.432 51.55 83.666 35.354 136.285 39.7229V39.7321Z"
      fill="#F4E758"
    />
    <path
      d="M137.574 10.8133C126.131 8.24497 114.706 7.45894 103.692 7.86109C99.689 38.7724 88.9587 78.9882 81.793 95.5223L128.078 90.7604C134.905 68.0659 138.388 41.3133 137.574 10.8133Z"
      fill="url(#paint27_radial_28_6098)"
    />
    <path
      d="M81.1529 101.372C95.8042 101.372 109.532 104.296 121.35 109.397C123.919 103.382 126.222 97.0027 128.224 90.2666L26.0664 100.778C27.5379 105.265 29.2562 109.799 31.267 114.323C44.9587 106.225 62.288 101.381 81.1529 101.381V101.372Z"
      fill="url(#paint28_linear_28_6098)"
    />
    <path
      d="M75.1297 166.293C94.351 154.484 110.529 135.189 121.652 109.077C109.752 103.94 95.9322 100.988 81.1803 100.988C62.1967 100.988 44.7485 105.869 30.9746 114.021C39.4656 133.179 53.1755 152.108 75.1297 166.293Z"
      fill="url(#paint29_radial_28_6098)"
    />
    <mask
      id="mask2_28_6098"
      // style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="30"
      y="100"
      width="92"
      height="67"
    >
      <path
        d="M75.1297 166.293C94.351 154.484 110.529 135.189 121.652 109.077C109.752 103.94 95.9322 100.988 81.1803 100.988C62.1967 100.988 44.7485 105.869 30.9746 114.021C39.4656 133.179 53.1755 152.108 75.1297 166.293Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask2_28_6098)">
      <g opacity="0.34">
        <path
          opacity="0.52"
          d="M21.2474 106.216L28.468 109.543L35.0213 112.559L40.3042 114.99L46.1264 117.668L120.251 108.583C120.818 107.779 121.376 106.956 121.933 106.133C121.96 106.088 121.988 106.051 122.024 106.005C122.527 105.256 123.03 104.507 123.533 103.739L21.2383 106.216H21.2474Z"
          fill="#E0DB58"
        />
        <path
          opacity="0.52"
          d="M28.3301 106.079L32.0135 110.777L35.3587 115.045L38.055 118.482L39.6728 125.199L93.1323 142.903C93.7812 142.556 94.9603 140.911 95.6092 140.554C95.6458 140.536 95.6732 140.518 95.7098 140.5C96.3039 140.171 96.898 139.842 97.4921 139.503L28.3301 106.079Z"
          fill="#E0DB58"
        />
        <path
          opacity="0.52"
          d="M40.6137 104.049L25.6973 112.851L64.5329 156.174C65.3829 156.312 66.2421 156.439 67.1012 156.567C67.1469 156.567 67.1926 156.586 67.2383 156.586C68.0152 156.705 68.8012 156.814 69.5873 156.924L40.6228 104.049H40.6137Z"
          fill="#E0DB58"
        />
        <path
          opacity="0.52"
          d="M52.3601 105.202L47.8906 123.61L111.486 126.516C112.236 125.958 112.994 125.383 113.753 124.816C113.79 124.789 113.835 124.761 113.872 124.725C114.557 124.204 115.243 123.673 115.928 123.143L52.3601 105.202Z"
          fill="#E0DB58"
        />
        <path
          d="M51.5324 137.884C53.0372 137.017 50.7891 130.294 46.5112 122.866C42.2334 115.439 37.5457 110.12 36.041 110.987C34.5362 111.854 36.7843 118.577 41.0621 126.005C45.34 133.432 50.0277 138.751 51.5324 137.884Z"
          fill="url(#paint30_radial_28_6098)"
        />
        <path
          d="M61.9594 128.998C62.8129 127.485 57.4536 122.844 49.9892 118.631C42.5247 114.418 35.7817 112.229 34.9282 113.741C34.0747 115.254 39.4339 119.895 46.8984 124.108C54.3629 128.32 61.1059 130.51 61.9594 128.998Z"
          fill="url(#paint31_radial_28_6098)"
        />
      </g>
    </g>
    <path
      d="M36.1309 124.404L66.8319 108.427L62.8378 102.56C51.0016 104.607 40.1616 108.583 30.9668 114.03C32.5114 117.513 34.2298 120.977 36.1309 124.413V124.404Z"
      fill="url(#paint32_radial_28_6098)"
    />
    <path
      d="M83.007 155.727L84.1586 154.904C80.0457 123.783 52.9731 112.221 41.0912 108.921C40.8536 109.022 40.6068 109.132 40.3691 109.232C52.6167 112.733 80.1553 124.322 83.007 155.727Z"
      fill="url(#paint33_radial_28_6098)"
    />
    <path
      d="M101.907 135.893L102.693 134.769C90.875 112.724 66.8552 106.207 53.3098 104.671C53.0082 104.754 52.7157 104.836 52.4141 104.927C66.2976 106.591 94.3938 114.415 101.907 135.893Z"
      fill="url(#paint34_radial_28_6098)"
    />
    <path
      d="M93.4909 145.6L94.3501 144.833C84.5338 120.685 63.9415 108.428 46.6396 106.765C46.3745 106.856 46.1094 106.947 45.8535 107.048C63.2103 108.821 82.916 119.99 93.4909 145.609V145.6Z"
      fill="url(#paint35_radial_28_6098)"
    />
    <path
      d="M41.0723 108.912C53.0273 112.23 80.438 123.929 84.2494 155.553L88.6457 152.427C92.8409 140.993 69.1685 111.343 44.3809 107.568C43.5218 107.404 41.9314 107.705 41.0723 108.903V108.912Z"
      fill="url(#paint36_radial_28_6098)"
    />
    <path
      d="M46.6113 106.755C64.0412 108.418 84.8346 120.84 94.5686 145.353L99.687 140.765C88.7556 116.919 61.7836 105.338 50.7517 105.402C50.7517 105.402 47.242 105.055 46.6113 106.755Z"
      fill="url(#paint37_radial_28_6098)"
    />
    <path
      d="M53.2832 104.671C67.0114 106.225 91.543 112.879 103.187 135.692L106.734 130.637C106.734 130.637 91.2505 105.932 57.3139 103.656C57.3139 103.656 54.2612 103.282 53.2832 104.671Z"
      fill="url(#paint38_radial_28_6098)"
    />
    <path
      d="M89.6419 114.314C81.7541 109.168 71.0513 104.58 57.3139 103.656C57.3139 103.656 54.2612 103.282 53.2832 104.671C62.1215 105.676 75.4384 108.802 86.9182 116.982L89.6419 114.314Z"
      fill="url(#paint39_radial_28_6098)"
    />
    <path
      d="M80.328 123.435L83.6458 120.19C71.8004 110.237 57.9168 105.365 50.742 105.411C50.742 105.411 47.2322 105.064 46.6016 106.764C57.7249 107.824 70.21 113.271 80.3188 123.435H80.328Z"
      fill="url(#paint40_radial_28_6098)"
    />
    <path
      d="M73.775 129.842L76.9831 126.698C68.5104 117.558 56.5828 109.432 44.3809 107.577C43.5218 107.413 41.9314 107.714 41.0723 108.912C48.9783 111.105 63.6479 116.973 73.775 129.842Z"
      fill="url(#paint41_radial_28_6098)"
    />
    <path
      d="M81.1807 100.988C80.7969 100.988 80.4221 101.006 80.0382 101.006C72.8908 114.643 59.6379 132.356 50.0684 144.485C56.9142 152.446 65.1767 159.858 75.1301 166.293C94.3514 154.484 110.529 135.189 121.652 109.077C109.752 103.94 95.9326 100.988 81.1807 100.988Z"
      fill="url(#paint42_radial_28_6098)"
    />
    <path
      d="M46.7606 106.718C41.1121 108.701 35.811 111.151 30.9668 114.021C39.4578 133.178 53.1677 152.107 75.1219 166.292C82.6075 161.695 89.627 155.955 96.0706 149.127C77.9461 140.143 56.65 118.947 46.7606 106.718Z"
      fill="url(#paint43_radial_28_6098)"
    />
    <g opacity="0.35">
      <path
        d="M90.0083 75.021L91.2604 71.1182L91.2696 71.1365V67.7456L88.9023 75.021H90.0083Z"
        fill="url(#paint44_linear_28_6098)"
      />
      <path
        d="M92.5217 75.021L91.2787 71.1182L91.2695 71.1365V67.7456L93.6368 75.021H92.5217Z"
        fill="url(#paint45_linear_28_6098)"
      />
      <path
        d="M87.4878 82.0953L88.8314 77.5893L85.084 74.8565H89.855L91.2717 70.4419L92.789 74.8565H97.3224L93.7578 77.699L95.0739 82.0953L91.2717 79.4447L87.4878 82.0953Z"
        fill="#004712"
      />
      <path
        d="M93.3379 77.6537L94.6266 81.5473L94.6083 81.529L96.6009 84.271L94.2427 76.9956L93.3379 77.6537Z"
        fill="url(#paint46_linear_28_6098)"
      />
      <path
        d="M91.3098 79.125L94.6184 81.5562L94.6093 81.5288L96.6018 84.2708L90.4141 79.7831L91.3098 79.125Z"
        fill="url(#paint47_linear_28_6098)"
      />
      <path
        d="M92.5973 75.0949L96.692 75.0766L96.6737 75.0858L99.9001 74.0347H92.25L92.5973 75.0949Z"
        fill="url(#paint48_linear_28_6098)"
      />
      <path
        d="M93.375 77.4804L96.7019 75.0858H96.6745L99.9009 74.0347L93.7132 78.5407L93.375 77.4804Z"
        fill="url(#paint49_linear_28_6098)"
      />
      <path
        d="M91.1182 79.1436L87.8095 81.5656L87.8187 81.5474L85.8262 84.2893L92.0139 79.7925L91.1182 79.1436Z"
        fill="url(#paint50_linear_28_6098)"
      />
      <path
        d="M89.0891 77.6631L87.8004 81.5567L87.8187 81.5476L85.8262 84.2896L88.1843 77.0142L89.0891 77.6631Z"
        fill="url(#paint51_linear_28_6098)"
      />
      <path
        d="M89.024 77.4433L85.7062 75.0487H85.7244L82.498 73.9976L88.6858 78.5036L89.024 77.4433Z"
        fill="url(#paint52_linear_28_6098)"
      />
      <path
        d="M89.8009 75.049L85.7062 75.0307L85.7244 75.049L82.498 73.9979L90.1482 73.9888L89.8009 75.049Z"
        fill="url(#paint53_linear_28_6098)"
      />
    </g>
    <path
      d="M85.44 82.269L87.1309 76.6022L82.4238 73.1656H88.4288L90.2019 67.6177L92.1213 73.1656H97.8155L93.3278 76.7393L94.9913 82.269L90.2019 78.9421L85.44 82.269Z"
      fill="white"
    />
    <path
      d="M97.8149 73.1655H96.2886L90.0551 75.1489L85.8782 80.8065L85.4395 82.2689L90.2014 78.942L94.9907 82.2689L93.3272 76.7392L97.8149 73.1655Z"
      fill="url(#paint54_linear_28_6098)"
    />
    <path
      d="M90.0551 75.1489L85.8782 80.8065L85.4395 82.2689L90.2014 78.942L94.9907 82.2689L93.3272 76.7393L90.0551 75.1489Z"
      fill="url(#paint55_linear_28_6098)"
    />
    <path
      d="M90.2038 67.6177L89.957 68.4037L90.0027 75.3592L93.3205 73.1656H92.1232L90.2038 67.6177Z"
      fill="url(#paint56_linear_28_6098)"
    />
    <path
      d="M84.0873 73.166H82.4238L87.1309 76.6026L86.6648 78.1747L89.7906 75.3322L84.0873 73.166Z"
      fill="url(#paint57_linear_28_6098)"
    />
    <path
      d="M90.084 76.3001L92.6066 80.605L94.9921 82.2685L93.3287 76.7388L94.2335 76.0259L90.084 76.3001Z"
      fill="url(#paint58_linear_28_6098)"
    />
    <path
      d="M94.9909 82.2686L94.2049 79.6454L90.5489 75.5142L89.123 79.7003L90.2016 78.9416L94.9909 82.2686Z"
      fill="url(#paint59_linear_28_6098)"
    />
    <g opacity="0.35">
      <path
        d="M127.422 48.5704L119.598 48.9086L121.024 56.7507L115.815 50.7092L110.55 56.7872L112.095 48.8446L104.061 48.6618L111.226 44.9053L106.391 38.4159L113.712 41.6515L115.76 33.9922L117.944 41.624L125.128 38.5073L120.384 44.9693L127.422 48.5704Z"
        fill="#004712"
      />
      <path
        d="M107.23 39.0925L103.465 36.0854L110.512 45.5819L111.59 45.0152L107.23 39.0925Z"
        fill="url(#paint60_linear_28_6098)"
      />
      <path
        d="M107.23 39.0925L103.465 36.0854L114.287 40.8474L113.976 42.0173L107.23 39.0925Z"
        fill="url(#paint61_linear_28_6098)"
      />
      <path
        d="M120.429 55.8825L122.522 60.2331L120.283 48.6162L119.076 48.6619L120.429 55.8825Z"
        fill="url(#paint62_linear_28_6098)"
      />
      <path
        d="M120.43 55.8828L122.523 60.2334L114.836 51.2397L115.622 50.3257L120.43 55.8828Z"
        fill="url(#paint63_linear_28_6098)"
      />
      <path
        d="M126.353 48.4966L131.06 49.5751L120.585 44.082L119.863 45.0509L126.353 48.4966Z"
        fill="url(#paint64_linear_28_6098)"
      />
      <path
        d="M126.353 48.4966L131.06 49.5751L119.242 49.9773L119.014 48.7891L126.353 48.4966Z"
        fill="url(#paint65_linear_28_6098)"
      />
      <path
        d="M124.341 39.2194L128.107 36.2124L117.285 40.9652L117.596 42.1442L124.341 39.2194Z"
        fill="url(#paint66_linear_28_6098)"
      />
      <path
        d="M124.342 39.2194L128.108 36.2124L121.061 45.6997L119.982 45.1421L124.342 39.2194Z"
        fill="url(#paint67_linear_28_6098)"
      />
      <path
        d="M115.812 34.9973V30.1714L112.787 41.6055L113.893 42.099L115.812 34.9973Z"
        fill="url(#paint68_linear_28_6098)"
      />
      <path
        d="M115.812 34.9973V30.1714L118.838 41.6055L117.723 42.099L115.812 34.9973Z"
        fill="url(#paint69_linear_28_6098)"
      />
      <path
        d="M105.02 48.3325L100.322 49.411L112.131 49.8132L112.369 48.625L105.02 48.3325Z"
        fill="url(#paint70_linear_28_6098)"
      />
      <path
        d="M105.02 48.3326L100.322 49.4111L110.788 43.918L111.51 44.8868L105.02 48.3326Z"
        fill="url(#paint71_linear_28_6098)"
      />
      <path
        d="M110.868 55.8369L108.775 60.1875L116.462 51.2029L115.676 50.2798L110.868 55.8369Z"
        fill="url(#paint72_linear_28_6098)"
      />
      <path
        d="M110.868 55.8366L108.775 60.1872L111.006 48.5703L112.221 48.616L110.868 55.8366Z"
        fill="url(#paint73_linear_28_6098)"
      />
    </g>
    <path
      d="M101.684 47.7658L109.617 43.5614L108.484 41.9528L104.27 36.2677L112.597 39.9511L114.809 31.1768L115.951 35.6645L117.139 39.9877L125.466 36.3043L122.276 40.5178L115.585 46.0383L110.257 47.7566L101.684 47.7658Z"
      fill="white"
    />
    <path
      d="M110.65 48.177L109.06 56.8417L114.772 50.0141L120.676 56.8417L119.049 48.177L127.979 47.6926L120.082 43.5248L125.466 36.3042L123.098 37.3462L114.644 44.4022L103.914 46.5866L101.684 47.7657L110.65 48.177Z"
      fill="url(#paint74_linear_28_6098)"
    />
    <path
      d="M110.65 48.177L109.06 56.8417L114.772 50.0141L120.676 56.8417L119.049 48.177L127.979 47.6926L120.082 43.5248L125.466 36.3042L123.098 37.3462L114.644 44.4022L103.914 46.5866L101.684 47.7657L110.65 48.177Z"
      fill="url(#paint75_linear_28_6098)"
    />
    <path
      d="M120.081 43.5244C118.308 43.7164 116.498 43.7621 114.963 45.0599L116.416 46.0745L123.856 47.9116L127.978 47.6922L120.09 43.5244H120.081Z"
      fill="url(#paint76_linear_28_6098)"
    />
    <path
      d="M115.969 49.9593L120.676 56.8417L119.049 48.177L120.008 48.1222L115.795 46.3125L116.005 46.7969L115.969 46.7878V49.9593Z"
      fill="url(#paint77_linear_28_6098)"
    />
    <path
      d="M120.676 56.8418L119.049 48.1771L115.494 46.2212L113.775 51.2116L114.772 50.0143L120.676 56.8418Z"
      fill="url(#paint78_linear_28_6098)"
    />
    <path
      d="M110.649 48.1768L109.059 56.8415L111.472 53.9624L115.466 44.9504L116.562 42.5923L111.243 44.731L109.625 48.1311L110.649 48.1768Z"
      fill="url(#paint79_linear_28_6098)"
    />
    <path
      d="M117.935 40.2162L117.798 39.6952L117.14 39.9877L115.952 35.6645L114.809 31.1768L114.133 33.8822L114.242 43.6528L117.935 40.2162Z"
      fill="url(#paint80_linear_28_6098)"
    />
    <path
      d="M114.616 44.2742L106.5 37.2547L104.27 36.2676L108.483 41.9526L109.616 43.5613L108.977 43.8994L109.251 44.2833L114.616 44.2742Z"
      fill="url(#paint81_linear_28_6098)"
    />
    <g opacity="0.35">
      <path
        d="M88.0258 35.0694L80.202 35.4076L81.6278 43.2497L76.418 37.2173L71.1534 43.2862L72.6981 35.3436L64.6641 35.1608L71.8298 31.4043L66.9948 24.9149L74.3159 28.1505L76.3632 20.4912L78.5476 28.1322L85.7316 25.0155L80.988 31.4683L88.0258 35.0694Z"
        fill="#004712"
      />
      <path
        d="M67.8379 25.5915L64.0723 22.5845L71.1192 32.0809L72.1977 31.5142L67.8379 25.5915Z"
        fill="url(#paint82_linear_28_6098)"
      />
      <path
        d="M67.8379 25.5915L64.0723 22.5845L74.894 27.3464L74.5832 28.5163L67.8379 25.5915Z"
        fill="url(#paint83_linear_28_6098)"
      />
      <path
        d="M81.0324 42.3911L83.1255 46.7326L80.8862 35.1157L79.6797 35.1614L81.0324 42.3911Z"
        fill="url(#paint84_linear_28_6098)"
      />
      <path
        d="M81.0331 42.3914L83.1262 46.7329L75.4395 37.7483L76.2255 36.8252L81.0331 42.3914Z"
        fill="url(#paint85_linear_28_6098)"
      />
      <path
        d="M86.9562 34.9966L91.6632 36.0751L81.1889 30.582L80.4668 31.5509L86.9562 34.9966Z"
        fill="url(#paint86_linear_28_6098)"
      />
      <path
        d="M86.9566 34.9966L91.6636 36.0751L79.8457 36.4773L79.6172 35.2891L86.9566 34.9966Z"
        fill="url(#paint87_linear_28_6098)"
      />
      <path
        d="M84.9467 25.719L88.7123 22.7119L77.8906 27.4738L78.2014 28.6437L84.9467 25.719Z"
        fill="url(#paint88_linear_28_6098)"
      />
      <path
        d="M84.9476 25.719L88.7133 22.7119L81.6664 32.1992L80.5879 31.6416L84.9476 25.719Z"
        fill="url(#paint89_linear_28_6098)"
      />
      <path
        d="M76.4173 21.4969V16.6802L73.3828 28.1051L74.4979 28.5987L76.4173 21.4969Z"
        fill="url(#paint90_linear_28_6098)"
      />
      <path
        d="M76.418 21.4969V16.6802L79.4433 28.1051L78.3282 28.5987L76.418 21.4969Z"
        fill="url(#paint91_linear_28_6098)"
      />
      <path
        d="M65.625 34.8403L60.918 35.9097L72.7359 36.321L72.9736 35.1237L65.625 34.8403Z"
        fill="url(#paint92_linear_28_6098)"
      />
      <path
        d="M65.625 34.8402L60.918 35.9096L71.3923 30.4165L72.1144 31.3945L65.625 34.8402Z"
        fill="url(#paint93_linear_28_6098)"
      />
      <path
        d="M71.4739 42.345L69.3809 46.6865L77.0676 37.7019L76.2724 36.7788L71.4739 42.345Z"
        fill="url(#paint94_linear_28_6098)"
      />
      <path
        d="M71.4739 42.3449L69.3809 46.6863L71.611 35.0786L72.8266 35.1152L71.4739 42.3449Z"
        fill="url(#paint95_linear_28_6098)"
      />
    </g>
    <path
      d="M62.2871 34.2744L70.2206 30.07L69.0872 28.4523L64.8737 22.7672L73.2002 26.4506L75.4121 17.6763L76.5546 22.164L77.7428 26.4872L86.0693 22.8038L82.8794 27.0264L76.189 32.5378L70.8604 34.2561L62.2871 34.2744Z"
      fill="white"
    />
    <path
      d="M71.2443 34.677L69.663 43.3417L75.3755 36.5233L81.2799 43.3417L79.653 34.677L88.5827 34.2017L80.6858 30.0339L86.0693 22.8042L83.702 23.8553L75.2476 30.9022L64.5173 33.0866L62.2871 34.2748L71.2443 34.677Z"
      fill="url(#paint96_linear_28_6098)"
    />
    <path
      d="M71.2443 34.677L69.663 43.3417L75.3755 36.5233L81.2799 43.3417L79.653 34.677L88.5827 34.2017L80.6858 30.0339L86.0693 22.8042L83.702 23.8553L75.2476 30.9022L64.5173 33.0866L62.2871 34.2748L71.2443 34.677Z"
      fill="url(#paint97_linear_28_6098)"
    />
    <path
      d="M80.6867 30.0332C78.9136 30.2251 77.1039 30.2708 75.5684 31.5687L77.0216 32.5832L84.4615 34.4204L88.5836 34.201L80.6959 30.0332H80.6867Z"
      fill="url(#paint98_linear_28_6098)"
    />
    <path
      d="M76.574 36.4595L81.2811 43.3419L79.6542 34.6772L80.6139 34.6223L76.4004 32.8218L76.6015 33.3062L76.574 33.2879V36.4595Z"
      fill="url(#paint99_linear_28_6098)"
    />
    <path
      d="M81.2815 43.3418L79.6546 34.6771L76.0992 32.7212L74.3809 37.7207L75.3771 36.5234L81.2815 43.3418Z"
      fill="url(#paint100_linear_28_6098)"
    />
    <path
      d="M71.2433 34.6764L69.6621 43.3411L72.0751 40.462L76.0692 31.45L77.166 29.1011L71.8466 31.2398L70.2288 34.6307L71.2433 34.6764Z"
      fill="url(#paint101_linear_28_6098)"
    />
    <path
      d="M78.5385 26.7248L78.4014 26.1947L77.7434 26.4872L76.5552 22.164L75.4127 17.6763L74.7363 20.3817L74.846 30.1523L78.5385 26.7248Z"
      fill="url(#paint102_linear_28_6098)"
    />
    <path
      d="M75.2195 30.7742L67.1032 23.7547L64.873 22.7676L69.0866 28.4526L70.2199 30.0704L69.5801 30.4086L69.8543 30.7833L75.2195 30.7742Z"
      fill="url(#paint103_linear_28_6098)"
    />
    <g opacity="0.35">
      <path
        d="M50.5961 63.5422L42.7814 63.8804L44.1981 71.7225L38.9883 65.6901L33.7237 71.759L35.2684 63.8164L27.2344 63.6336L34.4092 59.8771L29.5651 53.3969L36.8862 56.6233L38.9426 48.9731L41.118 56.605L48.302 53.4883L43.5583 59.9411L50.5961 63.5422Z"
        fill="#004712"
      />
      <path
        d="M30.4154 54.0642L26.6406 51.0571L33.6967 60.5535L34.766 59.9869L30.4154 54.0642Z"
        fill="url(#paint104_linear_28_6098)"
      />
      <path
        d="M30.4154 54.0642L26.6406 51.0571L37.4623 55.819L37.1607 56.9981L30.4154 54.0642Z"
        fill="url(#paint105_linear_28_6098)"
      />
      <path
        d="M43.6047 70.8634L45.6977 75.2049L43.4676 63.5972L42.252 63.6337L43.6047 70.8634Z"
        fill="url(#paint106_linear_28_6098)"
      />
      <path
        d="M43.6054 70.8636L45.6984 75.2051L38.0117 66.2205L38.7978 65.2974L43.6054 70.8636Z"
        fill="url(#paint107_linear_28_6098)"
      />
      <path
        d="M49.5356 63.4789L54.2335 64.5483L43.7683 59.0552L43.0371 60.024L49.5356 63.4789Z"
        fill="url(#paint108_linear_28_6098)"
      />
      <path
        d="M49.536 63.479L54.234 64.5484L42.416 64.9505L42.1875 63.7623L49.536 63.479Z"
        fill="url(#paint109_linear_28_6098)"
      />
      <path
        d="M47.517 54.1916L51.2918 51.1846L40.4609 55.9465L40.7717 57.1164L47.517 54.1916Z"
        fill="url(#paint110_linear_28_6098)"
      />
      <path
        d="M47.5166 54.1916L51.2914 51.1846L44.2354 60.681L43.166 60.1143L47.5166 54.1916Z"
        fill="url(#paint111_linear_28_6098)"
      />
      <path
        d="M38.9902 49.9701V45.1533L35.9648 56.5783L37.0708 57.0718L38.9902 49.9701Z"
        fill="url(#paint112_linear_28_6098)"
      />
      <path
        d="M38.9902 49.9701V45.1533L42.0156 56.5783L40.9096 57.0718L38.9902 49.9701Z"
        fill="url(#paint113_linear_28_6098)"
      />
      <path
        d="M28.194 63.3125L23.4961 64.3819L35.314 64.7932L35.5425 63.5958L28.194 63.3125Z"
        fill="url(#paint114_linear_28_6098)"
      />
      <path
        d="M28.194 63.3129L23.4961 64.3823L33.9705 58.8892L34.6925 59.8671L28.194 63.3129Z"
        fill="url(#paint115_linear_28_6098)"
      />
      <path
        d="M34.0442 70.8172L31.9512 75.1587L39.6379 66.1741L38.8518 65.251L34.0442 70.8172Z"
        fill="url(#paint116_linear_28_6098)"
      />
      <path
        d="M34.0442 70.8175L31.9512 75.159L34.1813 63.5513L35.3969 63.5878L34.0442 70.8175Z"
        fill="url(#paint117_linear_28_6098)"
      />
    </g>
    <path
      d="M24.8672 62.7471L32.7915 58.5427L31.6582 56.9249L27.4447 51.2399L35.7803 54.9233L37.9922 46.1489L39.1255 50.6366L40.3137 54.9598L48.6493 51.2764L45.4595 55.4991L38.769 61.0196L33.4313 62.7379L24.8672 62.7471Z"
      fill="white"
    />
    <path
      d="M33.8243 63.1492L32.234 71.8138L37.9556 64.9954L43.8509 71.8138L42.2331 63.1492L51.1537 62.6739L43.2659 58.5061L48.6493 51.2764L46.2729 52.3275L37.8276 59.3835L27.0882 61.5588L24.8672 62.747L33.8243 63.1492Z"
      fill="url(#paint118_linear_28_6098)"
    />
    <path
      d="M33.8243 63.1492L32.234 71.8138L37.9556 64.9954L43.8509 71.8138L42.2331 63.1492L51.1537 62.6739L43.2659 58.5061L48.6493 51.2764L46.2729 52.3275L37.8276 59.3835L27.0882 61.5588L24.8672 62.747L33.8243 63.1492Z"
      fill="url(#paint119_linear_28_6098)"
    />
    <path
      d="M43.2668 58.5059C41.4937 58.6978 39.6839 58.7435 38.1484 60.0414L39.6017 61.0559L47.0416 62.893L51.1637 62.6737L43.2759 58.5059H43.2668Z"
      fill="url(#paint120_linear_28_6098)"
    />
    <path
      d="M39.1444 64.9312L43.8514 71.8135L42.2337 63.1489L43.1842 63.094L38.9707 61.2935L39.1809 61.7779L39.1535 61.7596L39.1444 64.9312Z"
      fill="url(#paint121_linear_28_6098)"
    />
    <path
      d="M43.8518 71.8136L42.2341 63.149L38.6786 61.2021L36.9512 66.1926L37.9566 64.9952L43.8518 71.8136Z"
      fill="url(#paint122_linear_28_6098)"
    />
    <path
      d="M33.8247 63.1486L32.2344 71.8133L34.6473 68.9342L38.6415 59.9222L39.7474 57.5732L34.4188 59.712L32.8011 63.1029L33.8247 63.1486Z"
      fill="url(#paint123_linear_28_6098)"
    />
    <path
      d="M41.1108 55.197L40.9737 54.6669L40.3156 54.9593L39.1274 50.6362L37.9941 46.1484L37.3086 48.863L37.4183 58.6245L41.1108 55.197Z"
      fill="url(#paint124_linear_28_6098)"
    />
    <path
      d="M37.7917 59.2463L29.6846 52.2269L27.4453 51.2397L31.6588 56.9248L32.7922 58.5426L32.1524 58.8807L32.4266 59.2555L37.7917 59.2463Z"
      fill="url(#paint125_linear_28_6098)"
    />
    <g opacity="0.35">
      <path
        d="M87.6215 143.945L79.7977 144.283L81.2235 152.125L76.0137 146.084L70.7491 152.162L72.2938 144.219L64.2598 144.036L71.4255 140.28L66.5904 133.79L73.9115 137.026L75.9589 129.367L78.1433 136.999L85.3273 133.882L80.5837 140.344L87.6215 143.945Z"
        fill="#004712"
      />
      <path
        d="M67.4336 134.468L63.668 131.461L70.7149 140.948L71.7934 140.391L67.4336 134.468Z"
        fill="url(#paint126_linear_28_6098)"
      />
      <path
        d="M67.4336 134.468L63.668 131.461L74.4897 136.223L74.1789 137.393L67.4336 134.468Z"
        fill="url(#paint127_linear_28_6098)"
      />
      <path
        d="M80.6301 151.257L82.7231 155.598L80.4838 143.991L79.2773 144.027L80.6301 151.257Z"
        fill="url(#paint128_linear_28_6098)"
      />
      <path
        d="M80.6308 151.257L82.7238 155.599L75.0371 146.614L75.8231 145.691L80.6308 151.257Z"
        fill="url(#paint129_linear_28_6098)"
      />
      <path
        d="M86.5538 143.872L91.2609 144.95L80.7865 139.448L80.0645 140.426L86.5538 143.872Z"
        fill="url(#paint130_linear_28_6098)"
      />
      <path
        d="M86.5542 143.872L91.2613 144.951L79.4433 145.353L79.2148 144.165L86.5542 143.872Z"
        fill="url(#paint131_linear_28_6098)"
      />
      <path
        d="M84.5443 134.585L88.31 131.587L77.4883 136.34L77.799 137.519L84.5443 134.585Z"
        fill="url(#paint132_linear_28_6098)"
      />
      <path
        d="M84.5453 134.585L88.311 131.587L81.2641 141.075L80.1855 140.517L84.5453 134.585Z"
        fill="url(#paint133_linear_28_6098)"
      />
      <path
        d="M76.0156 130.372V125.546L72.9902 136.98L74.0962 137.474L76.0156 130.372Z"
        fill="url(#paint134_linear_28_6098)"
      />
      <path
        d="M76.0156 130.372V125.546L79.0409 136.98L77.9259 137.474L76.0156 130.372Z"
        fill="url(#paint135_linear_28_6098)"
      />
      <path
        d="M65.2207 143.707L60.5137 144.786L72.3316 145.188L72.5693 144L65.2207 143.707Z"
        fill="url(#paint136_linear_28_6098)"
      />
      <path
        d="M65.2207 143.708L60.5137 144.786L70.9881 139.293L71.7101 140.262L65.2207 143.708Z"
        fill="url(#paint137_linear_28_6098)"
      />
      <path
        d="M71.0696 151.211L68.9766 155.562L76.6633 146.577L75.8772 145.654L71.0696 151.211Z"
        fill="url(#paint138_linear_28_6098)"
      />
      <path
        d="M71.0696 151.211L68.9766 155.562L71.2067 143.945L72.4223 143.991L71.0696 151.211Z"
        fill="url(#paint139_linear_28_6098)"
      />
    </g>
    <path
      d="M61.8848 143.141L69.8182 138.937L68.6849 137.328L64.4714 131.643L72.7979 135.327L75.0097 126.552L76.1522 131.031L77.3404 135.363L85.6669 131.68L82.4771 135.893L75.7866 141.414L70.458 143.132L61.8848 143.141Z"
      fill="white"
    />
    <path
      d="M70.8511 143.552L69.2607 152.217L74.9732 145.389L80.8776 152.217L79.2507 143.552L88.1804 143.068L80.2835 138.9L85.6669 131.679L83.2997 132.721L74.8452 139.777L64.1149 141.962L61.8848 143.141L70.8511 143.552Z"
      fill="url(#paint140_linear_28_6098)"
    />
    <path
      d="M70.8511 143.552L69.2607 152.217L74.9732 145.389L80.8776 152.217L79.2507 143.552L88.1804 143.068L80.2835 138.9L85.6669 131.679L83.2997 132.721L74.8452 139.777L64.1149 141.962L61.8848 143.141L70.8511 143.552Z"
      fill="url(#paint141_linear_28_6098)"
    />
    <path
      d="M80.2824 138.899C78.5093 139.091 76.6996 139.137 75.1641 140.435L76.6173 141.449L84.0572 143.287L88.1793 143.067L80.2916 138.899H80.2824Z"
      fill="url(#paint142_linear_28_6098)"
    />
    <path
      d="M76.1698 145.335L80.8768 152.217L79.2499 143.553L80.2096 143.498L75.9961 141.688L76.1972 142.172L76.1698 142.163V145.335Z"
      fill="url(#paint143_linear_28_6098)"
    />
    <path
      d="M80.8772 152.217L79.2503 143.552L75.6949 141.596L73.9766 146.587L74.9728 145.389L80.8772 152.217Z"
      fill="url(#paint144_linear_28_6098)"
    />
    <path
      d="M70.8521 143.552L69.2617 152.217L71.6747 149.338L75.6688 140.326L76.7656 137.968L71.4462 140.107L69.8284 143.507L70.8521 143.552Z"
      fill="url(#paint145_linear_28_6098)"
    />
    <path
      d="M78.1343 135.591L77.9972 135.07L77.3391 135.363L76.1509 131.03L75.0084 126.552L74.332 129.257L74.4417 139.028L78.1343 135.591Z"
      fill="url(#paint146_linear_28_6098)"
    />
    <path
      d="M74.8171 139.649L66.7009 132.63L64.4707 131.643L68.6842 137.328L69.8176 138.936L69.1778 139.274L69.452 139.649H74.8171Z"
      fill="url(#paint147_linear_28_6098)"
    />
    <path
      d="M13.6944 31.4691C13.6944 31.4691 1.15441 128.307 74.6579 176.392L75.2154 174.646L75.1788 165.771C7.87227 121.991 19.1418 32.6481 19.1418 32.6481C19.1418 32.6481 77.9665 -1.95571 136.965 11.3155L141.681 8.15307C78.0214 -6.47999 13.6944 31.4691 13.6944 31.4691Z"
      fill="url(#paint148_linear_28_6098)"
    />
    <path
      d="M132.129 8.94839C128.482 8.28118 108.539 4.99994 93.1016 7.05643C77.6551 9.11291 60.536 14.277 49.0379 18.134C37.5399 21.9911 21.6729 29.7144 20.2014 30.6375C18.7299 31.5606 17.9804 32.2278 17.5143 33.6994C17.0481 35.1709 15.796 52.875 18.1449 71.7672C20.4939 90.6504 26.5994 107.532 28.9575 113.674C31.3156 119.816 35.5108 127.713 36.1049 128.801C36.7082 129.888 35.1726 128.91 34.871 128.691C34.5694 128.462 34.1947 129.029 35.3189 131.533C36.4431 134.038 41.9088 142.574 45.9486 147.821C49.9885 153.058 56.8069 159.876 61.1484 163.468C63.9452 165.79 71.0286 170.991 74.8491 173.175L74.9405 172.636C57.8214 160.735 53.1326 154.932 44.6142 143.635C56.3225 159.182 71.0469 168.587 74.7669 170.661L77.0336 168.459L76.2658 167.015L75.1233 166.046L75.169 165.744C75.169 165.744 75.1599 165.744 75.1508 165.753C65.1608 159.255 56.9074 151.751 50.0799 143.689C47.7218 141.13 45.318 138.288 42.905 135.135C26.6633 113.857 21.4353 87.753 18.8396 71.5936C16.2347 55.4342 17.9621 34.5768 18.0444 33.8182C18.1266 33.0596 18.7116 32.2644 20.3111 31.0488C21.9014 29.8332 35.0447 23.5814 47.8863 18.9201C60.7279 14.2587 79.9949 8.59194 100.139 7.58654C120.284 6.58115 133.628 9.68873 136.023 10.228C138.417 10.7764 135.767 9.59733 132.12 8.93011L132.129 8.94839Z"
      fill="url(#paint149_linear_28_6098)"
    />
    <path
      d="M16.5636 33.3062C17.3953 30.5459 26.6175 26.9356 43.4899 19.8248C60.3622 12.7139 90.9536 6.20624 110.093 6.91916C129.232 7.63208 136.123 9.10361 138.244 9.85308L140.2 9.77996L141.287 9.00307L141.836 8.09821C116.39 2.24865 90.8074 4.80783 69.2554 9.9719C64.9688 11.1235 60.7004 12.2843 57.2638 13.2166C31.3246 21.013 13.6571 31.3777 13.584 31.4234L16.5636 33.3062Z"
      fill="url(#paint150_radial_28_6098)"
    />
    <path
      d="M139.129 10.2917L138.097 10.3466L136.963 11.3154C139.02 88.8861 113.199 142.053 75.4241 165.598V166.219L76.8499 167.38L75.3327 171.383L74.8574 173.184L74.7751 174.427L74.1719 175.149V176.072C74.3273 176.173 74.4826 176.282 74.6472 176.383H74.7477C150.408 128.16 141.835 8.09814 141.835 8.09814L140.747 9.003L139.129 10.3009V10.2917Z"
      fill="url(#paint151_radial_28_6098)"
    />
    <path
      d="M139.129 10.2917L138.097 10.3466L136.963 11.3154C139.02 88.8861 113.199 142.053 75.4241 165.598L77.6542 169.51L74.8574 173.193L74.7751 174.436L74.1719 175.158V176.081C74.3273 176.182 74.4826 176.291 74.6472 176.392H74.7477C150.408 128.16 141.835 8.09814 141.835 8.09814L140.747 9.003L139.129 10.3009V10.2917Z"
      fill="url(#paint152_radial_28_6098)"
    />
    <path
      d="M62.3719 163.852C57.0525 159.365 47.0533 150.005 41.8436 141.505C36.6338 133.014 37.2736 131.771 39.0102 134.147C40.7468 136.524 45.8377 142.3 46.6329 143.095C46.6329 143.095 28.4261 119.414 22.668 92.9263C22.668 92.9263 35.0983 144.019 74.464 165.927C74.464 165.927 73.6049 166.256 73.2576 166.603C72.9103 166.951 73.0108 168.111 73.0565 168.386C73.0565 168.386 72.6543 170.351 59.3466 158.35C59.3466 158.35 68.2397 165.863 71.3473 167.289C71.3473 167.289 58.7159 157.473 52.4185 150.462C46.1211 143.452 40.628 136.78 40.7102 138.078C40.7925 139.375 51.6964 156.074 62.3628 163.843L62.3719 163.852Z"
      fill="url(#paint153_linear_28_6098)"
    />
    <path
      d="M139.35 38.2051C138.509 54.5748 136.498 64.647 132.888 82.1865C129.278 99.7261 121.728 118.198 109.974 136.917C101.428 150.535 90.7435 159.931 83.0203 165.698C80.5342 167.554 75.9642 170.753 74.7852 170.679C74.8126 171.667 74.8674 172.59 74.8674 172.59C74.8674 172.59 75.0228 172.617 75.1416 172.553C76.7137 171.676 88.0381 165.15 100.03 151.586C112.954 136.971 121.472 118.792 124.753 110.31C128.025 101.828 134.323 83.5027 137.43 62.2797C140.538 41.0568 141.68 18.865 139.953 14.2036C140.373 15.4649 140.209 21.8446 139.368 38.2143L139.35 38.2051Z"
      fill="white"
    />
    <path
      d="M141.835 8.09814L140.985 8.80192C141.113 9.39602 140.19 10.2095 139.816 10.2095C139.395 10.2095 139.605 10.7122 139.688 11.1783C139.77 11.6444 138.892 11.3428 138.892 11.3428C139.898 11.8912 139.523 12.9332 139.944 14.1945C140.364 15.4558 140.199 21.8355 139.359 38.2051C138.518 54.5748 136.269 64.6104 132.659 82.1591C129.049 99.6987 121.728 118.198 109.974 136.917C101.428 150.535 90.7432 159.931 83.0199 165.698C80.8446 167.325 77.0515 169.994 75.3607 170.57V171.392L74.8762 173.193L74.794 174.436L74.666 176.392H74.7666C150.409 128.16 141.835 8.09814 141.835 8.09814Z"
      fill="url(#paint154_linear_28_6098)"
    />
    <path
      d="M137.266 62.2249C134.158 83.4479 128.025 101.828 124.753 110.301C121.481 118.783 112.962 136.962 100.029 151.577C87.1056 166.192 74.8672 172.581 74.8672 172.581V173.184C74.8672 173.184 83.1571 168.97 91.5567 161.083C99.9472 153.195 110.047 141.176 117.075 127.594C124.908 112.44 132.102 93.6846 134.999 77.9822C137.896 62.2889 141.004 44.7402 140.958 27.749C140.958 24.5683 140.858 22.2011 140.72 20.4736C141.013 29.6319 139.624 46.1752 137.275 62.2249H137.266Z"
      fill="url(#paint155_linear_28_6098)"
    />
    <path
      d="M40.7478 143.808C36.516 136.496 30.511 125.893 28.7196 121.47C25.8679 114.423 23.519 99.9817 23.519 99.9817C13.7849 68.7597 15.5672 37.8392 16.5635 33.3058L14.7172 30.7832C13.9769 31.1945 13.5838 31.423 13.5838 31.423C13.5838 31.423 5.52241 94.2327 40.7478 143.808Z"
      fill="url(#paint156_radial_28_6098)"
    />
    <path
      d="M139.816 10.2095C140.237 10.2095 141.324 9.2041 140.904 8.61914C140.904 8.61914 139.515 9.70679 138.93 9.67024C138.345 9.63368 136.983 9.3412 137.705 9.72507C138.427 10.109 137.312 10.8767 136.965 11.3246C139.03 89.0689 113.082 142.309 75.1603 165.771L75.1146 166.183C75.1146 166.183 76.3394 166.356 76.248 166.96C76.1017 167.91 76.6958 169.473 74.6485 170.031C74.5571 170.177 74.4931 170.287 74.484 170.341C74.3743 171.868 80.1142 167.864 83.0115 165.698C90.7439 159.922 101.419 150.526 109.965 136.917C121.719 118.198 129.031 99.7627 132.641 82.2231C136.252 64.6836 138.564 54.5748 139.396 38.2052C140.237 21.8355 140.374 16.4521 139.981 14.1488C139.588 11.8364 139.889 11.9004 138.884 11.352C138.884 11.352 139.761 11.6445 139.679 11.1875C139.597 10.7213 139.387 10.2186 139.807 10.2186L139.816 10.2095Z"
      fill="url(#paint157_radial_28_6098)"
    />
    <path
      d="M75.261 167.681C75.4347 167.526 75.782 167.005 75.3067 166.64C74.8314 166.274 74.1916 166.42 73.7163 166.831C73.2411 167.243 73.4147 168.294 74.018 168.23C74.6212 168.166 74.932 167.992 75.261 167.681Z"
      fill="url(#paint158_linear_28_6098)"
    />
    <path
      d="M138.8 10.5388C138.48 10.292 138.16 10.5753 138.087 10.7398C138.014 10.9043 137.868 11.3613 138.252 11.6173C138.636 11.8732 139.641 11.1968 138.8 10.5388Z"
      fill="url(#paint159_linear_28_6098)"
    />
    <path
      d="M140.859 8.7564C141.233 8.39994 140.045 7.91553 139.597 8.42736C139.149 8.9392 139.378 9.94459 139.917 9.5333C140.456 9.122 140.859 8.7564 140.859 8.7564Z"
      fill="url(#paint160_linear_28_6098)"
    />
    <path
      d="M139.24 9.94457C139.459 9.72521 138.673 9.07628 139.167 8.34508C139.377 8.02518 139.651 7.99776 139.222 7.88808C138.792 7.7784 136.791 7.11119 134.359 6.80957C134.359 6.80957 138.107 7.61389 138.308 8.26282C138.518 8.91176 138.088 8.71982 137.878 9.02144C137.668 9.32306 139.012 10.1639 139.231 9.94457H139.24Z"
      fill="url(#paint161_linear_28_6098)"
    />
    <path
      d="M75.1247 166.183C75.7096 166.448 76.1301 167.399 75.4903 167.874C74.8596 168.349 73.1139 169.035 73.3241 169.455C73.5343 169.876 74.8505 169.876 75.8559 169.3C75.8559 169.3 74.0644 170.141 74.8048 170.196C75.5451 170.25 90.0319 162.445 103.486 145.024C116.94 127.603 126.519 106.216 130.897 88.2195C135.275 70.2229 138.163 52.5554 138.985 37.3648C139.808 22.1742 139.918 18.3446 139.644 15.2918C139.369 12.2391 138.903 11.5262 138.574 11.9375C138.245 12.3488 137.587 11.1149 137.423 11.6907C137.258 12.2665 138.492 33.9008 135.85 52.3086C133.209 70.7165 129.489 91.4459 122.15 108.949C114.801 126.452 113.896 129.587 103.157 144.202C112.571 129.34 117.525 121.05 119.481 114.195C110.734 130.629 100.771 150.124 75.1247 166.183Z"
      fill="url(#paint162_linear_28_6098)"
    />
    <path
      d="M13.9485 32.2008C14.0308 33.1239 14.9174 32.5755 14.9174 33.4621C14.9174 34.3487 14.4969 34.5132 14.1587 33.8825C13.8206 33.2519 13.6926 33.2519 13.656 33.9282C13.6103 34.5955 12.1845 48.5339 14.2044 65.7444C16.2152 82.9549 18.0615 93.146 20.3282 100.239C22.5766 107.285 25.5745 115.566 27.4208 120.365C36.5699 140.847 51.2486 161.083 74.6286 176.383L74.784 174.437C67.9199 169.839 58.6703 162.226 54.7675 158.323C50.8648 154.42 35.7747 139.102 27.1283 113.839C27.1283 113.839 28.6364 116.105 29.3128 117.193C29.3128 117.193 29.4773 115.85 28.3896 113.08C27.302 110.311 19.2405 89.5814 16.6357 70.2687C14.0308 50.9651 15.548 35.1804 15.7948 33.8643C16.0416 32.5481 16.7362 31.4056 21.1417 29.2669C25.5471 27.1281 51.468 14.451 78.9975 9.49716C106.527 4.54331 124.578 6.72776 127.512 7.14819C130.446 7.56863 136.981 8.96704 137.594 8.67456C138.206 8.39123 137.493 7.90681 135.373 7.49551C133.252 7.07507 126.251 4.58901 95.4037 5.76806C93.786 5.83204 92.1682 5.93258 90.5321 6.07882C74.7931 8.05305 60.2698 11.9741 48.1685 16.1602C35.8752 20.5931 26.4245 25.1082 23.0885 26.7534C16.0416 30.2357 13.8571 31.2868 13.9394 32.2099L13.9485 32.2008Z"
      fill="url(#paint163_linear_28_6098)"
    />
    <path
      d="M74.6298 176.374L74.7851 174.427C67.921 169.83 58.6714 162.216 54.7687 158.313C51.3869 154.932 39.6238 142.995 30.9225 123.454L29.2773 124.322C38.5178 143.434 52.7761 162.061 74.6389 176.374H74.6298Z"
      fill="url(#paint164_radial_28_6098)"
    />
    <line x1="35" y1="321.5" x2="1990" y2="321.5" stroke="white" strokeWidth="9" />
    <defs>
      <radialGradient
        id="paint0_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(26.244 109.227) scale(135.738 135.738)"
      >
        <stop offset="0.12" stopColor="white" />
        <stop offset="0.14" stopColor="#FEF9C8" />
        <stop offset="0.15" stopColor="#FEF498" />
        <stop offset="0.16" stopColor="#FDF170" />
        <stop offset="0.18" stopColor="#FDED51" />
        <stop offset="0.19" stopColor="#FDEB3A" />
        <stop offset="0.2" stopColor="#FDEA2D" />
        <stop offset="0.21" stopColor="#FDEA29" />
        <stop offset="0.24" stopColor="#EBE12A" />
        <stop offset="0.3" stopColor="#BCC92F" />
        <stop offset="0.39" stopColor="#71A436" />
        <stop offset="0.41" stopColor="#5C9A39" />
        <stop offset="0.42" stopColor="#559738" />
        <stop offset="0.47" stopColor="#368937" />
        <stop offset="0.52" stopColor="#1E7E36" />
        <stop offset="0.58" stopColor="#0D7636" />
        <stop offset="0.64" stopColor="#037236" />
        <stop offset="0.71" stopColor="#007136" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_28_6098"
        x1="28.2866"
        y1="39.5673"
        x2="89.0581"
        y2="61.8871"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7DA83D" />
        <stop offset="0.36" stopColor="#70A135" />
        <stop offset="0.64" stopColor="#4A9137" />
        <stop offset="1" stopColor="#4A9137" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_28_6098"
        x1="37.5578"
        y1="45.0319"
        x2="49.6015"
        y2="45.4567"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7CF32" stopOpacity="0" />
        <stop offset="0.03" stopColor="#C7CF32" stopOpacity="0.07" />
        <stop offset="0.26" stopColor="#C7CF32" stopOpacity="0.57" />
        <stop offset="0.42" stopColor="#C7CF32" stopOpacity="0.88" />
        <stop offset="0.5" stopColor="#C7CF32" />
        <stop offset="0.59" stopColor="#C7CF32" stopOpacity="0.98" />
        <stop offset="0.66" stopColor="#C7CF32" stopOpacity="0.94" />
        <stop offset="0.72" stopColor="#C7CF32" stopOpacity="0.85" />
        <stop offset="0.79" stopColor="#C7CF32" stopOpacity="0.74" />
        <stop offset="0.85" stopColor="#C7CF32" stopOpacity="0.59" />
        <stop offset="0.9" stopColor="#C7CF32" stopOpacity="0.41" />
        <stop offset="0.96" stopColor="#C7CF32" stopOpacity="0.2" />
        <stop offset="1" stopColor="#C7CF32" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_28_6098"
        x1="67.9074"
        y1="45.5753"
        x2="86.2194"
        y2="52.2911"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7CF32" stopOpacity="0" />
        <stop offset="0.08" stopColor="#C7CF32" stopOpacity="0.21" />
        <stop offset="0.23" stopColor="#C7CF32" stopOpacity="0.54" />
        <stop offset="0.36" stopColor="#C7CF32" stopOpacity="0.79" />
        <stop offset="0.45" stopColor="#C7CF32" stopOpacity="0.94" />
        <stop offset="0.5" stopColor="#C7CF32" />
        <stop offset="0.95" stopColor="#C7CF32" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_28_6098"
        x1="99.4032"
        y1="52.2091"
        x2="110.639"
        y2="69.425"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7CF32" stopOpacity="0" />
        <stop offset="0.07" stopColor="#C7CF32" stopOpacity="0.19" />
        <stop offset="0.18" stopColor="#C7CF32" stopOpacity="0.48" />
        <stop offset="0.28" stopColor="#C7CF32" stopOpacity="0.7" />
        <stop offset="0.37" stopColor="#C7CF32" stopOpacity="0.86" />
        <stop offset="0.45" stopColor="#C7CF32" stopOpacity="0.96" />
        <stop offset="0.5" stopColor="#C7CF32" />
        <stop offset="0.95" stopColor="#C7CF32" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_28_6098"
        x1="68.7796"
        y1="98.0805"
        x2="128.052"
        y2="77.15"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDEA40" />
        <stop offset="0.05" stopColor="#EAE340" />
        <stop offset="0.26" stopColor="#98C441" />
        <stop offset="0.45" stopColor="#56AC43" />
        <stop offset="0.61" stopColor="#279B44" />
        <stop offset="0.73" stopColor="#0A9044" />
        <stop offset="0.8" stopColor="#008D45" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_28_6098"
        x1="50.5645"
        y1="80.3949"
        x2="142.933"
        y2="53.3498"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFED65" />
        <stop offset="0.11" stopColor="#FDEA40" />
        <stop offset="0.12" stopColor="#F7E740" />
        <stop offset="0.29" stopColor="#C1D040" />
        <stop offset="0.46" stopColor="#93BC40" />
        <stop offset="0.61" stopColor="#70AD40" />
        <stop offset="0.76" stopColor="#57A240" />
        <stop offset="0.89" stopColor="#489C40" />
        <stop offset="1" stopColor="#439A40" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_28_6098"
        x1="32.9247"
        y1="77.8931"
        x2="129.594"
        y2="24.5553"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFED65" />
        <stop offset="0.1" stopColor="#FDEA40" />
        <stop offset="0.48" stopColor="#9ABF3D" />
        <stop offset="0.84" stopColor="#459A3B" />
        <stop offset="1" stopColor="#248C3B" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_28_6098"
        x1="37.2008"
        y1="97.7054"
        x2="66.668"
        y2="14.8609"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#FDEA40" />
        <stop offset="0.48" stopColor="#C7CF32" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_28_6098"
        x1="34.0199"
        y1="97.2676"
        x2="30.3639"
        y2="26.3873"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E0DB58" />
        <stop offset="0.12" stopColor="#D5D547" />
        <stop offset="0.26" stopColor="#CDD13B" />
        <stop offset="0.47" stopColor="#C8CF34" />
        <stop offset="0.95" stopColor="#C7CF32" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_28_6098"
        x1="34.0659"
        y1="99.1684"
        x2="14.4973"
        y2="37.3823"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEDA3A" />
        <stop offset="0.91" stopColor="#B6C742" />
      </linearGradient>
      <radialGradient
        id="paint11_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(26.2275 109.218) scale(26.7878)"
      >
        <stop offset="0.67" stopColor="white" />
        <stop offset="0.82" stopColor="#FFED65" />
        <stop offset="1" stopColor="#FFED65" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint12_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(27.4916 96.5716) rotate(8.04) scale(5.13683 25.8193)"
      >
        <stop stopColor="white" />
        <stop offset="0.06" stopColor="#FFFDE8" />
        <stop offset="0.18" stopColor="#FFF9C1" />
        <stop offset="0.3" stopColor="#FFF7A1" />
        <stop offset="0.42" stopColor="#FFF58B" />
        <stop offset="0.52" stopColor="#FFF47D" />
        <stop offset="0.62" stopColor="#FFF479" />
        <stop offset="0.86" stopColor="#FFF479" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(33.3647 96.6405) rotate(23.64) scale(5.13683 26.6304)"
      >
        <stop stopColor="white" />
        <stop offset="0.06" stopColor="#FFFDE8" />
        <stop offset="0.18" stopColor="#FFF9C1" />
        <stop offset="0.3" stopColor="#FFF7A1" />
        <stop offset="0.42" stopColor="#FFF58B" />
        <stop offset="0.52" stopColor="#FFF47D" />
        <stop offset="0.62" stopColor="#FFF479" />
        <stop offset="0.86" stopColor="#FFF479" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(49.4176 130.789) rotate(42.59) scale(4.59611 31.0914)"
      >
        <stop stopColor="white" />
        <stop offset="0.06" stopColor="#FFFDE8" />
        <stop offset="0.18" stopColor="#FFF9C1" />
        <stop offset="0.3" stopColor="#FFF7A1" />
        <stop offset="0.42" stopColor="#FFF58B" />
        <stop offset="0.52" stopColor="#FFF47D" />
        <stop offset="0.62" stopColor="#FFF479" />
        <stop offset="0.86" stopColor="#FFF479" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-19.949 114.245) rotate(-12.91) scale(3.64986 25.2786)"
      >
        <stop stopColor="white" />
        <stop offset="0.03" stopColor="#FFFDEF" />
        <stop offset="0.09" stopColor="#FFFACB" />
        <stop offset="0.16" stopColor="#FFF8AD" />
        <stop offset="0.24" stopColor="#FFF696" />
        <stop offset="0.32" stopColor="#FFF585" />
        <stop offset="0.4" stopColor="#FFF47C" />
        <stop offset="0.52" stopColor="#FFF479" />
        <stop offset="0.56" stopColor="#FFF479" stopOpacity="0.97" />
        <stop offset="0.61" stopColor="#FFF479" stopOpacity="0.89" />
        <stop offset="0.68" stopColor="#FFF479" stopOpacity="0.75" />
        <stop offset="0.75" stopColor="#FFF479" stopOpacity="0.57" />
        <stop offset="0.83" stopColor="#FFF479" stopOpacity="0.32" />
        <stop offset="0.92" stopColor="#FFF479" stopOpacity="0.04" />
        <stop offset="0.93" stopColor="#FFF479" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint16_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(47.5034 124.509) rotate(-36.47) scale(4.05539 16.3568)"
      >
        <stop stopColor="white" />
        <stop offset="0.06" stopColor="#FFFDE8" />
        <stop offset="0.18" stopColor="#FFF9C1" />
        <stop offset="0.3" stopColor="#FFF7A1" />
        <stop offset="0.42" stopColor="#FFF58B" />
        <stop offset="0.52" stopColor="#FFF47D" />
        <stop offset="0.62" stopColor="#FFF479" />
        <stop offset="0.86" stopColor="#FFF479" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint17_linear_28_6098"
        x1="53.4971"
        y1="92.1503"
        x2="51.9798"
        y2="89.8653"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.23" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_28_6098"
        x1="54.4291"
        y1="93.7132"
        x2="56.4947"
        y2="94.7734"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_28_6098"
        x1="46.4049"
        y1="89.2617"
        x2="49.0555"
        y2="89.7918"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.94" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_28_6098"
        x1="44.4392"
        y1="89.5176"
        x2="42.5381"
        y2="87.6805"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.08" stopColor="white" />
        <stop offset="0.15" stopColor="white" stopOpacity="0.97" />
        <stop offset="0.27" stopColor="white" stopOpacity="0.89" />
        <stop offset="0.4" stopColor="white" stopOpacity="0.76" />
        <stop offset="0.55" stopColor="white" stopOpacity="0.57" />
        <stop offset="0.72" stopColor="white" stopOpacity="0.34" />
        <stop offset="0.9" stopColor="white" stopOpacity="0.06" />
        <stop offset="0.93" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_28_6098"
        x1="38.5623"
        y1="86.7667"
        x2="41.2038"
        y2="86.8124"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.06" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_28_6098"
        x1="37.4379"
        y1="85.4688"
        x2="35.162"
        y2="84.6097"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.03" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_28_6098"
        x1="31.013"
        y1="86.5195"
        x2="33.2615"
        y2="85.6969"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.41" stopColor="white" stopOpacity="0.57" />
        <stop offset="0.82" stopColor="white" stopOpacity="0.16" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_28_6098"
        x1="29.6421"
        y1="86.3093"
        x2="27.3936"
        y2="86.2087"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1" stopColor="white" />
        <stop offset="0.25" stopColor="white" stopOpacity="0.8" />
        <stop offset="0.53" stopColor="white" stopOpacity="0.46" />
        <stop offset="0.75" stopColor="white" stopOpacity="0.21" />
        <stop offset="0.92" stopColor="white" stopOpacity="0.06" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_28_6098"
        x1="23.6461"
        y1="90.7609"
        x2="25.4283"
        y2="89.4996"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_28_6098"
        x1="15.875"
        y1="34.7691"
        x2="135.745"
        y2="34.7691"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B5C654" />
        <stop offset="0.19" stopColor="#A2BD52" />
        <stop offset="0.3" stopColor="#BCCA4C" />
        <stop offset="0.36" stopColor="#9DBB4D" />
        <stop offset="0.41" stopColor="#88B04D" />
        <stop offset="0.45" stopColor="#81AD4E" />
        <stop offset="0.55" stopColor="#93B448" />
        <stop offset="0.63" stopColor="#A3BA44" />
        <stop offset="0.67" stopColor="#9BB744" />
        <stop offset="0.73" stopColor="#88AE46" />
        <stop offset="0.8" stopColor="#67A149" />
        <stop offset="0.81" stopColor="#609E4A" />
        <stop offset="1" stopColor="#3E9248" />
      </linearGradient>
      <radialGradient
        id="paint27_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-8.86399 32.0471) rotate(6.3) scale(135.738 175.957)"
      >
        <stop offset="0.89" stopColor="#004F1F" stopOpacity="0" />
        <stop offset="0.92" stopColor="#004F1F" stopOpacity="0.22" />
        <stop offset="0.98" stopColor="#004F1F" stopOpacity="0.79" />
        <stop offset="1" stopColor="#004F1F" />
      </radialGradient>
      <linearGradient
        id="paint28_linear_28_6098"
        x1="115.665"
        y1="116.325"
        x2="12.7952"
        y2="76.118"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5D5423" />
        <stop offset="0.06" stopColor="#81753B" />
        <stop offset="0.14" stopColor="#AB9C57" />
        <stop offset="0.18" stopColor="#BCAC62" />
        <stop offset="0.42" stopColor="#D2C37D" />
        <stop offset="0.64" stopColor="white" />
      </linearGradient>
      <radialGradient
        id="paint29_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(35.7254 120.453) rotate(-28.07) scale(76.9357 62.6883)"
      >
        <stop offset="0.03" stopColor="#F9F5E0" />
        <stop offset="0.06" stopColor="#F6F2D7" />
        <stop offset="0.1" stopColor="#EEEBC1" />
        <stop offset="0.16" stopColor="#E2E09D" />
        <stop offset="0.2" stopColor="#D8D77C" />
        <stop offset="0.35" stopColor="#96AF36" />
        <stop offset="0.44" stopColor="#619935" />
        <stop offset="0.51" stopColor="#3F8B35" />
        <stop offset="0.55" stopColor="#338635" />
        <stop offset="0.78" stopColor="#007936" />
      </radialGradient>
      <radialGradient
        id="paint30_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.0832 115.997) rotate(149.97) scale(3.07724 15.4672)"
      >
        <stop stopColor="white" />
        <stop offset="0.06" stopColor="#FFFDE8" />
        <stop offset="0.18" stopColor="#FFF9C1" />
        <stop offset="0.3" stopColor="#FFF7A1" />
        <stop offset="0.42" stopColor="#FFF58B" />
        <stop offset="0.52" stopColor="#FFF47D" />
        <stop offset="0.62" stopColor="#FFF479" />
        <stop offset="0.86" stopColor="#FFF479" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint31_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.9871 116.237) rotate(119.36) scale(3.07724 15.4672)"
      >
        <stop stopColor="white" />
        <stop offset="0.05" stopColor="#FFFDE8" />
        <stop offset="0.16" stopColor="#FFF9C1" />
        <stop offset="0.27" stopColor="#FFF7A1" />
        <stop offset="0.37" stopColor="#FFF58B" />
        <stop offset="0.47" stopColor="#FFF47D" />
        <stop offset="0.56" stopColor="#FFF479" />
        <stop offset="0.86" stopColor="#FFF479" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint32_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(47.9131 127.877) rotate(-117.62) scale(11.9095 18.967)"
      >
        <stop offset="0.76" stopColor="#D8D480" stopOpacity="0" />
        <stop offset="0.78" stopColor="#D8D480" stopOpacity="0.08" />
        <stop offset="0.84" stopColor="#D8D480" stopOpacity="0.29" />
        <stop offset="0.92" stopColor="#D8D480" stopOpacity="0.63" />
        <stop offset="1" stopColor="#D8D480" />
      </radialGradient>
      <radialGradient
        id="paint33_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(43.297 141.645) rotate(-58.28) scale(19.7275 36.5324)"
      >
        <stop offset="0.98" stopColor="#968E63" stopOpacity="0" />
        <stop offset="1" stopColor="#968E63" />
      </radialGradient>
      <radialGradient
        id="paint34_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(60.8471 137.823) rotate(-63.6) scale(21.0107 38.9088)"
      >
        <stop offset="0.98" stopColor="#968E63" stopOpacity="0" />
        <stop offset="1" stopColor="#968E63" />
      </radialGradient>
      <radialGradient
        id="paint35_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(50.1645 140.209) rotate(-61.84) scale(22.0571 37.5787)"
      >
        <stop offset="0.98" stopColor="#968E63" stopOpacity="0" />
        <stop offset="1" stopColor="#968E63" />
      </radialGradient>
      <radialGradient
        id="paint36_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(41.2427 143.92) rotate(-63.27) scale(24.5643 34.572)"
      >
        <stop offset="0.95" stopColor="#00542E" />
        <stop offset="0.96" stopColor="#005A31" />
        <stop offset="1" stopColor="#006B3A" />
      </radialGradient>
      <radialGradient
        id="paint37_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(47.087 138.813) rotate(-65.22) scale(23.5871 28.8287)"
      >
        <stop offset="0.96" stopColor="#00542E" />
        <stop offset="0.97" stopColor="#005A31" />
        <stop offset="1" stopColor="#006B3A" />
      </radialGradient>
      <radialGradient
        id="paint38_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(61.0972 137.506) rotate(-69.03) scale(22.1286 34.4223)"
      >
        <stop offset="0.96" stopColor="#00542E" />
        <stop offset="0.97" stopColor="#005A31" />
        <stop offset="1" stopColor="#006B3A" />
      </radialGradient>
      <radialGradient
        id="paint39_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(62.9995 129.916) rotate(-77.67) scale(15.5915 42.1547)"
      >
        <stop offset="0.93" stopColor="#00653C" stopOpacity="0" />
        <stop offset="0.94" stopColor="#00653C" stopOpacity="0.84" />
        <stop offset="0.95" stopColor="#00653C" />
      </radialGradient>
      <radialGradient
        id="paint40_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(54.3596 132.852) rotate(-77.36) scale(17.2029 38.8659)"
      >
        <stop offset="0.91" stopColor="#00653C" stopOpacity="0" />
        <stop offset="0.93" stopColor="#00653C" stopOpacity="0.42" />
        <stop offset="0.95" stopColor="#00653C" stopOpacity="0.84" />
        <stop offset="0.96" stopColor="#00653C" />
      </radialGradient>
      <radialGradient
        id="paint41_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(53.4611 137.19) rotate(-62.31) scale(14.8659 38.5413)"
      >
        <stop offset="0.91" stopColor="#00653C" stopOpacity="0" />
        <stop offset="0.93" stopColor="#00653C" stopOpacity="0.42" />
        <stop offset="0.95" stopColor="#00653C" stopOpacity="0.84" />
        <stop offset="0.96" stopColor="#00653C" />
      </radialGradient>
      <radialGradient
        id="paint42_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(53.4623 107.822) rotate(41.56) scale(49.5655 78.9376)"
      >
        <stop offset="0.76" stopColor="#004F1F" stopOpacity="0" />
        <stop offset="0.83" stopColor="#004F1F" stopOpacity="0.22" />
        <stop offset="0.96" stopColor="#004F1F" stopOpacity="0.79" />
        <stop offset="1" stopColor="#004F1F" />
      </radialGradient>
      <radialGradient
        id="paint43_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(99.7105 92.0661) rotate(147.49) scale(61.781 84.6629)"
      >
        <stop offset="0.76" stopColor="#004F1F" stopOpacity="0" />
        <stop offset="0.83" stopColor="#004F1F" stopOpacity="0.22" />
        <stop offset="0.96" stopColor="#004F1F" stopOpacity="0.79" />
        <stop offset="1" stopColor="#004F1F" />
      </radialGradient>
      <linearGradient
        id="paint44_linear_28_6098"
        x1="90.8674"
        y1="71.6575"
        x2="90.118"
        y2="71.3924"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint45_linear_28_6098"
        x1="91.6625"
        y1="71.6575"
        x2="92.412"
        y2="71.3924"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint46_linear_28_6098"
        x1="94.6246"
        y1="80.8692"
        x2="95.3867"
        y2="80.6431"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint47_linear_28_6098"
        x1="93.979"
        y1="81.341"
        x2="93.5285"
        y2="81.996"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint48_linear_28_6098"
        x1="96.0615"
        y1="74.8623"
        x2="96.082"
        y2="74.0676"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint49_linear_28_6098"
        x1="96.3066"
        y1="75.6249"
        x2="96.7932"
        y2="76.2645"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint50_linear_28_6098"
        x1="88.4502"
        y1="81.3596"
        x2="88.9008"
        y2="82.0146"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint51_linear_28_6098"
        x1="87.8037"
        y1="80.8877"
        x2="87.0342"
        y2="80.6563"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint52_linear_28_6098"
        x1="86.0998"
        y1="75.589"
        x2="85.6161"
        y2="76.2199"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint53_linear_28_6098"
        x1="86.3442"
        y1="74.8268"
        x2="86.3237"
        y2="74.032"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint54_linear_28_6098"
        x1="91.2159"
        y1="76.5199"
        x2="90.8137"
        y2="75.3774"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.46" stopColor="#50A9DE" />
        <stop offset="0.94" stopColor="#50A9DE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint55_linear_28_6098"
        x1="89.0772"
        y1="79.2802"
        x2="88.0169"
        y2="78.485"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.66" stopColor="#50A9DE" />
        <stop offset="0.94" stopColor="#50A9DE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint56_linear_28_6098"
        x1="90.8527"
        y1="71.4839"
        x2="90.2495"
        y2="71.4839"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.44" stopColor="#7AB9E5" />
        <stop offset="1" stopColor="#7AB9E5" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint57_linear_28_6098"
        x1="85.5863"
        y1="75.6247"
        x2="86.0158"
        y2="74.4913"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.75" stopColor="#A2CFEF" />
        <stop offset="1" stopColor="#A2CFEF" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint58_linear_28_6098"
        x1="92.5426"
        y1="76.9856"
        x2="92.5426"
        y2="76.428"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.46" stopColor="#6CB3E2" />
        <stop offset="1" stopColor="#6CB3E2" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint59_linear_28_6098"
        x1="92.0204"
        y1="79.4261"
        x2="92.8887"
        y2="78.8045"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.4" stopColor="#B8DBF3" />
        <stop offset="1" stopColor="#B8DBF3" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint60_linear_28_6098"
        x1="107.741"
        y1="40.3671"
        x2="107.051"
        y2="40.8806"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint61_linear_28_6098"
        x1="108.63"
        y1="39.4321"
        x2="108.972"
        y2="38.6522"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint62_linear_28_6098"
        x1="120.423"
        y1="54.1905"
        x2="121.268"
        y2="54.0273"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint63_linear_28_6098"
        x1="119.411"
        y1="55.0486"
        x2="118.765"
        y2="55.6031"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint64_linear_28_6098"
        x1="125.209"
        y1="47.4601"
        x2="125.607"
        y2="46.6981"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint65_linear_28_6098"
        x1="125.148"
        y1="48.769"
        x2="125.179"
        y2="49.6198"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint66_linear_28_6098"
        x1="122.943"
        y1="39.4575"
        x2="122.596"
        y2="38.6704"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint67_linear_28_6098"
        x1="123.827"
        y1="40.3853"
        x2="124.517"
        y2="40.8988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint68_linear_28_6098"
        x1="115.163"
        y1="36.222"
        x2="114.341"
        y2="36.0027"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint69_linear_28_6098"
        x1="116.452"
        y1="36.222"
        x2="117.284"
        y2="36.0027"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint70_linear_28_6098"
        x1="106.234"
        y1="48.8062"
        x2="106.205"
        y2="49.6659"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint71_linear_28_6098"
        x1="106.175"
        y1="47.5154"
        x2="105.776"
        y2="46.7534"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint72_linear_28_6098"
        x1="111.877"
        y1="55.3199"
        x2="112.523"
        y2="55.8744"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint73_linear_28_6098"
        x1="110.873"
        y1="54.4617"
        x2="110.029"
        y2="54.2986"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint74_linear_28_6098"
        x1="116.371"
        y1="45.4898"
        x2="115.476"
        y2="44.4022"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.8" stopColor="#50A9DE" />
        <stop offset="1" stopColor="#50A9DE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint75_linear_28_6098"
        x1="114.498"
        y1="46.1662"
        x2="114.251"
        y2="45.0237"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.72" stopColor="#50A9DE" />
        <stop offset="1" stopColor="#50A9DE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint76_linear_28_6098"
        x1="121.818"
        y1="44.8771"
        x2="121.288"
        y2="47.1895"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.55" stopColor="white" />
        <stop offset="0.72" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint77_linear_28_6098"
        x1="119.378"
        y1="48.8991"
        x2="119.67"
        y2="48.2136"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.46" stopColor="#6CB3E2" />
        <stop offset="1" stopColor="#6CB3E2" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint78_linear_28_6098"
        x1="116.947"
        y1="52.0982"
        x2="118.345"
        y2="51.4035"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.7" stopColor="#CBE5F7" />
        <stop offset="1" stopColor="#CBE5F7" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint79_linear_28_6098"
        x1="109.909"
        y1="48.3505"
        x2="113.162"
        y2="49.8768"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.75" stopColor="white" />
        <stop offset="0.88" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint80_linear_28_6098"
        x1="116.043"
        y1="37.4102"
        x2="114.846"
        y2="37.4102"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.73" stopColor="#7AB9E5" />
        <stop offset="1" stopColor="#7AB9E5" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint81_linear_28_6098"
        x1="108.117"
        y1="41.9435"
        x2="109.562"
        y2="40.1246"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.72" stopColor="#D9F0FC" />
        <stop offset="0.85" stopColor="#D9F0FC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint82_linear_28_6098"
        x1="68.3497"
        y1="26.8742"
        x2="67.6582"
        y2="27.3749"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint83_linear_28_6098"
        x1="69.2386"
        y1="25.9392"
        x2="69.5728"
        y2="25.1536"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint84_linear_28_6098"
        x1="81.0296"
        y1="40.6839"
        x2="81.8659"
        y2="40.5247"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint85_linear_28_6098"
        x1="80.0173"
        y1="41.542"
        x2="79.363"
        y2="42.1005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint86_linear_28_6098"
        x1="85.8093"
        y1="33.9565"
        x2="86.2063"
        y2="33.2034"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint87_linear_28_6098"
        x1="85.7492"
        y1="35.2653"
        x2="85.778"
        y2="36.125"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint88_linear_28_6098"
        x1="83.5473"
        y1="25.9572"
        x2="83.2002"
        y2="25.1701"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint89_linear_28_6098"
        x1="84.4315"
        y1="26.885"
        x2="85.1216"
        y2="27.3986"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint90_linear_28_6098"
        x1="75.7683"
        y1="22.7217"
        x2="74.9366"
        y2="22.5023"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint91_linear_28_6098"
        x1="77.0578"
        y1="22.7217"
        x2="77.8804"
        y2="22.5023"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint92_linear_28_6098"
        x1="66.8338"
        y1="35.3175"
        x2="66.803"
        y2="36.1683"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint93_linear_28_6098"
        x1="66.7732"
        y1="34.0177"
        x2="66.3743"
        y2="33.2557"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint94_linear_28_6098"
        x1="72.4792"
        y1="41.8276"
        x2="73.1252"
        y2="42.3821"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint95_linear_28_6098"
        x1="71.4753"
        y1="40.9696"
        x2="70.6307"
        y2="40.8065"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint96_linear_28_6098"
        x1="76.975"
        y1="31.9898"
        x2="76.0702"
        y2="30.9022"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.8" stopColor="#50A9DE" />
        <stop offset="1" stopColor="#50A9DE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint97_linear_28_6098"
        x1="75.1013"
        y1="32.6662"
        x2="74.8545"
        y2="31.5237"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.72" stopColor="#50A9DE" />
        <stop offset="1" stopColor="#50A9DE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint98_linear_28_6098"
        x1="82.4233"
        y1="31.3768"
        x2="81.8932"
        y2="33.6892"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.55" stopColor="white" />
        <stop offset="0.72" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint99_linear_28_6098"
        x1="79.9832"
        y1="35.3992"
        x2="80.2757"
        y2="34.7137"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.46" stopColor="#6CB3E2" />
        <stop offset="1" stopColor="#6CB3E2" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint100_linear_28_6098"
        x1="77.5524"
        y1="38.5982"
        x2="78.9508"
        y2="37.9035"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.7" stopColor="#CBE5F7" />
        <stop offset="1" stopColor="#CBE5F7" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint101_linear_28_6098"
        x1="70.5121"
        y1="34.8501"
        x2="73.7568"
        y2="36.3856"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.75" stopColor="white" />
        <stop offset="0.88" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint102_linear_28_6098"
        x1="76.6466"
        y1="23.9189"
        x2="75.4492"
        y2="23.9189"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.73" stopColor="#7AB9E5" />
        <stop offset="1" stopColor="#7AB9E5" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint103_linear_28_6098"
        x1="68.721"
        y1="28.4435"
        x2="70.1651"
        y2="26.6246"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.72" stopColor="#D9F0FC" />
        <stop offset="0.85" stopColor="#D9F0FC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint104_linear_28_6098"
        x1="30.9252"
        y1="55.346"
        x2="30.228"
        y2="55.8538"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint105_linear_28_6098"
        x1="31.8084"
        y1="54.4181"
        x2="32.1484"
        y2="53.6254"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint106_linear_28_6098"
        x1="43.6008"
        y1="69.1553"
        x2="44.4454"
        y2="68.9921"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint107_linear_28_6098"
        x1="42.5885"
        y1="70.0132"
        x2="41.9425"
        y2="70.5677"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint108_linear_28_6098"
        x1="48.3801"
        y1="62.427"
        x2="48.777"
        y2="61.6739"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint109_linear_28_6098"
        x1="48.3199"
        y1="63.736"
        x2="48.3487"
        y2="64.5957"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint110_linear_28_6098"
        x1="46.114"
        y1="54.4287"
        x2="45.7727"
        y2="53.6488"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint111_linear_28_6098"
        x1="47.0026"
        y1="55.3637"
        x2="47.6927"
        y2="55.8772"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint112_linear_28_6098"
        x1="38.3504"
        y1="51.1948"
        x2="37.5186"
        y2="50.9755"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint113_linear_28_6098"
        x1="39.63"
        y1="51.1948"
        x2="40.4618"
        y2="50.9755"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint114_linear_28_6098"
        x1="29.4091"
        y1="63.7935"
        x2="29.3784"
        y2="64.6443"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint115_linear_28_6098"
        x1="29.3486"
        y1="62.4941"
        x2="28.9496"
        y2="61.7321"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint116_linear_28_6098"
        x1="35.0521"
        y1="70.3"
        x2="35.7063"
        y2="70.8585"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint117_linear_28_6098"
        x1="34.0564"
        y1="69.4465"
        x2="33.2118"
        y2="69.2833"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint118_linear_28_6098"
        x1="39.5459"
        y1="60.462"
        x2="38.6502"
        y2="59.3835"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.8" stopColor="#50A9DE" />
        <stop offset="1" stopColor="#50A9DE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint119_linear_28_6098"
        x1="37.6723"
        y1="61.1475"
        x2="37.4346"
        y2="59.9959"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.72" stopColor="#50A9DE" />
        <stop offset="1" stopColor="#50A9DE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint120_linear_28_6098"
        x1="44.9943"
        y1="59.8494"
        x2="44.4641"
        y2="62.1618"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.55" stopColor="white" />
        <stop offset="0.72" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint121_linear_28_6098"
        x1="42.5536"
        y1="63.8709"
        x2="42.846"
        y2="63.1854"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.46" stopColor="#6CB3E2" />
        <stop offset="1" stopColor="#6CB3E2" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint122_linear_28_6098"
        x1="40.1227"
        y1="67.07"
        x2="41.5211"
        y2="66.3754"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.7" stopColor="#CBE5F7" />
        <stop offset="1" stopColor="#CBE5F7" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint123_linear_28_6098"
        x1="33.0844"
        y1="63.3223"
        x2="36.3382"
        y2="64.8578"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.75" stopColor="white" />
        <stop offset="0.88" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint124_linear_28_6098"
        x1="39.2188"
        y1="52.391"
        x2="38.0306"
        y2="52.391"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.73" stopColor="#7AB9E5" />
        <stop offset="1" stopColor="#7AB9E5" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint125_linear_28_6098"
        x1="31.3024"
        y1="56.9157"
        x2="32.7373"
        y2="55.0968"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.72" stopColor="#D9F0FC" />
        <stop offset="0.85" stopColor="#D9F0FC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint126_linear_28_6098"
        x1="67.9472"
        y1="135.743"
        x2="67.2572"
        y2="136.256"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint127_linear_28_6098"
        x1="68.8361"
        y1="134.808"
        x2="69.1775"
        y2="134.028"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint128_linear_28_6098"
        x1="80.6187"
        y1="149.557"
        x2="81.455"
        y2="149.397"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint129_linear_28_6098"
        x1="79.6063"
        y1="150.415"
        x2="78.9521"
        y2="150.973"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint130_linear_28_6098"
        x1="85.4039"
        y1="142.831"
        x2="85.8028"
        y2="142.069"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint131_linear_28_6098"
        x1="85.3437"
        y1="144.14"
        x2="85.3745"
        y2="144.991"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint132_linear_28_6098"
        x1="83.1338"
        y1="134.831"
        x2="82.7996"
        y2="134.045"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint133_linear_28_6098"
        x1="84.0237"
        y1="135.766"
        x2="84.7152"
        y2="136.266"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint134_linear_28_6098"
        x1="75.3666"
        y1="131.597"
        x2="74.544"
        y2="131.377"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint135_linear_28_6098"
        x1="76.6554"
        y1="131.597"
        x2="77.4872"
        y2="131.377"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint136_linear_28_6098"
        x1="66.4388"
        y1="144.187"
        x2="66.4101"
        y2="145.047"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint137_linear_28_6098"
        x1="66.3783"
        y1="142.888"
        x2="65.9814"
        y2="142.135"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint138_linear_28_6098"
        x1="72.0783"
        y1="150.696"
        x2="72.7326"
        y2="151.254"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint139_linear_28_6098"
        x1="71.0827"
        y1="149.842"
        x2="70.2381"
        y2="149.679"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004712" />
        <stop offset="1" stopColor="#004712" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint140_linear_28_6098"
        x1="76.5727"
        y1="140.865"
        x2="75.6769"
        y2="139.777"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.8" stopColor="#50A9DE" />
        <stop offset="1" stopColor="#50A9DE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint141_linear_28_6098"
        x1="74.699"
        y1="141.541"
        x2="74.4522"
        y2="140.399"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.72" stopColor="#50A9DE" />
        <stop offset="1" stopColor="#50A9DE" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint142_linear_28_6098"
        x1="82.019"
        y1="140.252"
        x2="81.4889"
        y2="142.565"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.55" stopColor="white" />
        <stop offset="0.72" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint143_linear_28_6098"
        x1="79.579"
        y1="144.265"
        x2="79.8714"
        y2="143.589"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.46" stopColor="#6CB3E2" />
        <stop offset="1" stopColor="#6CB3E2" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint144_linear_28_6098"
        x1="77.1481"
        y1="147.473"
        x2="78.5465"
        y2="146.779"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.7" stopColor="#CBE5F7" />
        <stop offset="1" stopColor="#CBE5F7" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint145_linear_28_6098"
        x1="70.1117"
        y1="143.726"
        x2="73.3656"
        y2="145.252"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.75" stopColor="white" />
        <stop offset="0.88" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint146_linear_28_6098"
        x1="76.2423"
        y1="132.785"
        x2="75.0449"
        y2="132.785"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.73" stopColor="#7AB9E5" />
        <stop offset="1" stopColor="#7AB9E5" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint147_linear_28_6098"
        x1="68.3186"
        y1="137.318"
        x2="69.7627"
        y2="135.491"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.72" stopColor="#D9F0FC" />
        <stop offset="0.85" stopColor="#D9F0FC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint148_linear_28_6098"
        x1="114.846"
        y1="161.448"
        x2="67.7938"
        y2="12.9058"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#001400" />
        <stop offset="0.09" stopColor="#004223" />
        <stop offset="0.41" stopColor="#004223" />
        <stop offset="0.54" stopColor="#003C1E" />
        <stop offset="0.71" stopColor="#002C12" />
        <stop offset="0.88" stopColor="#001400" />
        <stop offset="1" stopColor="#001400" />
      </linearGradient>
      <linearGradient
        id="paint149_linear_28_6098"
        x1="76.8599"
        y1="169.921"
        x2="76.8599"
        y2="7.91557"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E9262" />
        <stop offset="0.22" stopColor="#648B54" />
        <stop offset="0.44" stopColor="#658C56" />
        <stop offset="0.53" stopColor="#6A8F5D" />
        <stop offset="0.56" stopColor="#6E9262" />
        <stop offset="0.59" stopColor="#5F8155" />
        <stop offset="0.65" stopColor="#3B5734" />
        <stop offset="0.72" stopColor="#001400" />
        <stop offset="0.78" stopColor="#00260D" />
        <stop offset="0.85" stopColor="#004223" />
        <stop offset="0.97" stopColor="#004321" />
        <stop offset="1" stopColor="#00461C" />
      </linearGradient>
      <radialGradient
        id="paint150_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(85.5 63.6968) rotate(-101.33) scale(46.5819 93.1638)"
      >
        <stop offset="0.97" stopColor="#598364" />
        <stop offset="0.99" stopColor="#6E9262" />
      </radialGradient>
      <radialGradient
        id="paint151_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.08919 49.2962) rotate(11.71) scale(124.423 147.465)"
      >
        <stop offset="0.99" stopColor="#001400" />
        <stop offset="1" stopColor="#6E9262" />
      </radialGradient>
      <radialGradient
        id="paint152_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(1.63812 64.1682) rotate(44.82) scale(124.423 124.423)"
      >
        <stop offset="0.99" stopColor="#1C3419" />
        <stop offset="1" stopColor="#6E9262" />
      </radialGradient>
      <linearGradient
        id="paint153_linear_28_6098"
        x1="22.6771"
        y1="130.72"
        x2="74.4732"
        y2="130.72"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9CB092" />
        <stop offset="0.54" stopColor="#9CB092" />
        <stop offset="0.68" stopColor="#9DB194" />
        <stop offset="0.73" stopColor="#A4B69B" />
        <stop offset="0.78" stopColor="#B0BFA8" />
        <stop offset="0.82" stopColor="#C1CCBA" />
        <stop offset="0.85" stopColor="#D6DCD1" />
        <stop offset="0.86" stopColor="#E0E3DB" />
        <stop offset="0.93" stopColor="#E1E4DD" />
        <stop offset="0.96" stopColor="#E8EAE4" />
        <stop offset="0.98" stopColor="#F2F4F1" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint154_linear_28_6098"
        x1="122.441"
        y1="5.40186"
        x2="104.197"
        y2="136.752"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#001400" />
        <stop offset="0.56" stopColor="#001400" />
        <stop offset="0.76" stopColor="#002007" stopOpacity="0.75" />
        <stop offset="1" stopColor="#00461C" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint155_linear_28_6098"
        x1="105.029"
        y1="126.689"
        x2="139.404"
        y2="19.6876"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004223" />
        <stop offset="0.09" stopColor="#00461C" />
        <stop offset="0.15" stopColor="#105126" />
        <stop offset="0.42" stopColor="#537F51" />
        <stop offset="0.55" stopColor="#6E9262" />
        <stop offset="0.76" stopColor="#00461C" />
        <stop offset="1" stopColor="#00461C" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint156_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.0209 87.7122) scale(41.3305 41.3305)"
      >
        <stop offset="0.97" stopColor="#598364" />
        <stop offset="0.99" stopColor="#6E9262" />
      </radialGradient>
      <radialGradient
        id="paint157_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-9.9442 52.6981) rotate(35.4) scale(137.145 152.383)"
      >
        <stop offset="0.97" stopColor="#598364" />
        <stop offset="0.99" stopColor="#6E9262" />
      </radialGradient>
      <linearGradient
        id="paint158_linear_28_6098"
        x1="77.0159"
        y1="163.907"
        x2="73.5335"
        y2="168.513"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9CB092" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint159_linear_28_6098"
        x1="138.38"
        y1="11.9646"
        x2="138.626"
        y2="10.7764"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9CB092" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint160_linear_28_6098"
        x1="139.277"
        y1="9.5333"
        x2="141.818"
        y2="7.01981"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.16" stopColor="#F7F8F6" />
        <stop offset="0.41" stopColor="#E1E7DE" />
        <stop offset="0.73" stopColor="#BFCCB8" />
        <stop offset="1" stopColor="#9CB092" />
      </linearGradient>
      <linearGradient
        id="paint161_linear_28_6098"
        x1="136.526"
        y1="8.6467"
        x2="140.629"
        y2="4.59771"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.16" stopColor="#F7F8F6" />
        <stop offset="0.41" stopColor="#E1E7DE" />
        <stop offset="0.73" stopColor="#BFCCB8" />
        <stop offset="1" stopColor="#9CB092" />
      </linearGradient>
      <linearGradient
        id="paint162_linear_28_6098"
        x1="85.1695"
        y1="171.055"
        x2="148.454"
        y2="17.2935"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.04" stopColor="#E0E3DB" />
        <stop offset="0.05" stopColor="#D7DCD1" />
        <stop offset="0.08" stopColor="#C3CDBB" />
        <stop offset="0.1" stopColor="#B3C1AA" />
        <stop offset="0.14" stopColor="#A9B99E" />
        <stop offset="0.19" stopColor="#A2B497" />
        <stop offset="0.32" stopColor="#A1B396" />
        <stop offset="0.55" stopColor="#A2B498" />
        <stop offset="0.66" stopColor="#A9B99F" />
        <stop offset="0.74" stopColor="#B5C2AC" />
        <stop offset="0.81" stopColor="#C5CEBE" />
        <stop offset="0.86" stopColor="#DADED5" />
        <stop offset="0.88" stopColor="#E0E3DB" />
        <stop offset="0.93" stopColor="#E1E4DD" />
        <stop offset="0.96" stopColor="#E8EAE5" />
        <stop offset="0.99" stopColor="#F4F5F2" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint163_linear_28_6098"
        x1="21.032"
        y1="147.803"
        x2="105.869"
        y2="-11.3328"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E9262" />
        <stop offset="0.15" stopColor="#6C905F" />
        <stop offset="0.21" stopColor="#678D58" />
        <stop offset="0.23" stopColor="#648B54" />
        <stop offset="0.45" stopColor="#9CB092" />
        <stop offset="0.47" stopColor="#9DB194" />
        <stop offset="0.49" stopColor="#A4B69B" />
        <stop offset="0.5" stopColor="#C1CCBA" />
        <stop offset="0.51" stopColor="#E0E3DB" />
        <stop offset="0.57" stopColor="white" />
        <stop offset="0.59" stopColor="#FBFCFB" />
        <stop offset="0.61" stopColor="#F2F3F0" />
        <stop offset="0.63" stopColor="#E0E3DB" />
        <stop offset="0.69" stopColor="#C9D2C3" />
        <stop offset="0.78" stopColor="#9CB092" />
        <stop offset="0.83" stopColor="#9DB194" />
        <stop offset="0.85" stopColor="#A4B69B" />
        <stop offset="0.87" stopColor="#B0BFA8" />
        <stop offset="0.88" stopColor="#C1CCBA" />
        <stop offset="0.9" stopColor="#E0E3DB" />
        <stop offset="0.93" stopColor="#E1E4DD" />
        <stop offset="0.95" stopColor="#E8EAE4" />
        <stop offset="0.96" stopColor="#F2F4F1" />
        <stop offset="0.97" stopColor="white" />
      </linearGradient>
      <radialGradient
        id="paint164_radial_28_6098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(78.8102 134.791) rotate(139.35) scale(24.779 48.6403)"
      >
        <stop offset="0.61" stopColor="#001400" />
        <stop offset="0.95" stopColor="#001400" />
        <stop offset="0.96" stopColor="#1C3419" />
        <stop offset="0.99" stopColor="#56774D" />
        <stop offset="1" stopColor="#6E9262" />
      </radialGradient>
    </defs>
  </svg>
)

export default CricrushLogo
