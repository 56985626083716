import { PaymentKycStateEnum, riskProfileStatusEnum, UserProfileProps } from 'interfaces'
import { createContext, Dispatch, SetStateAction } from 'react'

export const CustomUserContext = createContext<{
  user?: UserProfileProps
  isLoading: boolean
  cohortDataLoading: boolean
  error?: Error
  setIsPhoneVerified: Dispatch<SetStateAction<boolean>>
  setIsEmailVerified: Dispatch<SetStateAction<boolean>>
  setFirstName: Dispatch<SetStateAction<string | undefined>>
  setLastName: Dispatch<SetStateAction<string | undefined>>
  setPhoneNumber: Dispatch<SetStateAction<string | undefined>>
  setEmailAddress: Dispatch<SetStateAction<string | undefined>>
  setPhoneIsoCode: Dispatch<SetStateAction<string | undefined>>
  setKycStatus: Dispatch<SetStateAction<PaymentKycStateEnum | undefined>>
  setUserCohort: Dispatch<SetStateAction<string | undefined>>
  setPostalCode: Dispatch<SetStateAction<string | undefined>>
  setCountry: Dispatch<SetStateAction<string | undefined>>
  setRiskProfileStatus: Dispatch<SetStateAction<riskProfileStatusEnum | undefined>>
  setUsername: Dispatch<SetStateAction<string | undefined>>
}>({
  user: undefined,
  isLoading: true,
  cohortDataLoading: false,
  error: undefined,
  setIsPhoneVerified: () => {},
  setIsEmailVerified: () => {},
  setFirstName: () => {},
  setLastName: () => {},
  setPhoneNumber: () => {},
  setEmailAddress: () => {},
  setPhoneIsoCode: () => {},
  setKycStatus: () => {},
  setUserCohort: () => {},
  setPostalCode: () => {},
  setCountry: () => {},
  setRiskProfileStatus: () => {},
  setUsername: () => {},
})
