import API_CONSTANTS from './constants'
import { doGet, doPatch, doPost } from 'api'
import { PaymentMethod, TokenPayloadType } from 'interfaces'
import { getHeaders } from 'utils/utils'

type PaymentFormData = {
  orderId: string
  token?: string
  paymentMethod: PaymentMethod
}

type CreateUserOrderAPI = {
  catalogId: string
  reservationId?: string
  quantity?: number
  listingId?: number
  sellerId?: number
  isIndianIP: boolean
  utmSource?: string
  utmCampaign?: string
  utmContent?: string
  utmMedium?: string
}

export const createUserOrder = (body: CreateUserOrderAPI, accessToken: string) => {
  return doPost(
    API_CONSTANTS.CREATE_USER_ORDER,
    {},
    { headers: getHeaders(accessToken), body: JSON.stringify(body) }
  )
}

export const getUserOrder = (id: string, accessToken: string) => {
  return doGet(API_CONSTANTS.GET_USER_ORDER, { id }, { headers: getHeaders(accessToken) })
}

export const getUserOrderGroup = (id: string, accessToken: string) => {
  return doGet(API_CONSTANTS.GET_USER_ORDER_GROUP, { id }, { headers: getHeaders(accessToken) })
}

export const cancelUserOrderGroup = (id: string, accessToken: string) => {
  return doPost(
    API_CONSTANTS.ORDER_GROUP_RECONCILIATION,
    { id },
    { headers: getHeaders(accessToken) }
  )
}

export const getUserOrderInvoice = (orderId: string, accessToken: string) => {
  return doGet(
    API_CONSTANTS.GET_USER_ORDER_INVOICES,
    { orderId, isAbsUrl: true },
    {
      headers: getHeaders(accessToken),
    }
  )
}

export const getSellerOrderGroup = (id: string, accessToken: string) => {
  return doGet(
    API_CONSTANTS.GET_SELLER_ORDER_GROUP,
    { id },
    {
      headers: getHeaders(accessToken),
    }
  )
}

export const getUserReservationPurchaseStatus = (id: string, accessToken: string) => {
  return doGet(
    API_CONSTANTS.GET_USER_RESERVATION_PURCHASE_STATUS,
    { id },
    { headers: getHeaders(accessToken) }
  )
}

export const createUserReservation = (body: { catalogId: string }, accessToken: string) => {
  return doPost(
    API_CONSTANTS.CREATE_USER_RESERVATION,
    {},
    { headers: getHeaders(accessToken), body: JSON.stringify(body) }
  )
}

export const revealPackItem = (id: string, accessToken: string) => {
  return doPatch(API_CONSTANTS.REVEAL_PACK_ITEM, { id }, { headers: getHeaders(accessToken) })
}

export const revealPack = (id: string, accessToken: string) => {
  return doPatch(API_CONSTANTS.REVEAL_PACK, { id }, { headers: getHeaders(accessToken) })
}

export const createUserOrderPayment = (body: PaymentFormData, accessToken: string) => {
  return doPost(
    API_CONSTANTS.CREATE_USER_ORDER_PAYMENT,
    {},
    { headers: getHeaders(accessToken), body: JSON.stringify(body) }
  )
}

export const patchUserOrder = (
  id: string,
  paymentMethod: string,
  useBonusDiscount: boolean,
  accessToken: string
) => {
  return doPatch(
    API_CONSTANTS.PATCH_USER_ORDER,
    { id },
    {
      headers: getHeaders(accessToken),
      body: JSON.stringify({
        paymentMethod,
        useBonusDiscount,
      }),
    }
  )
}

export const getInaiOrder = (orderId: string, accessToken: string) => {
  return doPost(
    API_CONSTANTS.GET_INAI_ORDER,
    {},
    { headers: getHeaders(accessToken), body: JSON.stringify({ orderId: orderId.toString() }) }
  )
}

export const cancelInaiOrder = (orderId: string, accessToken: string) => {
  return doPatch(
    API_CONSTANTS.CANCEL_INAI_ORDER,
    {},
    { headers: getHeaders(accessToken), body: JSON.stringify({ orderId: orderId.toString() }) }
  )
}

export const getTokenStatus = (tokenInfo: TokenPayloadType, accessToken: string) => {
  return doPost(
    API_CONSTANTS.TOKEN_STATUS,
    {},
    {
      headers: getHeaders(accessToken),
      body: JSON.stringify(tokenInfo),
    }
  )
}
