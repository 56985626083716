const PlayIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
}> = ({ className, width = '16', height = '21' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0565 9.81114C15.4102 10.0645 15.4102 10.5904 15.0565 10.8438L1.77727 20.356C1.35698 20.6571 0.772277 20.3567 0.772277 19.8397L0.772278 0.815279C0.772278 0.298286 1.35698 -0.00211707 1.77727 0.298945L15.0565 9.81114Z"
      fill="white"
    />
  </svg>
)

export default PlayIcon
