const WalletIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '17', height = '16', fill = '#36B286' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* filter="url(#filter0_d_1921_9013)"  removed as not working in case of safari browser */}
    <g>
      <path
        d="M14.125 3.125H3.34375C3.08008 3.125 2.875 2.91992 2.875 2.65625C2.875 2.42188 3.08008 2.1875 3.34375 2.1875H15.0625V1.71875C15.0625 0.957031 14.418 0.3125 13.6562 0.3125H2.875C1.82031 0.3125 1 1.16211 1 2.1875V11.5625C1 12.6172 1.82031 13.4375 2.875 13.4375H14.125C15.1504 13.4375 16 12.6172 16 11.5625V5C16 3.97461 15.1504 3.125 14.125 3.125ZM13.1875 9.21875C12.6602 9.21875 12.25 8.80859 12.25 8.28125C12.25 7.7832 12.6602 7.34375 13.1875 7.34375C13.6855 7.34375 14.125 7.7832 14.125 8.28125C14.125 8.80859 13.6855 9.21875 13.1875 9.21875Z"
        fill={fill}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1921_9013"
        x="0"
        y="0.3125"
        width="17"
        height="15.125"
        filterUnits="userSpaceOnUse"
        // colorInterpolationFilters="sRGB"
      >
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1921_9013" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1921_9013"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default WalletIcon
