import { Button, FlexBox, Image, TextField, theme } from '@rario/shared-components'
import Logo from 'components/Logo'
import { getCDNUrl } from 'utils/utils'
import { TextWrapper } from './VpnError.styles'

const VpnErrorPreview = () => {
  return (
    <FlexBox flexDirection={'column'} height={'100vh'} background={theme.colors.richBlackLight}>
      <FlexBox padding={'30px'}>
        <Logo isBeta />
      </FlexBox>
      <FlexBox
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
        padding={'40px'}
        top={'50%'}
        left={'50%'}
        mt="78.99px"
      >
        <Image src={getCDNUrl('/images/VpnErrorBanner.png')} width={'237px'} height="175px" />
        <TextField
          fontWeight="600"
          textTransform="uppercase"
          content="Howzat?!"
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          mt="78px"
          fontSize={'20px'}
          lineHeight={'24px'}
        />

        <TextWrapper
          content="You seem to be using an unblocker or proxy (VPN). Please turn off any of these settings and try and again. If the problem persists,"
          fontSize={['14px', '20px']}
          width={'100%'}
          mt={['12px', '29px']}
        />

        <FlexBox flexWrap={'wrap'} flexDirection="column" justifyContent={'center'}>
          <TextWrapper fontSize={'14px'} lineHeight={'17px'} mr="5px">
            please contact us at
          </TextWrapper>
          <Button
            as={'a'}
            href="mailto:support@rario.com"
            mb={'60px'}
            name="support@rario.com"
            fontSize={'17px'}
            color={theme.colors.mint}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default VpnErrorPreview
