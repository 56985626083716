const CricrushNavIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
  opacity?: number
}> = ({ className = '', width = '25', height = '24', fill = 'white', opacity = 1 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M21.1887 3.54827L18.0767 3.55629L18.0675 3.55631L18.0661 3.55627C18.0659 3.55628 18.0656 3.55629 18.0653 3.5563C18.0596 3.55652 18.0475 3.55721 18.031 3.5593C17.9963 3.56371 17.9525 3.57303 17.9104 3.59039C17.843 3.61822 17.7799 3.6606 17.7503 3.79231L14.7993 18.4489H18.1082L21.0884 4.06809L21.1887 3.54827Z"
      fill="url(#paint0_linear_1634_77444)"
      fillOpacity="0.25"
      stroke={fill}
      strokeWidth="1.09289"
      opacity={opacity}
    />
    <path
      d="M15.5578 3.54827L12.4459 3.55629L12.4367 3.55631L12.4353 3.55627C12.435 3.55628 12.4347 3.55629 12.4344 3.5563C12.4287 3.55652 12.4166 3.55721 12.4001 3.5593C12.3654 3.56371 12.3216 3.57303 12.2795 3.59039C12.2121 3.61822 12.149 3.6606 12.1194 3.79231L9.16841 18.4489H12.4773L15.4576 4.06809L15.5578 3.54827Z"
      fill="url(#paint1_linear_1634_77444)"
      fillOpacity="0.25"
      stroke={fill}
      strokeWidth="1.09289"
      opacity={opacity}
    />
    <path
      d="M10.0569 3.54838L6.9449 3.5564L6.9357 3.55643L6.93432 3.55638C6.93406 3.55639 6.93377 3.5564 6.93347 3.55641C6.92775 3.55663 6.91565 3.55732 6.89916 3.55941C6.86446 3.56382 6.82064 3.57314 6.77859 3.5905C6.7112 3.61833 6.64805 3.66072 6.61845 3.79251L3.6674 18.4536H6.9763L9.95658 4.06858L10.0569 3.54838Z"
      fill="url(#paint2_linear_1634_77444)"
      fillOpacity="0.25"
      stroke={fill}
      strokeWidth="1.09289"
      opacity={opacity}
    />
    <defs>
      <linearGradient
        id="paint0_linear_1634_77444"
        x1="17.9914"
        y1="18.9954"
        x2="17.9914"
        y2="3.00011"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={fill} />
        <stop offset="1" stopColor="#FFF100" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1634_77444"
        x1="12.3605"
        y1="18.9954"
        x2="12.3605"
        y2="3.00011"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={fill} />
        <stop offset="1" stopColor="#FFF100" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1634_77444"
        x1="6.85957"
        y1="19"
        x2="6.85957"
        y2="3.00023"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={fill} />
        <stop offset="1" stopColor={fill} stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default CricrushNavIcon
