import { customStyled, FlexBox, ThemeType } from '@rario/shared-components'
import { PLATFORM_ORIGIN } from 'config'
import { TabBackgroundVariant } from 'utils/utils'
export const HeaderWrapper = customStyled.header<{
  theme?: ThemeType
  top?: string
  pt: number
  zIndex?: number
}>`
  padding-top: ${({ pt = 0 }) => pt}px;
  width: 100%;
  height:70px;
  justify-content:center;
  z-index:${({ zIndex = 99998 }) => zIndex};
  background:transparent;
  display: flex;
  align-items: center;
  position: relative;

  & svg:hover path{
    opacity:1;
}
 & .menu-item:hover .dropdownMenu{
    display:block; 
 }
 & .menu-item:hover .icon{
  display:block;  

 }
& .menu-item:hover a{
  opacity:1;
}
.dropdown-menu{
  opacity:0.8;
}
& .dropdown-menu:hover{
 opacity:1;
}
  @media(max-width:${({ theme }) => theme.breakpoints[1]}){
    display:flex;
    align-items: center;
    z-index:${({ zIndex = 99999 }) => zIndex};
  }
`

export const Sidenav = customStyled(FlexBox)`
  transition: 0.5s;
`
export const BgOverlay = customStyled(FlexBox)<{ opacity?: number; theme?: ThemeType }>`
  height: 100vh;
  width:100%;
  position: absolute;
  max-width:${({ theme }) => theme.maxDesktopLayoutWidth}px;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.whites[3]};
  backdrop-filter: blur(7px);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    
    left: 0%;
    margin-right: -${({ theme }) => Number(theme.breakpoints[0].slice(0, -2)) / 2 + 'px'};
`
export const VaultLineseparator = customStyled.hr<{ margin?: string; theme?: ThemeType }>`
  width:95%;
  margin-top:${(props) => props.margin};
  border-radius: 5px;
  opacity:0.2;
  z-index:-999;
  position:absolute;
  background:${({ theme }) => theme.gradients.primaryLightBorder};
  @media(max-width:${({ theme }) => theme.breakpoints[2]}) {
    display:none;
  }
`
export const TabSeparator = customStyled(FlexBox)<{
  isSelected?: boolean
  theme?: ThemeType
}>`
  border-radius: 6px;
   background-attachment: fixed;
  background-blend-mode: lighten;
  background: radial-gradient(47.8% 54.28% at 89.69% 80.22%, rgba(249, 150, 107, 0.3) 11%,  rgba(251, 151, 108, 0) 142%), rgba(255, 255, 255, 0.04);
  background-clip:padding-box;
  margin-bottom:15px;
 justify-content: center;
 &::before{
  width:228px;
      content:"";
      position:absolute;
      top:-1px;
      right:-1px;
      left:0px;
      bottom:-1px;
      border-radius:7px;
  background-clip:padding-box;

      border:1.2px solid transparent;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box,linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite:exclude;
 }
 
 ${(props) =>
   !props.isSelected
     ? `
     background: radial-gradient(52.26% 66.97% at 93.64% 91.67%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
     &::before{
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.20), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)) border-box;
}`
     : `
    background: ${TabBackgroundVariant(PLATFORM_ORIGIN as string, props.theme).selected_background};
     &::before{
 background: ${TabBackgroundVariant(PLATFORM_ORIGIN as string, props.theme).selected_borderColor};
}`}
     @media (max-width:560px){
       width:220px;
       &::before{
        width:220px;
       }
     }
  `
export const TickerWrapper = customStyled(FlexBox)`
  overflow:hidden;
  position:relative;
  width:100%;
  min-height:56px;
`
export const TextSeparator = customStyled(FlexBox)`
  width:8px;
  height:8px;
  align-self:center;
  margin:0px 30px;
`
export const HomeBannerWrapper = customStyled(FlexBox)`
@media(max-width:375px){
padding-top:10px;
}
`
export const DropIcon = customStyled(FlexBox)<{ isItemOpen: boolean }>`
    transform: ${({ isItemOpen = false }) => (isItemOpen ? 'rotateZ(180deg)' : 'rotateZ(0deg)')};
    transition: transform 0.4s;
`
export const IconWrapper = customStyled(FlexBox)`
    padding: 8px;
    gap: 10px;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.02);
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(9px);
    border-radius: 33px;
    flex-grow: 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
`

export const IconWrapperSquare = customStyled(FlexBox)`
    gap: 10px;
    width: 48px;
    height: 48px;
    background: rgba(255, 255, 255, 0.02);
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(9px);
    border-radius: 3.13px;
    flex-grow: 0;
    border: 0.78px solid #1D2228;
    align-items: center;
    justify-content: center;
`
export const WalletBoxWrapper = customStyled(FlexBox)<{ theme?: ThemeType }>`
background: ${({ theme }) => theme.colors.whites[1]};
box-shadow: ${({ theme }) => `0px 2px 9px ${theme.colors.blacks[4]}`};
backdrop-filter: blur(9px);
border-radius: 33px;
position: relative;
`
