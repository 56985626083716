const TickIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  color?: string
  fill?: string
}> = ({ className, width = '14px', height = '14px', color = 'white', fill = '#36B286' }) => (
  <svg
    width={width}
    className={className}
    height={height}
    viewBox="0 0 14 14"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0H2C0.875 0 0 0.90625 0 2V12C0 13.125 0.875 14 2 14H12C13.0938 14 14 13.125 14 12V2C14 0.90625 13.0938 0 12 0ZM10.5938 5.625L6.59375 9.625C6.4375 9.8125 6.21875 9.875 6 9.875C5.75 9.875 5.53125 9.8125 5.375 9.625L3.375 7.625C3.03125 7.28125 3.03125 6.75 3.375 6.40625C3.71875 6.0625 4.25 6.0625 4.59375 6.40625L6 7.78125L9.375 4.40625C9.71875 4.0625 10.25 4.0625 10.5938 4.40625C10.9375 4.75 10.9375 5.28125 10.5938 5.625Z"
      fill={fill}
    />
  </svg>
)

export default TickIcon
