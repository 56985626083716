import React, { FunctionComponent, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import { API_HEADER_COOKIES, UTM_COOKIES } from 'constant'
import { getAppWebViewDataFromLocalStorage } from 'utils/utils'
import { useCustomUser } from 'contexts/CustomUserProvider'

const Footer: FunctionComponent = () => {
  const router = useRouter()
  const { user } = useCustomUser()

  const [cookies] = useCookies([
    UTM_COOKIES.UTM_SOURCE,
    UTM_COOKIES.UTM_CAMPAIGN,
    UTM_COOKIES.UTM_CONTENT,
    UTM_COOKIES.UTM_MEDIUM,
    UTM_COOKIES.UTM_REF,
    API_HEADER_COOKIES.APP_PLATFORM,
  ])

  useEffect(() => {
    ;(window as any)?.analytics?.page('Page Viewed', {
      rario_visitor: 1,
      platform: 'rario',
      current_path: router?.pathname,
      app_platform: cookies[API_HEADER_COOKIES.APP_PLATFORM],
      utm_source: cookies?.utm_source?.toString(),
      utm_campaign: cookies?.utm_campaign?.toString(),
      utm_content: cookies?.utm_content?.toString(),
      utm_medium: cookies?.utm_medium?.toString(),
      utm_ref: cookies?.utm_ref?.toString(),
      logged_in: user ? 'true' : 'false',
      ...(getAppWebViewDataFromLocalStorage() || {}),
    })
  }, [router?.pathname])

  return <></>
}

export default Footer
