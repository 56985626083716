import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'

import { ThemeType } from '../../theme'
import GlobalStyles from './Glolbal.styles'
import theme from 'shared-components/theme'

export interface Props {
  children?: React.ReactNode | any
  extraTheme?: ThemeType
  cssString?: string
}

const ThemeProvider: React.FunctionComponent<Props> = ({
  children,
  extraTheme = {},
  cssString,
}) => {
  return (
    // <CacheProvider value={createCache({ speedy: true, key: 'test' })}>
    <EmotionThemeProvider theme={{ ...theme, ...extraTheme }}>
      <GlobalStyles cssString={cssString} />
      {children}
    </EmotionThemeProvider>
    // </CacheProvider>
  )
}

export default ThemeProvider
